import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/financeiro",
    name: "p-financial",
    redirect: { name: "p-financial-overview" },
    component: () => import("@/views/financial/index.vue"),
    children: [
      {
        path: "/dashboard/financeiro/geral",
        name: "p-financial-overview",
        redirect: { name: "p-financial-payment-list" },
        component: () => import("@/views/financial/overview.vue"),
        children: [
          {
            path: "/dashboard/financeiro/geral/pagamentos",
            name: "p-financial-payment-list",
            component: () => import("@/views/financial/payment/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/geral/pagamentos/cadastrar",
                name: "p-financial-payment-create",
                component: () => import("@/views/financial/payment/form.vue"),
              },
              {
                path: "/dashboard/financeiro/geral/pagamentos/:id/editar",
                name: "p-financial-payment-edit",
                component: () => import("@/views/financial/payment/form.vue"),
              },
            ],
          },
          {
            path: "/dashboard/financeiro/geral/recebimentos",
            name: "p-financial-receipt-list",
            component: () => import("@/views/financial/receipt/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/geral/recebimentos/cadastrar",
                name: "p-financial-receipt-create",
                component: () => import("@/views/financial/receipt/form.vue"),
              },
              {
                path: "/dashboard/financeiro/geral/recebimentos/:id/editar",
                name: "p-financial-receipt-edit",
                component: () => import("@/views/financial/receipt/form.vue"),
              },
            ],
          },
          {
            path: "/dashboard/financeiro/geral/transferencias",
            name: "p-financial-transfer-list",
            component: () => import("@/views/financial/transfer/list.vue"),
            children: [
              {
                path: "/dashboard/financeiro/geral/transferencias/cadastrar",
                name: "p-financial-transfer-create",
                component: () => import("@/views/financial/transfer/form.vue"),
              },
              {
                path: "/dashboard/financeiro/geral/transferencias/:id/editar",
                name: "p-financial-transfer-edit",
                component: () => import("@/views/financial/transfer/form.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/empresas",
        name: "p-financial-company-list",
        component: () => import("@/views/financial/company/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/empresas/cadastrar",
            name: "p-financial-company-create",
            component: () => import("@/views/financial/company/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/empresas/:id/editar",
            name: "p-financial-company-edit",
            component: () => import("@/views/financial/company/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/prestadores-de-servicos",
        name: "p-financial-provider-list",
        component: () => import("@/views/financial/provider/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/prestadores-de-servicos/cadastrar",
            name: "p-financial-provider-create",
            component: () => import("@/views/financial/provider/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/prestadores-de-servicos/:id/editar",
            name: "p-financial-provider-edit",
            component: () => import("@/views/financial/provider/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/parceiros",
        name: "p-financial-partner-list",
        component: () => import("@/views/financial/partner/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/parceiros/cadastrar",
            name: "p-financial-partner-create",
            component: () => import("@/views/financial/partner/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/parceiros/:id/editar",
            name: "p-financial-partner-edit",
            component: () => import("@/views/financial/partner/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/fornecedores/clientes",
        name: "p-financial-customer-list",
        component: () => import("@/views/financial/customer/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/fornecedores/clientes/cadastrar",
            name: "p-financial-customer-create",
            component: () => import("@/views/financial/customer/form.vue"),
          },
          {
            path: "/dashboard/financeiro/fornecedores/clientes/:id/editar",
            name: "p-financial-customer-edit",
            component: () => import("@/views/financial/customer/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/contas-bancarias",
        name: "p-financial-bank-list",
        component: () => import("@/views/financial/bank/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/contas-bancarias/cadastrar",
            name: "p-financial-bank-create",
            component: () => import("@/views/financial/bank/form.vue"),
          },
          {
            path: "/dashboard/financeiro/contas-bancarias/:id/editar",
            name: "p-financial-bank-edit",
            component: () => import("@/views/financial/bank/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/estabelecimentos",
        name: "p-financial-sector-list",
        component: () => import("@/views/financial/sector/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/estabelecimentos/cadastrar",
            name: "p-financial-sector-create",
            component: () => import("@/views/financial/sector/form.vue"),
          },
          {
            path: "/dashboard/financeiro/estabelecimentos/:id/editar",
            name: "p-financial-sector-edit",
            component: () => import("@/views/financial/sector/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/categorias",
        name: "p-financial-category-list",
        component: () => import("@/views/financial/category/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/categorias/cadastrar",
            name: "p-financial-category-create",
            component: () => import("@/views/financial/category/form.vue"),
          },
          {
            path: "/dashboard/financeiro/categorias/:id/editar",
            name: "p-financial-category-edit",
            component: () => import("@/views/financial/category/form.vue"),
          },
        ],
      },
      {
        path: "/dashboard/financeiro/retencoes",
        name: "p-financial-retention-list",
        component: () => import("@/views/financial/retention/list.vue"),
        children: [
          {
            path: "/dashboard/financeiro/retencoes/cadastrar",
            name: "p-financial-retention-create",
            component: () => import("@/views/financial/retention/form.vue"),
          },
          {
            path: "/dashboard/financeiro/retencoes/:id/editar",
            name: "p-financial-retention-edit",
            component: () => import("@/views/financial/retention/form.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
