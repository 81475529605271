<template>
  <div class="form c-modal-refresh-token">
    <c-form-field-template class="password">
      <template #label-container>
        <label for="refresh-token-password">Sua Senha</label>
      </template>
      <p-input-password
        v-model="password"
        id="refresh-token-password"
        autocomplete="current-password"
        :feedback="false"
        required
      ></p-input-password>
    </c-form-field-template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate } })
export default class CModalRefreshToken extends Vue {
  @Prop({ required: true }) readonly props!: any;
  password = "";

  getFields() {
    return { password: this.password };
  }

  reset() {
    this.password = "";
  }
}
</script>

<style lang="scss" scoped>
.c-modal-refresh-token {
  .c-form-field-template {
    width: 100%;
    max-width: 280px;
  }
}
</style>
