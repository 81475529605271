import gojus from "@/services/gojus";
import { cd_owner, cd_owner_key, GFile } from "@/store/file/types";

const { VUE_APP_API_FILE_URL } = process.env;

export class FileService {
  fileEndpoint = "arquivos/";
  fileCDEndpoint = (cd: number) => `arquivos/${cd}/`;
  fileIncrementDownloadEndpoint = (cd: number) => `arquivos/${cd}/incremente-download/`;

  async getAll(cd_owner: cd_owner, cd_empresa?: number) {
    const response = await gojus.get<GFile[]>({
      entity: this.fileEndpoint,
      config: { query: { ...cd_owner, cd_empresa }, baseAPI: VUE_APP_API_FILE_URL },
    });

    return response;
  }

  async create(file: File, cd: number, key: cd_owner_key, cd_empresa?: number) {
    const response = await gojus.post<string, any>({
      entity: this.fileEndpoint,
      data: this.handleFile(file, cd, key),
      config: { query: { cd_empresa }, baseAPI: VUE_APP_API_FILE_URL },
    });

    return response;
  }

  async incrementDownload(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const response = await gojus.patch({
      entity: this.fileIncrementDownloadEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FILE_URL, query },
    });
    return response;
  }

  async delete(cd: number, cd_owner: cd_owner, cd_empresa?: number) {
    const response = await gojus.delete({
      entity: this.fileCDEndpoint(cd),
      config: {
        query: { ...cd_owner, cd_empresa },
        baseAPI: VUE_APP_API_FILE_URL,
      },
    });

    return response;
  }

  handleFile(file: File, cd: number, key: string) {
    const data = new FormData();

    data.append("aq_arquivo", file, file.name);
    data.append("qtd_clique", "0");
    data.append("qtd_download", "0");
    data.append(key, cd.toString());
    data.append("cd_estabelecimento", localStorage.getItem("CD_EMPRESA") || "");

    return data;
  }
}

export default FileService;
