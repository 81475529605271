import gojus from "@/services/gojus";

import { LawsuitPartner, LawsuitPartnerForm } from "@/store/lawsuit/partner/types";

import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class LawsuitPartnerService {
  endpoint = "parceiro/";
  cdEndpoint = (cd: number) => `parceiro/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<LawsuitPartner>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return { data };
  }

  async getAll(pagination: PaginationParams | null, filters?: any) {
    const response = await gojus.get<PaginationResponse<LawsuitPartner>>({
      entity: this.endpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    return response;
  }

  async create(form: LawsuitPartnerForm) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: this.handle(form),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return { data };
  }

  async edit(cd: number, form: LawsuitPartnerForm) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: this.handle(form),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return { data };
  }

  handle(partner: any): LawsuitPartner {
    return partner;
  }
}

export default LawsuitPartnerService;
