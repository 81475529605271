import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import CompanyService from "@/store/company/service";
import { Company, LawFirmRegisterForm } from "@/store/company/types";

@Module({ namespaced: true })
export class CompanyModule extends VuexModule {
  current: Company = { pk: 0 };
  all: Company[] = [];
  office: any | null = null;
  service = new CompanyService();

  @Mutation
  updateCurrent(company: Company) {
    if (company.pk === undefined) {
      if (this.all.length === 0) return;

      const cd_empresa = localStorage.getItem("CD_EMPRESA");
      company = this.all.find(({ pk }) => pk?.toString() === cd_empresa) || this.all[0];
    }

    this.current = company;
    localStorage.setItem("CD_EMPRESA", String(company.pk));
    localStorage.setItem("OFFICE_NAME", String(company.nm_fantasia || company.nm_nome_fantasia));
  }

  @Mutation
  updateCompanies(companies: Company[]) {
    this.all = companies;
  }

  @Mutation
  updateOffice(office: any | null) {
    this.office = office;
  }

  @Action
  async getAll() {
    try {
      const { data } = await this.service.getAll();
      this.context.commit("updateCompanies", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getOffice() {
    try {
      if (this.office?.id === Number(this.current.pk)) {
        return this.office;
      }

      const { data } = await this.service.getOffice(Number(this.current.pk));
      this.context.commit("updateOffice", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async register(payload: { form: LawFirmRegisterForm }) {
    try {
      const response = await this.service.register(payload.form);
      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default CompanyModule;
