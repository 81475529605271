<template>
  <c-form-template class="c-task-responsible" :useFooter="false" :isFlex="true">
    <c-form-field-template width="100%">
      <template #label>Selecione a Equipe</template>
      <p-dropdown
        class="team-update-dropdown"
        :value="(team || {}).id || null"
        :options="teams"
        optionLabel="nm_equipe"
        optionValue="id"
        @input="handleTeamChange"
      />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { Team } from "@/store/team/types";

@Component({ components: { CFormTemplate, CFormFieldTemplate } })
export default class CModalTaskTeam extends Vue {
  @Prop({ required: true }) readonly props!: { team?: Team | null };
  team: Team | null = null;

  get teams(): Team[] {
    return this.$store.state.department.responsible.all;
  }

  handleTeamChange(teamId: number) {
    this.team = this.teams.find((team) => team.id === teamId) || null;
  }

  getFields(): { cd_equipe: number; nm_equipe: string } | null {
    return this.team?.id ? { cd_equipe: this.team.id, nm_equipe: this.team.nm_equipe || "" } : null;
  }

  reset() {}

  created() {
    this.team = this.props.team || null;
    if (!this.teams.length) this.$store.dispatch("department/responsible/getAll");
  }
}
</script>
