import gojus from "@/services/gojus";

import { BankAccount, BankAccountForm } from "@/store/financial/bank-account/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class BankAccountService {
  endpoint = "contas-bancarias/";
  cdEndpoint = (cd: number) => `contas-bancarias/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<BankAccount>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<BankAccount[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getPaginated(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<BankAccount>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async create(bankAccount: BankAccountForm) {
    const response = await gojus.post<BankAccount, BankAccountForm>({
      entity: this.endpoint,
      data: bankAccount,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async edit(cd: number, bankAccount: BankAccountForm) {
    const response = await gojus.patch<BankAccount, BankAccountForm>({
      entity: this.cdEndpoint(cd),
      data: bankAccount,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });
    return response;
  }
}

export default BankAccountService;
