<template>
  <c-form-template class="c-modal-pauta" :useFooter="false">
    <c-form-field-template width="100%">
      <template #label>Filtrar Data da Audiência</template>
      <p-dropdown
        v-model="filtro_dt_audiencia"
        :options="dateOptions"
        optionLabel="label"
        optionValue="value"
        required
      />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Data da Audiência</template>
      <p-calendar
        v-model="dt_audiencia"
        dateFormat="dd/mm/yy"
        :locale="calendarLocale"
        :selectionMode="cadastroSelectionMode"
        required
      />
    </c-form-field-template>

    <footer class="options">
      <p-button class="p-button-danger option" icon="fas fa-times-circle" label="Cancelar" @click="onAskingToCancel" />

      <p-button v-if="loading.items" class="p-button-success option" disabled>
        <p-progress-spinner strokeWidth="8" animationDuration="0.8s" />
        <span>Aguarde</span>
      </p-button>
      <p-button
        v-else
        class="p-button-success option"
        icon="fas fa-download"
        label="Baixar"
        :disabled="!selectedItems"
        @click="onAskingToDownload"
      />
    </footer>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import Listbox from "primevue/listbox";
import PSlider from "primevue/slider/Slider.vue";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import { calendarConfig, formatDate, formatDateEn } from "@/utils/formatters";
import { handleExportXLSX } from "@/plugins/excellentexport";

const PListbox: any = Listbox;

@Component({
  components: {
    CFormTemplate,
    CFormFieldTemplate,
    PListbox,
    PSlider,
    PProgressSpinner,
  },
})
export default class CModalPauta extends Vue {
  @Prop({ required: true }) readonly props!: any;

  loading = { items: false };

  dt_audiencia = "";
  filtro_dt_audiencia = "nd";

  headers: string[] = [];
  content: string[][] = [];

  items: any[] = [];
  totalItems = 0;
  selectedItems = 0;

  handleExportXLSX = handleExportXLSX;
  formatDate = formatDate;

  get cadastroSelectionMode() {
    if (this.filtro_dt_audiencia === "ni") return "range";
    return "single";
  }

  get calendarLocale() {
    return calendarConfig;
  }

  get dateOptions() {
    return [
      { label: "Na Data", value: "nd" },
      { label: "No Intervalo", value: "ni" },
    ];
  }

  getFields() {
    const fields: any = { filtro_dt_audiencia: this.filtro_dt_audiencia };

    if (this.dt_audiencia) {
      if (Array.isArray(this.dt_audiencia)) {
        fields.dt_audiencia_inicial = formatDateEn(this.dt_audiencia[0]);
        fields.dt_audiencia_final = formatDateEn(this.dt_audiencia[1]);
      } else fields.dt_audiencia_inicial = formatDateEn(this.dt_audiencia);
    }

    fields.ie_situacao = ["CD", "CT", "CO", "CF", "RE"];

    return fields;
  }

  reset() {
    this.dt_audiencia = "";
    this.filtro_dt_audiencia = "nd";
  }

  async onAskingToDownload() {
    if (this.filtro_dt_audiencia === "ni") {
      const { data } = await this.$store.state.hearing.service.getPauta({
        dt_audiencia_inicial: formatDateEn(this.dt_audiencia[0]),
        dt_audiencia_final: formatDateEn(this.dt_audiencia[1]),
        filtro: this.filtro_dt_audiencia,
      });
      window.open(data.link);
    } else {
      const { data } = await this.$store.state.hearing.service.getPauta({
        dt_audiencia_inicial: formatDateEn(this.dt_audiencia),
        dt_audiencia_final: formatDateEn(this.dt_audiencia),
        filtro: this.filtro_dt_audiencia,
      });
      window.open(data.link);
    }
  }

  onAskingToCancel() {
    this.$store.commit("global/updateModalStatus", false);
  }

  @Watch("dt_audiencia")
  @Watch("filtro_dt_audiencia")
  async updateItems() {
    const filters = this.getFields();

    if (!filters.dt_audiencia_inicial || (this.filtro_dt_audiencia === "ni" && !filters.dt_audiencia_final)) {
      this.items = [];
      this.totalItems = 0;
      this.selectedItems = 0;

      return;
    }
    this.loading.items = true;

    const { data } = await this.$store.state.hearing.service.getAll(
      { nr_por_pagina: 1, page: 1, paginacao: true },
      filters
    );
    this.items = data.results;
    this.totalItems = data.count;
    this.selectedItems = this.totalItems;

    this.loading.items = false;
  }

  created() {
    this.items = this.props?.items || [];
  }
}
</script>

<style lang="scss">
.p-dialog.modal-pauta .p-dialog-content {
  padding-bottom: 1.1rem;
}

.c-modal-pauta {
  padding: 0 !important;
  max-width: 540px;

  font-size: 0.4rem !important;

  .p-calendar {
    .p-datepicker {
      position: absolute;
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;

    .option {
      padding: 0.6rem 0.8rem;
      margin: 0;

      + .option {
        margin-left: 8px;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
