import gojus from "@/services/gojus";
import { Forum } from "@/store/forum/types";

const { VUE_APP_API_BASE_URL } = process.env;

export class ForumService {
  endpoint = "foros/";
  cdEndpoint = (cd: number) => `foros/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<Forum>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_BASE_URL },
    });

    return { data };
  }

  async getAll(filters: any = {}) {
    const { data } = await gojus.get<Forum[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_BASE_URL },
    });

    return { data };
  }

  async create(forum: Forum) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: forum,
      config: { baseAPI: VUE_APP_API_BASE_URL },
    });

    return { data };
  }

  async edit(cd: number, forum: Forum) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: forum,
      config: { baseAPI: VUE_APP_API_BASE_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_BASE_URL },
    });

    return { data };
  }
}

export default ForumService;
