<template>
  <c-form-template class="c-end-lawsuit-modal" :isFlex="true" @click-back="$emit('click-back')" @submit="handleSubmit">
    <c-form-field-template width="calc(100% - 160px)">
      <template #label>Motivo do Encerramento</template>
      <p-dropdown
        v-model="cd_motivo_encerramento"
        :options="reasonsForProcessClosing"
        optionLabel="nm_motivo"
        optionValue="id"
        placeholder="Digite para adicionar um novo motivo..."
        editable
        required
      />
    </c-form-field-template>

    <c-form-field-template width="160px">
      <template #label>Data Encerramento</template>
      <p-calendar v-model="dt_enceramento" dateFormat="dd/mm/yy" :minDate="new Date()" :locale="calendarConfig" />
    </c-form-field-template>

    <c-form-field-template class="nr_valor_pago_processo" width="50%">
      <template #label>Valor Pago</template>
      <p-input-text v-model="nr_valor_pago_processo" v-money="moneyMask" v-if="isMounted" :autoResize="true" rows="5" />
    </c-form-field-template>

    <c-form-field-template class="nr_valor_pago_recebido" width="50%">
      <template #label>Valor Recebido</template>
      <p-input-text v-model="nr_valor_pago_recebido" v-money="moneyMask" v-if="isMounted" :autoResize="true" rows="5" />
    </c-form-field-template>

    <c-form-field-template class="ds_motivo_encerramento" width="100%">
      <template #label>Observações</template>
      <p-textarea v-model="ds_motivo_encerramento" :autoResize="true" rows="5" />
    </c-form-field-template>

    <template #submit-text>Confirmar</template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn } from "@/utils/formatters";
import moneyMask from "@/plugins/vmoney";
import { removeMoneyMaskAndConvert } from "@/utils/mask";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CTextAreaModal extends Vue {
  @Prop({ required: true }) readonly props!: any;

  calendarConfig = calendarConfig;

  dt_enceramento = formatDate(new Date().toString());
  ds_motivo_encerramento = "";
  cd_motivo_encerramento: string | number | null = null;

  nr_valor_pago_processo = "";
  nr_valor_pago_recebido = "";

  moneyMask = moneyMask;
  isMounted = false;

  get reasonsForProcessClosing(): any[] {
    return this.$store.state.lawsuit.reasonsForProcessClosing;
  }

  async handleSubmit() {
    if (typeof this.cd_motivo_encerramento === "string") {
      const nm_motivo = this.cd_motivo_encerramento?.toUpperCase().trim();
      const motivo_encerramento = this.reasonsForProcessClosing.find(
        (item) => item.nm_motivo.toUpperCase() === nm_motivo
      );

      if (motivo_encerramento) this.cd_motivo_encerramento = motivo_encerramento.id;
      else {
        const response = await this.$store.dispatch("lawsuit/createReasonForProcessClosing", { form: { nm_motivo } });
        this.cd_motivo_encerramento = response?.data?.id || null;
      }
    }

    this.$emit("submit");
  }

  getFields() {
    return {
      dt_enceramento: formatDateEn(this.dt_enceramento),
      ds_motivo_encerramento: this.ds_motivo_encerramento,
      cd_motivo_encerramento: this.cd_motivo_encerramento,
      nr_valor_pago_processo: removeMoneyMaskAndConvert(this.nr_valor_pago_processo),
      nr_valor_pago_recebido: removeMoneyMaskAndConvert(this.nr_valor_pago_recebido),
    };
  }

  reset() {
    this.dt_enceramento = "";
    this.ds_motivo_encerramento = "";
    this.cd_motivo_encerramento = null;
  }

  mounted() {
    this.isMounted = true;
    this.$store.dispatch("lawsuit/getAllReasonForProcessClosing");
    this.nr_valor_pago_recebido = Number(this.props.nr_valor_pago_recebido || 0).toFixed(2);
  }
}
</script>

<style lang="scss">
.p-dialog.end-lawsuit {
  width: 94vw;
  max-width: 520px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;

    .c-form-field-template.ds_motivo_encerramento .p-inputtextarea {
      // height: 78vh;
      overflow-y: auto !important;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #0000004d;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #3273dc;
      }
    }

    .p-calendar {
      .p-datepicker {
        position: absolute;
      }

      .p-inputtext {
        padding: 0.6rem !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
