import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Forum, ForumParams } from "@/store/forum/types";

import { ForumService } from "@/store/forum/service";

@Module({ namespaced: true })
export class ForumModule extends VuexModule {
  all: Forum[] = [];
  service = new ForumService();

  @Mutation
  updateForum() {}

  @Mutation
  updateForums(forums: Forum[]) {
    this.all = forums;
  }

  @Action
  async get(params: ForumParams) {
    try {
      const response = await this.service.get(params.cd);
      this.context.commit("updateForum");

      if (params.getCity && response.data.cd_cidade)
        response.data.ob_cidade = await this.context.dispatch(
          "location/getCity",
          { cd_cidade: response.data.cd_cidade },
          { root: true }
        );

      return response.data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload?: { getCity?: boolean }) {
    try {
      const response = await this.service.getAll();

      const promises: any[] = [];
      for (const forum of response.data || []) {
        forum.ob_cidade = null;
        if (payload?.getCity && forum.cd_cidade)
          promises.push(
            (async () =>
              (forum.ob_cidade = await this.context.dispatch(
                "location/getCity",
                { cd_cidade: forum.cd_cidade },
                { root: true }
              )))()
          );
      }

      await Promise.allSettled(promises);
      this.context.commit("updateForums", response?.data);

      return response?.data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: ForumParams) {
    try {
      const { data } = await this.service.create(params.forum);
      this.context.commit("updateForum");

      const message: ToastParams[] = [{ severity: "success", summary: "Foro cadastrado com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: ForumParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.forum);
      this.context.commit("updateForum");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Foro editado com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: ForumParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateForum");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Foro excluído com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ForumModule;
