import { TaskFormType, TaskOriginModule } from "@/store/task/types";

type Acronyms = { label: string; value: string; color?: string };
const acronyms: Acronyms = { label: "", value: "", color: "" };

export const situations = [
  { label: "Cadastrada", value: "CD", color: "cd" },
  { label: "Cancelada", value: "CA", color: "ca" },
  { label: "Contratada", value: "CT", color: "ct" },
  { label: "Confirmada", value: "CO", color: "co" },
  { label: "Conferência", value: "CF", color: "cf" },
  { label: "Concluída", value: "CL", color: "cl" },
  { label: "Reaberta", value: "RE", color: "re" },
];

export const PROCESSUAL = 5;

export function getSituation(value?: string): Acronyms {
  return situations.find((situation) => situation.value === value) || acronyms;
}

export const activeStatus = [
  { label: "Ativo", value: true, color: "success-color" },
  { label: "Inativo", value: false, color: "danger-color" },
];

export function getActiveStatus(value?: boolean) {
  return activeStatus.find((status) => status.value === value) || acronyms;
}

export const chargeTypes = [
  { label: "Mensal", value: "ME" },
  { label: "Semanal", value: "SE" },
];

export const customerTypes = [
  { label: "Pessoa Física", value: "PF" },
  { label: "Pessoa Jurídica", value: "PJ" },
];

export const serviceTypes = [
  { label: "Audiência", value: "AU" },
  { label: "Diligência", value: "DI" },
];

export const debtorTypes = [
  { label: "Pessoa Física", value: true },
  { label: "Pessoa Jurídica", value: false },
];

export function getServiceType(serviceType: string) {
  return serviceTypes.find((item) => item.value === serviceType) || acronyms;
}

export const actTypes = [
  { label: "Audiência", value: "AU" },
  { label: "Diligência", value: "DI" },
];

export const checkMoveStatus = [
  { label: "Verificadas", value: true },
  { label: "Não Verificada", value: false },
  { label: "Todas", value: "null" },
];

export function getCheckMoveStatus(value?: boolean) {
  return checkMoveStatus.find((item) => item.value === value) || { label: "", value: "null" };
}

export function getLogisticsActType(acttype: string) {
  return actTypes.find((item) => item.value === acttype) || acronyms;
}

export const responsible = [
  { label: "Advogado", value: "AD" },
  { label: "Preposto", value: "PR" },
  { label: "Advogado e Preposto", value: "PA" },
];

export function getResponsible(value?: string) {
  return responsible.find((item) => item.value === value) || acronyms;
}

export function getCorrespondentType(value: string, label?: string) {
  return correspondentTypes.find((item) => item.value === value || item.label === label) || { value, label: value };
}

export const dateFilters = [
  { label: "Na Data", value: "nd" },
  { label: "No Intervalo", value: "ni" },
  { label: "Fora do Intervalo", value: "fi" },
  { label: "Antes de", value: "ad" },
  { label: "Depois de", value: "dd" },
];

export function getDateFilter(value: string, label?: string) {
  return dateFilters.find((item) => item.value === value || item.label === label);
}

export const envs = [
  { label: "Desenvolvimento", value: "development" },
  { label: "Produção", value: "prod" },
  { label: "Homologação", value: "staging" },
];

export function getEnv() {
  const { VUE_APP_MODE } = process.env;
  return envs.find((item) => item.value === VUE_APP_MODE) || envs[0];
}

export const lawsuitRisks = [
  { label: "Remota", value: "RE" },
  { label: "Provável", value: "PR" },
  { label: "Possível", value: "PO" },
];

export function getLawsuitRisk(value: string) {
  return lawsuitRisks.find((item) => item.value === value);
}

export const customerPositions = [
  { label: "Ativo", value: "A", color: "success-color" },
  { label: "Passivo", value: "P", color: "secondary-color" },
];

export function getLogisticsCustomerPosition(value?: string) {
  return customerPositions.find((item) => item.value === value) || acronyms;
}

export function getPaymentMethod(value: string) {
  return paymentMethods.find((item) => item.value === value) || acronyms;
}

export const typeOfAgreements = [
  { label: "Judicial", value: "J" },
  { label: "Extrajudicial", value: "E" },
];

export function getTypeOfAgreement(value?: string) {
  return typeOfAgreements.find((item) => item.value === value) || acronyms;
}

export const agreementOrigin = [
  { label: "Audiência", value: "A" },
  { label: "Interno", value: "I" },
  { label: "Externo", value: "E" },
];

export function getAgreementOrigin(value: string) {
  return agreementOrigin.find((item) => item.value === value) || acronyms;
}

export const priorities = [
  { label: "Normal", value: "NO" },
  { label: "Alta", value: "AL" },
];

export function getPriority(value: string) {
  return priorities.find((item) => item.value === value) || acronyms;
}

export const lawsuitTypes = [
  { label: "Judicial", value: true, color: "warning-color" },
  { label: "Extrajudicial", value: false, color: "tertiary-color" },
];

export function getLawsuitType(value?: boolean) {
  return lawsuitTypes.find((item) => item.value === value) || acronyms;
}

export const booleanTypes = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export function getBooleanTypes(value: boolean) {
  return booleanTypes.find((item) => item.value === value) || acronyms;
}

export const filterBooleanTypes = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
  { label: "Todos", value: null },
];

export function getFilterBooleanTypes(value: boolean | null) {
  return filterBooleanTypes.find((item) => item.value === value);
}

export const typeOfLawyer = [
  { label: "Advogado Particular", value: "AP" },
  { label: "Defensoria Pública", value: "DP" },
  { label: "Causa Própria", value: "CP" },
  { label: "Sem Advogado", value: "SA" },
];

export function getTypeOfLawyer(value?: string) {
  return typeOfLawyer.find((item) => item.value === value) || acronyms;
}

export function getTaskStatus(value: string) {
  return taskStatus.find((item) => item.value === value) || acronyms;
}

export const activeLawsuitStatus = [
  { label: "Ativo", value: "AT", color: "success-color" },
  { label: "Inativo", value: "IN", color: "danger-color" },
];

export function getActiveLawsuitStatus(value?: "AT" | "IN"): Acronyms {
  return activeLawsuitStatus.find((status) => status.value === value) || acronyms;
}

export const genderTypes = [
  { label: "Masculino", value: "M" },
  { label: "Feminino", value: "F" },
  { label: "Outro", value: "N" },
];

export function getGenderType(value: "M" | "F" | "N"): Acronyms {
  return genderTypes.find((status) => status.value === value) || genderTypes[0];
}

export const personTypes = [
  { label: "Pessoa Física", value: true },
  { label: "Pessoa Jurídica", value: false },
];

export function getPersonTypes(value: boolean) {
  return personTypes.find((item) => item.value === value) || false;
}

export const genre = [
  { label: "Masculino", value: "N" },
  { label: "Feminino", value: "F" },
  { label: "Não Binário", value: "S" },
];

export function getGenre(value: "N" | "S" | "F"): Acronyms {
  return genre.find((genre) => genre.value === value) || genre[0];
}

export const maritalStatus = [
  { label: "Casado(a)", value: "CA" },
  { label: "Solteiro(a)", value: "SO" },
];

export function getMaritalStatus(value: "CA" | "SO"): Acronyms {
  return maritalStatus.find((status) => status.value === value) || maritalStatus[0];
}

export const debtorStatus = [
  { label: "Em Aberto", icon: "fas fa-envelope-open-text", value: "EA", color: "ea" },
  {
    label: "Encerrado",
    icon: "fas fa-window-close",
    subStatus: [
      { label: "Débito Baixado", icon: "fas fa-arrow-down", value: "DB", color: "en" },
      { label: "Débito Cancelado", icon: "fas fa-times", value: "DC", color: "en" },
      { label: "Acordo com Pagamentos à Vista", icon: "fas fa-coins", value: "PV", color: "en" },
      { label: "Parcelamento Concluído", icon: "fas fa-check-circle", value: "PC", color: "en" },
      { label: "Desistência", icon: "fas fa-times-circle", value: "DE", color: "en" },
    ],
  },
  { label: "Cobrança Suspensa", icon: "fas fa-gavel", value: "SU", color: "su" },
  { label: "Acordos de Terceiros", icon: "fas fa-hands-helping", value: "AC", color: "ac" },
  {
    label: "Negociação",
    icon: "fas fa-briefcase",
    subStatus: [
      { label: "Negociação Pendente", icon: "fas fa-spinner", value: "NP", color: "warning-color" },
      { label: "Negociação Iniciada", icon: "fas fa-play-circle", value: "NI", color: "ni" },
      { label: "Negociação Concluída", icon: "fas fa-check-circle", value: "NC", color: "success-color" },
      { label: "Negociação Alvo", icon: "fas fa-bullseye", value: "AL", color: "secondary-color" },
      { label: "Sem Interesse", icon: "far fa-angry", value: "SI", color: "si" },
      {
        label: "Inviabilidade",
        icon: "fas fa-times",
        subStatus: [
          { label: "Proposta Não Aceita", icon: "fas fa-handshake-alt-slash", value: "IP", color: "iv" },
          { label: "Contraproposta Incompatível", icon: "fas fa-balance-scale-left", value: "IC", color: "iv" },
        ],
      },
      { label: "Sem Contato", icon: "fas fa-comment-slash", value: "SC", color: "sc" },
    ],
  },
];

export const debtorPriorities = [
  { label: "Alta", icon: "fas fa-angle-up", value: "PA", color: "pa" },
  { label: "Média", icon: "fas fa-bars", value: "PM", color: "pm" },
  { label: "Baixa", icon: "fas fa-angle-down", value: "PB", color: "pb" },
];

export function getDebtorStatus(value: any | null) {
  if (!value) return acronyms;
  if (value === "AC") return { label: "Acordo", icon: "fas fa-hands-helping", value: "AC", color: "primary-color" };

  for (const status of debtorStatus) {
    if (status.subStatus)
      for (const item of status.subStatus) {
        if (item.subStatus) {
          for (const subItems of item.subStatus) {
            if (subItems.value === value) return subItems;
          }
        }
        if (item.value === value) return item;
      }
    else if (status.value === value) return status;
  }

  return acronyms;
}

export const periodicityTypes = [
  { label: "Quinzenal", value: "QU" },
  { label: "Mensal", value: "ME" },
  { label: "Bimestral", value: "BI" },
  { label: "Trimestral", value: "TR" },
  { label: "Semestral", value: "SE" },
  { label: "Anual", value: "AN" },
];

export function getPeriodicityTypes(value: string): Acronyms {
  return periodicityTypes.find((status) => status.value === value) || acronyms;
}

export const feesTypes = [
  { label: "Fixo", value: "FI" },
  { label: "Processo Ativo", value: "PA" },
  { label: "Remuneração por Entrada", value: "RE" },
  { label: "Remuneração por Saída", value: "RS" },
];

export function getFeesTypes(value: string): Acronyms {
  return feesTypes.find((status) => status.value === value) || acronyms;
}

export const relationTypes = [
  { label: "Relacionado", value: "RE" },
  { label: "Apenso", value: "AP" },
  { label: "Principal", value: "PR" },
];

export function getRelationTypes(value: any): Acronyms {
  return relationTypes.find((status) => status.value === value) || acronyms;
}

export const relationFilterTypes = [
  { label: "Todos", value: null },
  { label: "Principais", value: "PR" },
  { label: "Apensos", value: "AP" },
  { label: "Relacionados", value: "RE" },
  { label: "Principais, Apensos e Relacionados", value: "PRR" },
];

export const lawsuitCustomerContractTypes = [
  { label: "Dias", value: "D" },
  { label: "Meses", value: "M" },
  { label: "Anos", value: "A" },
  { label: "Tempo Indeterminado", value: "I" },
];

export function getLawsuitCustomerContractTypes(value: any): Acronyms {
  return lawsuitCustomerContractTypes.find((status) => status.value === value) || acronyms;
}

export const taskOriginModules: { label: string; value: TaskOriginModule; module: TaskFormType }[] = [
  { label: "Cobrança", value: "CO", module: "debtor" },
  { label: "Andamentos", value: "AN", module: "movement" },
  { label: "Expediente", value: "EX", module: "expedient" },
  { label: "Processo", value: "JU", module: "lawsuit" },
  { label: "Publicação", value: "PU", module: "publication" },
  { label: "Administrativa", value: "AD", module: "administrative" },
  { label: "Tarefa", value: "TA", module: "standalone" },
];

export function getTaskOriginModules(valueOrModule: TaskOriginModule | TaskFormType) {
  return taskOriginModules.find((status) => status.value === valueOrModule || status.module === valueOrModule) || null;
}

// =============================

export type AcronymsT<T = unknown> = T | null;

export const booleanAcronyms = [
  { label: "Sim", value: true, color: "#0cbe39" },
  { label: "Não", value: false, color: "#f14668" },
];

export type BooleanAcronymsT = AcronymsT<(typeof booleanAcronyms)[0]>;

export function getBooleanAcronym(value: boolean): BooleanAcronymsT {
  return booleanAcronyms.find((item) => item.value === value) || null;
}

export const financialStatusAcronyms = [
  { label: "Efetuado", value: true, color: "#0cbe39" },
  { label: "Pendente", value: false, color: "#f14668" },
];

export type PaymentStatusAcronymT = AcronymsT<(typeof financialStatusAcronyms)[0]>;

export function getFinancialStatusAcronym(value: boolean): PaymentStatusAcronymT {
  return financialStatusAcronyms.find((item) => item.value === value) || null;
}

export const activeAcronyms = [
  { label: "Ativo", value: true, color: "#0cbe39" },
  { label: "Inativo", value: false, color: "#f14668" },
];

export type ActiveAcronymsT = AcronymsT<(typeof activeAcronyms)[0]>;

export function getActiveAcronym(value: boolean): ActiveAcronymsT {
  return activeAcronyms.find((item) => item.value === value) || null;
}

export const correspondentTypes = [
  { label: "Correspondente", value: "CO" },
  { label: "Interno", value: "IN" },
  { label: "Preposto", value: "PR" },
  { label: "Advogado", value: "AD" },
  { label: "Todos", value: "TO" },
];

export type CorrespondentTypeAcronymsT = AcronymsT<(typeof correspondentTypes)[0]>;

export function getCorrespondentTypeAcronym(value: string): CorrespondentTypeAcronymsT {
  return correspondentTypes.find((item) => item.value === value) || { label: value, value };
}

export const correspondentPaymentTypes = [
  { label: "Mensal", value: "ME" },
  { label: "Semanal", value: "SE" },
];

export type CorrespondentPaymentTypeAcronymsT = AcronymsT<(typeof correspondentPaymentTypes)[0]>;

export function getCorrespondentPaymentTypeAcronym(value: string): CorrespondentPaymentTypeAcronymsT {
  return correspondentPaymentTypes.find((item) => item.value === value) || { label: value, value };
}

export const genderAcronyms = [
  { label: "Masculino", value: "M" },
  { label: "Feminino", value: "F" },
  { label: "N/A", value: "N" },
];

export type GenderAcronymsT = AcronymsT<(typeof genderAcronyms)[0]>;

export function getGenderAcronym(value: string): GenderAcronymsT {
  return genderAcronyms.find((item) => item.value === value) || { label: value, value };
}

export const maritalStatusAcronyms = [
  { label: "Solteiro(a)", value: "SO" },
  { label: "Casado(a)", value: "CA" },
];

export type MaritalStatusAcronymsT = AcronymsT<(typeof maritalStatusAcronyms)[0]>;

export function getMaritalStatusAcronym(value: string): MaritalStatusAcronymsT {
  return maritalStatusAcronyms.find((item) => item.value === value) || { label: value, value };
}

export const taskStatus = [
  { label: "A vencer", value: "AB", color: "secondary-color" },
  { label: "Prazo do dia", value: "PD", color: "info-color" },
  { label: "Vencida", value: "VE", color: "warning-color" },
  { label: "Concluída", value: "CO", color: "success-color" },
  { label: "Cancelada", value: "CA", color: "danger-color" },
  { label: "Reagendada  ", value: "RA", color: "primary-color" },
];

export type TaskStatusAcronymsT = AcronymsT<(typeof taskStatus)[0]>;

export function getTaskStatusAcronym(value: string): TaskStatusAcronymsT {
  return taskStatus.find((item) => item.value === value) || { label: value, value, color: "info-color" };
}

export const groupLevelOptions = [
  { label: "Usuário", value: 1 },
  { label: "Coordenador", value: 4 },
  { label: "Gestor", value: 6 },
  { label: "Admin", value: 10 },
];

export type GroupLevelAcronymsT = AcronymsT<(typeof groupLevelOptions)[0]>;

export function getGroupLevelAcronym(value: number): GroupLevelAcronymsT {
  return groupLevelOptions.find((item) => item.value === value) || { label: String(value), value };
}

export const paymentStatusOptions = [
  { label: "Pendente", value: "PE", color: "#f14668" },
  { label: "Parcial", value: "VP", color: "#fdba74" },
  { label: "Efetuado", value: "VT", color: "#0cbe39" },
];

export type PaymentStatusAcronymsT = AcronymsT<(typeof paymentStatusOptions)[0]>;

export function getPaymentStatusAcronym(value: string): PaymentStatusAcronymsT {
  return paymentStatusOptions.find((item) => item.value === value) || paymentStatusOptions[0];
}

export const supplierModules = [
  { label: "Fornecedor", value: "EM", permission: "financialModule" },
  { label: "Prestador de Serviço", value: "PS", permission: "financialModule" },
  { label: "Cliente", value: "CL", permission: "financialModule" },
  { label: "Parceiro", value: "PA", permission: "financialModule" },
  { label: "Colaborador", value: "FU", permission: "financialModule" },
  { label: "Jurídico", value: "JU", permission: "processModule" },
  { label: "Cobrança", value: "CO", permission: "collectionModule" },
  { label: "Logística", value: "LO", permission: "logisticsModule" },
  { label: "Correspondente", value: "CR", permission: "logisticsModule" },
];

export type SupplierModuleAcronymsT = AcronymsT<(typeof supplierModules)[0]>;

export function getSupplierModuleAcronym(value: string): SupplierModuleAcronymsT {
  return (
    supplierModules.find((item) => item.value === value) || {
      label: String(value),
      value,
      permission: "financialModule",
    }
  );
}

export const financialTypeOptions = [
  { label: "A vista", value: "AV" },
  { label: "Parcelado", value: "PA" },
  { label: "Recorrente", value: "RE" },
  { label: "Indeterminado", value: "IN" },
];

export type PaymentOptionAcronymsT = AcronymsT<(typeof financialTypeOptions)[0]>;

export function getFinancialTypeOptionAcronym(value: string): PaymentOptionAcronymsT {
  return financialTypeOptions.find((item) => item.value === value) || { label: String(value), value };
}

export const expenseOptions = [
  { label: "Fixa", value: "FI" },
  { label: "Variável", value: "VA" },
  { label: "Pessoa", value: "PE" },
  { label: "Imposto", value: "IM" },
  { label: "Transferência", value: "TR" },
];

export type ExpenseOptionAcronymsT = AcronymsT<(typeof expenseOptions)[0]>;

export function getExpenseOptionAcronym(value: string): ExpenseOptionAcronymsT {
  return expenseOptions.find((item) => item.value === value) || { label: String(value), value };
}

export const performanceOptions = [
  { label: "Judicial", value: "judicial" },
  { label: "Administrativa", value: "administrativa" },
  { label: "Mista", value: "mista" },
];

export type PerformanceOptionAcronymsT = AcronymsT<(typeof performanceOptions)[0]>;

export function getPerformanceOptionAcronym(value: string): PerformanceOptionAcronymsT {
  return performanceOptions.find((item) => item.value === value) || { label: String(value), value };
}

export const paymentMethods = [
  { label: "PIX", value: "PX" },
  { label: "Dinheiro", value: "DI" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Débito Automático", value: "DA" },
  { label: "Transferência Bancária", value: "TB" },
  { label: "Boleto Bancário", value: "BB" },
  { label: "Promissória", value: "PR" },
  { label: "Cheque", value: "CH" },
];

export type PaymentMethodAcronymsT = AcronymsT<(typeof paymentMethods)[0]>;

export function getPaymentMethodAcronym(value: string): PaymentMethodAcronymsT {
  return paymentMethods.find((item) => item.value === value) || { label: String(value), value };
}

export const frequencyOptions = [
  { label: "Semanal", value: 7 },
  { label: "Quinzenal", value: 15 },
  { label: "Mensal", value: 30 },
  { label: "Bimestral", value: 60 },
  { label: "Trimestral", value: 90 },
  { label: "Semestral", value: 180 },
  { label: "Anual", value: 365 },
];

export type FrequencyOptionAcronymsT = AcronymsT<(typeof frequencyOptions)[0]>;

export function getFrequencyOptionAcronym(value: number): FrequencyOptionAcronymsT {
  return frequencyOptions.find((item) => item.value === value) || { label: String(value), value };
}
