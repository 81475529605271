<script lang="ts" setup>
import { onMounted, onUnmounted } from "vue";
import store from "@/store";

const props = defineProps<{ html: string }>();

function clearPrint() {
  store.state.global.print.html = "";
  store.state.global.print.show = false;
  window.onafterprint = () => {};
}

function startPrint() {
  if (window.innerWidth < 768) {
    window.onfocus = clearPrint;
  } else {
    window.onafterprint = clearPrint;
  }

  window.print();
}

onMounted(() => {
  document.querySelector("body")?.classList.add("has-print");
  startPrint();
});

onUnmounted(() => {
  document.querySelector("body")?.classList.remove("has-print");
  clearPrint();
});
</script>

<template>
  <div class="c-print">
    <div class="print-content" v-html="props.html"></div>
  </div>
</template>

<style lang="scss">
body.has-print {
  height: auto;

  #app #l-dashboard,
  .c-env,
  .p-toast,
  > .p-component {
    display: none !important;
  }

  .c-print {
    height: auto;
  }
}
</style>
