import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { CauseOfActionT } from "@/store/lawsuit/action-cause/types";

import { ActionCauseService } from "@/store/lawsuit/action-cause/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class ActionCauseModule extends VuexModule {
  all: CauseOfActionT[] = [];
  list: CauseOfActionT[] = [];
  current: Partial<CauseOfActionT> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new ActionCauseService();

  @Mutation
  updateCurrent(actionCause: CauseOfActionT | null) {
    this.current = actionCause || {};
  }

  @Mutation
  updateActionCauseList(actionCauses: CauseOfActionT[]) {
    this.list = actionCauses;
  }

  @Mutation
  updateActionCauseAll(actionCauses: CauseOfActionT[]) {
    this.all = actionCauses;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const { data: actionCause } = await this.service.get(payload.cd);
      this.context.commit("updateCurrent", actionCause);

      return actionCause;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getPaginated() {
    try {
      const response = await this.service.getPaginated(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        this.filters
      );

      this.context.commit("updateActionCauseList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(this.filters);
      this.context.commit("updateActionCauseAll", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: any }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Causa da Ação cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: any }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Causa da Ação editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Causa da Ação excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ActionCauseModule;
