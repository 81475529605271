import gojus from "@/services/gojus";

import { CauseOfActionT, CauseOfActionFormT } from "@/store/lawsuit/action-cause/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class ActionCauseService {
  endpoint = "causa-acao/";
  cdEndpoint = (cd: number) => `causa-acao/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<CauseOfActionT>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<CauseOfActionT[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getPaginated(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<CauseOfActionT>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async create(actionCause: CauseOfActionFormT) {
    const response = await gojus.post<CauseOfActionT, CauseOfActionFormT>({
      entity: this.endpoint,
      data: actionCause,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async edit(cd: number, actionCause: CauseOfActionFormT) {
    const response = await gojus.patch<CauseOfActionT, CauseOfActionFormT>({
      entity: this.cdEndpoint(cd),
      data: actionCause,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }
}

export default ActionCauseService;
