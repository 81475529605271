<template>
  <c-form-template
    class="c-move-modal"
    :useFooter="false"
    :isFlex="true"
    v-if="dt_movimentacao"
    @submit="$emit('submit')"
  >
    <c-form-field-template width="calc(100% - 100px)">
      <template #label>Data da Movimentação</template>
      <p-calendar v-model="dt_movimentacao" dateFormat="dd/mm/yy" :locale="calendarConfig" disabled />
    </c-form-field-template>

    <c-form-field-template width="100px">
      <template #label>Hora</template>
      <p-input-mask v-model="hr_movimentacao" mask="99:99" disabled />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Movimentação</template>
      <p-editor v-model="ds_movimentacao" editor-style="height: 120px" />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Observações</template>
      <p-editor v-model="ds_observacao" editor-style="height: 80px" />
    </c-form-field-template>

    <p-file-upload
      ref="pFileUpload"
      :multiple="true"
      mode="advanced"
      :chooseLabel="(props.move || {}).aq_arquivo ? 'Substituir anexo' : 'Anexar arquivo'"
      uploadLabel="remove"
      :customUpload="true"
      style="width: 100%; margin-top: 32px"
      @uploader="onFileUpload"
    >
      <template #empty>
        <p class="p-file-upload-empty">Arraste e solte um arquivo aqui para adicionar!</p>
      </template>
    </p-file-upload>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PEditor from "primevue/editor";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn, formatHours } from "@/utils/formatters";
import { MovementForm } from "@/store/movement/types";

@Component({ components: { CFormFieldTemplate, CFormTemplate, PEditor } })
export default class CMoveModal extends Vue {
  @Prop({ required: true }) readonly props!: {
    move?: MovementForm;
  };
  calendarConfig = calendarConfig;

  ds_movimentacao = "";
  ds_observacao = "";
  dt_movimentacao = "";
  hr_movimentacao = "";

  @Watch("hr_movimentacao")
  onUpdateHRDiligencia(value: string) {
    const unmask = value.replaceAll("_", "0");
    const hr = Number(unmask.substring(0, 2));
    const min = Number(unmask.substring(3, 5));

    if (hr < 0 || hr > 23 || min < 0 || min > 59) this.hr_movimentacao = "";
    else this.hr_movimentacao = value;
  }

  onFileUpload() {
    const pFileUpload: any = this.$refs.pFileUpload;

    pFileUpload?.clear();
    setTimeout(() => {
      (pFileUpload?.$refs?.fileInput as HTMLInputElement).click();
    }, 1000);
  }

  fixNumber(num: number) {
    let number = num + "";
    if (number.length < 2) number = "0" + number;
    return number;
  }

  getFields() {
    return {
      dt_movimentacao: formatDateEn(this.dt_movimentacao),
      hr_movimentacao: formatHours(this.hr_movimentacao),
      ds_movimentacao: this.ds_movimentacao,
      ds_observacao: this.ds_observacao,
      aq_arquivo: (this.$refs.pFileUpload as any)?.files,
    };
  }

  mounted() {
    const pFileUpload: any = this.$refs.pFileUpload;

    pFileUpload?.$children.forEach((element: any) => {
      if (element.label === "remove") element.$el.style.display = "none";
    });
  }

  reset() {}

  created() {
    this.ds_movimentacao = this.props.move?.ds_movimentacao || "";
    this.ds_observacao = this.props.move?.ds_observacao || "";
    this.dt_movimentacao = formatDate(this.props.move?.dt_movimentacao || new Date().toString());
    this.hr_movimentacao =
      formatHours(this.props.move?.hr_movimentacao) ||
      `${this.fixNumber(new Date().getHours())}:${this.fixNumber(new Date().getMinutes())}`;
  }
}
</script>

<style lang="scss">
.p-dialog.move {
  width: 94vw;
  max-width: 640px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar-track,
    .p-calendar .p-datepicker::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #0000004d;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar,
    .p-calendar .p-datepicker::-webkit-scrollbar {
      width: 6px;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar-thumb,
    .p-calendar .p-datepicker::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3273dc;
    }

    .p-datepicker {
      height: 240px;
      overflow-y: scroll;
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
