import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SettingService } from "@/store/setting/service";

import {
  SettingGroup,
  SettingEndpoint,
  SettingEndpointParams,
  SettingApplication,
  SettingApplicationParams,
} from "@/store/setting/types";
import { ToastParams } from "@/store/global/types";
import { Pagination, PaginationRow } from "../types";

@Module({ namespaced: true })
class SettingModule extends VuexModule {
  groups: SettingGroup[] = [];
  endpoints: SettingEndpoint[] = [];
  applications: SettingApplication[] = [];

  pagination: { group: Pagination; endpoint: Pagination; application: Pagination } = {
    group: { row: 10, total: 0, current: 1 },
    endpoint: { row: 10, total: 0, current: 1 },
    application: { row: 10, total: 0, current: 1 },
  };

  service = new SettingService();

  @Mutation
  updateGroups(groups: SettingGroup[]) {
    this.groups = groups;
  }

  @Mutation
  updateEndpoints(endpoints: SettingEndpoint[]) {
    this.endpoints = endpoints;
  }

  @Mutation
  updateEndpointPaginationRow(row: PaginationRow) {
    this.pagination.endpoint.row = row;
  }

  @Mutation
  updateEndpointPaginationTotal(total: number) {
    this.pagination.endpoint.total = total;
  }

  @Mutation
  updateEndpointCurrentPaginationPage(current: number) {
    this.pagination.endpoint.current = current;
  }

  @Mutation
  updateApplications(applications: SettingEndpoint[]) {
    this.applications = applications;
  }

  @Mutation
  updateGroupsPaginationRow(row: PaginationRow) {
    this.pagination.group.row = row;
  }

  @Mutation
  updateGroupsPaginationTotal(total: number) {
    this.pagination.group.total = total;
  }

  @Mutation
  updateGroupsCurrentPaginationPage(current: number) {
    this.pagination.group.current = current;
  }

  @Action
  async getGroup(params: { cd: number }) {
    try {
      const { data } = await this.service.getGroup(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getGroups() {
    try {
      const { data } = await this.service.getGroups();
      this.context.commit("updateGroups", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createGroup(params: { group: SettingGroup }) {
    try {
      const { data } = await this.service.createGroup(params.group);

      const message: ToastParams[] = [{ severity: "success", summary: "Grupo CADASTRADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editGroup(params: { cd: number; group: SettingGroup }) {
    try {
      const { data } = await this.service.editGroup(params.cd, params.group);

      const message: ToastParams[] = [{ severity: "success", summary: "Grupo EDITADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteGroup(params: { cd: number }) {
    try {
      const { data } = await this.service.deleteGroup(params.cd);

      const message: ToastParams[] = [{ severity: "success", summary: `Grupo #${params.cd} DELETADO` }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getEndpoint(params: SettingEndpointParams) {
    try {
      const { data } = await this.service.getEndpoint(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getEndpoints(params?: SettingEndpointParams) {
    try {
      const { data } = await this.service.getEndpoints(
        {
          page: this.pagination.endpoint.current,
          nr_por_pagina: this.pagination.endpoint.row,
          paginacao: true,
        },
        params?.filters
      );

      this.context.commit("updateEndpoints", data.results);
      this.context.commit("updateEndpointPaginationTotal", data.count);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createEndpoint(params: SettingEndpointParams) {
    try {
      const { data } = await this.service.createEndpoint(params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Endpoint CADASTRADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editEndpoint(params: SettingEndpointParams) {
    try {
      const { data } = await this.service.editEndpoint(params.cd, params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Endpoint EDITADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteEndpoint(params: SettingEndpointParams) {
    try {
      const { data } = await this.service.deleteEndpoint(params.cd);

      const message: ToastParams[] = [{ severity: "success", summary: `Endpoint #${params.cd} DELETADO` }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getApplication(params: SettingApplicationParams) {
    try {
      const { data } = await this.service.getApplication(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getApplications(params?: SettingApplicationParams) {
    try {
      const response = await this.service.getApplications(params?.filters);
      this.context.commit("updateApplications", response?.data);

      return response?.data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createApplication(params: SettingApplicationParams) {
    try {
      const { data } = await this.service.createApplication(params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Application CADASTRADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editApplication(params: SettingApplicationParams) {
    try {
      const { data } = await this.service.editApplication(params.cd, params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Application EDITADO" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteApplication(params: SettingApplicationParams) {
    try {
      const { data } = await this.service.deleteApplication(params.cd);

      const message: ToastParams[] = [{ severity: "success", summary: `Application #${params.cd} DELETADO` }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default SettingModule;
