<template>
  <c-form-template class="c-report-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="50%">
      <template #label>{{ props.label.start }}</template>
      <p-calendar
        v-model="dt_inicial"
        :minDate="minStartDate"
        :maxDate="maxStartDate"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
      />
    </c-form-field-template>

    <c-form-field-template width="50%">
      <template #label>{{ props.label.end }}</template>
      <p-calendar
        v-model="dt_final"
        :minDate="minDate"
        :maxDate="maxEndDate"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
      />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn } from "@/utils/formatters";
import { ReportQuery } from "@/store/report/types";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CReportModal extends Vue implements ReportQuery {
  @Prop({ required: true }) readonly props!: { [key: string]: any };
  calendarConfig = calendarConfig;
  formatDate = formatDate;

  dt_inicial = "";
  dt_final = "";

  get minDate() {
    const minDate = new Date(this.dt_inicial);
    if (isNaN(minDate.getDate())) return this.props.min?.end;
    return minDate;
  }

  get maxEndDate() {
    return this.props.max?.end;
  }

  get maxStartDate() {
    return this.props.max?.start;
  }

  get minStartDate() {
    return this.props.min?.start;
  }

  getFields() {
    return {
      dt_inicial: formatDateEn(this.dt_inicial),
      dt_final: formatDateEn(this.dt_final),
    };
  }

  reset() {
    this.dt_inicial = "";
    this.dt_final = "";
  }
}
</script>

<style lang="scss">
.p-dialog.report {
  width: 94vw;
  max-width: 580px;

  .p-dialog-header {
    padding: 1.5rem 1rem;
  }

  .p-dialog-content {
    padding: 0.5rem 1rem 1.5rem;

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
