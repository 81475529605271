import { Action, Module, VuexModule } from "vuex-module-decorators";

import CategoryModule from "@/store/financial/category/module";
import BillToPayModule from "@/store/financial/bill-to-pay/module";
import BillToReceiveModule from "@/store/financial/bill-to-receive/module";
import BankAccountModule from "@/store/financial/bank-account/module";
import CostCenterModule from "@/store/financial/cost-center/module";
import FinancialFileModule from "@/store/financial/file/module";
import FavoredModule from "@/store/financial/favored/module";
import TransferModule from "@/store/financial/transfer/module";
import gojus from "@/services/gojus";
import { FinancialSummaryT } from "./types";

const endpoints = { summary: "resultados/" };
const { VUE_APP_API_FINANCIAL_URL } = process.env;

@Module({ namespaced: true })
export class FinancialModule extends VuexModule {
  static modules = {
    favored: FavoredModule,
    category: CategoryModule,
    transfer: TransferModule,
    bankAccount: BankAccountModule,
    costCenter: CostCenterModule,
    billToPay: BillToPayModule,
    billToReceive: BillToReceiveModule,
    file: FinancialFileModule,
  };

  generalFilters = {};

  @Action
  async getSummary(filters: any) {
    const response = await gojus.get<FinancialSummaryT>({
      entity: endpoints.summary,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }
}

export default FinancialModule;
