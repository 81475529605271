import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Category } from "@/store/financial/category/types";

import { CategoryService } from "@/store/financial/category/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class CategoryModule extends VuexModule {
  all: Category[] = [];
  list: Category[] = [];
  current: Partial<Category> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new CategoryService();

  @Mutation
  updateCurrent(category: Category) {
    this.current = category;
  }

  @Mutation
  updateCategoryList(categories: Category[]) {
    this.list = categories;
  }

  @Mutation
  updateCategoryAll(categories: Category[]) {
    this.all = categories;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const { data: category } = await this.service.get(payload.cd);
      this.context.commit("updateCurrent", category);

      return category;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getPaginated() {
    try {
      const response = await this.service.getPaginated(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        this.filters
      );

      this.context.commit("updateCategoryList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(this.filters);
      this.context.commit("updateCategoryAll", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: any }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Categoria cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: any }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Categoria editada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Categoria excluída com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default CategoryModule;
