import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { GojusParams, GojusPromise } from "@/services/gojus/types";

import configInterceptors from "@/services/gojus/interceptors";

export class GojusService {
  http: AxiosInstance;

  constructor(config: AxiosRequestConfig = {}) {
    if (!config.timeout) config.timeout = 40000;
    this.http = configInterceptors(axios.create(config));
  }

  get<T>(params: GojusParams): GojusPromise<T> {
    const response = this.http.get<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  post<T, D = T>(params: GojusParams<D>): GojusPromise<T> {
    const response = this.http.post<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  patch<T, D = T>(params: GojusParams<D>): GojusPromise<T> {
    const response = this.http.patch<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  delete<T>(params: GojusParams): GojusPromise<T> {
    const response = this.http.delete<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  getCancelToken() {
    return axios.CancelToken.source();
  }
}

export default new GojusService();
