import gojus from "@/services/gojus";

import { Favored, FavoredForm } from "@/store/financial/favored/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class FavoredService {
  endpoint = "favorecidos/";
  cdEndpoint = (cd: number) => `favorecidos/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<Favored>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<Favored[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getPaginated(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<Favored>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async create(favored: FavoredForm) {
    const response = await gojus.post<Favored, FavoredForm>({
      entity: this.endpoint,
      data: favored,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async edit(cd: number, favored: FavoredForm) {
    const response = await gojus.patch<Favored, FavoredForm>({
      entity: this.cdEndpoint(cd),
      data: favored,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });
    return response;
  }
}

export default FavoredService;
