import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Workplace, WorkplacePayload } from "@/store/department/workplace/types";

import { WorkplaceService } from "@/store/department/workplace/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class WorkplaceModule extends VuexModule {
  all: Workplace[] = [];
  current: Workplace = { id: 0 };

  filters: any = {};
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  service = new WorkplaceService();

  @Mutation
  updateCurrent(workplace: Workplace) {
    this.current = workplace;
  }

  @Mutation
  updateWorkplaces(workplaces: Workplace[]) {
    this.all = workplaces;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: WorkplacePayload) {
    try {
      const { data: workplace } = await this.service.get(payload.cd);

      if (workplace.cd_cidade)
        workplace.ob_cidade = await this.context.dispatch(
          "location/getCity",
          { cd_cidade: workplace.cd_cidade },
          { root: true }
        );

      this.context.commit("updateCurrent", workplace);

      return workplace;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        this.filters
      );

      for (const workplace of response.data.results) {
        if (workplace.cd_cidade)
          workplace.ob_cidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: workplace.cd_cidade },
            { root: true }
          );
      }

      this.context.commit("updateWorkplaces", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: WorkplacePayload) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Local de trabalho cadastrado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: WorkplacePayload) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Local de trabalho editado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: WorkplacePayload) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Local de trabalho excluído com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default WorkplaceModule;
