import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";

import { PublicationT } from "@/store/publication/types";
import { Pagination, PaginationResponse, PaginationRow } from "@/store/types";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
export class PublicationModule extends VuexModule {
  list: PublicationT[] = [];
  endpoints = { root: "publication" };
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  @Mutation
  setPublicationList(publications: PublicationT[]) {
    this.list = publications;
  }

  @Mutation
  setPaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  setPaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  setCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async getList() {
    try {
      const pagination = {
        page: this.pagination.current,
        nr_por_pagina: this.pagination.row,
        paginacao: true,
      };

      const response = await gojus.get<PaginationResponse<PublicationT>>({
        entity: this.endpoints.root,
        config: { query: { ...pagination }, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setPublicationList", response?.data?.results);
      this.context.commit("setPaginationTotal", response?.data?.count);

      return response?.data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default PublicationModule;
