<template>
  <div class="c-modal-task-excel">
    <header class="header">
      <h5>Exportar tarefas</h5>
      <h6>
        Foram encontradas <b>{{ totalItems }}</b> tarefas no total.
      </h6>
    </header>

    <main class="content">
      <p-listbox
        :options="headerOptions.filter((header) => header.show)"
        optionLabel="label"
        dataKey="label"
        @change="$event.value.selected = !$event.value.selected"
      >
        <template #option="{ option }">
          <p-checkbox v-model="option.selected" :style="{ pointerEvents: 'none' }" :binary="true" />
          <label :style="{ marginLeft: '12px', cursor: 'pointer' }" v-text="option.label"></label>
        </template>
      </p-listbox>
    </main>

    <footer class="footer">
      <p class="export-alert">
        <i class="fas fa-triangle-exclamation"></i> Tempo estimado: {{ minutes }} minuto{{ minutes > 1 ? "s" : "" }}.
      </p>

      <div class="options">
        <p-button
          class="p-button-danger option"
          icon="fas fa-times-circle"
          label="Cancelar"
          @click="onAskingToCancel"
        />

        <p-button v-if="loading.items" class="p-button-success option" disabled>
          <p-progress-spinner strokeWidth="8" animationDuration="0.8s" />
          <span>Aguarde</span>
        </p-button>
        <p-button
          v-else
          class="p-button-success option"
          icon="fas fa-download"
          label="Baixar"
          @click="onAskingToDownload"
        />
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import PButton from "primevue/button/Button.vue";
import Listbox from "primevue/listbox";
import Checkbox from "primevue/checkbox";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import CFormFieldTemplate from "@/components/form/field.vue";

import { Task } from "@/store/task/types";
import { formatDate, formatHours, formatProcessNumberInCnjPattern } from "@/utils/formatters";
import { isTaskCoordinator } from "@/utils/validators";

import { handleExportXLSX } from "@/plugins/excellentexport";
import { getTaskStatus } from "@/utils/acronyms";
import { Pagination } from "@/store/types";
import { User } from "@/store/user/types";
import { Team } from "@/store/team/types";

const PListbox: any = Listbox;
const PCheckbox: any = Checkbox;

@Component({ components: { CFormFieldTemplate, PButton, PListbox, PCheckbox, PProgressSpinner } })
export default class CModalExcel extends Vue {
  @Prop({ required: true }) readonly props!: any;
  loading = { items: false };

  headers: string[] = [];
  content: string[][] = [];

  items: Task[] = [];
  totalItems = 0;
  pages = 0;
  minutes = 0;

  handleExportXLSX = handleExportXLSX;
  formatDate = formatDate;

  headerOptions = [
    {
      label: "ID",
      handle: (item: Task) => item.id,
      selected: true,
      show: true,
    },
    {
      label: "Tipo de Tarefa",
      handle: (item: Task) => item.ob_tipo_tarefa?.nm_tipo_tarefa,
      selected: true,
      show: true,
    },
    {
      label: "Equipe",
      handle: (item: Task) => item.nm_equipe,
      selected: true,
      show: true,
    },
    {
      label: "Titulo da Tarefa",
      handle: (item: Task) => item.nm_titulo,
      selected: true,
      show: true,
    },
    {
      label: "Descrição",
      handle: (item: Task) => item.ds_descricao,
      selected: true,
      show: true,
    },
    {
      label: "Número do Processo",
      handle: (item: Task) => formatProcessNumberInCnjPattern(item.nr_processo),
      selected: true,
      show: true,
    },
    {
      label: "Parte Contrária",
      handle: (item: Task) => item.ob_devedor?.nm_devedor || item.nm_parte_contraria,
      selected: true,
      show: true,
    },
    {
      label: "Cliente",
      handle: (item: Task) => item.ob_cliente?.nm_cliente,
      selected: true,
      show: true,
    },
    {
      label: "Estado",
      handle: (item: Task) => item.nm_estado,
      selected: true,
      show: true,
    },
    {
      label: "Comarca",
      handle: (item: Task) => item.nm_cidade,
      selected: true,
      show: true,
    },
    {
      label: "Data da criação",
      handle: (item: Task) => formatDate(item.dt_criado),
      selected: true,
      show: true,
    },
    {
      label: "Criado por",
      handle: (item: Task) => item.nm_usuario_cri,
      selected: true,
      show: true,
    },
    {
      label: "Prazo Fatal",
      handle: (item: Task) => formatDate(item.dt_fatal),
      selected: true,
      show: true,
    },
    {
      label: "Data do Vencimento",
      handle: (item: Task) => formatDate(item.dt_vencimento),
      selected: true,
      show: true,
    },
    {
      label: "Hora do Vencimento",
      handle: (item: Task) => formatHours(item.hr_vencimento),
      selected: true,
      show: true,
    },
    {
      label: "Responsável",
      handle: (item: Task) => item.ob_responsavel?.nm_funcionario,
      selected: true,
      show: true,
    },
    {
      label: "Status",
      handle: (item: Task) => getTaskStatus(item.ie_status || "AB").label,
      selected: true,
      show: true,
    },
    {
      label: "Data da Conclusão",
      handle: (item: Task) => formatDate(item.dt_conclusao),
      selected: true,
      show: true,
    },
    {
      label: "Concluída por",
      handle: (item: Task) => item.nm_usuario_conclusao || "",
      selected: true,
      show: true,
    },
  ];

  get hasTeamFilter() {
    return !!this.$store.state.task.filters.cd_equipe;
  }

  get filters() {
    const taskFilters = this.$store.state.task.filters;
    const cd_equipe = this.$store.state.user.employee?.equipes?.map((team: any) => team.id) || "null";

    return { cd_equipe, ...taskFilters };
  }

  get tabView(): string {
    return this.$store.state.global.currentTabView;
  }

  get hasPermissionCoordinator() {
    return this.$store.state.user.permissions.taskCoordinator;
  }

  get pagination(): Pagination {
    return this.$store.state.task.pagination;
  }

  get user(): User | null {
    return this.$store.state.user.current;
  }

  get isGeneralCoordinator() {
    const groups = this.user?.grupos || [];
    const result = groups.some((group) => group.nm_grupo?.toLowerCase().includes("coordenador geral"));

    return result;
  }

  get teams(): Team[] {
    const allTeams = this.$store.state.department.responsible?.all || [];
    const userTeams = this.$store.state.user.employee?.equipes || [];

    if (this.isGeneralCoordinator) {
      return allTeams;
    }

    return userTeams;
  }

  async getTasks(page: number, filters: any) {
    try {
      const cd_equipe = this.teams.map((team) => team.id);
      if (!this.isGeneralCoordinator && !filters.cd_equipe) filters.cd_equipe = cd_equipe;

      const response = await this.$store.state.task.service.getAll(
        { inicio: String((page - 1) * 1000), fim: String(page * 1000) },
        filters
      );
      const tasks: Task[] = response?.data || [];

      const responsibleList: { [key: number]: any } = {};
      const lawsuitCustomerList: { [key: number]: any } = {};
      const collectionCustomerList: { [key: number]: any } = {};
      const debtorList: { [key: number]: any } = {};
      const taskTypeList: { [key: number]: any } = {};

      for (const task of tasks) {
        const responsibleId = Number(task.cd_responsaveis?.[0]);
        const customerId = Number(task.cd_cliente);
        const debtorId = Number(task.cd_devedor);
        const taskTypeId = Number(task.cd_tipo_tarefa);

        if (responsibleId) responsibleList[responsibleId] = null;
        if (debtorId) debtorList[debtorId] = null;
        if (taskTypeId) taskTypeList[taskTypeId] = null;
        if (customerId) {
          if (task.ie_modulo_origem === "CO") collectionCustomerList[customerId] = null;
          else lawsuitCustomerList[customerId] = null;
        }
      }

      const responsiblePromises = Object.keys(responsibleList).map((_id) =>
        this.$store.state.department.employee.service.getAll(
          { page: 1, nr_por_pagina: 10, paginacao: true },
          { cd_authuser: _id }
        )
      );
      const lawsuitCustomerPromises = Object.keys(lawsuitCustomerList).map((_id) =>
        this.$store.dispatch("lawsuit/customer/get", { cd: _id })
      );
      const collectionCustomerPromises = Object.keys(collectionCustomerList).map((_id) =>
        this.$store.dispatch("collection/customer/get", { cd: _id })
      );
      const debtorPromises = Object.keys(debtorList).map((_id) => this.$store.dispatch("debtor/search", { cd: _id }));
      const taskTypePromises = Object.keys(taskTypeList).map((_id) =>
        this.$store.dispatch("task/getType", { cd: _id })
      );

      const [responsibleResults, lawsuitCustomerResults, collectionCustomerResults, debtorResults, taskTypeResults] =
        await Promise.allSettled([
          Promise.allSettled(responsiblePromises),
          Promise.allSettled(lawsuitCustomerPromises),
          Promise.allSettled(collectionCustomerPromises),
          Promise.allSettled(debtorPromises),
          Promise.allSettled(taskTypePromises),
        ]);

      const responsibleResultItems = (
        responsibleResults.status === "fulfilled"
          ? responsibleResults.value.map((item) =>
              item.status === "fulfilled" ? item.value.data?.results?.[0] || null : null
            )
          : []
      ).filter((item) => item);
      const collectionCustomerResultItems = (
        collectionCustomerResults.status === "fulfilled"
          ? collectionCustomerResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);
      const lawsuitCustomerResultItems = (
        lawsuitCustomerResults.status === "fulfilled"
          ? lawsuitCustomerResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);
      const debtorResultItems = (
        debtorResults.status === "fulfilled"
          ? debtorResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);
      const taskTypeResultItems = (
        taskTypeResults.status === "fulfilled"
          ? taskTypeResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);

      for (const responsibleItem of responsibleResultItems) {
        responsibleList[responsibleItem.cd_authuser] = responsibleItem;
      }
      for (const collectionCustomerItem of collectionCustomerResultItems) {
        collectionCustomerList[collectionCustomerItem.id] = collectionCustomerItem;
      }
      for (const lawsuitCustomerItem of lawsuitCustomerResultItems) {
        lawsuitCustomerList[lawsuitCustomerItem.id] = lawsuitCustomerItem;
      }
      for (const debtorItem of debtorResultItems) {
        debtorList[debtorItem.id] = debtorItem;
      }
      for (const taskTypeItem of taskTypeResultItems) {
        taskTypeList[taskTypeItem.id] = taskTypeItem;
      }

      for (const task of tasks) {
        const responsibleId = Number(task.cd_responsaveis?.[0]);
        const customerId = Number(task.cd_cliente);
        const debtorId = Number(task.cd_devedor);
        const taskTypeId = Number(task.cd_tipo_tarefa);

        task.ob_responsavel = responsibleList[responsibleId] || null;
        task.ob_devedor = debtorList[debtorId] || null;
        task.ob_tipo_tarefa = taskTypeList[taskTypeId] || null;
        task.ob_cliente =
          task.ie_modulo_origem === "CO" ? collectionCustomerList[customerId] : lawsuitCustomerList[customerId];
      }

      return tasks;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  async onAskingToDownload() {
    this.loading.items = true;

    const selected = this.headerOptions.filter((item) => item.show && item.selected);
    const filters = {
      coordenador: this.tabView === "coordinator",
      ...this.filters,
      resumo: this.$store.state.task.subtitleFilter.ie_status || this.$store.state.task.summaryFilter,
    };
    if (filters.cd_equipe === "null") delete filters.cd_equipe;
    if (filters.coordenador && !this.hasPermissionCoordinator) filters.coordenador = undefined;
    if (filters.coordenador) delete filters.coordenador;

    if (this.isGeneralCoordinator) {
      if (this.hasTeamFilter) {
        filters.cd_equipe = this.$store.state.task.filters.cd_equipe;
      } else {
        delete filters.cd_equipe;
      }
    }

    try {
      const allTasks: Task[] = [];

      for (let page = 1; page <= this.pages; page++) {
        const tasks = await this.getTasks(page, filters);
        tasks?.forEach((task) => allTasks.push(task));
      }

      this.items = allTasks;

      this.headers = selected.map((header) => header.label);
      this.content = this.items.map((item) => selected.map((header) => header.handle(item)?.toString() || ""));

      handleExportXLSX(this.headers, this.content);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    } finally {
      this.loading.items = false;
    }
  }

  onAskingToCancel() {
    this.$store.commit("global/updateModalStatus", false);
  }

  created() {
    this.items = this.$store.state.task.all;
    this.totalItems = this.$store.state.task.pagination.total;
    this.pages = Math.ceil(this.totalItems / 1000);
    this.minutes = Math.ceil(this.totalItems / 20 / 60);
  }
}
</script>

<style lang="scss">
.c-modal-task-excel {
  overflow-y: auto;
  max-height: 90vh;
  width: 100%;
  max-width: 420px;
  padding: 4px;

  .header {
    padding: 6px 12px 8px;

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    h6 {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;

      b {
        font-weight: 900;
      }
    }
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--info-color);
  }

  .p-listbox {
    border: none;
  }

  .p-listbox-list {
    display: flex;
    flex-wrap: wrap;

    .p-listbox-item {
      width: 50%;
      padding: 6px 12px;
      border-radius: var(--border-radius-50);
    }
  }

  .footer {
    padding: 12px 12px 6px;

    .export-alert {
      margin-bottom: 24px;
      font-size: 13px;
      font-weight: 600;

      i {
        color: var(--warning-color);
        font-size: 14px;
        margin-right: 4px;
      }
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .p-button {
        flex: none;
        width: 110px;
        font-weight: 600;
        font-size: 14px;
        height: 34px;
        border-radius: var(--border-radius-50);
      }
    }
  }
}
</style>
