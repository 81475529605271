<template>
  <div class="c-modal-file-upload">
    <header class="header">
      <div class="header-container">
        <h3 class="title">{{ props.title || "Adicionar Anexo" }}</h3>
        <p-button
          class="close p-button-danger p-button-text"
          icon="fas fa-times-circle"
          title="Fechar"
          @click="handleClose"
        />
      </div>
    </header>

    <main class="content">
      <p-file-upload
        ref="pFileUpload"
        mode="advanced"
        :disabled="props.fileUpload"
        chooseLabel="Adicionar"
        uploadLabel="Enviar"
        cancelLabel="Limpar"
        :multiple="true"
        :customUpload="true"
        @uploader="onFileUpload"
      >
        <template #empty>
          <p class="p-file-upload-empty">Arraste e solte um arquivo aqui para adicionar!</p>
        </template>
      </p-file-upload>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component
export default class CFileUploadModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  @Ref() readonly pFileUpload!: { progress: number };

  interval: any = null;
  size = 0;
  files: File[] = [];

  get fileUpload() {
    return this.$store.state.global.fileUpload;
  }

  @Watch("fileUpload")
  onLoadingUpdate(value: boolean) {
    if (this.pFileUpload && value) {
      this.interval = setInterval(() => {
        this.pFileUpload.progress += 10;
        // if (this.pFileUpload.progress >= 100) this.pFileUpload.progress = 0;
      }, 10);
    } else this.handleClose();
  }

  getFields() {
    return this.files;
  }

  reset() {
    this.files = [];
  }

  onFileUpload({ files }: { files: File[] }) {
    this.files = files;
    this.$emit("upload");
  }

  handleClose() {
    if (this.interval) {
      this.pFileUpload.progress = 100;

      clearInterval(this.interval);
      this.interval = null;
    }

    this.$store.commit("global/updateModalStatus", false);
  }
}
</script>

<style lang="scss">
.c-modal-file-upload {
  width: 50vw;
  max-width: 580px;
  min-width: 460px;

  .header {
    padding: 1rem;
    position: relative;

    .title {
      margin-right: 2rem;
    }
  }

  .p-button {
    &.close {
      display: inline-block;
      position: absolute;
      top: 0.9rem;
      right: 0.5rem;
      padding: 0;
      font-size: 1.4rem;
    }
  }

  .p-fileupload-content {
    padding: 3rem 1rem;
  }

  .p-file-upload-empty {
    font-size: 1.1rem;
  }

  .p-fileupload-row {
    overflow-x: hidden;
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 0.95rem;
    }

    .p-fileupload-buttonbar {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding: 0.75rem 0.5rem;
    }

    .p-fileupload-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      > div {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    width: 92vw;
    min-width: unset;
  }
}
</style>
