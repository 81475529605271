import gojus from "@/services/gojus";
import { Group } from "@/store/group/types";

const { VUE_APP_API_AUTH_URL } = process.env;

export class GroupService {
  endpoint = "grupos/";
  cdEndpoint = (cd: number) => `grupos/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<Group>({ entity: this.cdEndpoint(cd), config: { baseAPI: VUE_APP_API_AUTH_URL } });
    return { data };
  }

  async getAll() {
    const { data } = await gojus.get<Group[]>({ entity: this.endpoint, config: { baseAPI: VUE_APP_API_AUTH_URL } });
    return { data };
  }

  async create(group: Group) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: group,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async edit(cd: number, group: Group) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: group,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({ entity: this.cdEndpoint(cd), config: { baseAPI: VUE_APP_API_AUTH_URL } });
    return { data };
  }
}

export default GroupService;
