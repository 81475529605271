import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Favored, FavoredForm } from "@/store/financial/favored/types";

import { FavoredService } from "@/store/financial/favored/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class FavoredModule extends VuexModule {
  all: Favored[] = [];
  list: Favored[] = [];
  current: Partial<Favored> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new FavoredService();

  @Mutation
  updateCurrent(favored: Favored) {
    this.current = favored;
  }

  @Mutation
  updateFavoredList(favoredList: Favored[]) {
    this.list = favoredList;
  }

  @Mutation
  updateFavoredAll(favoredList: Favored[]) {
    this.all = favoredList;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const { data: favored } = await this.service.get(payload.cd);
      this.context.commit("updateCurrent", favored);

      return favored;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getPaginated(payload?: { tab?: string }) {
    try {
      const filters: any = {};
      if (["EM", "PS", "CL"].includes(payload?.tab || "")) filters.ie_tipo_favorecido = payload?.tab;

      const response = await this.service.getPaginated(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        { ...this.filters, ...filters }
      );

      this.context.commit("updateFavoredList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(this.filters);
      this.context.commit("updateFavoredAll", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: FavoredForm }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Fornecedor cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: FavoredForm }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Fornecedor editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Fornecedor excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default FavoredModule;
