import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";

import Vue from "vue";
import Chart from "chart.js";

import PDataTable from "primevue/datatable";
import PDialog from "primevue/dialog";
import PColumn from "primevue/column";
import PColumnGroup from "primevue/columngroup";
import PRow from "primevue/row";
import PButton from "primevue/button";
import PInputText from "primevue/inputtext";
import PInputPassword from "primevue/password";
import PMenubar from "primevue/menubar";
import PCalendar from "primevue/calendar";
import PInlineMessage from "primevue/inlinemessage";
import PInputNumber from "primevue/inputnumber";
import PInputMask from "primevue/inputmask";
import PToast from "primevue/toast";
import PFileUpload from "primevue/fileupload";
import PCard from "primevue/card";
import PCheckbox from "primevue/checkbox";
import PBreadcrumb from "primevue/breadcrumb";
import PDropdown from "primevue/dropdown";
import PSelectButton from "primevue/selectbutton";
import PTextarea from "primevue/textarea";
import PMultiSelect from "primevue/multiselect";
import PChips from "primevue/chips";
import ToastService from "primevue/toastservice";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Skeleton from "primevue/skeleton";

Vue.use(ToastService);

Vue.component("p-button", PButton);
Vue.component("p-select-button", PSelectButton);
Vue.component("p-dropdown", PDropdown);
Vue.component("p-multi-select", PMultiSelect);
Vue.component("p-textarea", PTextarea);
Vue.component("p-input-text", PInputText);
Vue.component("p-input-mask", PInputMask);
Vue.component("p-input-password", PInputPassword);
Vue.component("p-inline-message", PInlineMessage);
Vue.component("p-toast", PToast);
Vue.component("p-calendar", PCalendar);
Vue.component("p-file-upload", PFileUpload);
Vue.component("p-dialog", PDialog);
Vue.component("p-checkbox", PCheckbox);
Vue.component("p-input-number", PInputNumber);
Vue.component("p-chips", PChips);
Vue.component("p-menubar", PMenubar);
Vue.component("p-card", PCard);
Vue.component("p-breadcrumb", PBreadcrumb);
Vue.component("p-column", PColumn);
Vue.component("p-column-group", PColumnGroup);
Vue.component("p-row", PRow);
Vue.component("p-data-table", PDataTable);
Vue.component("tab-view", TabView);
Vue.component("tab-panel", TabPanel);
Vue.component("skeleton", Skeleton);

Chart.pluginService.register({
  beforeDraw: function (chart: any) {
    if (chart.config?.options?.elements?.center) {
      //Get ctx from string
      const ctx = chart.chart.ctx;

      //Get options from the center object in options
      const centerConfig = chart.config.options.elements.center;
      const fontFamily = centerConfig.fontFamily || "Arial";
      const txt = centerConfig.text;
      const color = centerConfig.color || "#000";
      const maxFontSize = centerConfig.maxFontSize || 25;
      const sidePadding = centerConfig.sidePadding || 20;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      //Start with a base font of 30px
      ctx.font = "30px " + fontFamily;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      const fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);

      //Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontFamily;
      ctx.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  },
});

if (Chart.defaults.global.animation) {
  Chart.defaults.global.animation.duration = 1000;
  Chart.defaults.global.animation.easing = "easeInOutBack";
}

if (Chart.defaults.global.legend) {
  Chart.defaults.global.legend.display = false;
}

if (Chart.defaults.global.tooltips) {
  if (Chart.defaults.global.tooltips.callbacks) {
    Chart.defaults.global.tooltips.callbacks.title = (tooltipItem, data) =>
      String(data.labels?.[tooltipItem[0]?.index || 0]);
    Chart.defaults.global.tooltips.callbacks.label = (tooltipItem, data) =>
      String(data.datasets?.[0]?.data?.[tooltipItem.index || 0]);
    Chart.defaults.global.tooltips.callbacks.afterLabel = (tooltipItem, data) => {
      const dataset = data.datasets?.[0];
      const meta = (dataset as any)?._meta;
      const total = meta[Object.keys(meta)[0]].total;
      const percent = Math.round((Number(dataset?.data?.[tooltipItem.index || 0] || 0) / total) * 100);
      if (!percent) return "";

      return `(${percent}%)`;
    };
  }

  Chart.defaults.global.tooltips.backgroundColor = "#fff";
  Chart.defaults.global.tooltips.titleFontSize = 14;
  Chart.defaults.global.tooltips.titleFontColor = "#333";
  Chart.defaults.global.tooltips.bodyFontColor = "#000";
  Chart.defaults.global.tooltips.bodyFontSize = 14;
  Chart.defaults.global.tooltips.displayColors = false;
}
