import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  Diligence,
  DiligenceParams,
  DiligenceSummary,
  DiligenceActType,
  DiligenceCancelParams,
  DiligenceSituations,
  DiligenceCorrespondent,
} from "@/store/logistics/diligence/types";
import { ToastParams } from "@/store/global/types";

import { DiligenceService } from "@/store/logistics/diligence/service";
import { Pagination, PaginationRow } from "@/store/types";
import { LogisticsCustomer } from "../customer/types";
import { FileParams } from "@/store/file/types";
import { Forum } from "@/store/forum/types";
import { LogisticsActType } from "../acttype/types";
import { City } from "@/store/location/types";

const cache = {
  customers: {} as { [key: number]: LogisticsCustomer | null },
  cities: {} as { [key: number]: City | null },
  types: {} as { [key: number]: LogisticsActType | null },
  forums: {} as { [key: number]: Forum | null },
  files: {} as { [key: number]: number | null },
  correspondents: {} as { [key: number]: DiligenceCorrespondent | null },
};

@Module({ namespaced: true })
export class DiligenceModule extends VuexModule {
  all: Diligence[] = [];
  types: DiligenceActType[] = [];
  actTypes: DiligenceActType[] = [];
  customers: LogisticsCustomer[] = [];
  summary: DiligenceSummary = {
    nao_confirmadas_dia_atual: 0,
    nao_confirmadas_dia_seguinte: 0,
    em_atraso: 0,
    termos_em_atraso: 0,
    diligencia_em_atraso: 0,
    nao_contratadas_dia_atual: 0,
    nao_contratadas_dia_seguinte: 0,
    confirmadas: 0,
    nao_contratadas: 0,
    concluidas_canceladas: 0,
  };
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  summaryFilter = "";
  subtitleFilter: DiligenceSituations | "" = "";

  service = new DiligenceService();

  @Mutation
  updateDiligence() {}

  @Mutation
  updateDiligences(diligences: Diligence[]) {
    this.all = diligences;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
    if (this.summaryFilter) this.filters.resumo = this.summaryFilter;
    if (this.filters.ie_situacao?.length)
      if (this.filters.ie_situacao.length === 1) this.subtitleFilter = this.filters.ie_situacao[0];
      else this.subtitleFilter = "";
  }

  @Mutation
  updateSummaryFilter(value: any) {
    this.summaryFilter = value;
    this.filters.resumo = value;
  }

  @Mutation
  updateSubtitleFilter(value: DiligenceSituations | "") {
    this.subtitleFilter = value;
  }

  @Mutation
  updateTypes(diligenceTypes: DiligenceActType[]) {
    this.types = diligenceTypes;
  }

  @Mutation
  updateActTypes(diligenceActTypes: DiligenceActType[]) {
    this.actTypes = diligenceActTypes;
  }

  @Mutation
  updateSummary(summary: any) {
    for (const key in summary) {
      const newKey = key.replaceAll("-", "_").toLocaleLowerCase();
      summary[newKey] = summary[key];

      delete summary[key];
    }

    this.summary = summary;
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Mutation
  updateLogisticsCustomers(customers: LogisticsCustomer[]) {
    this.customers = customers;
  }

  @Action
  async get(params: DiligenceParams) {
    try {
      const diligenceResponde = await this.service.get(params.cd);
      const diligence = diligenceResponde.data;
      const filesResponse = await this.context.dispatch("getAllFiles", { cd: diligence.id });
      const requests = [];

      if (params?.request?.cd_cliente !== false && Number(diligence.cd_cliente))
        requests.push(this.service.getLogisticsCustomer(Number(diligence.cd_cliente)));
      else requests.push(null);
      if (params.request?.cd_cidade !== false && Number(diligence.cd_cidade))
        requests.push(this.context.dispatch("location/getCity", { cd_cidade: diligence.cd_cidade }, { root: true }));
      else requests.push(null);
      if (params.request?.cd_tipo !== false && Number(diligence.cd_tipo))
        requests.push(this.context.dispatch("acttype/get", { cd: diligence.cd_tipo }, { root: true }));
      else requests.push(null);
      if (params.request?.cd_foro !== false && Number(diligence.cd_foro))
        requests.push(this.context.dispatch("forum/get", { cd: diligence.cd_foro }, { root: true }));
      else requests.push(null);

      diligence.history?.map(async (log) => {
        const response = await this.context.dispatch("forum/get", { cd: log.cd_foro }, { root: true });
        log.nm_foro = response.nm_foro;
      });

      try {
        const [ob_cliente, ob_cidade, ob_tipo, ob_foro] = await Promise.all(requests);
        diligence.ob_cliente = ob_cliente || {};
        diligence.ob_cidade = ob_cidade || {};
        diligence.ob_tipo = ob_tipo || {};
        diligence.ob_foro = ob_foro || {};
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      return { diligence, files: filesResponse };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getAll(params?: DiligenceParams) {
    try {
      const { data } = await this.service.getAll(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        { ie_situacao: this.subtitleFilter, ...this.filters, ...(params?.filters || {}) }
      );

      data.results = await this.context.dispatch("getAdditionalData", { items: data.results, options: params });

      if (params?.update?.list !== false) {
        this.context.commit("updateDiligences", data.results);
        this.context.commit("updatePaginationTotal", data.count);
        await this.context.dispatch("getSummary", { ie_solicitacao: params?.filters?.ie_solicitacao });
      }

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getAllSolicitation(params?: DiligenceParams) {
    try {
      const { data } = await this.service.getAllSolicitation(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        { ...this.filters, ...(params?.filters || {}) }
      );

      data.results = data.results.map((item) => ({
        ...item,
        ob_cliente: { id: 0 },
        ob_cidade: { id: 0 },
        ob_tipo: { id: 0 },
        ob_foro: { id: 0 },
        qtd_anexo: 0,
        ie_tipo: "DI",
      }));

      if (params?.update?.list !== false) {
        this.context.commit("updateDiligences", data.results);
        this.context.commit("updatePaginationTotal", data.count);
        await this.context.dispatch("getSummary", { ie_solicitacao: params?.filters?.ie_solicitacao });
      }

      for (const diligence of data.results) {
        const requests = [];

        if (params?.request?.cd_tipo !== false && Number(diligence.cd_tipo))
          requests.push(this.context.dispatch("acttype/get", { cd: diligence.cd_tipo }, { root: true }));
        else requests.push(null);
        if (params?.request?.cd_foro === true && Number(diligence.cd_foro))
          requests.push(this.context.dispatch("forum/get", { cd: diligence.cd_foro }, { root: true }));
        else requests.push(null);
        if (params?.request?.qtd_anexo !== false)
          requests.push(this.context.dispatch("getFilesQuantity", { cd: diligence.id }));
        else requests.push(null);

        try {
          const [ob_tipo, ob_foro, qtd_anexo] = await Promise.allSettled(requests);
          diligence.ob_tipo = ob_tipo.status === "fulfilled" ? await ob_tipo.value : {};
          diligence.ob_foro = ob_foro.status === "fulfilled" ? await ob_foro.value : {};
          diligence.qtd_anexo = qtd_anexo.status === "fulfilled" ? await qtd_anexo.value : 0;
        } catch (error) {
          continue;
        }
      }

      if (params?.update?.list !== false) this.context.commit("updateDiligences", data.results);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getSolicitation(params?: DiligenceParams) {
    try {
      const { data } = await this.service.getSolicitation({ ...this.filters, ...(params?.filters || {}) });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getAllFiles({ cd, cd_empresa }: FileParams) {
    try {
      const response = await this.service.getAllFiles(cd, cd_empresa);
      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getFilesQuantity(params: DiligenceParams) {
    try {
      const response = await this.context.dispatch("getAllFiles", {
        cd: params.cd,
      });

      return response?.length ?? 0;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async create(params: DiligenceParams) {
    try {
      const { data } = await this.service.create(params.diligence);

      const message: ToastParams[] = [{ severity: "success", summary: "Diligência cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      if (params.files)
        this.context.dispatch("createFile", {
          cd: data.id,
          files: params.files,
        });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async edit(params: DiligenceParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.diligence);

      const messages: ToastParams[] = [
        {
          severity: "success",
          summary: "Diligência editada com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", messages, { root: true });

      if (params.files)
        this.context.dispatch("createFile", {
          cd: data.id,
          files: params.files,
        });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getLogisticsCustomers(params?: DiligenceParams) {
    try {
      const { data } = await this.service.getLogisticsCustomers(
        { page: 1, nr_por_pagina: 10, paginacao: true },
        params?.filters
      );
      this.context.commit("updateLogisticsCustomers", data.results);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createFile(params: DiligenceParams) {
    try {
      if (params.files)
        for (const file of params.files || []) {
          const response = await this.service.createFile(params.cd, file, params.cd_empresa);

          if (response.status !== 201) return false;
        }

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: params.files?.length > 1 ? "Arquivos cadastrados com sucesso!" : "Arquivo cadastrado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return true;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteFile(params: DiligenceParams) {
    try {
      const response = await this.service.removeFile(params.cd_diligencia, params.cd, params.cd_estabelecimento);

      const message: ToastParams[] = [{ severity: "success", summary: "Arquivo excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCounterFile(params: DiligenceParams) {
    try {
      const response = await this.service.getCounterFile(params.cd, params.cd_diligencia, params.cd_estabelecimento);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async addCorrespondent(params: any) {
    try {
      const { data } = await this.service.addCorrespondent(params.cd, params.form);

      const messages: ToastParams[] = [
        {
          severity: "success",
          summary: "Correspondente adicionado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async removeCorrespondent(params: DiligenceParams) {
    try {
      const response = await this.service.removeCorrespondent(params.cd);

      const messages: ToastParams[] = [{ severity: "success", summary: "Solicitação cancelada com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async reopen(params: DiligenceParams) {
    try {
      const { data } = await this.service.reopen(params.cd);

      const messages: ToastParams[] = [{ severity: "success", summary: "Diligência reaberta com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async cancel(params: DiligenceCancelParams) {
    try {
      const { data } = await this.service.cancel(params.cd, params.ds_motivo_cancelamento);

      const messages: ToastParams[] = [{ severity: "success", summary: "Diligência cancelada com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async finish(params: DiligenceParams) {
    try {
      const { data } = await this.service.finish(params.cd);

      const messages: ToastParams[] = [
        {
          severity: "success",
          summary: "Diligência finalizada com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async accept(params: { cd: number }) {
    try {
      const { data } = await this.service.accept(params.cd, this.context.rootState.user.current.id);

      const messages: ToastParams[] = [{ severity: "success", summary: "Diligência aceita com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async requestConference(params: { cd: number; form: any }) {
    try {
      const { data } = await this.service.requestConference(params.cd, params.form);

      const messages: ToastParams[] = [{ severity: "success", summary: "Solicitação de conferência enviada!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getTypes() {
    try {
      const { data } = await this.service.getTypes();

      this.context.commit("updateTypes", data);
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getSummary(params: any) {
    try {
      const { data } = await this.service.getSummary(params?.ie_solicitacao);
      this.context.commit("updateSummary", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return {};
    }
  }

  @Action
  async getAdditionalData({ items, options }: { items: Diligence[]; options: DiligenceParams }) {
    const cacheIds = {
      customers: Object.keys(cache.customers).map(Number),
      cities: Object.keys(cache.cities).map(Number),
      types: Object.keys(cache.types).map(Number),
      forums: Object.keys(cache.forums).map(Number),
      files: Object.keys(cache.files).map(Number),
      correspondents: Object.keys(cache.correspondents).map(Number),
    };

    const requestIds = {
      customers: [...new Set(items.map((item) => Number(item.cd_cliente)).filter(Boolean))] as unknown as number[],
      cities: [...new Set(items.map((item) => Number(item.cd_cidade)).filter(Boolean))] as unknown as number[],
      types: [...new Set(items.map((item) => Number(item.cd_tipo)).filter(Boolean))] as unknown as number[],
      forums: [...new Set(items.map((item) => Number(item.cd_foro)).filter(Boolean))] as unknown as number[],
      files: [...new Set(items.map((item) => Number(item.id)).filter(Boolean))] as unknown as number[],
      correspondents: [
        ...new Set(
          items
            .reduce((result, item) => [...result, ...(item.cd_correspondente || [])], [] as DiligenceCorrespondent[])
            .map((correspondent) => Number(correspondent.cd_correspondente))
            .filter(Boolean)
        ),
      ] as unknown as number[],
    };

    if (options?.request?.cd_cliente === false) {
      requestIds.customers = [];
    }
    if (options?.request?.cd_cidade === false) {
      requestIds.cities = [];
    }
    if (options?.request?.cd_tipo === false) {
      requestIds.types = [];
    }
    if (options?.request?.cd_foro === false) {
      requestIds.forums = [];
    }
    if (options?.request?.qtd_anexo === false) {
      requestIds.files = [];
    }
    if (options?.request?.cd_correspondente !== true) {
      requestIds.correspondents = [];
    }

    requestIds.customers = requestIds.customers.filter((id) => !cacheIds.customers.includes(id));
    requestIds.cities = requestIds.cities.filter((id) => !cacheIds.cities.includes(id));
    requestIds.types = requestIds.types.filter((id) => !cacheIds.types.includes(id));
    requestIds.forums = requestIds.forums.filter((id) => !cacheIds.forums.includes(id));
    requestIds.files = requestIds.files.filter((id) => !cacheIds.files.includes(id));
    requestIds.correspondents = requestIds.correspondents.filter((id) => !cacheIds.correspondents.includes(id));

    await Promise.allSettled([
      ...requestIds.customers.map(async (id) => {
        const result = await this.service.getLogisticsCustomer(id);
        cache.customers[Number(id)] = result || null;
      }),
      ...requestIds.cities.map(async (id) => {
        const result = await this.context.dispatch("location/getCity", { cd_cidade: id }, { root: true });
        cache.cities[Number(id)] = result || null;
      }),
      ...requestIds.types.map(async (id) => {
        const result = await this.context.dispatch("acttype/get", { cd: id }, { root: true });
        cache.types[Number(id)] = result || null;
      }),
      ...requestIds.forums.map(async (id) => {
        const result = await this.context.dispatch("forum/get", { cd: id }, { root: true });
        cache.forums[Number(id)] = result || null;
      }),
      ...requestIds.files.map(async (id) => {
        const result = await this.context.dispatch("getFilesQuantity", { cd: id });
        cache.files[Number(id)] = result || null;
      }),
      ...requestIds.correspondents.map(async (id) => {
        const result = await await this.context.dispatch("correspondent/get", { cd: id }, { root: true });
        cache.correspondents[Number(id)] = result || null;
      }),
    ]);

    for (const item of items) {
      item.ob_cliente = cache.customers[Number(item.cd_cliente)] || undefined;
      item.ob_cidade = cache.cities[Number(item.cd_cidade)] || undefined;
      item.ob_tipo = cache.types[Number(item.cd_tipo)] || undefined;
      item.ob_foro = cache.forums[Number(item.cd_foro)] || undefined;
      item.qtd_anexo = cache.files[Number(item.id)] || 0;
      item.ie_tipo = "DI";

      item.cd_correspondente?.forEach((correspondent) => {
        correspondent.ob_correspondente = cache.correspondents[Number(correspondent?.cd_correspondente)] || undefined;
      });
    }

    return items;
  }
}

export default DiligenceModule;
