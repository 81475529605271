import { Pagination, PaginationRow } from "@/store/types";

export const paginationRowOptions: PaginationRow[] = [10];

export const paginationTemplate =
  "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";

export const paginationReportTemplate = "{first}-{last} de {totalRecords}";

export function generatePaginationOptions(pagination: Partial<Pagination>) {
  return {
    page: pagination.current,
    nr_por_pagina: pagination.row,
    paginacao: true,
  };
}
