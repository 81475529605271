import gojus from "@/services/gojus";

import { Workplace, WorkplaceForm } from "@/store/department/workplace/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_DEPARTMENT_URL } = process.env;

export class WorkplaceService {
  endpoint = "trabalhos/locais/";
  cdEndpoint = (cd: number) => `trabalhos/locais/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<Workplace>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async getAll(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<Workplace>>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_DEPARTMENT_URL,
      },
    });

    return response;
  }

  async create(workplace: WorkplaceForm) {
    const response = await gojus.post<Workplace, WorkplaceForm>({
      entity: this.endpoint,
      data: workplace,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async edit(cd: number, workplace: WorkplaceForm) {
    const response = await gojus.patch<Workplace, WorkplaceForm>({
      entity: this.cdEndpoint(cd),
      data: workplace,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });
    return response;
  }
}

export default WorkplaceService;
