import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import GroupService from "@/store/group/service";

import { ToastParams } from "@/store/global/types";
import { Group, GroupParams } from "@/store/group/types";

@Module({ namespaced: true })
export class GroupModule extends VuexModule {
  all: Group[] = [];
  service = new GroupService();

  @Mutation
  updateGroup() {}

  @Mutation
  updateGroups(groups: Group[]) {
    this.all = groups;
  }

  @Action
  async get(params: GroupParams) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateGroup");

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const { data } = await this.service.getAll();
      this.context.commit("updateGroups", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: GroupParams) {
    try {
      const { data } = await this.service.create(params.group);
      this.context.commit("updateGroup");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Grupo criado com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: GroupParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.group);
      this.context.commit("updateGroup");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Grupo editado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: GroupParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateGroup");

      const message: ToastParams[] = [{ severity: "success", summary: "Grupo excluído com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default GroupModule;
