<template>
  <div class="c-loading-modal">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-property-decorator";

export default class CLoadingModal extends Vue {
  getFields() {}

  reset() {}
}
</script>

<style lang="scss">
.c-modal.loading-modal {
  overflow: hidden;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;

  .p-dialog-header {
    display: none;
  }
}

.c-loading-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .loader {
    $green: #008744;
    $blue: #0057e7;
    $red: #d62d20;
    $yellow: #ffa700;
    $white: #fff;

    $width: 120px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    z-index: 999;

    background-color: $white;

    .loader {
      width: $width;
      margin: 0 auto;

      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }

    .circular {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      margin: auto;
      animation: rotate 2s linear infinite;
      transform-origin: center center;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;

      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }

    @keyframes color {
      100%,
      0% {
        stroke: $red;
      }
      40% {
        stroke: $blue;
      }
      66% {
        stroke: $green;
      }
      80%,
      90% {
        stroke: $yellow;
      }
    }
  }
}
</style>
