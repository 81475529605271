import Vue from "vue";
import VueMeta from "vue-meta";

export function config() {
  Vue.use(VueMeta, {
    keyName: "metaInfo",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true,
  });
}
