import { ToastParams } from "@/store/global/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ResponsibleService } from "./service";

@Module({ namespaced: true })
export class ResponsibleModule extends VuexModule {
  all: any = [];

  service = new ResponsibleService();

  @Mutation
  updateResponsible(teams: any) {
    this.all = teams;
  }

  @Action
  async getAll(filters?: any) {
    try {
      const response = await this.service.getAll(filters);
      for (const item of response.data) {
        item.qnt_funcionarios = item.cd_funcionarios.length;
      }
      this.context.commit("updateResponsible", response.data);
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(form: any) {
    try {
      const { data } = await this.service.create(form);

      if (data) {
        for (const responsible of form.cd_responsaveis) {
          await this.context.dispatch("add", { cd_equipe: data.id, cd_funcionario: responsible });
        }
      }

      const message: ToastParams[] = [{ severity: "success", summary: "Equipe cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit({ cd, form }: { cd: string; form: any }) {
    const cd_funcionarios_antigos = form.team.cd_funcionarios.map((item: any) => {
      return item.id;
    });

    for (const responsibleAdd of form.cd_responsaveis) {
      if (!cd_funcionarios_antigos.includes(responsibleAdd))
        await this.context.dispatch("add", { cd_equipe: form.team.id, cd_funcionario: responsibleAdd });
    }

    for (const responsibleRemove of cd_funcionarios_antigos) {
      if (!form.cd_responsaveis.includes(responsibleRemove))
        await this.context.dispatch("remove", { cd_equipe: form.team.id, cd_funcionario: responsibleRemove });
    }

    const response = await this.service.update(cd, form);

    return response;
  }

  @Action
  async add(params: any) {
    try {
      const response = await this.service.add(params.cd_equipe, { cd_funcionario: params.cd_funcionario });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async remove(params: any) {
    try {
      const response = await this.service.remove(params.cd_equipe, params.cd_funcionario);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ResponsibleModule;
