import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/cobranca/devedores",
    name: "debtor",
    component: () => import("@/views/collection/debtor/list.vue"),
  },
  {
    path: "/dashboard/cobranca/devedores/adicionar",
    name: "debtor-add",
    component: () => import("@/views/collection/debtor/add.vue"),
  },
  {
    path: "/dashboard/cobranca/devedores/:cd",
    name: "debtor-details",
    component: () => import("@/views/collection/debtor/details.vue"),
  },
  {
    path: "/dashboard/cobranca/devedores/:cd/editar",
    name: "debtor-edit",
    component: () => import("@/views/collection/debtor/edit.vue"),
  },
  {
    path: "/dashboard/cobranca/devedores/:cd/auditoria",
    name: "debtor-audit",
    component: () => import("@/views/collection/debtor/audit.vue"),
  },
  {
    path: "/dashboard/cobranca/clientes",
    name: "collection-customer",
    component: () => import("@/views/collection/customer/list.vue"),
  },
  {
    path: "/dashboard/cobranca/clientes/:cd/detalhes",
    name: "collection-customer-details",
    component: () => import("@/views/collection/customer/details.vue"),
  },
  {
    path: "/dashboard/cobranca/clientes/:cd/editar",
    name: "collection-customer-edit",
    component: () => import("@/views/collection/customer/edit.vue"),
  },
  {
    path: "/dashboard/cobranca/clientes/adicionar",
    name: "collection-customer-add",
    component: () => import("@/views/collection/customer/add.vue"),
  },
  {
    path: "/dashboard/cobranca/regra-cobranca",
    name: "collection-rule",
    component: () => import("@/views/develop.vue"),
  },
  {
    path: "/dashboard/cobranca/tipos-debito",
    name: "collection-debit-types",
    component: () => import("@/views/develop.vue"),
  },
];

export default routes;
