import gojus from "@/services/gojus";

import { BillToPay, BillToPayForm } from "@/store/financial/bill-to-pay/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class BillToPayService {
  endpoint = "pagamentos/";
  cdEndpoint = (cd: number) => `pagamentos/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<BillToPay>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getAll(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<BillToPay>>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_FINANCIAL_URL,
      },
    });

    return response;
  }

  async create(billToPay: BillToPayForm) {
    const response = await gojus.post<BillToPay, BillToPayForm>({
      entity: this.endpoint,
      data: billToPay,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async edit(cd: number, billToPay: BillToPayForm) {
    const response = await gojus.patch<BillToPay, BillToPayForm>({
      entity: this.cdEndpoint(cd),
      data: billToPay,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });
    return response;
  }
}

export default BillToPayService;
