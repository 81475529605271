import gojus from "@/services/gojus";

import {
  Debtor,
  DebtorAgreement,
  DebtorAgreementForm,
  DebtorAgreementType,
  DebtorAgreementTypeForm,
  DebtorMove,
  DebtorMoveForm,
  DebtorRating,
  DebtorRatingForm,
  DebtorStatus,
  DebtorStatusForm,
  DebtType,
  DebtTypeForm,
  Partner,
  PartnerForm,
  DebtorAddress,
  DebtorAddressForm,
  DebtorUpdatedDebtForm,
  DebtorUpdatedDebt,
} from "@/store/collection/debtor/types";

import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_COLLECTION_URL, VUE_APP_API_ANALYTICS_URL } = process.env;

export class DebtorService {
  endpoint = "devedores/";
  infoCardEndpoint = (card: string) => `devedores/info-cards/?card=${card}`;
  endpointExcel = "cobranca/devedores/";
  movementExcelEndpoint = "cobranca/atualizacoes-movimentos";
  cdEndpoint = (cd: number) => `devedores/${cd}/`;
  debtTypeEndpoint = "tipo-debito/";
  debtTypeCDEndpoint = (cd: number) => `tipo-debito/${cd}/`;
  partnerEndpoint = "socios/";
  statusEndpoint = "status/";
  ratingEndpoint = "classificacao/";
  agreementTypeEndpoint = "tipo-acordo/";
  cdStatusEndpoint = (cd: number) => `status/${cd}/`;
  cdRatingEndpoint = (cd: number) => `classificacao/${cd}/`;
  cdAgreementTypeEndpoint = (cd: number) => `tipo-acordo/${cd}/`;
  partnerCDEndpoint = (cd: number) => `socios/${cd}/`;
  cdPartnerCDEndpoint = (cd: number, cd_devedor: number) => `socios/${cd}/${cd_devedor}/`;
  agreementEndpoint = "acordos/";
  agreementCDEndpoint = (cd: number) => `acordos/${cd}/`;
  cdAgreementCDEndpoint = (cd: number, cd_devedor: number) => `acordos/${cd}/${cd_devedor}/`;
  parcelCDEndpoint = (cd: number) => `parcelas/${cd}/`;
  cdParcelCDEndpoint = (cd: number, cd_acordo: number) => `parcelas/${cd}/acordo/${cd_acordo}/`;
  reopenParcelEndpoint = (cd: number, cd_acordo: number) => `parcelas/reabrir/${cd}/acordo/${cd_acordo}/`;
  moveEndpoint = "movimentacoes/";
  moveCDEndpoint = (cd: number) => `movimentacoes/${cd}/`;
  cdMoveCDEndpoint = (cd: number, cd_devedor: number) => `movimentacoes/${cd}/${cd_devedor}/`;
  addressEndpoint = "endereco/";
  addressCDEndpoint = (cd: number) => `endereco/${cd}/`;
  cdAddressCDEndpoint = (cd: number, cd_devedor: number) => `endereco/${cd}/${cd_devedor}/`;
  updatedDebtEndpoint = "atualizacao-debito/";
  updatedDebtCDEndpoint = (cd: number) => `atualizacao-debito/${cd}/`;
  cdUpdatedDebtCDEndpoint = (cd: number, cd_devedor: number) => `atualizacao-debito/${cd}/${cd_devedor}/`;
  holderEndPoint = "devedores-substitutos/";
  summaryEndpoint = "devedores/resumo/";
  updatelessEndPoint = "devedores/sem-atualizacoes/";
  updatesEndPoint = "devedores-movimentacoes/";
  dossier = (cd: string | number) => `cobranca/devedor/${cd}/dossie`;

  async get(cd: number) {
    const { data } = await gojus.get<Debtor>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async getAll(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<Debtor>>({
      entity: filters?.infoCard ? this.infoCardEndpoint(filters.infoCard) : this.endpoint,
      config: { query: { ...pagination, ...filters }, baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async getDossier(cd: number) {
    const response = await gojus.get<any>({
      entity: this.dossier(cd),
      config: { baseAPI: VUE_APP_API_ANALYTICS_URL },
    });

    return response;
  }

  async getUpdates(filter: any) {
    const { data } = await gojus.get<Debtor[]>({
      entity: this.updatesEndPoint,
      config: {
        query: { ...filter },
        baseAPI: VUE_APP_API_COLLECTION_URL,
      },
    });

    return { data };
  }

  async getReportExcel(pagination?: PaginationParams | null, filters?: any) {
    const { data } = await gojus.get<any>({
      entity: this.endpointExcel,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return { data };
  }

  async getReportMovementExcel(filters?: any) {
    const { data } = await gojus.get<any>({
      entity: this.movementExcelEndpoint,
      config: { query: filters, baseAPI: VUE_APP_API_ANALYTICS_URL },
    });

    return { data };
  }

  async create(debtor: Debtor) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: debtor,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return { data };
  }

  async edit(cd: number, debtor: Debtor) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: debtor,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async createHolder(form: any) {
    const { data } = await gojus.post({
      entity: this.holderEndPoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return { data };
  }

  async strategic(cd: number, form: { ie_estrategico: boolean }) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async editDebtorParcial(cd: number, form: any) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async checkMovement(cdMove: number, cdDebtor: number, date: string) {
    const { data } = await gojus.patch({
      entity: this.cdMoveCDEndpoint(cdMove, cdDebtor),
      data: { dt_verificacao: date },
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return { data };
  }

  async getTypes() {
    const response = await gojus.get<DebtType[]>({
      entity: this.debtTypeEndpoint,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getType(cd_tipo_debito: number) {
    const response = await gojus.get<DebtType>({
      entity: this.debtTypeCDEndpoint(cd_tipo_debito),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getSummary(filter?: any) {
    const { data } = await gojus.get<any>({
      entity: this.summaryEndpoint,
      config: {
        query: filter,
        baseAPI: VUE_APP_API_COLLECTION_URL,
      },
    });

    return data;
  }

  async getUpdateless(filters?: any) {
    const { data } = await gojus.get<any>({
      entity: this.updatelessEndPoint,
      config: {
        query: filters,
        baseAPI: VUE_APP_API_COLLECTION_URL,
      },
    });

    return { data };
  }

  async createDebtType(form: DebtTypeForm) {
    form.nm_tipo_debito = form.nm_tipo_debito.toUpperCase();

    const response = await gojus.post<DebtType, DebtTypeForm>({
      entity: this.debtTypeEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getStatusCD(cd_status: number) {
    const response = await gojus.get<DebtorStatus>({
      entity: this.cdStatusEndpoint(cd_status),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getStatus() {
    const response = await gojus.get<DebtorStatus[]>({
      entity: this.statusEndpoint,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async createStatus(form: DebtorStatusForm) {
    form.nm_status = form.nm_status.toUpperCase();

    const response = await gojus.post<DebtorStatus, DebtorStatusForm>({
      entity: this.statusEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getRating(cd_classificacao: number) {
    const response = await gojus.get<DebtorRating>({
      entity: this.cdRatingEndpoint(cd_classificacao),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getRatings() {
    const response = await gojus.get<DebtorRating[]>({
      entity: this.ratingEndpoint,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async createRating(form: DebtorRatingForm) {
    form.nm_classificacao = form.nm_classificacao.toUpperCase();

    const response = await gojus.post<DebtorRating, DebtorRatingForm>({
      entity: this.ratingEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getAgreementType(cd_tipo_acordo: number) {
    const response = await gojus.get<DebtorAgreementType>({
      entity: this.cdAgreementTypeEndpoint(cd_tipo_acordo),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getAgreementTypes() {
    const response = await gojus.get<DebtorAgreementType[]>({
      entity: this.agreementTypeEndpoint,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async createAgreementType(form: DebtorAgreementTypeForm) {
    form.nm_tipo_acordo = form.nm_tipo_acordo.toUpperCase();

    const response = await gojus.post<DebtorAgreementType, DebtorAgreementTypeForm>({
      entity: this.agreementTypeEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getPartners(cd_devedor: number) {
    const response = await gojus.get<PaginationResponse<Partner[]>>({
      entity: this.partnerCDEndpoint(cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async addPartner(partner: PartnerForm) {
    const response = await gojus.post<Partner, PartnerForm>({
      entity: this.partnerEndpoint,
      data: partner,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async editPartner(partner: PartnerForm) {
    const response = await gojus.patch<Partner, PartnerForm>({
      entity: this.cdPartnerCDEndpoint(Number(partner.id), Number(partner.cd_devedor)),
      data: partner,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async deletePartner(cd: number, cd_devedor: number) {
    const response = await gojus.delete({
      entity: this.cdPartnerCDEndpoint(cd, cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return response;
  }

  async getAgreement(cd: number, cd_devedor: number) {
    const response = await gojus.get<DebtorAgreement>({
      entity: this.cdAgreementCDEndpoint(cd, cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    if (response.data.cd_tipo_debito)
      response.data.ob_tipo_debito = (await this.getType(response.data.cd_tipo_debito)).data;

    return response;
  }

  async getAgreements(cd_devedor: number) {
    const response = await gojus.get<DebtorAgreement[]>({
      entity: this.agreementCDEndpoint(cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    for (const agreement of response.data) {
      if (agreement.cd_tipo_debito) agreement.ob_tipo_debito = (await this.getType(agreement.cd_tipo_debito)).data;
      else agreement.ob_tipo_debito = { id: 0 };
      if (agreement.cd_tipo_acordo)
        agreement.ob_tipo_acordo = (await this.getAgreementType(agreement.cd_tipo_acordo)).data;
      else agreement.ob_tipo_acordo = { id: 0 };
    }

    return response;
  }

  async addAgreement(agreement: DebtorAgreementForm) {
    const form = new FormData();

    (Object.keys(agreement) as Array<keyof typeof agreement>).forEach((agreementKey) => {
      if (agreement[agreementKey] !== undefined && agreement[agreementKey] !== null)
        form.append(agreementKey, agreement[agreementKey]);
    });

    const response = await gojus.post<DebtorAgreement, FormData>({
      entity: this.agreementEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async setAgreementFile(cd: number, cd_devedor: number, file: any) {
    const form = new FormData();
    form.append("aq_arquivo", file);

    const response = await gojus.patch({
      entity: this.cdAgreementCDEndpoint(cd, cd_devedor),
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async editAgreement(cd: number, cd_devedor: number, agreement: DebtorAgreementForm) {
    const form = new FormData();

    (Object.keys(agreement) as Array<keyof typeof agreement>).forEach((agreementKey) => {
      if (agreement[agreementKey] !== undefined && agreement[agreementKey] !== null)
        form.append(agreementKey, agreement[agreementKey]);
    });

    const response = await gojus.patch({
      entity: this.cdAgreementCDEndpoint(cd, cd_devedor),
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async homologate(cd: number, cd_devedor: number, ie_homologado: boolean) {
    const response = await gojus.patch({
      entity: this.cdAgreementCDEndpoint(cd, cd_devedor),
      data: { ie_homologado },
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async cancelAgreement(cd: number, cd_devedor: number) {
    const response = await gojus.delete({
      entity: this.cdAgreementCDEndpoint(cd, cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getParcels(cd_acordo: number) {
    const response = await gojus.get<DebtorAgreement[]>({
      entity: this.parcelCDEndpoint(cd_acordo),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async editParcel(cd: number, cd_acordo: number, form: any) {
    const response = await gojus.patch({
      entity: this.cdParcelCDEndpoint(cd, cd_acordo),
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async reopenParcel(cd_parcela: number, cd_acordo: number) {
    try {
      const response = await gojus.patch({
        entity: this.reopenParcelEndpoint(cd_parcela, cd_acordo),
        config: { baseAPI: VUE_APP_API_COLLECTION_URL },
      });

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      return null;
    }
  }

  async createMove(form: DebtorMoveForm) {
    const response = await gojus.post<DebtorMove, DebtorMoveForm>({
      entity: this.moveEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async getAddress(cd: number) {
    const response = await gojus.get<DebtorAddress[]>({
      entity: this.addressCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async addAddress(form: DebtorAddressForm) {
    const response = await gojus.post<DebtorAddress, DebtorAddressForm>({
      entity: this.addressEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async editAddress(address: DebtorAddressForm) {
    const response = await gojus.patch<DebtorAddress, DebtorAddressForm>({
      entity: this.cdAddressCDEndpoint(Number(address.id), Number(address.cd_devedor)),
      data: address,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async deleteAddress(cd: number, cd_devedor: number) {
    const response = await gojus.delete({
      entity: this.cdAddressCDEndpoint(cd, cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return response;
  }

  async getUpdatedDebt(cd: number) {
    const response = await gojus.get<DebtorUpdatedDebt[]>({
      entity: this.updatedDebtCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async addUpdatedDebt(form: DebtorUpdatedDebtForm) {
    const response = await gojus.post<DebtorUpdatedDebt, DebtorUpdatedDebtForm>({
      entity: this.updatedDebtEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async editUpdatedDebt(updatedDebt: DebtorUpdatedDebtForm) {
    const response = await gojus.patch<DebtorUpdatedDebt, DebtorUpdatedDebtForm>({
      entity: this.cdUpdatedDebtCDEndpoint(Number(updatedDebt.id), Number(updatedDebt.cd_devedor)),
      data: updatedDebt,
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return response;
  }

  async deleteUpdatedDebt(cd: number, cd_devedor: number) {
    const response = await gojus.delete({
      entity: this.cdUpdatedDebtCDEndpoint(cd, cd_devedor),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return response;
  }
}

export default DebtorService;
