<template>
  <c-form-template class="c-task-distribution" :useFooter="false" :isFlex="true">
    <c-form-field-template width="60%">
      <template #label>Titulo da Tarefa</template>
      <p-input-text v-model="nm_titulo" placeholder="DISTRIBUIÇÃO" autofocus />
    </c-form-field-template>
    <c-form-field-template width="40%">
      <template #label>Equipe da Tarefa<b class="is-required" /></template>
      <p-dropdown
        v-model="cd_equipe"
        :options="teams"
        optionValue="id"
        optionLabel="nm_equipe"
        required
        @input="handleTeamInput"
      />
    </c-form-field-template>

    <c-form-field-template width="60%">
      <template #label>Data do Vencimento<b class="is-required" /></template>
      <p-calendar v-model="dt_vencimento" dateFormat="dd/mm/yy" :locale="calendarConfig" required />
    </c-form-field-template>
    <c-form-field-template width="40%">
      <template #label>Hora do Vencimento<b class="is-required" /></template>
      <p-input-mask v-model="hr_vencimento" mask="99:99" required />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Descrição</template>
      <p-textarea v-model="ds_descricao" :autoResize="true" rows="5" cols="auto" />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDateEn, formatHours } from "@/utils/formatters";
import { Team } from "@/store/team/types";

@Component({ components: { CFormTemplate, CFormFieldTemplate } })
export default class CModalTaskDistribution extends Vue {
  nm_titulo = "DISTRIBUIÇÃO";
  cd_equipe = null;
  nm_equipe = "";
  dt_vencimento = "";
  hr_vencimento = "23:59";
  ds_descricao = "";

  calendarConfig = calendarConfig;

  get teams(): Team[] {
    return this.$store.state.department.responsible.all;
  }

  handleTeamInput(teamId: number) {
    const team = this.teams?.find((item) => item.id === teamId);
    this.nm_equipe = team?.nm_equipe || "";
  }

  getFields(disableValidation?: boolean) {
    const form = {
      nm_titulo: this.nm_titulo,
      nm_equipe: this.nm_equipe,
      cd_equipe: this.cd_equipe,
      dt_vencimento: formatDateEn(this.dt_vencimento),
      hr_vencimento: formatHours(this.hr_vencimento),
      ds_descricao: this.ds_descricao,
    };

    if (!disableValidation && (!form.dt_vencimento || !form.hr_vencimento || !form.cd_equipe)) {
      this.$store.commit("global/updateToast", [
        { summary: "Preencha todos os campos obrigatórios!", severity: "warn" },
      ]);

      return { hasError: true };
    }

    return form;
  }

  reset() {}

  created() {
    this.$store.dispatch("department/responsible/getAll");
  }
}
</script>

<style lang="scss">
.p-dialog.task-distribution .p-dialog-content {
  padding-bottom: 1.1rem;
  width: 90vw;
  max-width: 640px;
}

.c-task-distribution {
  padding: 0 !important;
  font-size: 0.4rem !important;

  .p-inputtextarea {
    max-height: 40vh;
  }
}
</style>
