import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/profile",
    name: "dashboard-profile",
    component: () => import("@/views/profile/profile.vue"),
  },
];

export default routes;
