import gojus from "@/services/gojus";

import { Correspondent, CorrespondentForm } from "@/store/correspondent/types";

const { VUE_APP_API_LOGISTICS_URL } = process.env;

export class CorrespondentService {
  endpoint = "correspondentes/";
  register = "correspondentes/cadastro/";
  cdEndpoint = (cd: number) => `correspondentes/${cd}/`;
  honoraryEndPoint = "correspondentes/honorarios/";
  profileEndPoint = "perfil-correspondente/";
  cdProfileEndPoint = (cd: number) => `perfil-correspondente/${cd}/`;
  inviteEndpoint = "usuarios/convite/";

  async get(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<Correspondent>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL, query },
    });

    return { data };
  }

  async getAll(filters: any) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<any>({
      entity: this.endpoint,
      config: { query: { ...filters, ...query }, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async getHonorary(filters: any) {
    const { data } = await gojus.get<any>({
      entity: this.honoraryEndPoint,
      config: { query: filters, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return data;
  }

  async getProfile(filters: any) {
    const { data } = await gojus.get<any>({
      entity: this.profileEndPoint,
      config: { query: filters, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async create(form: CorrespondentForm) {
    const response = await gojus.post<Correspondent, CorrespondentForm>({
      entity: this.register,
      data: form,
      config: { useToken: false, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return response;
  }

  async edit(cd: number, form: CorrespondentForm) {
    const { data } = await gojus.patch<Correspondent, CorrespondentForm>({
      entity: this.cdEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }
  async editProfile(cd: number, form: CorrespondentForm) {
    const { data } = await gojus.patch<Correspondent, CorrespondentForm>({
      entity: this.cdProfileEndPoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async invite(ds_email: string) {
    const { data } = await gojus.get<{ ds_email: string }>({
      entity: this.inviteEndpoint,
      config: {
        query: { ds_email },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        useToken: false,
      },
    });

    return { data };
  }
}

export default CorrespondentService;
