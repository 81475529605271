import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";

import CorrespondentService from "@/store/correspondent/service";
import { Correspondent, CorrespondentParams, Honorary } from "@/store/correspondent/types";
import { ToastParams } from "../global/types";
import { Pagination, PaginationRow } from "../types";

@Module({ namespaced: true })
export class CorrespondentModule extends VuexModule {
  current: Correspondent = { id: 0 };
  profile: Correspondent | null = null;
  all: Correspondent[] = [];
  service = new CorrespondentService();
  filters: any = {};
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  @Mutation
  updateCorrespondent(correspondent: Correspondent) {
    this.current = correspondent;
  }

  @Mutation
  setProfile(profile: Correspondent) {
    this.profile = profile;
  }

  @Mutation
  updateCorrespondents(correspondents: Correspondent[]) {
    this.all = correspondents;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(params?: { cd?: number }) {
    try {
      if (!params?.cd) params = { cd: this.context.rootState.user.current.id };

      const { data } = await this.service.get(params?.cd || 0);
      this.context.commit("updateCorrespondent", data);

      return data;
    } catch (error: any) {
      if (error?.status !== 404) {
        this.context.dispatch("global/handleError", error, { root: true });
      }
    }
  }

  @Action
  async getProfile() {
    try {
      const response = await this.service.getProfile({});
      this.context.commit("setProfile", response.data || null);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params: CorrespondentParams) {
    try {
      const { data } = await this.service.getAll({
        ...this.filters,
        ...(params?.filters || {}),
      });
      if (data.results) this.context.commit("updateCorrespondents", data.results);
      else this.context.commit("updateCorrespondents", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getList(params: CorrespondentParams) {
    try {
      const response = await this.service.getAll({
        page: this.pagination.current,
        nr_por_pagina: this.pagination.row,
        paginacao: true,
        ...this.filters,
        ...(params?.filters || {}),
      });

      this.context.commit("updateCorrespondents", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getHonorary(params: any) {
    try {
      const result = await this.service.getHonorary({
        ...this.filters,
        ...(params?.filters || {}),
      });

      const itens: Honorary[] = result.results;

      result.results = itens;

      return result;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: CorrespondentParams) {
    try {
      const response = await this.service.create(params.form);
      this.context.commit("updateCorrespondent");

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: CorrespondentParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.form);
      this.context.commit("updateCorrespondent");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Seus dados foram atualizados com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editProfile(params: CorrespondentParams) {
    try {
      const { data } = await this.service.editProfile(params.cd, params.form);
      this.context.commit("updateCorrespondent");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Seus dados foram atualizados com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async invite(params: { ds_email: string }) {
    try {
      const { data } = await this.service.invite(params.ds_email);
      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default CorrespondentModule;
