<template>
  <div class="c-modal-lawsuit-preview">
    <c-dashboard-infos-template :items="generalInfos" :columns="2" />
    <div class="c-dashboard-infos-template litisconsorte">
      <c-dashboard-infos-template :columns="2" :items="litisconsorteAutorInfos" />
      <c-dashboard-infos-template :columns="1" :items="litisconsorteReuInfos" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CDashboardInfosTemplate from "@/components/dashboard/infos.vue";
import { handleLitisconsorteInfoItems } from "@/views/lawsuit/utils";

import { Lawsuit, LawsuitLitisconsorte } from "@/store/lawsuit/types";
import { InfoItem } from "@/utils/types";
import { formatProcessNumberInCnjPattern } from "@/utils/formatters";

@Component({ components: { CDashboardInfosTemplate } })
export default class CModalLawsuitPreview extends Vue {
  @Prop({ required: true }) readonly props!: { lawsuit: Lawsuit };
  litisconsorte: LawsuitLitisconsorte[] = [];

  get generalInfos() {
    const items: InfoItem[] = [];

    if (this.props.lawsuit.id)
      items.push({
        prefix: "#ID: ",
        text: this.props.lawsuit.id,
        title: "ID",
        icon: "fas fa-fingerprint",
      });

    if (this.props.lawsuit.nr_processo)
      items.push({
        prefix: "Processo: ",
        text: formatProcessNumberInCnjPattern(this.props.lawsuit.nr_processo),
        icon: "fas fa-gavel",
      });

    if (this.props.lawsuit.nm_cidade)
      items.push({
        prefix: "Comarca: ",
        text: this.props.lawsuit.nm_cidade + " / " + this.props.lawsuit.nm_estado,
        title: "Cidade e Estado",
        icon: "fas fa-street-view",
      });

    return items;
  }

  get litisconsorteReuInfos() {
    return handleLitisconsorteInfoItems(this.litisconsorte.filter((item) => item.nm_reu));
  }

  get litisconsorteAutorInfos() {
    return handleLitisconsorteInfoItems(this.litisconsorte.filter((item) => item.nm_autor));
  }

  async getLitisconsorte() {
    this.litisconsorte = [];

    if (this.props.lawsuit.nm_autor)
      this.litisconsorte.push({
        id: 0,
        cd_processo: Number(this.props.lawsuit.id),
        nm_autor: this.props.lawsuit.nm_autor,
        nr_cpf_autor: this.props.lawsuit.nr_cpf_autor,
        nr_cnpj_autor: this.props.lawsuit.nr_cnpj_autor,
      });
    if (this.props.lawsuit.nm_reu)
      this.litisconsorte.push({
        id: 0,
        cd_processo: Number(this.props.lawsuit.id),
        nm_reu: this.props.lawsuit.nm_reu,
      });

    const litisconsorteResponse: {
      data: LawsuitLitisconsorte[];
    } = await this.$store.state.lawsuit.service.getLitisconsorte(this.props.lawsuit.id);
    litisconsorteResponse.data.forEach((item) => this.litisconsorte.push(item));
  }

  created() {
    this.getLitisconsorte();
  }
}
</script>

<style lang="scss">
.c-modal-lawsuit-preview {
  overflow-y: auto;
  min-height: 240px;
  max-height: 90vh;
  width: 80vw;
  min-width: 360px;
  max-width: 1200px;

  padding: 4px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--info-color);
  }

  .litisconsorte {
    padding: 4px;

    .c-dashboard-infos-template {
      width: 50%;
      margin: 0;
    }
  }
}
</style>
