import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/avisos",
    name: "warning",
    component: () => import("@/views/warning/list.vue"),
  },
  {
    path: "/dashboard/avisos/adicionar",
    name: "warning-add",
    component: () => import("@/views/warning/add.vue"),
  },
  {
    path: "/dashboard/avisos/:cd",
    name: "warning-details",
    component: () => import("@/views/warning/details.vue"),
  },
  {
    path: "/dashboard/avisos/:cd/editar",
    name: "warning-edit",
    component: () => import("@/views/warning/edit.vue"),
  },
];

export default routes;
