import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";

import LocationService from "@/store/location/service";
import { State, City, CityParams } from "@/store/location/types";

@Module({ namespaced: true })
export class LocationModule extends VuexModule {
  states: State[] = [];
  cities: City[] = [];

  service = new LocationService();

  get hasStates() {
    return this.states.length !== 0;
  }

  @Mutation
  updateStates(states: State[]) {
    this.states = states;
  }

  @Mutation
  updateCities(cities: City[]) {
    this.cities = cities;
  }

  @Action
  async getStates() {
    try {
      const { data } = await this.service.getStates();
      this.context.commit("updateStates", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return [];
    }
  }

  @Action
  async getStatesRequest() {
    try {
      const { data } = await this.service.getStatesRequest();
      this.context.commit("updateStates", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCities(params: CityParams) {
    try {
      const { data } = await this.service.getCities(params?.cd_estado);
      this.context.commit("updateCities", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return [];
    }
  }

  @Action
  async getCity(params: CityParams) {
    try {
      const { data } = await this.service.getCity(params?.cd_cidade);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return null;
    }
  }
}

export default LocationModule;
