<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { TaskRecurrenceFormT, TaskRecurrenceT } from "@/store/task/types";

import PDropdown from "primevue/dropdown";
import PInputNumber from "primevue/inputnumber";
import PInputSwitch from "primevue/inputswitch";

import { taskRecurrenceTypeOptions, taskRecurrenceDayOptions } from "../utils";

@Component({ components: { PDropdown, PInputNumber, PInputSwitch } })
export default class CTaskRecurrenceModal extends Vue {
  @Prop({ required: true }) recurrenceForm!: Partial<TaskRecurrenceT> | null;

  taskRecurrenceTypeOptions = taskRecurrenceTypeOptions;
  taskRecurrenceDayOptions = taskRecurrenceDayOptions;

  form: TaskRecurrenceFormT = {
    dia_semana: [],
    tipo_recorrencia: 2,
    ie_status: true,
    qtd_repeticao: 1,
    nm_usuario_cri: "",
    id: null,
    cd_tarefa: null,
  };

  get isEditForm() {
    return Boolean(this.recurrenceForm?.id);
  }

  handleSelectDayOfWeek(option: { label: string; value: number }) {
    const indexOfOption = this.form.dia_semana.findIndex((day) => option.value === day);

    if (indexOfOption === -1) {
      this.form.dia_semana.push(option.value);
    } else {
      this.form.dia_semana.splice(indexOfOption, 1);
    }
  }

  handleSubmit() {
    this.$emit("submit:recurrence", { form: this.form });
    this.handleCloseModal();
  }

  handleCloseModal() {
    this.$emit("close:modal");
  }

  mounted() {
    document.body.style.overflowY = "hidden";
  }

  beforeDestroy() {
    document.body.style.overflowY = "unset";
  }

  created() {
    this.form.dia_semana = this.recurrenceForm?.cd_dia_recorrencia || [];
    this.form.tipo_recorrencia = this.recurrenceForm?.tipo_recorrencia || 2;
    this.form.ie_status = this.recurrenceForm?.ie_status ?? true;
    this.form.qtd_repeticao = this.recurrenceForm?.qtd_repeticao || 1;
    this.form.nm_usuario_cri = this.recurrenceForm?.nm_usuario_cri || this.$store.state.user.current?.username;
    this.form.id = this.recurrenceForm?.id || null;
    this.form.cd_tarefa = this.recurrenceForm?.cd_tarefa?.id || this.recurrenceForm?.cd_tarefa || null;
  }
}
</script>

<template>
  <div class="c-task-recurrence-modal" @click="handleCloseModal">
    <div class="modal-content" @click.stop>
      <i class="fa-solid fa-xmark" @click="handleCloseModal"></i>
      <h6 class="modal-title">{{ isEditForm ? "Editar" : "Adicionar" }} recorrência</h6>

      <form class="modal-form" @submit.prevent="handleSubmit">
        <div class="modal-form-repeat">
          <span class="modal-form-repeat-text">Repetir a cada:</span>
          <p-input-number v-model="form.qtd_repeticao" :min="1" :max="999" show-buttons />
          <p-dropdown
            v-model="form.tipo_recorrencia"
            :options="taskRecurrenceTypeOptions"
            :optionLabel="form.qtd_repeticao > 1 ? 'pluralLabel' : 'label'"
            optionValue="value"
          />
        </div>

        <div class="modal-form-weeks" v-if="form.tipo_recorrencia === 2 || form.tipo_recorrencia === 1">
          <p class="modal-form-weeks-title">Selecione os dias para recorrência:</p>
          <div class="modal-form-weeks-options">
            <span
              class="modal-form-weeks-option"
              v-for="option of taskRecurrenceDayOptions"
              :key="option.value"
              :class="{ 'is-selected': form.dia_semana.includes(option.value) }"
              @click="() => handleSelectDayOfWeek(option)"
            >
              {{ option.label }}
            </span>
          </div>
        </div>

        <div class="modal-form-months" v-if="form.tipo_recorrencia === 3">
          <p class="modal-form-months-title">
            Mensal no dia: <b>{{ new Date().getDate().toString().padStart(2, "0") }}</b>
          </p>
        </div>

        <div class="modal-form-status" v-if="isEditForm">
          <p class="modal-form-status-title">Status da Recorrência:</p>
          <div class="modal-form-status-option">
            <p-input-switch v-model="form.ie_status" />
            <span class="modal-form-status-option-name">
              {{ { true: "Ativo", false: "Inativo" }[String(form.ie_status)] }}
            </span>
          </div>
        </div>

        <div class="modal-form-footer">
          <button class="modal-form-cancel" type="button" @click="handleCloseModal">Cancelar</button>
          <button class="modal-form-submit" type="submit">Concluir</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.c-task-recurrence-modal {
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: grid;
  place-items: center;
  width: 100%;
  background-color: #000a;

  .modal-content {
    position: relative;
    width: 100%;
    max-width: 380px;
    padding: 24px 24px 16px;
    border-radius: 8px;
    background-color: #fff;

    .fa-xmark {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: 16px;
      transition: all 0.2s;

      &:hover {
        color: var(--danger-color);
      }
    }

    .modal-title {
      font-size: 16px;
    }

    .modal-form-repeat {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-top: 32px;

      .modal-form-repeat-text {
        flex: none;
        color: #555;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .p-inputnumber {
        width: 72px;
        height: 32px;

        .p-inputtext {
          width: 100%;
          box-shadow: none;
          text-align: center;
          border-radius: var(--border-radius-50);
        }

        .p-button {
          border: none;
          box-shadow: none;
          background-color: transparent;
          width: 1.75rem;

          .pi {
            color: #6c757d;
          }

          &:hover {
            .pi {
              color: var(--primary-color);
            }
          }
        }
      }

      .p-dropdown {
        width: 120px;
        height: 32px;
        box-shadow: none;
      }
    }

    .modal-form-weeks,
    .modal-form-months,
    .modal-form-status {
      margin-top: 24px;

      .modal-form-weeks-title,
      .modal-form-months-title,
      .modal-form-status-title {
        color: #555;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;

        b {
          font-size: 15px;
          letter-spacing: 1px;
        }
      }

      .modal-form-weeks-options {
        display: flex;
        gap: 8px;
        margin-top: 12px;

        .modal-form-weeks-option {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          height: 26px;
          border-radius: 999px;
          line-height: 0;
          border: 1px solid #eeea;
          background-color: #eeea;
          color: #555;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            border-color: var(--primary-color);
          }

          &.is-selected {
            color: #fff;
            border-color: var(--primary-color);
            background-color: var(--primary-color);
          }
        }
      }
    }

    .modal-form-status {
      .modal-form-status-option {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 12px;

        .p-inputswitch-slider {
          box-shadow: none;
        }

        .modal-form-status-option-name {
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
    }

    .modal-form-footer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 32px;

      .modal-form-cancel,
      .modal-form-submit {
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 8px;
        transition: all 0.2s;

        &:hover {
          background-color: #eee6;
        }
      }

      .modal-form-cancel {
        color: var(--danger-color);
      }

      .modal-form-submit {
        color: var(--success-color);
      }
    }
  }
}
</style>
