import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/logistica/clientes",
    name: "logistics-customer",
    component: () => import("@/views/logistics/customer/list.vue"),
  },
  {
    path: "/dashboard/logistica/clientes/:cd/detalhes",
    name: "logistics-customer-details",
    component: () => import("@/views/logistics/customer/details.vue"),
  },
  {
    path: "/dashboard/logistica/clientes/:cd/editar",
    name: "logistics-customer-edit",
    component: () => import("@/views/logistics/customer/edit.vue"),
  },
  {
    path: "/dashboard/logistica/clientes/:cd/auditar",
    name: "logistics-customer-audit",
    component: () => import("@/views/logistics/customer/audit.vue"),
  },
  {
    path: "/dashboard/logistica/clientes/adicionar",
    name: "logistics-customer-add",
    component: () => import("@/views/logistics/customer/add.vue"),
  },
  {
    path: "/dashboard/logistica/diligencias",
    name: "diligencia",
    component: () => import("@/views/logistics/diligence/list.vue"),
  },
  {
    path: "/dashboard/logistica/diligencias/adicionar",
    name: "diligencia-add",
    component: () => import("@/views/logistics/diligence/add.vue"),
  },
  {
    path: "/dashboard/logistica/diligencias/:cd",
    name: "diligencia-details",
    component: () => import("@/views/logistics/diligence/details.vue"),
  },
  {
    path: "/dashboard/logistica/diligencias/:cd/editar",
    name: "diligencia-edit",
    component: () => import("@/views/logistics/diligence/edit.vue"),
  },
  {
    path: "/dashboard/logistica/diligencias/:cd/auditar",
    name: "diligencia-audit",
    component: () => import("@/views/logistics/diligence/audit.vue"),
  },
  {
    path: "/dashboard/logistica/audiencias",
    name: "audiencia",
    component: () => import("@/views/logistics/hearing/list.vue"),
  },
  {
    path: "/dashboard/logistica/audiencias/adicionar",
    name: "audiencia-add",
    component: () => import("@/views/logistics/hearing/add.vue"),
  },
  {
    path: "/dashboard/logistica/audiencias/:cd",
    name: "audiencia-details",
    component: () => import("@/views/logistics/hearing/details.vue"),
  },
  {
    path: "/dashboard/logistica/audiencias/:cd/editar",
    name: "audiencia-edit",
    component: () => import("@/views/logistics/hearing/edit.vue"),
  },
  {
    path: "/dashboard/logistica/audiencias/:cd/auditar",
    name: "audiencia-audit",
    component: () => import("@/views/logistics/hearing/audit.vue"),
  },
  {
    path: "/dashboard/logistica/tipos-de-ato",
    name: "logistics-acttype",
    component: () => import("@/views/logistics/acttype/list.vue"),
  },
  {
    path: "/dashboard/logistica/tipos-de-ato/:cd",
    name: "logistics-acttype-details",
    component: () => import("@/views/logistics/acttype/details.vue"),
  },
  {
    path: "/dashboard/logistica/solicitacoes",
    name: "requests",
    component: () => import("@/views/logistics/request/list.vue"),
  },
  {
    path: "/dashboard/logistica/honorarios",
    name: "honorary",
    component: () => import("@/views/logistics/honorary/list.vue"),
  },
  {
    path: "/dashboard/logistica/solicitacoes/diligencia/:cd",
    name: "requests-diligence",
    component: () => import("@/views/logistics/request/details.vue"),
  },
  {
    path: "/dashboard/logistica/solicitacoes/audiencia/:cd",
    name: "requests-hearing",
    component: () => import("@/views/logistics/request/details.vue"),
  },
  {
    path: "/dashboard/logistica/correspondentes",
    name: "logistics-correspondent",
    component: () => import("@/views/logistics/correspondent/list.vue"),
  },
  {
    path: "/dashboard/logistica/correspondentes/:cd",
    name: "logistics-correspondent-details",
    component: () => import("@/views/logistics/correspondent/details.vue"),
  },
];

export default routes;
