<template>
  <c-form-template class="c-agreement-holder-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="75%">
      <template #label>Nome</template>
      <p-input-text v-model="nm_devedor" required />
    </c-form-field-template>

    <c-form-field-template>
      <template #label>Tipo de Pessoa</template>
      <p-select-button
        v-model="ie_tipo_pessoa"
        :options="customerTypes"
        optionLabel="label"
        optionValue="value"
        required
      />
    </c-form-field-template>

    <c-form-field-template width="16%">
      <template #label>{{ ie_tipo_pessoa === "PF" ? "CPF" : "CNPJ" }}</template>
      <p-input-mask v-show="ie_tipo_pessoa === 'PF'" v-model="nr_cpf" mask="999.999.999-99" />
      <p-input-mask v-show="ie_tipo_pessoa !== 'PF'" v-model="nr_cnpj" mask="99.999.999/9999-99" />
    </c-form-field-template>

    <c-form-field-template width="42%">
      <template #label>E-mail</template>
      <p-input-text v-model="ds_email" required />
    </c-form-field-template>

    <c-form-field-template width="42%">
      <template #label>Telefone</template>
      <p-input-mask v-model="nr_telefone" mask="(99) 99999-9999" required />
    </c-form-field-template>

    <c-form-field-template width="25%">
      <template #label>Logradouro</template>
      <p-input-text v-model="nm_rua" required />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Bairro</template>
      <p-input-text v-model="nm_bairro" required />
    </c-form-field-template>

    <c-form-field-template width="10%">
      <template #label>Número</template>
      <p-input-text v-model="nr_numero" type="number" required />
    </c-form-field-template>

    <c-form-field-template width="15%">
      <template #label>Estado</template>
      <p-dropdown
        v-model="cd_estado"
        dataKey="id"
        :options="states"
        optionLabel="nm_estado"
        optionValue="id"
        filter
        filterPlaceholder="Estado"
        autocomplete="off"
        required
        @change="getCities()"
      />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Cidade</template>
      <p-dropdown
        v-model="cd_cidade"
        dataKey="id"
        :options="cities"
        optionLabel="nm_cidade"
        optionValue="id"
        filter
        filterPlaceholder="Cidade"
        required
      />
    </c-form-field-template>

    <c-form-field-template width="10%">
      <template #label>CEP</template>
      <p-input-mask v-model="nr_cep" mask="99999-999" required />
    </c-form-field-template>
    <c-form-field-template width="50%">
      <template #label>Localização (Latitude e Longitude)</template>
      <p-input-text v-model="nr_coordenadas" :useGrouping="false" />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import { customerTypes } from "@/utils/acronyms";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import { removeCNPJMask, removeCPFMask } from "@/utils/mask";

@Component({ components: { CFormTemplate, CFormFieldTemplate } })
export default class CAgreementHolderModal extends Mixins() {
  @Prop({ required: true }) readonly props!: { [key: string]: any };

  customerTypes = customerTypes;

  loading = false;

  nm_devedor = "";
  ie_tipo_pessoa = "";
  nr_cpf = "";
  nr_cnpj = "";
  ds_email = "";
  nr_telefone = "";
  cd_estado = 0;
  cd_devedor = 0;
  cd_cidade = 0;
  nm_rua = "";
  nm_bairro = "";
  nr_numero = "";
  nr_cep = "";
  nr_coordenadas = "";
  cities = [];

  get states() {
    return this.$store.state.location.states;
  }

  async getCities() {
    this.cities = await this.$store.dispatch("location/getCities", {
      cd_estado: this.cd_estado,
    });
  }

  getFields(): any {
    return {
      nm_devedor: this.nm_devedor,
      ie_tipo_pessoa: this.ie_tipo_pessoa,
      nr_cpf: removeCPFMask(this.nr_cpf),
      nr_cnpj: removeCNPJMask(this.nr_cnpj),
      ds_email: this.ds_email,
      nr_telefone: this.nr_telefone,
      cd_estado: this.cd_estado,
      cd_devedor: this.cd_devedor,
      cd_cidade: this.cd_cidade,
      nm_rua: this.nm_rua,
      nm_bairro: this.nm_bairro,
      nr_numero: this.nr_numero,
      nr_cep: this.nr_cep,
      nr_coordenadas: this.nr_coordenadas,
    };
  }

  reset() {}

  async getComponentData() {
    await this.$store.dispatch("location/getStates");
  }

  created() {
    this.getComponentData();
  }
}
</script>

<style lang="scss">
.p-dialog.agreement-holder {
  width: 98vw;
  max-width: 1200px;
}
</style>
