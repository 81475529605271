export function addMoneyMask(value?: number | null) {
  if (!value) value = 0;

  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

export function removeMoneyMask(value?: string | number) {
  if (value === undefined) return "00.00";

  return value.toString().replace(/\./g, "").replace(",", ".").replace("R$ ", "");
}

export function removeMoneyMaskAndConvert(value?: string) {
  return parseFloat(removeMoneyMask(value));
}

export function removeCPFMask(value?: string | null): string {
  return value?.replace(/\./g, "").replace(/-/g, "") || "";
}

export function removeCNPJMask(value?: string | null): string {
  return value?.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "") || "";
}

export function addDateMask(date?: any): string {
  if (!date) return "";

  try {
    const maskedDate = date.replace(/\D/g, "");

    if (maskedDate?.length < 3) return maskedDate;
    if (maskedDate?.length < 5) return `${maskedDate.slice(0, 2)}/${maskedDate.slice(2, 4)}`;
    return `${maskedDate.slice(0, 2)}/${maskedDate.slice(2, 4)}/${maskedDate.slice(4, 8)}`;
  } catch {
    return date;
  }
}
