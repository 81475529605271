<template>
  <c-form-template class="c-pay-parcel-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="50%">
      <template #label>Data do Pagamento</template>
      <p-calendar
        v-model="date"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        yearRange="2000:2030"
        required
      />
    </c-form-field-template>
    <c-form-field-template width="50%">
      <template #label>Valor Pago</template>
      <p-input-text v-model="value" v-money="moneyMask" />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn } from "@/utils/formatters";
import moneyMask from "@/plugins/vmoney";
import { removeMoneyMask } from "@/utils/mask";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CPayParcelModal extends Vue {
  @Prop({ required: true }) readonly props!: {
    parcelValue: string;
  };

  calendarConfig = calendarConfig;
  moneyMask = moneyMask;

  date = formatDate(new Date().toString());
  value = "";

  getFields() {
    return { date: formatDateEn(this.date), value: removeMoneyMask(this.value) };
  }

  reset() {
    this.date = "";
    this.value = "";
  }

  created() {
    this.value = this.props.parcelValue || "";
  }
}
</script>

<style lang="scss">
.p-dialog.pay-parcel {
  max-width: 24rem;

  .p-dialog-header {
    padding: 1.5rem 1rem;
  }

  .p-dialog-content {
    padding: 0.5rem 1rem 1.5rem;

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
