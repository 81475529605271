import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";
import { generatePaginationOptions } from "@/utils/pagination";

import { Pagination, PaginationResponse } from "@/store/types";
import {
  IntegrationPublicationFormT,
  IntegrationPublicationOriginT,
  IntegrationPublicationT,
  IntegrationPublicationTermFormT,
  IntegrationPublicationTermT,
} from "./types";

const { VUE_APP_API_INTEGRATION_URL } = process.env;

@Module({ namespaced: true })
class IntegrationPublicationModule extends VuexModule {
  list: IntegrationPublicationT[] = [];
  terms: IntegrationPublicationTermT[] = [];
  origins: IntegrationPublicationOriginT[] = [];

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  summary: { [key: string]: any } = {};

  @Mutation
  setSummary(summary: { [key: string]: any }) {
    this.summary = summary;
  }

  @Mutation
  setTerms(terms: IntegrationPublicationTermT[]) {
    this.terms = terms;
  }

  @Mutation
  setOrigins(origins: IntegrationPublicationOriginT[]) {
    this.origins = origins;
  }

  @Mutation
  setList(publications: IntegrationPublicationT[]) {
    this.list = publications;
  }

  @Mutation
  setPagination(payload: Partial<Pagination>) {
    this.pagination = { ...this.pagination, ...payload };
  }

  @Action
  async getSummary(payload?: { filters: any }) {
    try {
      const response = await gojus.get<IntegrationPublicationT>({
        entity: "/diarios/movimentacoes/contadores/",
        config: {
          query: { ...payload?.filters, cd_responsavel_tarefa: this.context.rootState.user.current?.id },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
          useEstablishment: true,
        },
      });
      const summary = response?.data || {};
      this.context.commit("setSummary", summary);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getList(payload: { filters: any }) {
    try {
      const filters = { ...payload?.filters };
      if (filters.filters?.ordenar_por === "date") {
        filters.filters.ordenar_por = "date,created_at";
      } else if (filters.filters?.ordenar_por === "-date") {
        filters.filters.ordenar_por = "-date,-created_at";
      }

      if (filters.exists_process) {
        filters.cd_responsavel_tarefa = this.context.rootState.user.current?.id;
      }

      const response = await gojus.get<PaginationResponse<IntegrationPublicationT>>({
        entity: "/diarios/movimentacoes/",
        config: {
          query: { ...generatePaginationOptions(this.pagination), ...filters },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
          useEstablishment: true,
        },
      });
      const publications = response?.data?.results || [];

      this.context.commit("setPagination", { total: response?.data?.count || 0 });
      this.context.commit("setList", publications);

      return response;
    } catch (error: any) {
      if (error?.status === 404) {
        this.context.commit("setPagination", { current: 1, row: 10, total: 0 });
        return await this.context.dispatch("getList", payload);
      }

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async search(payload: { filters: any }) {
    try {
      const response = await gojus.get<IntegrationPublicationT[]>({
        entity: "/diarios/movimentacoes/",
        config: {
          query: { ...payload.filters },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
          useEstablishment: true,
        },
      });
      const publications = response?.data || [];

      return publications;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return [];
    }
  }

  @Action
  async editPublication(payload: { id: number; form: Partial<IntegrationPublicationFormT> }) {
    try {
      await gojus.patch<IntegrationPublicationT, Partial<IntegrationPublicationFormT>>({
        entity: `/diarios/movimentacoes/${payload.id}/`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      const publication = this.list.find((publication) => publication.id === payload.id) || null;
      if (publication) {
        publication.is_read = payload.form.is_read ?? publication.is_read;
      }

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async getOrigins() {
    try {
      const response = await gojus.get<IntegrationPublicationOriginT[]>({
        entity: "/diarios/",
        config: {
          baseAPI: VUE_APP_API_INTEGRATION_URL,
          useEstablishment: true,
        },
      });
      this.context.commit("setOrigins", response?.data || []);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getTerm(payload: { id: number }) {
    try {
      const term = this.terms.find((term) => term.id === payload.id) || null;
      return term;
    } catch {
      return null;
    }
  }

  @Action
  async getTerms() {
    try {
      const response = await gojus.get<IntegrationPublicationTermT[]>({
        entity: "/diarios/termos/",
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.commit("setTerms", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createTerm(payload: { form: IntegrationPublicationTermFormT }) {
    try {
      await gojus.post<IntegrationPublicationTermT, IntegrationPublicationTermFormT>({
        entity: "/diarios/termos/",
        data: payload.form,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async editTerm(payload: { id: number; form: Partial<IntegrationPublicationTermFormT> }) {
    try {
      await gojus.patch<IntegrationPublicationTermT, Partial<IntegrationPublicationTermFormT>>({
        entity: `/diarios/termos/${payload.id}/`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async removeTerm(payload: { id: number }) {
    try {
      await gojus.delete({
        entity: `/diarios/termos/${payload.id}/`,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }
}

export default IntegrationPublicationModule;
