<template>
  <c-dashboard-template class="c-parcel-list-modal" :tableLoading="loading.list" :useHeader="false" :useTable="true">
    <template #table>
      <p-data-table class="p-datatable-striped" dataKey="id" :value="parcels" :scrollable="true" scrollHeight="50vh">
        <template #empty v-if="loading.list">Aguarde...</template>
        <template #empty v-else>Nenhuma parcela encontrada.</template>

        <p-column field="id" header="ID" headerStyle="width: 5rem;" sortable>
          <template #body="{ data }">
            <span class="p-column-title">ID</span>
            <span class="p-column-text break" v-text="data.id" />
          </template>
        </p-column>
        <p-column field="nr_parcela" header="Parcela" headerStyle="4rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Parcela</span>
            <span class="p-column-text" v-text="data.nr_parcela" />
          </template>
        </p-column>
        <p-column field="nr_valor" header="Valor" headerStyle="width: 8rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Valor</span>
            <span class="p-column-text" v-text="addMoneyMask(data.nr_valor)" />
          </template>
        </p-column>
        <p-column field="dt_vencimento" header="Vencimento" headerStyle="width: 8rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Vencimento</span>
            <span class="p-column-text" v-text="formatDate(data.dt_vencimento)" />
          </template>
        </p-column>
        <p-column field="dt_pagamento" header="Pagamento" headerStyle="width: 8rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Pagamento</span>
            <p class="p-column-text" v-text="formatDate(data.dt_pagamento)" v-if="data.dt_pagamento" />
            <p class="p-column-text" v-text="addMoneyMask(data.nr_valor_pago)" v-if="data.dt_pagamento" />
          </template>
        </p-column>
        <p-column field="ie_status" header="Situação" headerStyle="width: 6rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Situação</span>
            <span
              :class="['p-column-text', 'situation', data.ie_status ? 'success-color' : 'danger-color']"
              v-text="data.ie_status ? 'Pago' : 'Pendente'"
            />
          </template>
        </p-column>

        <p-column header="Opções" headerStyle="width: 5rem;">
          <template #body="{ data }">
            <span class="p-column-title">Opções</span>
            <p-button
              class="p-button-text p-button-rounded"
              icon="fas fa-ellipsis-v"
              :title="`Opções da Parcela #${data.id}`"
              aria-haspopup="true"
              aria-controls="overlay-menu"
              @click="onToggle($event, data)"
            />
            <p-menu
              id="overlay-menu"
              :ref="`${data.id}`"
              :model="getOptions(props.agreement, data)"
              style="width: 8rem"
              appendTo="app"
              popup
            />
          </template>
        </p-column>
      </p-data-table>
    </template>
  </c-dashboard-template>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import CDashboardTemplate from "@/components/dashboard/dashboard.vue";
import Menu from "primevue/menu/Menu.vue";

import { DebtorAgreement } from "@/store/collection/debtor/types";
import { ParcelMenu } from "@/views/collection/debtor/mixins";
import { formatDate } from "@/utils/formatters";
import { addMoneyMask } from "@/utils/mask";
import { getAgreementOrigin } from "@/utils/acronyms";

const PMenu: any = Menu;

@Component({ components: { CDashboardTemplate, PMenu } })
export default class CAgreementListModal extends Mixins(ParcelMenu) {
  @Prop({ required: true }) readonly props!: { [key: string]: any };

  formatDate = formatDate;
  addMoneyMask = addMoneyMask;
  getAgreementOrigin = getAgreementOrigin;

  loading = { list: false };
  parcels: DebtorAgreement[] = [];

  async getComponentData() {
    if (!this.props.agreement?.id) return;

    this.loading.list = true;

    const response = await this.$store.state.debtor.service.getParcels(this.props.agreement.id);
    this.parcels = response?.data || [];

    this.loading.list = false;
  }

  getFields() {
    return {};
  }

  reset() {}

  mounted() {
    this.getComponentData();
  }
}
</script>

<style lang="scss">
.p-dialog.parcel-list {
  width: 98vw;
  max-width: 800px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;

    &::-webkit-scrollbar-track,
    .p-datatable-scrollable-body::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #0000004d;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar,
    .p-datatable-scrollable-body::-webkit-scrollbar {
      width: 6px;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar-thumb,
    .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3273dc;
    }

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
