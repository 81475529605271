import gojus from "@/services/gojus";
import { SettingGroup, SettingEndpoint, SettingApplication, SettingApplicationForm } from "@/store/setting/types";

import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_AUTH_URL } = process.env;

export class SettingService {
  applicationEndpoint = "aplicacao/";
  applicationCDEndpoint = (cd: number) => `aplicacao/${cd}/`;
  groupEndpoint = "grupos/";
  groupCDEndpoint = (cd: number) => `grupos/${cd}/`;
  endpointEndpoint = "endpoints/";
  endpointCDEndpoint = (cd: number) => `endpoints/${cd}/`;

  async getGroup(cd: number) {
    const { data } = await gojus.get<SettingGroup>({
      entity: this.groupCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async getGroups() {
    const { data } = await gojus.get<SettingGroup[]>({
      entity: this.groupEndpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async createGroup(group: SettingGroup) {
    const { data } = await gojus.post<SettingGroup>({
      entity: this.groupEndpoint,
      data: group,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async editGroup(cd: number, group: SettingGroup) {
    const { data } = await gojus.patch<SettingGroup>({
      entity: this.groupCDEndpoint(cd),
      data: group,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async deleteGroup(cd: number) {
    const { data } = await gojus.delete({
      entity: this.groupCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async getEndpoint(cd: number) {
    const { data } = await gojus.get<SettingEndpoint>({
      entity: this.endpointCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async getEndpoints(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<SettingEndpoint>>({
      entity: this.endpointEndpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_AUTH_URL,
      },
    });

    return { data };
  }

  async createEndpoint(endpoint: SettingEndpoint) {
    const { data } = await gojus.post<SettingEndpoint>({
      entity: this.endpointEndpoint,
      data: endpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async editEndpoint(cd: number, endpoint: SettingEndpoint) {
    const { data } = await gojus.patch<SettingEndpoint>({
      entity: this.endpointCDEndpoint(cd),
      data: endpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async deleteEndpoint(cd: number) {
    const { data } = await gojus.delete({
      entity: this.endpointCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async getApplication(cd: number) {
    const { data } = await gojus.get<SettingApplication>({
      entity: this.applicationCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async getApplications(filters?: any) {
    const { data } = await gojus.get<SettingApplication[]>({
      entity: this.applicationEndpoint,
      config: { query: { ...(filters || {}) }, baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async createApplication(application: SettingApplicationForm) {
    const { data } = await gojus.post<SettingApplicationForm>({
      entity: this.applicationEndpoint,
      data: application,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async editApplication(cd: number, application: SettingApplicationForm) {
    const { data } = await gojus.patch<SettingApplication, SettingApplicationForm>({
      entity: this.applicationCDEndpoint(cd),
      data: application,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async deleteApplication(cd: number) {
    const { data } = await gojus.delete({
      entity: this.applicationCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }
}

export default SettingService;
