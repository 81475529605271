import gojus from "@/services/gojus";

import { ActionType, ActionTypeForm } from "@/store/lawsuit/action-type/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class ActionTypeService {
  endpoint = "tipo-acao/";
  cdEndpoint = (cd: number) => `tipo-acao/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<ActionType>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<ActionType[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getPaginated(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<ActionType>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async create(actionType: ActionTypeForm) {
    const response = await gojus.post<ActionType, ActionTypeForm>({
      entity: this.endpoint,
      data: actionType,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async edit(cd: number, actionType: ActionTypeForm) {
    const response = await gojus.patch<ActionType, ActionTypeForm>({
      entity: this.cdEndpoint(cd),
      data: actionType,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }
}

export default ActionTypeService;
