import gojus from "@/services/gojus";

import { Warning } from "@/store/warning/types";
import { PaginationParams, PaginationResponse } from "../types";
import { compareDates } from "@/utils/validators";

const { VUE_APP_API_AUTH_URL } = process.env;

export class WarningService {
  endpoint = "avisos/";
  cdEndpoint = (cd: number) => `avisos/${cd}/`;

  async getAllShow(ie_correspondente: boolean, ie_escritorio: boolean) {
    const response = await gojus.get<Warning[]>({
      entity: this.endpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    const warnings = response?.data || [];
    const filteredWarnings = warnings
      .filter((warning) => {
        const isOffice = ie_escritorio && warning.ie_escritorio;
        const isCorrespondent = ie_correspondente && warning.ie_correspondente;

        return warning.ie_ativo && (isCorrespondent || isOffice);
      })
      .filter((warning) => {
        const started = !compareDates(String(warning.dt_exibicao_inicial));
        const ended = !compareDates(String(warning.dt_exibicao_final));

        return started && !ended;
      });

    return filteredWarnings;
  }

  async get(cd: number) {
    const { data } = await gojus.get<Warning>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async getAll(pagination: PaginationParams, filters: any) {
    const { data } = await gojus.get<PaginationResponse<Warning>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async create(warning: Warning) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: warning,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async edit(cd: number, warning: Warning) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: warning,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({ entity: this.cdEndpoint(cd), config: { baseAPI: VUE_APP_API_AUTH_URL } });
    return { data };
  }
}

export default WarningService;
