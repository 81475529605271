import gojus from "@/services/gojus";

import { BillToReceive, BillToReceiveForm } from "@/store/financial/bill-to-receive/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class BillToReceiveService {
  endpoint = "recebimentos/";
  cdEndpoint = (cd: number) => `recebimentos/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<BillToReceive>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getAll(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<BillToReceive>>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_FINANCIAL_URL,
      },
    });

    return response;
  }

  async create(billToReceive: BillToReceiveForm) {
    const response = await gojus.post<BillToReceive, BillToReceiveForm>({
      entity: this.endpoint,
      data: billToReceive,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async edit(cd: number, billToReceive: BillToReceiveForm) {
    const response = await gojus.patch<BillToReceive, BillToReceiveForm>({
      entity: this.cdEndpoint(cd),
      data: billToReceive,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });
    return response;
  }
}

export default BillToReceiveService;
