<template>
  <c-form-template class="c-input-text-modal" :useFooter="false" @submit="$emit('submit')">
    <c-form-field-template width="100%">
      <template #label>{{ props.label }}</template>
      <p-input-text v-model="field" v-uppercase="null" required autofocus v-if="props.isUppercase" />
      <p-input-text v-model="field" required autofocus v-else />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CInputTextModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  field = "";

  getFields() {
    return { field: this.field };
  }

  reset() {
    this.field = "";
  }

  created() {
    this.field = this.props?.field || "";
  }
}
</script>

<style lang="scss"></style>
