import gojus from "@/services/gojus";

import {
  ExpedientAttorney,
  ExpedientAttorneyAccountForm,
  ExpedientAttorneyAccountMigrationStatus,
  ExpedientAttorneyForm,
} from "@/store/expedient/attorney/types";

const { VUE_APP_API_PROCESS_URL, VUE_APP_API_JURIDICAL_URL } = process.env;

export class ExpedientAttorneyService {
  root = "attorney";
  account = "account";
  byId = (_id: number) => `attorney/${_id}`;
  accountById = (_id: number) => `account/${_id}`;
  county = "county";

  async get(cd: number) {
    const { data } = await gojus.get<ExpedientAttorney>({
      entity: this.byId(cd),
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });
    return { data };
  }

  async getAll(filters?: any) {
    const { data } = await gojus.get<ExpedientAttorney[]>({
      entity: this.root,
      config: {
        query: { ...(filters || {}) },
        baseAPI: VUE_APP_API_PROCESS_URL,
      },
    });

    return { data };
  }

  async migrateProcesses(payload: { system: number; cd_estabelecimento: number }) {
    const { data } = await gojus.get<ExpedientAttorneyAccountMigrationStatus[]>({
      entity: this.county,
      config: {
        query: { ...payload },
        baseAPI: VUE_APP_API_PROCESS_URL,
      },
    });

    await Promise.allSettled(
      (data || []).map(async (item) => {
        if (item.finalizado && item.code_migration) {
          const result = await gojus.get<{ QTD_PER_CODE: number }>({
            entity: `processos/${item.code_migration}/`,
            config: {
              query: { ...payload },
              baseAPI: VUE_APP_API_JURIDICAL_URL,
            },
          });
          item.finalizado = (result?.data?.QTD_PER_CODE || 0) >= item.qtd;
        }
      })
    );

    return { data };
  }

  async search(filters?: any) {
    const { data } = await gojus.get<ExpedientAttorney[]>({
      entity: this.root,
      config: {
        query: { ...(filters || {}) },
        baseAPI: VUE_APP_API_PROCESS_URL,
      },
    });

    return { data };
  }

  async create(attorney: ExpedientAttorneyForm) {
    const { data } = await gojus.post<ExpedientAttorney, ExpedientAttorneyForm>({
      entity: this.root,
      data: attorney,
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });

    return { data };
  }

  async createAccount(account: ExpedientAttorneyAccountForm) {
    const { data } = await gojus.post<any, ExpedientAttorneyAccountForm>({
      entity: this.account,
      data: account,
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });

    return { data };
  }

  async edit(cd: number, attorney: ExpedientAttorneyForm) {
    const { data } = await gojus.patch<ExpedientAttorney, ExpedientAttorneyForm>({
      entity: this.byId(cd),
      data: attorney,
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });

    return { data };
  }

  async editAccount(cd: number, account: ExpedientAttorneyAccountForm) {
    const { data } = await gojus.patch<any, ExpedientAttorneyAccountForm>({
      entity: this.accountById(cd),
      data: account,
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.byId(cd),
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });
    return { data };
  }

  async deleteAccount(cd: number) {
    const { data } = await gojus.delete({
      entity: this.accountById(cd),
      config: { baseAPI: VUE_APP_API_PROCESS_URL },
    });

    return { data };
  }
}

export default ExpedientAttorneyService;
