import gojus from "@/services/gojus";
import { Company, LawFirmRegisterForm } from "@/store/company/types";

const { VUE_APP_API_AUTH_URL } = process.env;

export class CompanyService {
  endpoint = "estabelecimentos/";
  cdEndpoint = (cd: number) => `estabelecimentos/${cd}/`;
  office = (cd: number) => `estabelecimentos/${cd}/`;
  registerEndpoint = "estabelecimentos/auto-cadastro/";

  async getAll() {
    const { data } = await gojus.get<Company[]>({
      entity: this.endpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL, useCompany: false },
    });
    return { data };
  }

  async getOffice(officeId: number) {
    const { data } = await gojus.get<Company>({
      entity: this.office(officeId),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async register(form: LawFirmRegisterForm) {
    const response = await gojus.post<Company, LawFirmRegisterForm>({
      entity: this.registerEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_AUTH_URL, useCompany: false, useToken: false },
    });
    return response;
  }
}

export default CompanyService;
