<template>
  <c-form-template class="c-task-reschedule" :useFooter="false" :isFlex="true">
    <c-form-field-template width="calc(50% - 80px)">
      <template #label>Prazo Fatal</template>
      <p-calendar v-model="dt_fatal" dateFormat="dd/mm/yy" :disabled="true" :locale="calendarConfig" required />
    </c-form-field-template>
    <c-form-field-template width="calc(50% - 80px)">
      <template #label>Data do Vencimento</template>
      <p-calendar
        v-model="dt_vencimento"
        dateFormat="dd/mm/yy"
        :minDate="new Date()"
        :maxDate="dt_limite"
        :locale="calendarConfig"
        required
        @change="handleChangeExpirationDate"
      />
    </c-form-field-template>
    <c-form-field-template width="160px">
      <template #label>Hora do Vencimento</template>
      <p-input-mask v-model="hr_vencimento" mask="99:99" required />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Justificativa do Reagendamento</template>
      <p-textarea v-model="ds_justificativa_reagendamento" :autoResize="true" rows="4" cols="auto" required autofocus />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn, formatHours } from "@/utils/formatters";
import { TaskReschedule } from "@/store/task/types";

@Component({ components: { CFormTemplate, CFormFieldTemplate } })
export default class CModalTaskReschedule extends Vue implements TaskReschedule {
  @Prop({ required: true }) readonly props!: any;
  dt_vencimento = "";
  hr_vencimento = "23:59";
  ds_justificativa_reagendamento = "";
  dt_fatal: string | null = null;

  formatDate = formatDate;
  calendarConfig = calendarConfig;

  get dt_limite() {
    let dt_limite = new Date(this.props.dt_fatal);
    dt_limite.setDate(dt_limite.getDate() + 1);
    return this.props.dt_fatal != "" && this.props.dt_fatal != null ? dt_limite : null;
  }

  handleChangeExpirationDate() {
    const expirationDate = formatDateEn(this.dt_vencimento);
    const invalidLength = expirationDate.length !== 10;
    const invalidDateObject = typeof this.dt_vencimento === "string" && !new Date(expirationDate)?.getDate();

    if (invalidLength || invalidDateObject) this.dt_vencimento = "";
  }

  getFields() {
    const form: TaskReschedule = {
      dt_vencimento: formatDateEn(this.dt_vencimento),
      hr_vencimento: formatHours(this.hr_vencimento),
      ds_justificativa_reagendamento: this.ds_justificativa_reagendamento,
    };

    return form;
  }

  reset() {}

  @Watch("hr_vencimento")
  onUpdateHRDiligencia(value: string) {
    const unmask = value.replaceAll("_", "0");
    const hr = Number(unmask.substring(0, 2));
    const min = Number(unmask.substring(3, 5));

    if (hr < 0 || hr > 23 || min < 0 || min > 59) this.hr_vencimento = "";
    else this.hr_vencimento = value;
  }

  created() {
    this.dt_fatal = this.props.dt_fatal != "" && this.props.dt_fatal != null ? formatDate(this.props.dt_fatal) : null;
  }
}
</script>

<style lang="scss">
.p-dialog.task-reschedule .p-dialog-content {
  padding-bottom: 1.1rem;
  width: 90vw;
  max-width: 600px;
}

.c-task-reschedule {
  padding: 0 !important;
  font-size: 0.4rem !important;

  .p-inputtextarea {
    max-height: 40vh;
  }
}
</style>
