import { Action, Module, VuexModule } from "vuex-module-decorators";

import { ReportQuery } from "@/store/report/types";

import { ReportService } from "@/store/report/service";
import { ModalParams } from "../global/types";

@Module({ namespaced: true })
export class ReportModule extends VuexModule {
  service = new ReportService();

  @Action
  async getCompletedRequestDiligence() {
    try {
      const params: ModalParams = {
        content: {
          title: "Gerar Relatório das Solicitações de Diligências Completas",
        },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCompletedRequestDiligence(query);
            this.context.commit("global/updateModalStatus", false, {
              root: true,
            });

            window.open(response.data.link);
          },
        },
        props: {
          label: {
            start: "Data Conclusão Inicial",
            end: "Data Conclusão Final",
          },
        },
        options: { modal: "report", asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCompletedRequestHearing() {
    try {
      const params: ModalParams = {
        content: {
          title: "Gerar Relatório das Solicitações de Audiências Completas",
        },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCompletedRequestHearing(query);
            this.context.commit("global/updateModalStatus", false, {
              root: true,
            });

            window.open(response.data.link);
          },
        },
        props: {
          label: {
            start: "Data Conclusão Inicial",
            end: "Data Conclusão Final",
          },
        },
        options: { modal: "report", asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCompletedAllRequest() {
    try {
      const params: ModalParams = {
        content: {
          title: "Gerar Relatório das Solicitações de Diligências e Audiências",
        },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCompletedAllRequest(query);
            this.context.commit("global/updateModalStatus", false, {
              root: true,
            });

            window.open(response.data.link);
          },
        },
        props: {
          label: {
            start: "Data Conclusão Inicial",
            end: "Data Conclusão Final",
          },
        },
        options: { modal: "report", asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCollectionFees() {
    try {
      const params: ModalParams = {
        content: { title: "Gerar Relatório dos Honorários" },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCollectionFees(query);
            this.context.commit("global/updateModalStatus", false, {
              root: true,
            });

            window.open(response.data.link);
          },
        },
        props: {
          label: {
            start: "Data Pagamento Inicial",
            end: "Data Pagamento Final",
          },
        },
        options: { modal: "report", asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCollectionAgreement() {
    try {
      const params: ModalParams = {
        content: {
          title: "Gerar Relatório dos Acordos",
          text: "Acordos em todas as datas.",
        },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCollectionAgreement(query);
            this.context.commit("global/updateModalStatus", false, { root: true });

            window.open(response.data.link);
          },
        },
        options: { asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCollectionGeneral() {
    try {
      const params: ModalParams = {
        content: {
          title: "Gerar Relatório de Cobrança",
          text: "Relatório geral do modulo Cobrança",
        },
        accept: {
          text: "Baixar",
          icon: "fas fa-file-download",
          class: "p-button-success",
          function: async (query: ReportQuery) => {
            const response = await this.service.getCollectionGeneral(query);
            this.context.commit("global/updateModalStatus", false, { root: true });

            window.open(response.data.link);
          },
        },
        options: { asyncClose: true },
      };

      this.context.dispatch("global/showModal", params, { root: true });
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ReportModule;
