import gojus from "@/services/gojus";

import { Transfer, TransferForm } from "@/store/financial/transfer/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class TransferService {
  endpoint = "transferencias/";
  cdEndpoint = (cd: number) => `transferencias/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<Transfer>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<Transfer[]>({
      entity: this.endpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async getPaginated(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<Transfer>>({
      entity: this.endpoint,
      config: { query: { ...filters, ...pagination }, baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async create(transfer: TransferForm) {
    const response = await gojus.post<Transfer, TransferForm>({
      entity: this.endpoint,
      data: transfer,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async edit(cd: number, transfer: TransferForm) {
    const response = await gojus.patch<Transfer, TransferForm>({
      entity: this.cdEndpoint(cd),
      data: transfer,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });
    return response;
  }
}

export default TransferService;
