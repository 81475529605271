import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/teams",
    name: "team",
    component: () => import("@/views/team/list.vue"),
  },
  {
    path: "/dashboard/teams/:cd/details",
    name: "team-details",
    component: () => import("@/views/team/details.vue"),
  },
];

export default routes;
