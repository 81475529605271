<template>
  <c-form-template class="c-text-area-modal" :useFooter="false" @submit="$emit('submit')">
    <c-form-field-template width="100%" :useLabel="props.label">
      <template #label>{{ props.label }}</template>
      <p-textarea v-model="field" :autoResize="true" rows="6" cols="auto" required autofocus />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CTextAreaModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  field = "";

  getFields() {
    if (!this.field) {
      this.$store.commit("global/updateToast", [
        { summary: `O campo "${this.props.label}" é obrigatório!`, severity: "warn" },
      ]);

      return { hasError: true };
    }

    return { field: this.field };
  }

  reset() {
    this.field = "";
  }

  created() {
    this.field = this.props?.field || "";
  }
}
</script>

<style lang="scss">
.p-dialog.text-area {
  width: 100%;
  max-width: 600px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-inputtextarea {
    max-height: 240px;
  }

  .p-dialog-content {
    overflow-y: auto !important;
    padding: 0 1rem 1rem 1rem;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #0000004d;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3273dc;
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
