import gojus from "@/services/gojus";
import { Report, ReportQuery } from "./types";

const { VUE_APP_API_ANALYTICS_URL } = process.env;

export class ReportService {
  diligenceCompletedRequestEndpoint = "diligencias/solicitacoes-concluidas";
  hearingCompletedRequestEndpoint = "audiencias/solicitacoes-concluidas";
  completedAllRequestEndpoint = "audiencias/todas-solicitacoes";
  collectionFeesEndpoint = "cobranca/honorarios";
  collectionAgreementEndpoint = "cobranca/acordos";
  collectionGeneralEndpoint = "cobranca/acordos/relatorio-geral";

  async getCompletedRequestDiligence(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.diligenceCompletedRequestEndpoint,
      config: {
        query: {
          dt_conclusao_inicial: query.dt_inicial,
          dt_conclusao_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }

  async getCompletedRequestHearing(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.hearingCompletedRequestEndpoint,
      config: {
        query: {
          dt_conclusao_inicial: query.dt_inicial,
          dt_conclusao_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }

  async getCompletedAllRequest(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.completedAllRequestEndpoint,
      config: {
        query: {
          dt_conclusao_inicial: query.dt_inicial,
          dt_conclusao_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }

  async getCollectionFees(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.collectionFeesEndpoint,
      config: {
        query: {
          dt_pagamento_inicial: query.dt_inicial,
          dt_pagamento_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }

  async getCollectionAgreement(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.collectionAgreementEndpoint,
      config: {
        query: {
          dt_pagamento_inicial: query.dt_inicial,
          dt_pagamento_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }

  async getCollectionGeneral(query: ReportQuery) {
    const response = await gojus.get<Report>({
      entity: this.collectionGeneralEndpoint,
      config: {
        query: {
          dt_pagamento_inicial: query.dt_inicial,
          dt_pagamento_final: query.dt_final,
        },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return response;
  }
}

export default ReportService;
