<template>
  <c-task-form
    v-if="!loading.form"
    :loading="loading.submit"
    :formType="props.formType"
    @submit="onSubmit"
    @click-back="$emit('close')"
  >
    <template #submit-text>
      {{ props.task && props.task.id ? "Salvar" : "Adicionar" }}
    </template>
  </c-task-form>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CTaskForm from "@/views/task/components/form.vue";
import { Task, TaskForm, TaskFormType } from "@/store/task/types";

@Component({ components: { CTaskForm } })
export default class CTaskFormModal extends Vue {
  @Prop({ required: true }) readonly props!: { task?: Task; formType: TaskFormType };

  form: TaskForm | null = null;
  loading = { submit: false, form: true };

  getFields(): TaskForm | null {
    return this.form;
  }

  onSubmit(event: { form: TaskForm }, files?: File[]) {
    this.form = { ...event.form, files };
    this.$emit("submit");
    this.loading.submit = true;
  }

  reset() {}

  async getComponentData() {
    if (this.props.task) {
      if (this.props.task.cd_cliente && !this.props.task.ob_cliente) {
        if (this.props.formType === "lawsuit")
          this.props.task.ob_cliente = await this.$store.dispatch("lawsuit/customer/get", {
            cd: this.props.task.cd_cliente,
          });
        else
          this.props.task.ob_cliente = await this.$store.dispatch("collection/customer/get", {
            cd: this.props.task.cd_cliente,
          });
      }

      if (this.props.task.cd_devedor && !this.props.task.ob_devedor)
        this.props.task.ob_devedor = await this.$store.dispatch("debtor/get", { cd: this.props.task.cd_devedor });

      if (this.props.task.ob_devedor) {
        this.props.task.nm_parte_contraria = this.props.task.ob_devedor?.nm_devedor || "";
        this.props.task.cd_cidade = this.props.task.ob_devedor?.ob_cidade?.id || 0;
      }

      this.$store.commit("task/updateCurrent", this.props.task);
    }

    this.loading.form = false;
  }

  created() {
    this.getComponentData();
  }
}
</script>

<style lang="scss">
.p-dialog.task-form {
  width: 98vw;
  max-width: 1280px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;
    min-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #0000004d;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3273dc;
    }

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }
}
</style>
