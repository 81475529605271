import Vue from "vue";
import Vuex, { Store } from "vuex";

import LogisticsActTypeModule from "@/store/logistics/acttype/module";
import CompanyModule from "@/store/company/module";
import CorrespondentModule from "@/store/correspondent/module";
import CourtModule from "@/store/court/module";
import DebtorModule from "@/store/collection/debtor/module";
import DiligenceModule from "@/store/logistics/diligence/module";
import HearingModule from "@/store/logistics/hearing/module";
import FileModule from "@/store/file/module";
import ForumModule from "@/store/forum/module";
import GlobalModule from "@/store/global/module";
import FinancialModule from "@/store/financial/module";
import LogisticsModule from "@/store/logistics";
import CollectionModule from "@/store/collection";
import TaskModule from "@/store/task/module";
import LawsuitModule from "@/store/lawsuit/module";
import LocationModule from "@/store/location/module";
import GroupModule from "@/store/group/module";
import ReportModule from "@/store/report/module";
import SettingModule from "@/store/setting/module";
import TeamModule from "@/store/team/module";
import UserModule from "@/store/user/module";
import WarningModule from "@/store/warning/module";
import MovementModule from "@/store/movement/module";
import PersonalDepartmentModule from "@/store/department/module";
import ExpedientModule from "@/store/expedient/module";
import PublicationModule from "@/store/publication";
import IntegrationModule from "@/store/integration/module";

Vue.use(Vuex);
const store = new Store<any>({});

store.registerModule("acttype", LogisticsActTypeModule);
store.registerModule("collection", CollectionModule);
store.registerModule("publication", PublicationModule);
store.registerModule("company", CompanyModule);
store.registerModule("correspondent", CorrespondentModule);
store.registerModule("court", CourtModule);
store.registerModule("group", GroupModule);
store.registerModule("diligence", DiligenceModule);
store.registerModule("debtor", DebtorModule);
store.registerModule("hearing", HearingModule);
store.registerModule("file", FileModule);
store.registerModule("forum", ForumModule);
store.registerModule("global", GlobalModule);
store.registerModule("financial", FinancialModule);
store.registerModule("logistics", LogisticsModule);
store.registerModule("task", TaskModule);
store.registerModule("department", PersonalDepartmentModule);
store.registerModule("expedient", ExpedientModule);
store.registerModule("lawsuit", LawsuitModule);
store.registerModule("movement", MovementModule);
store.registerModule("location", LocationModule);
store.registerModule("report", ReportModule);
store.registerModule("setting", SettingModule);
store.registerModule("team", TeamModule);
store.registerModule("user", UserModule);
store.registerModule("warning", WarningModule);
store.registerModule("integration", IntegrationModule);

export default store;
