import gojus from "@/services/gojus";

const { VUE_APP_API_DEPARTMENT_URL } = process.env;

export class ResponsibleService {
  endpoint = "equipes/";
  endpointById = (cd: string) => `equipes/${cd}/`;
  addEmployeeEndPoint = (cd_equipe: number) => `equipes/${cd_equipe}/add-funcionario/`;
  removeEmployeeEndPoint = (cd_equipe: number, cd_funcionario: number) =>
    `/equipes/${cd_equipe}/remover-funcionario/${cd_funcionario}/`;

  async getAll(filters?: any) {
    const response = await gojus.get<any>({
      entity: this.endpoint,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_DEPARTMENT_URL,
      },
    });

    return response;
  }

  async create(team: any) {
    const response = await gojus.post<any>({
      entity: this.endpoint,
      data: team,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async update(cd: string, team: any) {
    const response = await gojus.patch<any>({
      entity: this.endpointById(cd),
      data: team,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async add(cd_equipe: number, form: any) {
    const response = await gojus.post<any>({
      entity: this.addEmployeeEndPoint(cd_equipe),
      data: form,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async remove(cd_equipe: number, cd_funcionario: number) {
    const response = await gojus.delete({
      entity: this.removeEmployeeEndPoint(cd_equipe, cd_funcionario),
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }
}

export default ResponsibleService;
