<template>
  <div class="c-modal-excel-logistics">
    <main class="content">
      <p-listbox
        :options="headerOptions.filter((header) => header.show)"
        optionLabel="label"
        @change="$event.value.selected = !$event.value.selected"
      >
        <template #option="{ option }">
          <p-checkbox v-model="option.selected" :style="{ pointerEvents: 'none' }" :binary="true" />
          <label :style="{ marginLeft: '12px', cursor: 'pointer' }" v-text="option.label"></label>
        </template>
      </p-listbox>
    </main>

    <footer class="options">
      <p-button v-if="loading.items" class="p-button-success option" disabled>
        <p-progress-spinner strokeWidth="8" animationDuration="0.8s" />
        <span>Aguarde</span>
      </p-button>
      <p-button
        v-else
        class="p-button-success option"
        icon="fas fa-download"
        :label="`Baixar ${totalItems} Registros`"
        @click="onAskingToDownload"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import PSlider from "primevue/slider/Slider.vue";
import PButton from "primevue/button/Button.vue";
import Listbox from "primevue/listbox";
import Checkbox from "primevue/checkbox";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import CFormFieldTemplate from "@/components/form/field.vue";

import { Hearing } from "@/store/logistics/hearing/types";
import { Diligence } from "@/store/logistics/diligence/types";
import { formatComarca, formatDateTime, formatDate, formatHours } from "@/utils/formatters";

import { handleExportXLSX } from "@/plugins/excellentexport";
import { getResponsible, getSituation } from "@/utils/acronyms";
import { ToastParams } from "@/store/global/types";

const PListbox: any = Listbox;
const PCheckbox: any = Checkbox;

@Component({
  components: { CFormFieldTemplate, PButton, PSlider, PListbox, PCheckbox, PProgressSpinner },
})
export default class CModalExcel extends Vue {
  @Prop({ required: true }) readonly props!: any;
  loading = { items: false };

  headers: string[] = [];
  content: string[][] = [];

  items: any[] = [];
  totalItems = 0;
  selectedItems = 0;

  handleExportXLSX = handleExportXLSX;
  formatDate = formatDate;
  formatComarca = formatComarca;

  headerOptions = [] as any[];

  get isDiligence() {
    return this.props?.is?.includes("diligence");
  }

  get isHearing() {
    return this.props?.is?.includes("hearing");
  }

  get isCorrespondent() {
    return this.props?.is?.includes("correspondent");
  }

  get module() {
    return this.isDiligence ? "diligence" : "hearing";
  }

  get formatDateTime() {
    return (item: Diligence & Hearing) => formatDateTime(item.dt_audiencia, item.hr_audiencia);
  }

  get filters() {
    return this.$store.state[this.module].filters;
  }

  handleXLSXData(selected: any) {
    this.headers = selected.map((header: any) => header.label);
    this.content = this.items.map((item) => selected.map((header: any) => header.handle(item)?.toString() || ""));
  }

  async onAskingToDownload() {
    this.loading.items = true;

    const selected = this.headerOptions.filter((item) => item.show && item.selected);
    let results: Diligence[] & Hearing[] = [];

    try {
      let response: any = null;
      if (this.isCorrespondent) {
        response = await this.$store.state[this.module].service.getAllSolicitation({}, this.filters);
        results = response.data;
      } else {
        response = await this.$store.state[this.module].service.getAll({}, this.filters);
        results = response.data;
      }
    } catch (error) {
      const messages: ToastParams[] = [{ severity: "error", summary: "Erro inesperado, tente novamente mais tarde!" }];
      this.$store.commit("global/updateToast", messages);
      if (process.env.NODE_ENV === "development") console.error(error);

      return;
    }

    results = await this.$store.dispatch(`${this.module}/getAdditionalData`, {
      items: results,
      options: { request: { cd_correspondente: true, qtd_anexo: false } },
    });

    this.items = results;

    this.handleXLSXData(selected);
    handleExportXLSX(this.headers, this.content, this.module);

    this.loading.items = false;
  }

  created() {
    this.headerOptions = [
      {
        label: "ID",
        handle: (item: Diligence | Hearing) => item.id,
        selected: true,
        show: true,
      },
      {
        label: "Workflow",
        handle: (item: Diligence | Hearing) => item.nr_workflow,
        selected: true,
        show: true,
      },
      {
        label: "Processo",
        handle: (item: Diligence | Hearing) => item.nr_processo,
        selected: true,
        show: true,
      },
      {
        label: "Autor",
        handle: (item: Diligence | Hearing) => item.nm_autor,
        selected: true,
        show: true,
      },
      {
        label: "Réu",
        handle: (item: Diligence | Hearing) => item.nm_reu,
        selected: true,
        show: true,
      },
      {
        label: "Cliente",
        handle: (item: Diligence | Hearing) => item.ob_cliente?.nm_cliente,
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Comarca",
        handle: (item: Diligence | Hearing) => item.nm_cidade,
        selected: true,
        show: true,
      },
      {
        label: "Estado",
        handle: (item: Diligence | Hearing) => item.nm_estado,
        selected: true,
        show: true,
      },
      {
        label: "Foro",
        handle: (item: Diligence | Hearing) => item.ob_foro?.nm_foro,
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Data da Audiência",
        handle: (item: Hearing) => formatDate(item.dt_audiencia),
        selected: true,
        show: this.isHearing,
      },
      {
        label: "Horário",
        handle: (item: Hearing) => formatHours(item.hr_audiencia),
        selected: true,
        show: this.isHearing,
      },
      {
        label: "Responsável Audiência",
        handle: (item: Hearing) => getResponsible(item.ie_responsavel)?.label,
        selected: true,
        show: this.isHearing,
      },
      {
        label: "Responsável Cadastro",
        handle: (item: Diligence | Hearing) => item.nm_usuario_cri,
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Responsável Conclusão",
        handle: (item: Diligence | Hearing) => item.nm_responsavel_conclusao,
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Correspondente",
        handle: (item: Diligence & Hearing) =>
          item?.cd_correspondente?.length ? item.cd_correspondente[0].ob_correspondente?.nm_correspondente || "" : "",
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Correspondente (R$)",
        handle: (item: Diligence & Hearing) =>
          item?.cd_correspondente?.length ? item.cd_correspondente[0]?.nr_valor_correspondente || "" : "",
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Preposto",
        handle: (item: Hearing) =>
          item?.cd_preposto?.length ? item.cd_preposto[0].ob_correspondente?.nm_correspondente || "" : "",
        selected: true,
        show: !this.isCorrespondent && this.isHearing,
      },
      {
        label: "Preposto (R$)",
        handle: (item: Hearing) => (item?.cd_preposto?.length ? item.cd_preposto[0]?.nr_valor_preposto || "" : ""),
        selected: true,
        show: !this.isCorrespondent && this.isHearing,
      },
      {
        label: "Tipo de Diligência",
        handle: (item: Diligence) => item.ob_tipo?.nm_tipo_ato || "",
        selected: true,
        show: this.isDiligence,
      },
      {
        label: "Tipo de Audiência",
        handle: (item: Hearing) => item.ob_tipo?.nm_tipo_ato || "",
        selected: true,
        show: this.isHearing,
      },
      {
        label: "Prazo",
        handle: (item: Diligence) => formatDate(item.dt_prazo),
        selected: true,
        show: this.isDiligence,
      },
      {
        label: "Data do Cadastro",
        handle: (item: Diligence | Hearing) => formatDate(item.dt_criado),
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Data da Conclusão",
        handle: (item: Diligence | Hearing) => formatDate(item.dt_conclusao),
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Data Aceito",
        handle: (item: Diligence | Hearing) => formatDate(item.dt_aceito),
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Virtual",
        handle: (item: Diligence | Hearing) => (item.ie_processo_virtual ? "Sim" : "Não"),
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Confirmada",
        handle: (item: Diligence | Hearing) => (item.ie_confirmada ? "Sim" : "Não"),
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Status",
        handle: (item: Diligence | Hearing) => getSituation(item.ie_situacao).label.toLocaleUpperCase(),
        selected: true,
        show: true,
      },
      {
        label: "Pago (R$)",
        handle: (item: Diligence | Hearing) => item.nr_valor_solicitacao,
        selected: true,
        show: !this.isCorrespondent,
      },
      {
        label: "Recebido (R$)",
        handle: (item: Diligence | Hearing) => item.nr_valor_recebido,
        selected: true,
        show: !this.isCorrespondent,
      },
    ];
    this.items = this.props?.items || [];

    if (this.props?.items) {
      this.handleXLSXData(this.headerOptions.filter((item) => item.show));
      handleExportXLSX(this.headers, this.content);

      return;
    }

    this.items = this.$store.state[this.module].all;
    this.totalItems = this.$store.state[this.module].pagination.total;
    this.selectedItems = this.totalItems;
  }
}
</script>

<style lang="scss">
.p-dialog.modal-excel-logistics {
  overflow: hidden;
  margin: 0 0.5rem;

  .p-dialog-header {
    padding: 1rem 0.5rem;
  }

  .p-dialog-content {
    padding: 0;

    .c-modal-excel-logistics {
      overflow-y: scroll;
      min-width: 40rem;
      max-height: 80vh;
      padding: 4px 4px 4rem;
      position: relative;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #0000004d;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--info-color);
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 12px);
        position: fixed;
        z-index: 999;
        bottom: 0;
        background-color: #fff;
        padding-bottom: 12px;

        .option {
          padding: 0.6rem 1rem;

          + .option {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
