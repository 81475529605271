import gojus from "@/services/gojus";

import { JuridicalFile, JuridicalFileForm } from "@/store/lawsuit/file/types";
const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class JuridicalFileService {
  endpoint = "arquivos/";
  cdEndpoint = (cd: number) => `arquivos/${cd}/`;

  async create(form: Omit<JuridicalFileForm, "aq_arquivo"> & { aq_arquivo: File }) {
    const formData = new FormData();

    formData.append("aq_arquivo", form.aq_arquivo);
    formData.append("cd_tese", String(form.cd_tese));

    const response = await gojus.post<JuridicalFile, any>({
      entity: this.endpoint,
      data: formData,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }
}

export default JuridicalFileService;
