<template>
  <c-form-template class="c-agreement-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="calc(50% - 150px)">
      <template #label>Tipo de Acordo</template>

      <div class="p-inputgroup">
        <p-dropdown
          v-model="cd_tipo_acordo"
          ref="cdTipoAcordo"
          :class="[validation.field === 'cdTipoAcordo' ? 'error' : '']"
          :options="agreementTypes"
          dataKey="id"
          optionLabel="nm_tipo_acordo"
          optionValue="id"
          filter
          filterPlaceholder="Tipo de Acordo"
          required
          @change="validation.field === 'cdTipoAcordo' ? (validation.field = null) : ''"
        />
        <p-button
          class="p-button-success"
          icon="fas fa-plus"
          title="Cadastrar um novo Tipo de Acordo"
          @click="onAskingToCreateDebtorAgreementType(props.debtor)"
        />
      </div>
    </c-form-field-template>

    <c-form-field-template width="calc(50% - 150px)">
      <template #label>Tipo de Débito</template>

      <div class="p-inputgroup">
        <p-dropdown
          v-model="cd_tipo_debito"
          ref="cdTipoDebito"
          :class="[validation.field === 'cdTipoDebito' ? 'error' : '']"
          :options="debtorTypes"
          dataKey="id"
          optionLabel="nm_tipo_debito"
          optionValue="id"
          filter
          filterPlaceholder="Tipo de Débito"
          required
          @change="validation.field === 'cdTipoDebito' ? (validation.field = null) : ''"
        />
        <p-button
          class="p-button-success"
          icon="fas fa-plus"
          title="Cadastrar tipo de Débito"
          @click="onAskingToCreateDebtType(props.debtor)"
        />
      </div>
    </c-form-field-template>

    <c-form-field-template minWidth="300px">
      <template #label>Origem do Acordo</template>
      <p-select-button
        v-model="ie_origem_acordo"
        :options="agreementOrigin"
        optionLabel="label"
        optionValue="value"
        required
      />
    </c-form-field-template>

    <c-form-field-template width="180px">
      <template #label>Entrada</template>
      <p-input-text v-model="nr_valor_entrada" v-money="moneyMask" required />
    </c-form-field-template>

    <c-form-field-template width="180px">
      <template #label>Débito Negociado</template>
      <p-input-text v-model="nr_valor_debito_negociado" v-money="moneyMask" required />
    </c-form-field-template>

    <c-form-field-template width="180px">
      <template #label>Parcela</template>
      <p-input-text v-model="nr_valor_parcela" v-money="moneyMask" required />
    </c-form-field-template>

    <c-form-field-template minWidth="230px">
      <template #label>Forma de Pagamento</template>
      <p-select-button
        v-model="ie_forma_pagamento"
        :options="paymentMethods"
        optionLabel="label"
        optionValue="value"
        required
        @input="handlePaymentMethodInput($event)"
      />
    </c-form-field-template>

    <c-form-field-template width="160px">
      <template #label>Parcelas</template>
      <p-input-number
        v-model.number="nr_qtd_parcela"
        class="p-input-number"
        mode="decimal"
        showButtons
        :min="1"
        :disabled="ie_forma_pagamento === 'A'"
      />
    </c-form-field-template>

    <c-form-field-template width="210px">
      <template #label>Data Entrada</template>
      <p-calendar
        v-model="dt_entrada"
        ref="dt_entrada"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        :showOnFocus="false"
        showIcon
        monthNavigator
        yearNavigator
        yearRange="2000:2030"
        @input="
          dt_entrada = addDateMask(dt_entrada);
          handleDateInput('dt_entrada');
        "
      />
    </c-form-field-template>

    <c-form-field-template width="180px">
      <template #label>Inicio do Pagamento</template>
      <p-calendar
        v-model="dt_inicio_debito"
        ref="dtInicioDebto"
        :class="[validation.field === 'dtInicioDebto' ? 'error' : '']"
        dateFormat="mm/yy"
        view="month"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        yearRange="2000:2030"
        required
        @change="validation.field === 'dtInicioDebto' ? (validation.field = null) : ''"
      />
    </c-form-field-template>

    <c-form-field-template width="180px">
      <template #label>Fim do Pagamento</template>
      <p-calendar
        v-model="dt_fim_debito"
        dateFormat="mm/yy"
        view="month"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        yearRange="2000:2030"
        :minDate="new Date(dt_inicio_debito)"
        disabled
        required
      />
    </c-form-field-template>

    <c-form-field-template width="140px">
      <template #label>Dia do Vencimento</template>
      <p-calendar
        v-model="nr_dia_vencimento"
        :showOtherMonths="false"
        ref="nrDiaVencimento"
        :class="[validation.field === 'nrDiaVencimento' ? 'error' : '']"
        dateFormat="dd"
        :locale="calendarConfig"
        required
        @change="validation.field === 'nrDiaVencimento' ? (validation.field = null) : ''"
      />
    </c-form-field-template>

    <p-file-upload
      ref="pFileUpload"
      mode="basic"
      :chooseLabel="(props.agreement || {}).aq_arquivo ? 'Substituir anexo' : 'Anexar arquivo'"
      :customUpload="true"
      style="width: 100%; margin-top: 32px"
      @uploader="onFileUpload"
    />
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Ref, Watch } from "vue-property-decorator";
import moneyMask from "@/plugins/vmoney";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn, formatMonthDate } from "@/utils/formatters";
import {
  Debtor,
  DebtorAgreement,
  DebtorAgreementForm,
  DebtorAgreementType,
  DebtType,
} from "@/store/collection/debtor/types";
import { DebtorAgreementTypeOptions, DebtTypeOptions } from "@/views/collection/debtor/mixins";
import { agreementOrigin, paymentMethods } from "@/utils/acronyms";
import { addDateMask, removeMoneyMask } from "@/utils/mask";
import { validate } from "@/components/modal/agreement/utils";
import { ToastParams } from "@/store/global/types";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CAgreementModal
  extends Mixins(DebtTypeOptions, DebtorAgreementTypeOptions)
  implements DebtorAgreementForm
{
  @Prop({ required: true }) readonly props!: {
    debtor: Debtor;
    agreement: DebtorAgreement;
  };

  @Ref() cdTipoDebito!: any;
  @Ref() cdTipoAcordo!: any;
  @Ref() nrDiaVencimento!: any;
  @Ref() dtInicioDebto!: any;

  @Watch("ie_forma_pagamento")
  @Watch("nr_qtd_parcela")
  @Watch("dt_inicio_debito")
  onUpdateDtInicioDebito() {
    if (this.dt_inicio_debito) {
      this.setFinalDate();
    }
  }

  calendarConfig = calendarConfig;
  moneyMask = moneyMask;
  agreementOrigin = agreementOrigin;
  paymentMethods = paymentMethods;
  addDateMask = addDateMask;

  cd_devedor = 0;
  cd_tipo_debito = 0;
  cd_tipo_acordo = 0;
  nr_valor_entrada = "";
  nr_valor_debito_negociado = "";
  nr_valor_parcela = "";
  nr_qtd_parcela = 0;
  nr_dia_vencimento = this.dayPickers();
  ie_forma_pagamento: any = "A";
  ie_origem_acordo: any = "A";
  dt_inicio_debito = "";
  dt_fim_debito = "";
  dt_entrada = "";

  debtorTypes: DebtType[] = [];
  agreementTypes: DebtorAgreementType[] = [];
  validation: { error: string | null; field: string | null } = { error: null, field: null };

  setFinalDate() {
    if (this.ie_forma_pagamento !== "P") return (this.dt_fim_debito = this.dt_inicio_debito);
    if (this.dt_inicio_debito?.length < 8) return;

    const fimDebto = new Date(this.dt_inicio_debito);
    fimDebto.setMonth(fimDebto.getMonth() + this.nr_qtd_parcela);
    this.dt_fim_debito = formatDate(fimDebto?.toString());
  }

  getFields(): DebtorAgreementForm {
    const payDay = new Date(this.nr_dia_vencimento).getDate();

    return {
      cd_devedor: this.cd_devedor,
      cd_tipo_debito: this.cd_tipo_debito,
      nr_valor_entrada: removeMoneyMask(this.nr_valor_entrada),
      nr_valor_debito_negociado: removeMoneyMask(this.nr_valor_debito_negociado),
      cd_tipo_acordo: this.cd_tipo_acordo,
      nr_valor_parcela: removeMoneyMask(this.nr_valor_parcela),
      nr_qtd_parcela: this.nr_qtd_parcela,
      nr_dia_vencimento: payDay,
      ie_forma_pagamento: this.nr_qtd_parcela >= 1 ? "P" : "A",
      ie_origem_acordo: this.ie_origem_acordo,
      dt_inicio_debito: formatDateEn(`${payDay}/${formatMonthDate(this.dt_inicio_debito)}`),
      dt_fim_debito: formatDateEn(`${payDay}/${formatMonthDate(this.dt_fim_debito)}`),
      dt_entrada: formatDateEn(this.dt_entrada) || undefined,
      aq_arquivo: (this.$refs.pFileUpload as any)?.files?.[0],
    };
  }

  onFileUpload() {
    const pFileUpload: any = this.$refs.pFileUpload;

    pFileUpload?.clear();
    setTimeout(() => {
      (pFileUpload?.$refs?.fileInput as HTMLInputElement).click();
    }, 1000);
  }

  dayPickers() {
    const date = new Date(Date.now());
    if (this.props?.agreement?.nr_dia_vencimento) date.setDate(this.props.agreement.nr_dia_vencimento);

    return date;
  }

  handleDateInput(ref: string) {
    const calendarComponent: any = this.$refs[ref];
    if (!calendarComponent) return;

    const calendarInputComponent: any = calendarComponent.$refs?.input;
    if (!calendarInputComponent) return;

    const calendarInputEl = calendarInputComponent.$el;

    setTimeout(() => {
      calendarInputEl.selectionStart = calendarInputEl.selectionEnd = 100;
    }, 0);
  }

  validate() {
    const form = this.getFields();

    this.validation = validate(form);
    if (this.validation.error) {
      const messages: ToastParams[] = [{ severity: "error", summary: this.validation.error }];
      this.$store.commit("global/updateToast", messages);

      const field: any = this.$refs[this.validation.field || ""];
      if (field) field.$el.scrollIntoView(true);

      return false;
    }

    return true;
  }

  reset() {}

  handlePaymentMethodInput(event: any) {
    this.ie_forma_pagamento = event === null ? "A" : event;
    if (this.ie_forma_pagamento === "A") this.nr_qtd_parcela = 0;
    else if (this.nr_qtd_parcela === 0) this.nr_qtd_parcela = this.props.agreement?.nr_qtd_parcela || 1;
  }

  async getAgreementTypeList() {
    const response = await this.$store.state.debtor.service.getAgreementTypes();
    if (response.status !== 200) return;

    this.agreementTypes = response.data;
  }

  async getComponentData() {
    this.getAgreementTypeList();

    this.debtorTypes = (await this.$store.state.debtor.service.getTypes())?.data;
  }

  mounted() {
    this.getComponentData();
  }

  created() {
    this.cd_devedor = this.props.debtor.id || 0;
    this.cd_tipo_debito = this.props.agreement?.cd_tipo_debito || 0;
    this.cd_tipo_acordo = this.props.agreement?.cd_tipo_acordo || 0;
    this.nr_valor_entrada = this.props.agreement?.nr_valor_entrada?.toFixed(2) || "";
    this.nr_valor_debito_negociado = this.props.agreement?.nr_valor_debito_negociado?.toFixed(2) || "";
    this.nr_valor_parcela = this.props.agreement?.nr_valor_parcela?.toFixed(2) || "";
    this.nr_qtd_parcela = this.props.agreement?.nr_qtd_parcela ?? 0;
    this.ie_forma_pagamento = this.props.agreement?.ie_forma_pagamento || "A";
    this.ie_origem_acordo = this.props.agreement?.ie_origem_acordo || "A";
    this.dt_inicio_debito = formatMonthDate(this.props.agreement?.dt_inicio_debito);
    this.dt_fim_debito = formatMonthDate(this.props.agreement?.dt_fim_debito);
    this.dt_entrada = formatDate(this.props.agreement?.dt_entrada);
  }
}
</script>

<style lang="scss">
.p-dialog.agreement-form {
  width: 98vw;
  max-width: 1200px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;
    min-height: 40vh;

    .c-form-field-template.ds_liminar .p-inputtextarea {
      height: 40vh;

      overflow-y: auto !important;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #0000004d;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #3273dc;
      }
    }

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-fileupload {
    > .p-button {
      height: 40px;
      border-radius: 8px !important;
    }
  }

  .file-upload-button-edit-container {
    width: 100%;
    margin-top: 32px;

    .file-upload-button-edit {
      height: 40px;
      border-radius: 8px !important;

      * {
        font-size: 16px;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
