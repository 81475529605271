import gojus from "@/services/gojus";
import { Team, TeamForm } from "@/store/team/types";

export class TeamService {
  endpoint = "equipes/";
  cdEndpoint = (cd: number) => `equipes/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<Team>({ entity: this.cdEndpoint(cd) });
    return { data };
  }

  async getAll() {
    const { data } = await gojus.get<Team[]>({ entity: this.endpoint });
    return { data };
  }

  async create(team: TeamForm) {
    const { data } = await gojus.post<Team, TeamForm>({
      entity: this.endpoint,
      data: team,
    });

    return { data };
  }

  async edit(cd: number, team: TeamForm) {
    const { data } = await gojus.patch<Team, TeamForm>({
      entity: this.cdEndpoint(cd),
      data: team,
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({ entity: this.cdEndpoint(cd) });
    return { data };
  }
}

export default TeamService;
