import { DebtorAgreementForm } from "@/store/collection/debtor/types";

export function validate(agreement: DebtorAgreementForm) {
  if (!agreement?.cd_tipo_acordo)
    return {
      field: "cdTipoAcordo",
      error: 'O campo "Tipo de Acordo" é obrigatório!',
    };

  if (!agreement?.cd_tipo_debito)
    return {
      field: "cdTipoDebito",
      error: 'O campo "Tipo de Débito" é obrigatório!',
    };

  if (!agreement?.nr_dia_vencimento)
    return {
      field: "nrDiaVencimento",
      error: 'O campo "Dia do vencimento" é obrigatório!',
    };

  if (agreement.nr_dia_vencimento > 30)
    return {
      field: "nrDiaVencimento",
      error: 'O valor em "Dia do vencimento" é invalido!',
    };

  if (!agreement.dt_inicio_debito)
    return {
      field: "dtInicioDebto",
      error: 'O campo "Inicio do Débito" é obrigatório!',
    };

  return { field: null, error: null };
}
