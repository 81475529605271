import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/juridico/processos",
    name: "process",
    component: () => import("@/views/lawsuit/list.vue"),
  },
  {
    path: "/dashboard/juridico/processos/adicionar",
    name: "process-add",
    component: () => import("@/views/lawsuit/add.vue"),
  },
  {
    path: "/dashboard/juridico/areas",
    name: "areas",
    component: () => import("@/views/lawsuit/area/list.vue"),
  },
  {
    path: "/dashboard/juridico/areas/:cd",
    name: "areas-details",
    component: () => import("@/views/lawsuit/area/details.vue"),
  },
  {
    path: "/dashboard/juridico/instancias",
    name: "instancia",
    component: () => import("@/views/lawsuit/instance/list.vue"),
  },
  {
    path: "/dashboard/juridico/instancias/:cd",
    name: "instancia-details",
    component: () => import("@/views/lawsuit/instance/details.vue"),
  },
  {
    path: "/dashboard/juridico/fases",
    name: "fase",
    component: () => import("@/views/lawsuit/stage/list.vue"),
  },
  {
    path: "/dashboard/juridico/fases/:cd",
    name: "fase-details",
    component: () => import("@/views/lawsuit/stage/details.vue"),
  },
  {
    path: "/dashboard/juridico/processos/:cd",
    name: "process-details",
    component: () => import("@/views/lawsuit/details.vue"),
  },
  {
    path: "/dashboard/juridico/processos/:cd/editar",
    name: "process-edit",
    component: () => import("@/views/lawsuit/edit.vue"),
  },
  {
    path: "/dashboard/juridico/processos/:cd/auditar",
    name: "process-audit",
    component: () => import("@/views/lawsuit/audit.vue"),
  },
  {
    path: "/dashboard/juridico/processos/numero/:nr_processo",
    name: "process-number",
    component: () => import("@/views/lawsuit/details.vue"),
  },
  {
    path: "/dashboard/juridico/foros",
    name: "forum",
    component: () => import("@/views/forum/list.vue"),
  },
  {
    path: "/dashboard/juridico/foros/:cd/details",
    name: "forum-details",
    component: () => import("@/views/forum/details.vue"),
  },
  {
    path: "/dashboard/juridico/foros/:cd/edit",
    name: "forum-edit",
    component: () => import("@/views/forum/edit.vue"),
  },
  {
    path: "/dashboard/juridico/foros/add",
    name: "forum-add",
    component: () => import("@/views/forum/add.vue"),
  },
  {
    path: "/dashboard/juridico/tribunais",
    name: "juridical-court",
    component: () => import("@/views/develop.vue"),
  },
  {
    path: "/dashboard/juridico/clientes",
    name: "lawsuit-customer",
    component: () => import("@/views/lawsuit/customer/list.vue"),
  },
  {
    path: "/dashboard/juridico/clientes/adicionar",
    name: "lawsuit-customer-add",
    component: () => import("@/views/lawsuit/customer/add.vue"),
  },
  {
    path: "/dashboard/juridico/clientes/:cd/detalhes",
    name: "lawsuit-customer-details",
    component: () => import("@/views/lawsuit/customer/details.vue"),
  },
  {
    path: "/dashboard/juridico/clientes/:cd/editar",
    name: "lawsuit-customer-edit",
    component: () => import("@/views/lawsuit/customer/edit.vue"),
  },
  {
    path: "/dashboard/juridico/tipo-de-acao",
    name: "juridical-action-type",
    component: () => import("@/views/lawsuit/action-type/list.vue"),
  },
  {
    path: "/dashboard/juridico/tipo-de-acao/adicionar",
    name: "juridical-action-type-add",
    component: () => import("@/views/lawsuit/action-type/form.vue"),
  },
  {
    path: "/dashboard/juridico/tipo-de-acao/:cd",
    name: "juridical-action-type-details",
    component: () => import("@/views/lawsuit/action-type/details.vue"),
  },
  {
    path: "/dashboard/juridico/tipo-de-acao/:cd/editar",
    name: "juridical-action-type-edit",
    component: () => import("@/views/lawsuit/action-type/form.vue"),
  },
  {
    path: "/dashboard/juridico/causa-da-acao",
    name: "juridical-action-cause",
    component: () => import("@/views/lawsuit/action-cause/list.vue"),
  },
  {
    path: "/dashboard/juridico/causa-da-acao/adicionar",
    name: "juridical-action-cause-add",
    component: () => import("@/views/lawsuit/action-cause/form.vue"),
  },
  {
    path: "/dashboard/juridico/causa-da-acao/:cd",
    name: "juridical-action-cause-details",
    component: () => import("@/views/lawsuit/action-cause/details.vue"),
  },
  {
    path: "/dashboard/juridico/causa-da-acao/:cd/editar",
    name: "juridical-action-cause-edit",
    component: () => import("@/views/lawsuit/action-cause/form.vue"),
  },
  {
    path: "/dashboard/juridico/teses",
    name: "juridical-theses-bank",
    component: () => import("@/views/lawsuit/theses-bank/list.vue"),
  },
  {
    path: "/dashboard/juridico/teses/adicionar",
    name: "juridical-theses-bank-add",
    component: () => import("@/views/lawsuit/theses-bank/form.vue"),
  },
  {
    path: "/dashboard/juridico/teses/:cd",
    name: "juridical-theses-bank-details",
    component: () => import("@/views/lawsuit/theses-bank/details.vue"),
  },
  {
    path: "/dashboard/juridico/teses/:cd/editar",
    name: "juridical-theses-bank-edit",
    component: () => import("@/views/lawsuit/theses-bank/form.vue"),
  },
];

export default routes;
