import { Component, Mixins, Vue } from "vue-property-decorator";
import {
  Debtor,
  DebtorAgreement,
  DebtorAgreementForm,
  DebtorMove,
  DebtorMoveForm,
  DebtorParcel,
  DebtorRatingForm,
  DebtTypeForm,
  DebtorAgreementTypeForm,
  Partner,
  DebtorStatusForm,
} from "@/store/collection/debtor/types";
import { ModalParams, ToastParams } from "@/store/global/types";
import { MovementExternal, MovementForm } from "@/store/movement/types";
import { formatDate, formatDateEn } from "@/utils/formatters";
import { debtorStatus, debtorPriorities } from "@/utils/acronyms";
import { isTaskCoordinator } from "@/utils/validators";
import { User } from "@/store/user/types";

const TROCA_DE_TIRULARIDADE = 3;

@Component
export class DebtorPartnerOptions extends Vue {
  context!: string;
  currentPartner: Partner | null = null;
  showPartnerModal = false;
  partners: Partner[] = [];

  get debtor(): Debtor {
    return this.$store.state.debtor.current || { id: 0 };
  }

  addPartner() {
    this.currentPartner = null;
    this.showPartnerModal = true;
  }

  editPartner(partner: Partner) {
    this.currentPartner = partner;
    this.showPartnerModal = true;
  }

  removePartner(partner: Partner) {
    const params: ModalParams = {
      content: {
        title: `Remover sócio ${partner.nm_socio}?`,
        text: "Esta ação não pode ser desfeita!",
      },
      accept: {
        text: "Remover",
        class: "p-button-danger",
        function: async () => {
          const response = await this.$store.state.debtor.service.deletePartner(partner.id, this.debtor?.id);

          if (response.status === 204) {
            const messages: ToastParams[] = [
              {
                summary: "Sócio removido com sucesso!",
                severity: "success",
              },
            ];
            this.$store.commit("global/updateToast", messages);

            this.getPartnerList();
          }
        },
      },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async getPartnerList() {
    this.partners = (await this.$store.state.debtor.service.getPartners(this.debtor.id))?.data || [];
  }

  getPartnerOptions(partner: Partner) {
    return [
      {
        label: "Editar",
        icon: "fas fa-edit",
        command: () => this.editPartner(partner),
      },
      {
        label: "Deletar",
        icon: "fas fa-trash",
        command: () => this.removePartner(partner),
      },
    ];
  }

  onTogglePartner(_id: number, event: any) {
    (this.$refs[`partner-${_id}`] as any)?.toggle(event);
  }
}

@Component
export class MoveOptions extends Vue {
  context!: string;
  moves: DebtorMove[] = [];
  forms: DebtorMoveForm[] = [];

  onAskingToViewMove(move: DebtorMove & MovementExternal) {
    const params: ModalParams = {
      content: {
        title: `Movimentação ${move.id ? "#" + move.id : ""}`,
        text: move.ds_movimentacao || move.ds_mocimento,
      },
      options: {
        style: {
          modal: { maxHeight: "100vh", maxWidth: "640px", minWidth: "320px", overflowY: "auto", overflowX: "hidden" },
          content: { lineHeight: "1.3rem", fontSize: "1.05rem", textAlign: "justify", color: "#444" },
        },
        closable: true,
        useFooter: false,
        isHTML: true,
      },
    };
    this.$store.dispatch("global/showModal", params);
  }

  onAskingToViewDebtorMoveObservation(move: DebtorMove & MovementExternal) {
    const params: ModalParams = {
      content: {
        title: `Observações da Movimentação ${move.id ? "#" + move.id : ""}`,
        text: move.ds_observacao,
      },
      options: {
        style: {
          modal: { maxHeight: "100vh", maxWidth: "640px", minWidth: "320px", overflowY: "auto", overflowX: "hidden" },
          content: { lineHeight: "1.3rem", fontSize: "1.05rem", textAlign: "justify", color: "#444" },
        },
        closable: true,
        useFooter: false,
        isHTML: true,
      },
    };
    this.$store.dispatch("global/showModal", params);
  }

  onAskingToAddMove(debtor?: Debtor, movement?: string) {
    const params: ModalParams = {
      content: {
        title: `Adicionar Movimentação ao Devedor ${debtor?.id ? "#" + debtor.id : ""}`,
      },
      accept: {
        text: "Adicionar",
        function: (form: MovementForm) => this.handleAddMove({ ...form, cd_devedor: debtor?.id || 0 }),
      },
      props: { cd_devedor: debtor?.id, move: { ds_movimentacao: movement } },
      options: { modal: "move" },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleAddMove(form: DebtorMoveForm) {
    await this.$store.dispatch("movement/create", { form, service: "collection" });
    this.getMoveList();
  }

  async handleCancelMove(move: DebtorMove, debtor: Debtor) {
    await this.$store.dispatch("movement/cancel", {
      cd: move.id,
      cd_owner: move.isLawsuit ? debtor.ob_lawsuit?.id : debtor.id,
      service: move.isLawsuit ? "juridical" : "collection",
    });

    if (this.context === "list") this.getComponentData();
    if (this.context === "details") this.getMoveList();
  }

  async handleMovementFavorite(move: DebtorMove, debtor: Debtor) {
    if (!move.ie_ativo) return;
    move.ie_favorito = !move.ie_favorito;

    await this.$store.dispatch("movement/favorite", {
      cd: move.id,
      cd_owner: move.isLawsuit ? debtor.ob_lawsuit?.id : move.cd_devedor,
      ie_favorito: move.ie_favorito,
      service: move.isLawsuit ? "juridical" : "collection",
    });
  }

  getMoveList() {}
  getComponentData() {}
}

@Component
export class MoveMenu extends Mixins(MoveOptions) {
  selectedMovement: any = null;

  getMoveOptions(move: DebtorMove, debtor: Debtor) {
    const items = [
      {
        label: "Movimentação",
        icon: "fas fa-info-circle",
        command: () => this.onAskingToViewMove(move),
      },
      {
        label: "Observação",
        icon: "fas fa-info-circle",
        command: () => this.onAskingToViewDebtorMoveObservation(move),
      },
      {
        label: "Cancelar",
        icon: "fas fa-ban",
        command: () => this.handleCancelMove(move, debtor),
      },
      {
        label: "Anexos",
        icon: "fas fa-file",
        command: () => {
          this.selectedMovement = move;
        },
      },
    ];

    return items;
  }

  onToggleMove(event: Event, move: Debtor) {
    const menuItem: any = this.$refs[`move${move.id}`];
    menuItem?.toggle(event);
  }

  onAskingToDownloadMoveFile(file: any) {
    if (!file.aq_arquivo) return;
    window.open(file.aq_arquivo, "_blank");
  }
}

@Component
export class AgreementOptions extends Vue {
  context!: string;

  onAskingToListParcel(agreement: DebtorAgreement) {
    const params: ModalParams = {
      content: { title: `Lista de Parcelas do Acordo #${agreement.id}` },
      props: { agreement },
      options: { modal: "parcel-list", closable: true, useFooter: false },
    };

    this.$store.dispatch("global/showModal", params);
  }

  onAskingToListAgreement(debtor: Debtor) {
    const params: ModalParams = {
      content: { title: `Lista de Acordos do Devedor #${debtor.id}` },
      props: { debtor },
      options: { modal: "agreement-list", closable: true, useFooter: false },
    };

    this.$store.dispatch("global/showModal", params);
  }

  onAskingToAddAgreement(debtor: Debtor) {
    const params: ModalParams = {
      content: { title: `Adicionar Acordo ao Devedor #${debtor.id}` },
      accept: {
        text: "Adicionar",
        class: "p-button-success",
        function: (form: DebtorAgreementForm) => this.handleAddAgreement(form, debtor),
      },
      props: { debtor },
      options: { modal: "agreement-form", closable: true },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleAddAgreement(form: DebtorAgreementForm, debtor: Debtor) {
    const response = await this.$store.state.debtor.service.addAgreement(form);
    if (response.status >= 200 && response.status < 300) {
      const message: ToastParams[] = [{ severity: "success", summary: "Acordo cadastrado com sucesso!" }];
      this.$store.commit("global/updateToast", message, { root: true });

      this.onAskingToListAgreement(debtor);
      if (form.cd_tipo_acordo === TROCA_DE_TIRULARIDADE) this.addHolder(form.cd_devedor);
    }
  }

  addHolder(cd_devedor: number) {
    setTimeout(() => {
      const params: ModalParams = {
        content: { title: "Cadastrar Titular" },
        accept: {
          text: "Adicionar",
          class: "p-button-success",
          function: (form: any) => this.handleAddHolder(form, cd_devedor),
        },
        options: { modal: "agreement-holder" },
      };

      this.$store.dispatch("global/showModal", params);
    }, 200);
  }

  async handleAddHolder(formHolder: any, cd_devedor: number) {
    formHolder.nm_usuario_edi = this.user.username;
    await this.$store.dispatch("debtor/createHolder", { formHolder, cd_devedor });
  }

  get user(): User {
    return this.$store.state.user.current;
  }

  onAskingToEditAgreement(debtor: Debtor, agreement: DebtorAgreement) {
    const params: ModalParams = {
      content: {
        title: `Editar Acordo #${agreement.id} do Devedor #${debtor.id}`,
      },
      accept: {
        text: "Salvar",
        class: "p-button-success",
        function: (form: DebtorAgreementForm) => this.handleEditAgreement(agreement.id, debtor, form),
      },
      props: { debtor, agreement },
      options: { modal: "agreement-form", closable: true },
    };

    this.$store.dispatch("global/showModal", params);
  }

  onAskingToDownloadFile(agreement: DebtorAgreement) {
    if (!agreement.aq_arquivo) return;
    window.open(agreement.aq_arquivo, "_blank");
  }

  async handleEditAgreement(cd_acordo: number, debtor: Debtor, form: DebtorAgreementForm) {
    const response = await this.$store.state.debtor.service.editAgreement(cd_acordo, debtor.id, form);

    if (response.status >= 200 && response.status < 300) {
      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Acordo #${cd_acordo} editado com sucesso!`,
        },
      ];
      this.$store.commit("global/updateToast", message, { root: true });

      this.onAskingToListAgreement(debtor);
    }
  }

  async onAskingTohomologate(debtor: Debtor, agreement: DebtorAgreement) {
    const params: ModalParams = {
      content: {
        title: `Marcar o acordo #${agreement.id} como homologado?`,
        text: `Deseja marcar o acordo #${agreement.id} como homologado?`,
      },
      accept: { text: "Sim", function: () => this.handleHomologate(debtor, agreement, true) },
      recuse: { text: "Não", function: () => this.handleHomologate(debtor, agreement, false) },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleHomologate(debtor: Debtor, agreement: DebtorAgreement, answer: boolean) {
    const response = await this.$store.state.debtor.service.homologate(agreement.id, debtor.id, answer);
    if (response.status >= 200 && response.status < 300) {
      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Acordo #${agreement.id} editado com sucesso!`,
        },
      ];
      this.$store.commit("global/updateToast", message, { root: true });
      this.onAskingToListAgreement(debtor);
    }
  }

  goToAudit(debtor: Debtor) {
    this.$router.push({
      name: "debtor-audit",
      params: { cd: debtor.id.toString() },
    });
  }

  onAskingToCancelAgreement(debtor: Debtor, agreement: DebtorAgreement) {
    const params: ModalParams = {
      content: {
        title: `Cancelar Acordo #${agreement.id} do Devedor #${debtor.id}?`,
        text: "Essa ação não pode ser desfeita!",
      },
      accept: {
        text: "Cancelar",
        class: "p-button-danger",
        function: () => this.handleCancelAgreement(agreement.id, debtor),
      },
      recuse: {
        text: "Fechar",
      },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleCancelAgreement(cd_acordo: number, debtor: Debtor) {
    const response = await this.$store.state.debtor.service.cancelAgreement(cd_acordo, debtor.id);

    if (response.status >= 200 && response.status < 300) {
      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Acordo #${cd_acordo} cancelado com sucesso!`,
        },
      ];
      this.$store.commit("global/updateToast", message, { root: true });

      this.onAskingToListAgreement(debtor);
    }
  }

  getListData() {}
  getComponentData() {}
}

@Component
export class AgreementMenu extends Mixins(AgreementOptions) {
  getOptions(debtor: Debtor, agreement: DebtorAgreement) {
    const options = [
      {
        label: "Parcelas",
        icon: "fas fa-file-invoice-dollar",
        command: () => this.onAskingToListParcel(agreement),
      },
      {
        label: "Homologado",
        icon: "far fa-bookmark",
        command: () => this.onAskingTohomologate(debtor, agreement),
      },
      {
        label: "Editar",
        icon: "fas fa-edit",
        command: () => this.onAskingToEditAgreement(debtor, agreement),
      },
      {
        label: "Cancelar",
        icon: "fas fa-times",
        command: () => this.onAskingToCancelAgreement(debtor, agreement),
      },
    ];

    if (agreement.aq_arquivo)
      options.push({
        label: "Visualizar anexo",
        icon: "fas fa-file-lines",
        command: () => this.onAskingToDownloadFile(agreement),
      });

    return options;
  }

  onToggle(event: Event, debtor: Debtor) {
    const menuItem: any = this.$refs[debtor.id];
    menuItem.toggle(event);
  }

  getComponentData() {}
}

@Component
export class ParcelOptions extends Mixins(AgreementOptions) {
  declare context: string;

  onAskingToEditParcel(agreement: DebtorAgreement, parcel: DebtorParcel) {
    const params: ModalParams = {
      content: {
        title: `Parcela #${parcel.id} do Acordo #${agreement.id}`,
      },
      accept: {
        class: "p-button-success",
        function: (form: any) => this.handleEditParcel(agreement, parcel.id, form),
      },
      recuse: { function: () => this.onAskingToListParcel(agreement) },
      props: { parcelValue: parcel.nr_valor?.toFixed(2) },
      options: { modal: "pay-parcel" },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleEditParcel(agreement: DebtorAgreement, cd_parcela: number, form: { date: string; value: string }) {
    const response = await this.$store.state.debtor.service.editParcel(cd_parcela, agreement.id, {
      dt_pagamento: form.date,
      nr_valor_pago: Number(form.value),
    });

    if (response.status >= 200 && response.status < 300) {
      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Parcela #${cd_parcela} do Acordo #${agreement.id} editado com sucesso!`,
        },
      ];
      this.$store.commit("global/updateToast", message, { root: true });

      this.onAskingToListParcel(agreement);
    }
  }

  async handleReopenParcel(agreement: DebtorAgreement, cd_parcela: number) {
    const response = await this.$store.state.debtor.service.reopenParcel(cd_parcela, agreement.id);

    if (response.status < 300) {
      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Parcela #${cd_parcela} do Acordo #${agreement.id} reaberta com sucesso!`,
        },
      ];
      this.$store.commit("global/updateToast", message, { root: true });

      this.getComponentData();
    }
  }
}

@Component
export class ParcelMenu extends Mixins(ParcelOptions) {
  getOptions(agreement: DebtorAgreement, parcel: DebtorParcel) {
    const options: any[] = [];

    if (parcel.ie_status) {
      options.push({
        label: "Reabrir",
        icon: "fas fa-receipt",
        command: () => this.handleReopenParcel(agreement, parcel.id),
      });
    } else {
      options.push({
        label: "Dar Baixa",
        icon: "fas fa-receipt",
        command: () => this.onAskingToEditParcel(agreement, parcel),
      });
    }

    return options;
  }

  onToggle(event: Event, debtor: Debtor) {
    const menuItem: any = this.$refs[debtor.id];
    menuItem.toggle(event);
  }

  getComponentData() {}
}

@Component
export class DebtTypeOptions extends Mixins(AgreementOptions) {
  declare context: string;

  onAskingToCreateDebtType(debtor: Debtor) {
    const params: ModalParams = {
      content: { title: "Cadastrar Tipo de Debito" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: (fields: { field: string }) => this.handleCreateDebtType({ nm_tipo_debito: fields.field }, debtor),
      },
      props: { label: "Nome do Tipo" },
      options: { modal: "input-text" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  async handleCreateDebtType(form: DebtTypeForm, debtor: Debtor) {
    await this.$store.state.debtor.service.createDebtType(form);
    this.onAskingToAddAgreement(debtor);
  }

  getListData() {}
  getComponentData() {}
}

@Component
export class DebtorStatusOptions extends Vue {
  context!: string;

  onAskingToCreateDebtorStatus() {
    const params: ModalParams = {
      content: { title: "Cadastrar Status de Devedor" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: (fields: { field: string }) => this.handleCreateDebtorStatus({ nm_status: fields.field }),
      },
      props: { label: "Nome do Status" },
      options: { modal: "input-text" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  async handleCreateDebtorStatus(form: DebtorStatusForm) {
    await this.$store.state.debtor.service.createStatus(form);
    this.getStatusList();
  }

  getStatusList() {}
  getComponentData() {}
}

@Component
export class DebtorRatingOptions extends Vue {
  context!: string;

  onAskingToCreateDebtorRating() {
    const params: ModalParams = {
      content: { title: "Cadastrar Classificação do Devedor" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: (fields: { field: string }) => this.handleCreateDebtorRating({ nm_classificacao: fields.field }),
      },
      props: { label: "Nome da Classificação" },
      options: { modal: "input-text" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  async handleCreateDebtorRating(form: DebtorRatingForm) {
    await this.$store.state.debtor.service.createRating(form);
    this.getRatingList();
  }

  getRatingList() {}
  getComponentData() {}
}

@Component
export class DebtorAgreementTypeOptions extends Mixins(AgreementOptions) {
  declare context: string;

  onAskingToCreateDebtorAgreementType(debtor: Debtor) {
    const params: ModalParams = {
      content: { title: "Cadastrar Tipo de Devedor" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: (fields: { field: string }) =>
          this.handleCreateDebtorAgreementType({ nm_tipo_acordo: fields.field }, debtor),
      },
      props: { label: "Nome do Tipo" },
      options: { modal: "input-text" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  async handleCreateDebtorAgreementType(form: DebtorAgreementTypeForm, debtor: Debtor) {
    await this.$store.state.debtor.service.createAgreementType(form);
    this.onAskingToAddAgreement(debtor);
  }

  getAgreementTypeList() {}
  getComponentData() {}
}

@Component
export class DebtorOptions extends Mixins(AgreementOptions, MoveOptions) {
  declare context: string;

  get debtor(): Debtor {
    return { id: 0 };
  }

  async onAskingToDossier(debtor: Debtor) {
    let name = "dossie";

    try {
      const response = await this.$store.state.debtor.service.getDossier(debtor.id);
      if (response.status !== 200) return;

      const date = new Date().toDateString();
      const time = new Date().toTimeString().slice(0, 5);

      name = `${"dossie"}-${formatDate(date).replaceAll("/", "_")}-${time.replaceAll(":", "_")}`;

      const link = document.createElement("a");
      link.href = response.data.link;
      link.download = name;
      link.click();
    } catch {
      return;
    }
  }

  goToAdd() {
    this.$router.push({ name: "debtor-add" });
  }

  goToDetails(debtor: Debtor) {
    this.$router.push({
      name: "debtor-details",
      params: { cd: debtor.id.toString() },
    });
  }

  goToEdit(debtor: Debtor) {
    this.$router.push({
      name: "debtor-edit",
      params: { cd: debtor.id.toString() },
    });
  }

  onAskingToUpdateDebt(debtor: Debtor) {
    const params: ModalParams = {
      content: { title: `Atualizar débito do Devedor #${debtor.id}` },
      accept: {
        function: (form: { field: string }) => this.handleUpdateDebt(form.field, debtor),
      },
      props: { label: "Valor da Atualização", field: debtor.nr_valor_debito_atualizado?.toFixed(2) },
      options: { modal: "input-money" },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleUpdateDebt(value: string, debtor: Debtor) {
    await this.$store.dispatch("debtor/edit", {
      cd: debtor.id,
      form: { nr_valor_debito_atualizado: value, dt_debito: formatDateEn(new Date().toString()) },
    });
    await this.$store.state.debtor.service.addUpdatedDebt({ cd_devedor: debtor.id, nr_valor_debito_atualizado: value });

    if (this.context === "list") this.getListData();
    if (this.context === "details") this.getUpdatedDebtList();
  }

  async checkMovement(debtor: Debtor, movement?: DebtorMove) {
    movement = movement || debtor.movimentacao;
    if (!movement) return;
    const date = formatDateEn(new Date());

    try {
      await this.$store.state.debtor.service.checkMovement(movement.id, debtor.id, date);
      movement.dt_verificacao = date;

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Movimentação #${movement?.id} verificada em ${formatDate(date)}!`,
        },
      ];
      this.$store.commit("global/updateToast", message);
    } catch {
      const message: ToastParams[] = [
        { severity: "error", summary: "Algo deu errado", detail: "Tente novamente mais tarde" },
      ];
      this.$store.commit("global/updateToast", message);
    }
  }

  async onAskingToChangeStatus(debtor: Debtor, item: any, key: string) {
    const params: ModalParams = {
      content: { title: `Deseja definir o caso #${debtor.id} como ${item.label}?` },
      accept: {
        text: "Sim",
        function: () => this.handleStatus(debtor, item.value, key),
      },
      recuse: {
        text: "Não",
      },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleStatus(debtor: Debtor, answer: string, key: string) {
    if (debtor.ie_status_devedor === answer) return;
    await this.$store.state.debtor.service.editDebtorParcial(debtor.id, { [key]: answer });

    if (this.context === "list") this.getComponentData();
    if (this.context === "details") this.getComponentData();
  }

  onAskingToStrategic(debtor: Debtor) {
    const params: ModalParams = {
      content: {
        title: `Marcar Devedor #${debtor.id} como estratégico?`,
        text: `Deseja marcar o devedor #${debtor.id} como estratégico?`,
      },
      accept: { text: "Sim", function: () => this.handleStrategic(debtor, true) },
      recuse: { text: "Não", function: () => this.handleStrategic(debtor, false) },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleStrategic(debtor: Debtor, answer: boolean) {
    await this.$store.dispatch("debtor/strategic", { cd: debtor.id, ie_estrategico: answer });

    if (this.context === "list") this.getComponentData();
    if (this.context === "details") this.$router.replace({ name: "debtor" });
  }

  onAskingToDelete(debtor: Debtor) {
    const params: ModalParams = {
      content: {
        title: `Deletar Devedor #${debtor.id}`,
        text: `Tem certeza que deseja excluir o devedor #${debtor.id}?`,
      },
      accept: { function: () => this.handleDelete(debtor) },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleDelete(debtor: Debtor) {
    await this.$store.dispatch("debtor/delete", { cd: debtor.id });

    if (this.context === "list") this.getListData();
    if (this.context === "details") this.$router.replace({ name: "debtor" });
  }

  async onAskingToAddMoveStatus(debtor: Debtor, item: any, key: string) {
    this.onAskingToAddMove(debtor, item.label);
    this.handleStatus(debtor, item.value, key);
  }

  items(status: any, debtor: Debtor, key: string) {
    const classification: any = [];

    for (const itemStatus of status) {
      const item: {
        label: string;
        icon: string;
        items: any[] | null;
        command: any;
      } = {
        label: itemStatus.label,
        icon: itemStatus.icon,
        items: null,
        command: () => {},
      };

      if (itemStatus.value === "IP" || itemStatus.value === "IC") {
        item.command = () => this.onAskingToAddMoveStatus(debtor, itemStatus, key);
        classification.push(item);
      } else if (itemStatus.subStatus) {
        classification.push({
          label: itemStatus.label,
          icon: itemStatus.icon,
          items: this.items(itemStatus.subStatus, debtor, key),
        });
      } else {
        item.command = () => this.onAskingToChangeStatus(debtor, itemStatus, key);
        classification.push(item);
      }
    }

    return classification;
  }

  getDebtorOptions(debtor: Debtor) {
    return [
      {
        label: "Acordos",
        icon: "fas fa-hands-helping",
        command: () => this.onAskingToListAgreement(debtor),
      },
      {
        label: "Status do Devedor",
        icon: "fas fa-list",
        items: this.items(debtorStatus, debtor, "ie_status_devedor"),
        command: () => {},
      },
      {
        label: "Prioridade",
        icon: "fas fa-tag",
        items: this.items(debtorPriorities, debtor, "ie_prioridade"),
        command: () => {},
      },
      {
        label: "Estratégico",
        icon: "fas fa-book",
        command: () => this.onAskingToStrategic(debtor),
      },
    ];
  }

  onToggleDebtorOptions(event: Event) {
    const menuItem: any = this.$refs[`debtor-options`];
    menuItem.toggle(event);
  }

  getListData() {}
  getUpdatedDebtList() {}
  getComponentData() {}
}

@Component
export class ListMenu extends Mixins(DebtorOptions, AgreementOptions, MoveMenu) {
  getOptions(debtor: Debtor) {
    const items = [
      {
        label: "Visualizar",
        icon: "fas fa-info-circle",
        command: () => this.goToDetails(debtor),
      },
      {
        label: "Acordos",
        icon: "fas fa-handshake",
        command: () => this.onAskingToListAgreement(debtor),
      },
      {
        label: "Atualizar Débito",
        icon: "fas fa-money-bill-alt",
        command: () => this.onAskingToUpdateDebt(debtor),
      },
    ];

    //Classificação de devedores
    const classification: {
      label: string;
      icon: string;
      items: any[];
      command: any;
    } = {
      label: "Status do Devedor",
      icon: "fas fa-list",
      items: this.items(debtorStatus, debtor, "ie_status_devedor"),
      command: () => {},
    };

    items.push(classification);

    //Prioridade
    const priority: {
      label: string;
      icon: string;
      items: any[];
      command: any;
    } = {
      label: "Prioridade",
      icon: "fas fa-tag",
      items: this.items(debtorPriorities, debtor, "ie_prioridade"),
      command: () => {},
    };

    items.push(priority);

    items.push({ label: "Estratégico", icon: "fas fa-book", command: () => this.onAskingToStrategic(debtor) });

    items.push({
      label: "Editar",
      icon: "fas fa-edit",
      command: () => this.goToEdit(debtor),
    });

    if (this.hasPermissionCoordinator)
      items.push({
        label: "Deletar",
        icon: "fas fa-trash",
        command: () => this.onAskingToDelete(debtor),
      });

    return items;
  }

  get hasPermissionCoordinator() {
    return this.$store.state.user.permissions.taskCoordinator;
  }

  onToggle(event: Event, debtor: Debtor) {
    const menuItem: any = this.$refs[debtor.id];
    menuItem.toggle(event);
  }

  getComponentData() {}
}
