<template>
  <div class="c-request-conference form">
    <c-form-field-template width="100%">
      <template #label>Observação Final</template>
      <p-textarea v-model="ds_observacao_final" :autoResize="true" rows="5" cols="auto" autofocus />
    </c-form-field-template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate } })
export default class CCancellationMotiveModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  ds_observacao_final = "";

  getFields() {
    return { ds_observacao_final: this.ds_observacao_final };
  }

  reset() {
    this.ds_observacao_final = "";
  }
}
</script>

<style lang="scss"></style>
