import { Module, VuexModule } from "vuex-module-decorators";

import CustomerModule from "@/store/logistics/customer/module";

@Module({ namespaced: true })
export class LogisticsModule extends VuexModule {
  static modules = {
    customer: CustomerModule,
  };
}

export default LogisticsModule;
