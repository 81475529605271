import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { ActionType } from "@/store/lawsuit/action-type/types";

import { ActionTypeService } from "@/store/lawsuit/action-type/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class ActionTypeModule extends VuexModule {
  all: ActionType[] = [];
  list: ActionType[] = [];
  current: Partial<ActionType> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new ActionTypeService();

  @Mutation
  updateCurrent(actionType: ActionType | null) {
    this.current = actionType || {};
  }

  @Mutation
  updateActionTypeList(actionTypes: ActionType[]) {
    this.list = actionTypes;
  }

  @Mutation
  updateActionTypeAll(actionTypes: ActionType[]) {
    this.all = actionTypes;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const { data: actionType } = await this.service.get(payload.cd);
      this.context.commit("updateCurrent", actionType);

      return actionType;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getPaginated() {
    try {
      const response = await this.service.getPaginated(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        this.filters
      );

      this.context.commit("updateActionTypeList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(this.filters);
      this.context.commit("updateActionTypeAll", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: any }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Tipo de Ação cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: any }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Tipo de Ação editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Tipo de Ação excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ActionTypeModule;
