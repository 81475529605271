import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { LogisticsCustomer, LogisticsCustomerParams } from "@/store/logistics/customer/types";

import { LogisticsCustomerService } from "@/store/logistics/customer/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class LogisticsCustomerModule extends VuexModule {
  current: LogisticsCustomer = { id: 0 };
  all: LogisticsCustomer[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new LogisticsCustomerService();

  @Mutation
  updateLogisticsCustomer(customer: LogisticsCustomer) {
    this.current = customer;
  }

  @Mutation
  updateLogisticsCustomers(customers: LogisticsCustomer[]) {
    this.all = customers;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(params: LogisticsCustomerParams) {
    try {
      const { data } = await this.service.get(params.cd);

      if (params?.request?.cd_cidade !== false && Number(data.cd_cidade))
        data.ob_cidade = await this.context.dispatch("location/getCity", { cd_cidade: data.cd_cidade }, { root: true });
      for (const serviceValue of data.cd_valor_servico || []) {
        if (Number(serviceValue.cd_cidade))
          serviceValue.ob_cidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: serviceValue.cd_cidade },
            { root: true }
          );
      }
      this.context.commit("updateLogisticsCustomer", data);

      return data;
    } catch (error: any) {
      if (error.isGojusError && error.status === 404) return;

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params: LogisticsCustomerParams) {
    try {
      const { data } = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        { ...this.filters, ...(params?.filters || {}) }
      );

      if (params?.request?.cd_cidade)
        for (const customer of data.results) {
          if (Number(customer.cd_cidade))
            customer.ob_cidade = await this.context.dispatch(
              "location/getCity",
              { cd_cidade: customer.cd_cidade },
              { root: true }
            );
        }
      this.context.commit("updateLogisticsCustomers", data.results);
      this.context.commit("updatePaginationTotal", data.count);

      return data;
    } catch (error: any) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: LogisticsCustomerParams) {
    try {
      const { data } = await this.service.create(params.customer);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Cliente cadastrado com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateLogisticsCustomer", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: LogisticsCustomerParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.customer);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Cliente editado com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateLogisticsCustomer", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: LogisticsCustomerParams) {
    try {
      const { data } = await this.service.delete(params.cd);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Cliente excluído com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateLogisticsCustomer", { id: 0 });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default LogisticsCustomerModule;
