import { PrimeVueLocaleOptions } from "primevue/config";

export default {
  locale: {
    startsWith: "Começa com",
    contains: "Contém",
    notContains: "Não contém",
    endsWith: "Termina com",
    equals: "Igual a",
    notEquals: "Diferente de",
    noFilter: "Sem Filtro",
    lt: "Menor que",
    lte: "Menor ou igual a",
    gt: "Maior que",
    gte: "Maior ou igual a",
    dateIs: "Data é",
    dateIsNot: "Data não é",
    dateBefore: "Data é anterior",
    dateAfter: "Data é posterior",
    clear: "Limpar",
    apply: "Aplicar",
    matchAll: "Corresponde a Todos",
    matchAny: "Corresponde a Qualquer",
    addRule: "Adicionar Regra",
    removeRule: "Remover Regra",
    accept: "Sim",
    reject: "Não",
    choose: "Escolher",
    upload: "Carregar",
    cancel: "Cancelar",
    dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Sg", "Te", "Qa", "Qi", "Sx", "Sá"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: "Hoje",
    weekHeader: "Sm",
    firstDayOfWeek: 0,
    dateFormat: "dd/mm/yy",
    weak: "Fraca",
    medium: "Média",
    strong: "Forte",
    passwordPrompt: "Digite uma senha",
    emptyFilterMessage: "Nenhum resultado encontrado",
    emptyMessage: "Nenhuma opção disponível",
  } as PrimeVueLocaleOptions,
};
