import gojus from "@/services/gojus";
import { LogisticsActType, LogisticsActTypeForm } from "@/store/logistics/acttype/types";

const { VUE_APP_API_LOGISTICS_URL } = process.env;

export class LogisticsActTypeService {
  endpoint = "tipos/";
  cdEndpoint = (cd: number) => `tipos/${cd}/`;

  async get(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<LogisticsActType>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL, query },
    });

    return { data };
  }

  async getAll(filters?: any) {
    const { data } = await gojus.get<LogisticsActType[]>({
      entity: this.endpoint,
      config: { query: filters || {}, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async create(acttype: LogisticsActTypeForm) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: acttype,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async edit(cd: number, acttype: LogisticsActTypeForm) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: acttype,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }
}

export default LogisticsActTypeService;
