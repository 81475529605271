import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { BillToPay, BillToPayForm, FavoredTypes } from "@/store/financial/bill-to-pay/types";

import { BillToPayService } from "@/store/financial/bill-to-pay/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class BillToPayModule extends VuexModule {
  all: BillToPay[] = [];
  current: Partial<BillToPay> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  genericFilters: any = {};

  service = new BillToPayService();

  @Mutation
  updateCurrent(billToPay: BillToPay) {
    this.current = billToPay;
  }

  @Mutation
  updateBillToPayList(billToPays: BillToPay[]) {
    this.all = billToPays;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updateGenericFilters(value: any) {
    this.genericFilters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const response = await this.service.get(payload.cd);
      const billToPay = response.data;

      if (billToPay.cd_favorecido) {
        billToPay.ob_favorecido = await this.context.dispatch("getFavored", billToPay);
      }

      this.context.commit("updateCurrent", billToPay);

      return billToPay;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getFavored(billToPay: BillToPay) {
    try {
      const getFavoredItem = async () => {
        try {
          if (billToPay.ie_tipo_favorecido === "CO")
            return (await this.context.rootState.collection.customer.service.get(billToPay.cd_favorecido))?.data;
          else if (billToPay.ie_tipo_favorecido === "JU")
            return (await this.context.rootState.lawsuit.customer.service.get(billToPay.cd_favorecido))?.data;
          else if (billToPay.ie_tipo_favorecido === "FU")
            return (await this.context.rootState.department.employee.service.get(billToPay.cd_favorecido))?.data;
          else if (billToPay.ie_tipo_favorecido === "LO")
            return (await this.context.rootState.logistics.customer.service.get(billToPay.cd_favorecido))?.data;
          else if (billToPay.ie_tipo_favorecido === "CR") {
            return (await this.context.rootState.correspondent.service.get(billToPay.cd_favorecido))?.data;
          } else if (["FA", "PS", "EM", "CL"].includes(billToPay.ie_tipo_favorecido))
            return (await this.context.rootState.financial.favored.service.get(billToPay.cd_favorecido))?.data;

          return {};
        } catch (error) {
          return null;
        }
      };

      const favored = await getFavoredItem();

      return { ...favored, ie_tipo_favorecido: billToPay.ie_tipo_favorecido };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getFavoredList(payload: { query: string; ie_tipo_favorecido: FavoredTypes }) {
    try {
      if (payload.ie_tipo_favorecido === "CO")
        return (await this.context.rootState.collection.customer.service.getAll({}, { nm_cliente: payload.query }))
          ?.data;
      else if (payload.ie_tipo_favorecido === "JU")
        return (await this.context.rootState.lawsuit.customer.service.getAll({}, { nm_cliente: payload.query }))?.data;
      else if (payload.ie_tipo_favorecido === "FU")
        return (await this.context.rootState.department.employee.service.getAll({}, { nm_funcionario: payload.query }))
          ?.data;
      else if (payload.ie_tipo_favorecido === "LO")
        return (await this.context.rootState.logistics.customer.service.getAll({}, { nm_cliente: payload.query }))
          ?.data;
      else if (payload.ie_tipo_favorecido === "CR")
        return (await this.context.rootState.correspondent.service.getAll({ nm_correspondente: payload.query }))?.data;
      else if (["FA", "PS", "EM", "CL"].includes(payload.ie_tipo_favorecido)) {
        const filters: any = { nm_favorecido: payload.query };
        if (payload.ie_tipo_favorecido !== "FA") filters.ie_tipo_favorecido = payload.ie_tipo_favorecido;

        return (await this.context.rootState.financial.favored.service.getAll(filters))?.data;
      }

      return [];
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: { genericFilters: any; getFavored?: boolean }) {
    try {
      let filters: any = {};

      if (payload?.genericFilters?.day?.length) {
        filters.days_vencimento = payload.genericFilters.day.join(",");
      }

      if (typeof payload?.genericFilters?.monthAndYear === "object") {
        filters.mes_vencimento = payload.genericFilters.monthAndYear.getMonth() + 1;
        filters.ano_vencimento = payload.genericFilters.monthAndYear.getFullYear();
      }

      if (payload?.genericFilters?.status_pagamento) {
        filters.status_pagamento = payload.genericFilters.status_pagamento;
      }

      if (Object.keys(this.filters).length) {
        filters = {};
      }

      const response = await this.service.getAll(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        { ...this.filters, ...filters }
      );

      if (payload.getFavored) {
        await Promise.allSettled(
          response.data.results.map(async (billToPay) => {
            billToPay.ob_favorecido = await this.context.dispatch("getFavored", billToPay);
          })
        );
      }

      this.context.commit("updateBillToPayList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error: any) {
      if (error?.status === 404) {
        this.context.commit("updateCurrentPaginationPage", 1);
        return await this.context.dispatch("getAll", payload);
      }

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: BillToPayForm }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a pagar cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: BillToPayForm }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a pagar editada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a pagar excluída com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default BillToPayModule;
