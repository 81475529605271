import { Action, Module, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { JuridicalFileForm } from "@/store/lawsuit/file/types";

import { JuridicalFileService } from "@/store/lawsuit/file/service";

@Module({ namespaced: true })
export class JuridicalFileModule extends VuexModule {
  service = new JuridicalFileService();

  @Action
  async create(payload: { form: JuridicalFileForm }) {
    try {
      const requests = payload.form.aq_arquivo.map((aq_arquivo) =>
        this.service.create({ ...payload.form, aq_arquivo })
      );
      await Promise.allSettled(requests);

      this.context.commit(
        "global/updateToast",
        [{ severity: "success", summary: "Arquivo(s) anexado(s) com sucesso!" }] as ToastParams[],
        { root: true }
      );
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);

      this.context.commit(
        "global/updateToast",
        [{ severity: "success", summary: "Arquivo excluído com sucesso!" }] as ToastParams[],
        { root: true }
      );

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default JuridicalFileModule;
