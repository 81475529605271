<template>
  <hr class="c-divider" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CDivider extends Vue {}
</script>

<style lang="scss" scoped>
.c-divider {
  --margin-y: 0;
  --margin-x: 0;

  margin: var(--margin-y) var(--margin-x);

  border: 1px solid #dee2e6;
  background-color: #dee2e6;
}
</style>
