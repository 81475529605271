import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Warning, WarningParams } from "@/store/warning/types";

import { WarningService } from "@/store/warning/service";
import { Pagination, PaginationRow } from "../types";

@Module({ namespaced: true })
export class WarningModule extends VuexModule {
  all: Warning[] = [];
  current: Warning | null = null;
  pagination: Pagination = {
    row: 10,
    total: 0,
    current: 1,
  };
  filters: any = {};

  service = new WarningService();

  @Mutation
  updateWarning(warning: any) {
    this.current = warning;
  }

  @Mutation
  updateWarnings(warnings: Warning[]) {
    this.all = warnings;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async getAllShow() {
    try {
      const isCorrespondent = Boolean(this.context.rootState.user.current.ie_correspondente);
      const isOffice = !isCorrespondent;

      const filteredWarnings = await this.service.getAllShow(isCorrespondent, isOffice);

      this.context.commit("global/updateWarningMessages", filteredWarnings, { root: true });
      if (filteredWarnings.length !== 0) this.context.commit("global/updateWarningStatus", true, { root: true });

      return filteredWarnings;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async get(params: WarningParams) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateWarning", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const { data } = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        this.filters
      );

      this.context.commit("updateWarnings", data.results);
      this.context.commit("updatePaginationTotal", data.count);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: WarningParams) {
    try {
      const { data } = await this.service.create(params.form);
      this.context.commit("updateWarning");

      const message: ToastParams[] = [{ severity: "success", summary: "Aviso cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: WarningParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.form);
      this.context.commit("updateWarning");

      const message: ToastParams[] = [
        { severity: "success", summary: params.successMessage || "Aviso editado com sucesso!" },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: WarningParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateWarning");

      const message: ToastParams[] = [{ severity: "success", summary: "Aviso excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default WarningModule;
