<template>
  <c-form-template class="c-input-money-modal" :useFooter="false" @submit="$emit('submit')">
    <c-form-field-template width="100%">
      <template #label>{{ props.label }}</template>
      <p-input-text v-model="field" v-money="moneyMask" required autofocus />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import moneyMask from "@/plugins/vmoney";
import { removeMoneyMask } from "@/utils/mask";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CInputMoneyModal extends Vue {
  moneyMask = moneyMask;

  @Prop({ required: true }) readonly props!: any;
  field = "";

  getFields() {
    return { field: removeMoneyMask(this.field) };
  }

  reset() {
    this.field = "";
  }

  created() {
    this.field = this.props?.field || "";
  }
}
</script>

<style lang="scss"></style>
