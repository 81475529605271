<template>
  <div id="app" :class="[$store.state.global.whitelabel.joaoazedo ? 'joaoazedo' : '']">
    <router-view />
    <c-env />
    <c-modal />
    <p-toast position="top-right" />
    <c-page-loader />
    <c-print :html="$store.state.global.print.html" v-if="$store.state.global.print.show" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import CEnv from "@/components/env.vue";
import CModal from "@/components/modal/modal.vue";
import CPageLoader from "@/components/pageloader.vue";
import CPrint from "@/components/print.vue";
import { isJoaoAzedo } from "./utils/whitelabel";

@Component({
  components: { CEnv, CModal, CPageLoader, CPrint },
  metaInfo: {
    title: "Gojus",
    meta: [{ vmid: "description", name: "description", content: "Plataforma oficial Gojus" }],
  },
})
export default class App extends Vue {
  @Watch("hasToast")
  onHasErrorUpdated() {
    this.$store.commit("global/showToast", this.$toast);
  }

  get hasToast(): boolean {
    return this.$store.getters["global/hasToast"];
  }

  mounted() {
    setTimeout(() => {
      this.$store.state.global.whitelabel.joaoazedo = isJoaoAzedo();
    }, 500);
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  position: relative;
  font-family: var(--font-family);

  &.joaoazedo {
    @import "./assets/whitelabel/joaoazedo/styles.scss";
  }
}
</style>
