<template>
  <div :class="['c-dashboard-desc-template', { 'is-primary': isPrimary }]">
    <i class="fas fa-quote-left" />
    <p class="content">
      <slot name="default">
        {{ content }}
        <slot v-if="!content" name="empty">Nenhuma informação encontrada.</slot>
      </slot>
    </p>
    <i class="fas fa-quote-right" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CDashboardDescTemplate extends Vue {
  @Prop({ required: false, default: "" }) readonly content!: string;
  @Prop({ required: false, default: false }) readonly isPrimary!: boolean;
}
</script>

<style lang="scss" scoped>
.c-dashboard-desc-template {
  display: flex;
  margin: 12px 0;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--zinc-100);

  .content {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
  }

  .fa-quote-left,
  .fa-quote-right {
    margin-right: 0.4rem;
    color: var(--primary-color);
  }

  .fa-quote-right {
    margin-top: auto;
    margin-left: 0.4rem;
  }

  &.is-primary {
    .content {
      color: var(--primary-color);
      font-weight: 600;
      word-spacing: 0.25px;
      letter-spacing: 0.25px;
    }
  }
}
</style>
