import axios, { AxiosError } from "axios";

import { GojusError, GojusMessageError } from "@/services/gojus/types";
import store from "@/store";
import { isObject } from "@/utils/validators";

export const handleGojusErros: {
  [key: string]: (error: GojusError) => GojusError;
} = {
  code401(gojusError: GojusError) {
    store.commit("user/updateSessionState", { active: false });

    return gojusError;
  },

  code500(gojusError: GojusError) {
    return gojusError;
  },
};

export async function formatGojusError(axiosError: AxiosError) {
  const responseData = axiosError.response?.data as any;
  const gojusErrorMessages: GojusMessageError[] = [];

  const status = axiosError.response?.status || 500;

  if (status < 500) {
    if (isObject(responseData)) {
      Object.keys(responseData).forEach((errorName) => {
        if (Array.isArray(responseData[errorName])) {
          responseData[errorName].forEach((errorMessage: any) => {
            gojusErrorMessages.push({
              summary: errorName,
              detail: String(errorMessage),
              severity: "error",
            });
          });
        } else {
          gojusErrorMessages.push({
            summary: errorName,
            detail: String(responseData[errorName]),
            severity: "error",
          });
        }
      });
    } else {
      gojusErrorMessages.push({
        summary: status.toString(),
        detail: String(responseData),
        severity: "error",
      });
    }
  } else if (status >= 500) {
    gojusErrorMessages.push({
      title: "Erro interno",
      summary: "Por favor, tente novamente mais tarde.",
      severity: "error",
    });
  }

  const gojusError: GojusError = {
    status,
    messages: gojusErrorMessages,
    isGojusError: true,
    isCancel: axios.isCancel(axiosError),
  };

  const handleGojusErro = handleGojusErros[`code${status}`];
  if (handleGojusErro) return handleGojusErro(gojusError);

  return gojusError;
}

export default formatGojusError;
