<template>
  <ul class="c-dashboard-infos-template">
    <li
      v-for="(item, i) in items"
      :key="i"
      :title="item.title || item.text"
      :style="{
        minWidth: `${100 / columns}%`,
      }"
    >
      <i :class="item.icon" />

      <router-link v-if="item.to" :to="item.to" :target="item.to.target || ''">
        {{ item.prefix || "" }}{{ item.text }}{{ item.suffix || "" }}
      </router-link>
      <span v-else> {{ item.prefix || "" }}{{ item.text }}{{ item.suffix || "" }} </span>
    </li>

    <li
      v-if="button"
      :style="{
        minWidth: `${100 / columns}%`,
      }"
    >
      <p-button class="p-button-secondary" :label="button" @click="$emit('click-button')" />
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { InfoItem } from "@/utils/types";

@Component
export default class CDashboardInfosTemplate extends Vue {
  @Prop({ required: false, default: 2 }) readonly columns!: number;
  @Prop({ required: true }) readonly items!: InfoItem[];
  @Prop({ required: false, default: null }) readonly button!: string | null;
}
</script>

<style lang="scss">
.c-dashboard-infos-template {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12px 0 2px;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--zinc-100);

  + .c-dashboard-infos-template {
    margin-top: 2px;
  }

  li {
    margin: 6px 0;

    i {
      margin-right: 12px;
    }
  }
}
</style>
