<template>
  <div class="c-import-pauta">
    <header class="header">
      <div class="header-container">
        <h3 class="title">{{ props.title || "Importar pautas" }}</h3>
        <p-button
          class="close p-button-danger p-button-text"
          icon="fas fa-times-circle"
          title="Fechar"
          @click="handleClose"
        />
      </div>
    </header>

    <main class="content">
      <c-form-field-template width="100%" style="padding: 0.5rem 1rem 1rem">
        <template #label>Cliente</template>
        <p-auto-complete
          v-model="customerQuery"
          field="nm_cliente"
          :suggestions="customers"
          :minLength="3"
          forceSelection
          autofocus
          required
          @complete="searchLogisticsCustomer"
          @item-select="onLogisticsCustomerSelected"
        />
      </c-form-field-template>

      <p-file-upload
        ref="pFileUpload"
        mode="advanced"
        accept=".xlsx"
        chooseLabel="Adicionar"
        uploadLabel="Importar"
        cancelLabel="Cancelar"
        :fileLimit="1"
        :multiple="false"
        :customUpload="true"
        :disabled="props.fileUpload"
        @uploader="onFileUpload"
      >
        <template #empty>
          <p class="p-file-upload-empty">Arraste e solte o arquivo com as pautas aqui para adicionar!</p>
        </template>
      </p-file-upload>
    </main>
  </div>
</template>

<script lang="ts">
import { LogisticsCustomer } from "@/store/logistics/customer/types";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import CFormFieldTemplate from "@/components/form/field.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete.vue";

const PAutoComplete: any = AutoComplete; // remove lint error
@Component({ components: { CFormFieldTemplate, PAutoComplete } })
export default class CImportPauta extends Vue {
  @Prop({ required: true }) readonly props!: any;
  @Ref() readonly pFileUpload!: { progress: number };

  interval: any = null;
  size = 0;
  files: File[] = [];
  customerQuery = "";
  customer: LogisticsCustomer = { id: 0 };

  get fileUpload() {
    return this.$store.state.global.fileUpload;
  }

  @Watch("fileUpload")
  onLoadingUpdate(value: boolean) {
    if (this.pFileUpload && value) {
      this.interval = setInterval(() => {
        this.pFileUpload.progress += 10;
        // if (this.pFileUpload.progress >= 100) this.pFileUpload.progress = 0;
      }, 10);
    } else this.handleClose();
  }

  getFile() {
    return this.files[0];
  }

  getCustomerID() {
    return this.customer.id;
  }

  get customers(): LogisticsCustomer[] {
    return this.$store.state.logistics.customer.all;
  }

  onLogisticsCustomerSelected(event: any) {
    this.customer = event.value;
  }

  async searchLogisticsCustomer(event: any) {
    await this.$store.dispatch("logistics/customer/getAll", {
      filters: { nm_cliente: event.query },
    });
  }

  onFileUpload({ files }: { files: File[] }) {
    if (!this.customer?.id)
      return this.$store.commit("global/updateToast", [
        { detail: 'O campo "Cliente" é obrigatório!', severity: "error" },
      ]);

    this.files = files;
    this.$emit("upload");
  }

  handleClose() {
    if (this.interval) {
      this.pFileUpload.progress = 100;

      clearInterval(this.interval);
      this.interval = null;
    }

    this.$store.commit("global/updateModalStatus", false);
  }
}
</script>

<style lang="scss">
.c-import-pauta {
  width: 50vw;
  max-width: 580px;
  min-width: 460px;

  .header {
    padding: 1rem;
    position: relative;

    .title {
      margin-right: 2rem;
    }
  }

  .p-button {
    &.close {
      display: inline-block;
      position: absolute;
      top: 0.9rem;
      right: 0.5rem;
      padding: 0;
      font-size: 1.4rem;
    }
  }

  .p-fileupload-content {
    padding: 2.5rem 1.125rem;
  }

  .p-file-upload-empty {
    font-size: 1rem;
  }

  .p-fileupload-row {
    overflow-x: hidden;
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 0.95rem;
    }

    .p-fileupload-buttonbar {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding: 0.75rem 0.5rem;
    }

    .p-fileupload-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      > div {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    width: 92vw;
    min-width: unset;
  }
}
</style>
