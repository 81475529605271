import gojus from "@/services/gojus";

import { User, UserData, UserForm, UserLoginParams } from "@/store/user/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_AUTH_URL } = process.env;

export class UserService {
  endpoint = "usuarios/";
  cdEndpoint = (cd: number) => `usuarios/${cd}/`;
  dataEndpoint = "usuarios/userdata/";
  tokenEndpoint = "authentication/token/";
  tokenVerifyEndpoint = "authentication/token/verify/";
  tokenRefreshEndpoint = "authentication/token/refresh/";
  forgotPasswordEndpoint = "usuarios/esqueci-minha-senha/";
  resetPasswordEndpoint = "usuarios/nova-senha/";

  async getToken(username: string, password: string) {
    const { data } = await gojus.post<{ token: string }, UserLoginParams>({
      entity: this.tokenEndpoint,
      data: { username, password },
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async refreshToken(username?: string, password?: string) {
    const token = localStorage.getItem("TOKEN") || "";
    const response = await gojus.post<{ token: string }, Partial<UserLoginParams> & { token: string }>({
      entity: this.tokenRefreshEndpoint,
      data: { username, password, token },
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return response;
  }

  async getUserData() {
    const { data } = await gojus.get<UserData>({
      entity: this.dataEndpoint,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async forgotPassword(ds_email: string) {
    const { data } = await gojus.get<any>({
      entity: this.forgotPasswordEndpoint,
      config: {
        query: { ds_email },
        baseAPI: VUE_APP_API_AUTH_URL,
        useCompany: false,
        useToken: false,
      },
    });

    return { data };
  }

  async resetPassword(password: string, token: string) {
    const { data } = await gojus.post<any>({
      entity: this.resetPasswordEndpoint,
      data: { password, token },
      config: {
        baseAPI: VUE_APP_API_AUTH_URL,
        useCompany: false,
        useToken: false,
      },
    });

    return { data };
  }

  async get(cd: number) {
    const { data } = await gojus.get<User>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async getAll(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<User>>({
      entity: this.endpoint,
      config: {
        query: { ...pagination, ...(filters || {}) },
        baseAPI: VUE_APP_API_AUTH_URL,
      },
    });

    return { data };
  }

  async create(user: UserForm) {
    const { data } = await gojus.post<User, UserForm>({
      entity: this.endpoint,
      data: user,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }

  async edit(cd: number, user: UserForm) {
    const { data } = await gojus.patch<User, UserForm>({
      entity: this.cdEndpoint(cd),
      data: user,
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_AUTH_URL },
    });
    return { data };
  }
}

export default UserService;
