import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import * as vueMeta from "@/plugins/vuemeta";

import userRoutes from "@/router/user";
import dashboardRoutes from "@/router/dashboard";

Vue.use(VueRouter);
vueMeta.config();

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "root",
    redirect: () => ({ name: "dashboard" }),
  },
  userRoutes,
  dashboardRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/layouts/notfound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem("TOKEN");
  const requireAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requireAuth) {
    if (hasToken) next();
    else next({ name: "user-login" });
  } else {
    // user-login, ...
    if (hasToken && to.name?.includes("user-")) next({ name: "dashboard" });
    else next();
  }
});

export default router;
