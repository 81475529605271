<template>
  <div :class="['c-env', env.value]" v-if="env.value !== 'prod'" :title="env.label">
    <div class="env-days-remaining" v-if="company.dt_fim_teste">
      <p class="env-days-remaining-title">Período de teste em andamento</p>
      <p class="env-days-remaining-text">
        Restam <b>{{ daysRemaining }}</b> dias ao escritório
      </p>
    </div>
    <i class="fas fa-info-circle" v-else />
    <div class="env-groups">
      <em v-if="user && user.grupos && user.grupos.length > 1">
        Incluso nos grupos <b>{{ groups }}</b>
      </em>
      <em v-else>
        Grupo <b>{{ groups }}</b>
      </em>
    </div>
    <div class="env-version">
      <em class="env-text">
        Ambiente de
        <b>{{ env.label }}</b>
        Versão
        <b>{{ version }}</b>
      </em>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { getEnv } from "@/utils/acronyms";
import { UserData } from "@/store/user/types";
import { Company } from "@/store/company/types";

@Component
export default class CEnv extends Vue {
  get user(): UserData {
    return this.$store.state.user.current;
  }

  get company(): Company {
    return this.$store.state.company.current;
  }

  get env(): { [key: string]: string } {
    return getEnv();
  }

  get daysRemaining() {
    const currentDate = new Date();
    const testDate = new Date(this.company.dt_fim_teste || "");

    const diffMilliseconds = Math.abs(currentDate.getTime() - testDate.getTime());
    const diffDays = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  get groups() {
    return this.user.grupos?.reduce(
      (result, group, index, groups) =>
        `${result}${group.nm_grupo}${index + 1 === groups.length ? "" : index + 2 === groups.length ? " e " : ", "}`,
      ""
    );
  }
}
</script>

<style lang="scss">
.c-env {
  position: fixed;
  bottom: 2px;
  left: 2px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-shadow: var(--box-shadow-50);
  border-radius: 50%;
  overflow: visible;
  background-color: transparent;

  &.development {
    --env-color: var(--danger-color);
  }

  &.staging {
    --env-color: var(--warning-color);
  }

  &.prod {
    --env-color: var(--info-color);
  }

  i {
    overflow: hidden;
    color: var(--env-color);
    font-size: 1rem;
  }

  .env-days-remaining {
    max-width: max-content;
    padding: 6px 16px 6px 8px;
    border-radius: 0 999px 999px 0;
    background-color: var(--env-color);
    color: #fff;

    .env-days-remaining-title {
      font-size: 15px;
      font-weight: 600;
    }

    .env-days-remaining-text {
      margin-top: 1px;
      font-size: 14px;
      font-weight: 500;

      b {
        font-size: 15px;
      }
    }
  }

  .env-version,
  .env-groups {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .env-version .env-text,
  .env-groups {
    color: #fff;
    font-size: 0.96rem;
    font-weight: 600;
    text-shadow: 1px 1px 1px #0002;
  }

  // &:hover {
  //   gap: 0.25rem;
  //   width: 100%;
  //   margin: 0;
  //   border-radius: 0;
  //   padding: 4px;
  //   text-align: center;
  //   line-height: unset;
  //   background-color: var(--env-color);
  //   box-shadow: none;

  //   i,
  //   .env-days-remaining {
  //     display: none;
  //   }

  //   .env-version,
  //   .env-groups {
  //     width: 100%;
  //     height: min-content;
  //     opacity: 1;
  //     transition: all 0.5s ease;
  //   }
  // }
}
</style>
