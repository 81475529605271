import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/integracao",
    name: "integration",
    redirect: { name: "integration-movements", query: { tab: "integration-movements" } },
    component: () => import("@/views/integration/overview.vue"),
    children: [
      {
        path: "/dashboard/integracao/andamentos",
        name: "integration-movements",
        component: () => import("@/views/integration/movement/list.vue"),
      },
      {
        path: "/dashboard/integracao/publicacoes",
        name: "publication",
        component: () => import("@/views/integration/publication/list.vue"),
      },
      {
        path: "/dashboard/integracao/expedientes",
        name: "expedients",
        component: () => import("@/views/integration/expedient/list.vue"),
      },
      {
        path: "/dashboard/integracao/peticionamentos",
        name: "petitions",
        component: () => import("@/views/integration/petition/list.vue"),
      },
      {
        path: "/dashboard/integracao/processos-novos",
        name: "integration-found",
        component: () => import("@/views/integration/found/list.vue"),
      },
      {
        path: "/dashboard/integracao/migracao",
        name: "migrations",
        component: () => import("@/views/integration/migration/list.vue"),
      },
    ],
  },
  {
    path: "/dashboard/integracao/migracao/adicionar",
    name: "migrations-add",
    component: () => import("@/views/integration/migration/form.vue"),
  },
  {
    path: "/dashboard/integracao/migracao/:attorneyId/:accountId/editar",
    name: "migrations-edit",
    component: () => import("@/views/integration/migration/form.vue"),
  },
];

export default routes;
