<template>
  <div :class="['c-form-field-template', attrFor]" :style="formFieldTemplateStyle">
    <slot name="label-container" v-if="useLabel">
      <label :for="attrFor">
        <slot name="label"></slot>
      </label>
    </slot>

    <slot name="default"></slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CFormFieldTemplate extends Vue {
  @Prop({ required: false, default: true }) readonly useLabel!: boolean;
  @Prop({ required: false, default: "" }) readonly attrFor!: string;
  @Prop({ required: false, default: "" }) readonly width!: string;
  @Prop({ required: false, default: "" }) readonly minWidth!: string;
  @Prop({ required: false, default: true }) readonly maxWidth!: boolean;

  get formFieldTemplateStyle() {
    const style: any = {};

    if (this.width) {
      style.width = this.width;
      style.maxWidth = "unset";
    }

    if (this.minWidth) {
      style.minWidth = this.minWidth;
      if (!this.width) style.width = this.minWidth;
    }

    if (!this.maxWidth) {
      style.maxWidth = "unset";
      style.width = "auto";
    }

    return style;
  }
}
</script>

<style lang="scss">
.c-form-field-template {
  width: 25%;
  padding: 4px;
  min-width: 100px;

  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: unset;
  }

  .error {
    border-color: var(--danger-color);

    &:enabled:hover,
    &:enabled:focus {
      box-shadow: 0 0 0 0.2rem #f1466888;
      border-color: var(--danger-color);
    }
  }

  label,
  input,
  .p-dropdown,
  .p-multiselect,
  .p-inputnumber,
  .p-inputtextarea,
  .p-calendar,
  .p-password,
  .p-autocomplete-multiple-container {
    width: 100%;
  }

  .p-inputtext,
  .p-inputmask,
  .p-autocomplete-input {
    // keep input-text and drop-down the same size
    padding: 0.6rem 0.7rem;
  }

  .p-dropdown-label {
    padding: 0.6rem 0.5rem;
  }

  .p-multiselect .p-multiselect-label,
  .p-dropdown .p-dropdown-label {
    // keep input-text and drop-down the same size
    padding: 0.65rem 0.7rem !important;

    &.p-placeholder {
      padding: 0.72rem 0.7rem !important;
    }
  }

  label {
    display: inline-block;
    margin: 6px 0;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .p-inputgroup {
    &:has(.p-button-icon-only) {
      > *:first-child {
        width: calc(100% - 2.5rem);
      }

      .p-button-icon-only {
        flex: none;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    .p-dropdown-filter-container {
      .p-inputtext {
        width: 100%;
      }
    }
  }
}
</style>
