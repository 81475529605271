<template>
  <div class="c-form-header-template">
    <slot name="image" v-if="$slots['image']"></slot>
    <h3 class="title">
      <slot name="title"></slot>
    </h3>
    <h4 class="description">
      <slot name="description"></slot>
    </h4>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CFormHeaderTemplate extends Vue {}
</script>

<style lang="scss" scoped>
.c-form-header-template {
  .title {
    font-size: 1.7rem;
    font-weight: 500;
  }

  .description {
    margin-top: 0.4rem;

    font-size: 0.9rem;
    font-weight: 200;
  }
}
</style>
