<template>
  <div class="c-team-form-modal form">
    <c-form-field-template>
      <template #label>Nome da Equipe</template>
      <p-input-text v-model="nm_equipe" autofocus required />
    </c-form-field-template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate } })
export default class CTeamFormModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  nm_equipe = "";

  getFields() {
    return { nm_equipe: this.nm_equipe };
  }

  reset() {
    this.nm_equipe = "";
  }

  mounted() {
    this.nm_equipe = this.props.nm_equipe ?? "";
  }
}
</script>

<style lang="scss" scoped>
.c-team-form-modal {
  .c-form-field-template {
    width: 100%;
    max-width: 280px;
  }
}
</style>
