<template>
  <div class="c-modal-debtor-excel">
    <header class="header">
      <h5>Exportar devedores</h5>
      <h6>
        Foram encontradas <b>{{ totalItems }}</b> devedores no total.
      </h6>
    </header>

    <main class="content">
      <p-listbox
        :options="headerOptions.filter((header) => header.show)"
        optionLabel="label"
        dataKey="label"
        @change="$event.value.selected = !$event.value.selected"
      >
        <template #option="{ option }">
          <p-checkbox v-model="option.selected" :style="{ pointerEvents: 'none' }" :binary="true" />
          <label :style="{ marginLeft: '12px', cursor: 'pointer' }" v-text="option.label"></label>
        </template>
      </p-listbox>
    </main>

    <footer class="footer">
      <p class="export-alert">
        <i class="fas fa-triangle-exclamation"></i> Tempo estimado: {{ minutes }} minuto{{ minutes > 1 ? "s" : "" }}.
      </p>

      <div class="options">
        <p-button
          class="p-button-danger option"
          icon="fas fa-times-circle"
          label="Cancelar"
          @click="onAskingToCancel"
        />

        <p-button v-if="loading.items" class="p-button-success option" disabled>
          <p-progress-spinner strokeWidth="8" animationDuration="0.8s" />
          <span>Aguarde</span>
        </p-button>
        <p-button
          v-else
          class="p-button-success option"
          icon="fas fa-download"
          label="Baixar"
          @click="onAskingToDownload"
        />
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import PButton from "primevue/button/Button.vue";
import Listbox from "primevue/listbox";
import Checkbox from "primevue/checkbox";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import CFormFieldTemplate from "@/components/form/field.vue";

import { Debtor } from "@/store/collection/debtor/types";
import { formatDate, formatMovePreview, sortMovementByDate } from "@/utils/formatters";

import { handleExportXLSX } from "@/plugins/excellentexport";
import { getDebtorStatus, getTypeOfAgreement, getTypeOfLawyer } from "@/utils/acronyms";
import { Pagination } from "@/store/types";

const PListbox: any = Listbox;
const PCheckbox: any = Checkbox;

@Component({ components: { CFormFieldTemplate, PButton, PListbox, PCheckbox, PProgressSpinner } })
export default class CModalExcel extends Vue {
  @Prop({ required: true }) readonly props!: any;
  loading = { items: false };

  headers: string[] = [];
  content: string[][] = [];

  items: Debtor[] = [];
  totalItems = 0;
  pages = 0;
  minutes = 0;

  handleExportXLSX = handleExportXLSX;
  formatDate = formatDate;

  headerOptions = [
    {
      label: "ID",
      handle: (item: Debtor) => item.id,
      selected: true,
      show: true,
    },
    {
      label: "Devedor",
      handle: (item: Debtor) => item.nm_devedor || "",
      selected: true,
      show: true,
    },
    {
      label: "Processo",
      handle: (item: Debtor) => item.nr_processo || "",
      selected: true,
      show: true,
    },
    {
      label: "Workflow",
      handle: (item: Debtor) => item.nr_workflow || "",
      selected: true,
      show: true,
    },
    {
      label: "CPF",
      handle: (item: Debtor) => item.nr_cpf || "",
      selected: true,
      show: true,
    },
    {
      label: "CNPJ",
      handle: (item: Debtor) => item.nr_cnpj || "",
      selected: true,
      show: true,
    },
    {
      label: "Tipo de Devedor",
      handle: (item: Debtor) => getTypeOfAgreement(item.ie_tipo_devedor).label || "",
      selected: true,
      show: true,
    },
    {
      label: "Cliente",
      handle: (item: Debtor) => item.cd_cliente?.nm_cliente || "",
      selected: true,
      show: true,
    },
    {
      label: "Comarca",
      handle: (item: Debtor) => item.ob_cidade?.nm_cidade || item.endereco_set?.[0]?.nm_cidade || "",
      selected: true,
      show: true,
    },
    {
      label: "Estado",
      handle: (item: Debtor) => item.ob_cidade?.cd_estado?.nm_estado || item.endereco_set?.[0]?.nm_estado || "",
      selected: true,
      show: true,
    },
    {
      label: "Telefone",
      handle: (item: Debtor) =>
        item.nr_telefone || item.nr_telefone_dois || item.nr_telefone_tres || item.nr_telefone_quatro || "",
      selected: true,
      show: true,
    },
    {
      label: "E-mail",
      handle: (item: Debtor) => item.ds_email || item.ds_email_dois || item.ds_email_tres || item.ds_email_quatro || "",
      selected: true,
      show: true,
    },
    {
      label: "Advogado da Parte",
      handle: (item: Debtor) => item.nm_advogado_parte || "",
      selected: true,
      show: true,
    },
    {
      label: "OAB do Advogado da Parte",
      handle: (item: Debtor) => item.nr_oab_advogado_parte || "",
      selected: true,
      show: true,
    },
    {
      label: "Tipo de Advogado",
      handle: (item: Debtor) => getTypeOfLawyer(item.ie_tipo_advogado).label || "",
      selected: true,
      show: true,
    },
    {
      label: "Data Débito",
      handle: (item: Debtor) => formatDate(item.dt_debito) || "",
      selected: true,
      show: true,
    },
    {
      label: "Inicio do Débito",
      handle: (item: Debtor) => formatDate(item.dt_inicio_debito) || "",
      selected: true,
      show: true,
    },
    {
      label: "Recebimento (Escritório)",
      handle: (item: Debtor) => formatDate(item.dt_recebimento_escritorio) || "",
      selected: true,
      show: true,
    },
    {
      label: "Vencimento do Acordo",
      handle: (item: Debtor) => formatDate(item.dt_vencimento_acordo) || "",
      selected: true,
      show: true,
    },
    {
      label: "Débito (R$)",
      handle: (item: Debtor) => item.nr_valor_debito?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Débito Atualizado (R$)",
      handle: (item: Debtor) => item.nr_valor_debito_atualizado?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Classificação",
      handle: (item: Debtor) => item.ob_classificacao?.nm_classificacao || "",
      selected: true,
      show: true,
    },
    {
      label: "Status",
      handle: (item: Debtor) => getDebtorStatus(item.ie_status_devedor)?.label || "",
      selected: true,
      show: true,
    },
    {
      label: "Observações",
      handle: (item: Debtor) => item.ds_observacao || "",
      selected: true,
      show: true,
    },
    {
      label: "Movimentação",
      handle: (item: Debtor) => formatMovePreview(item.movimentacao?.ds_movimentacao || "") || "",
      selected: true,
      show: true,
    },
    {
      label: "Data da Movimentação",
      handle: (item: Debtor) => formatDate(item.movimentacao?.dt_movimentacao) || "",
      selected: true,
      show: true,
    },
    {
      label: "Hora da Movimentação",
      handle: (item: Debtor) => item.movimentacao?.hr_movimentacao || "",
      selected: true,
      show: true,
    },
    {
      label: "Origem da Movimentação",
      handle: (item: Debtor) => (item.movimentacao?.service === "juridical" ? "Processo" : "Cobrança"),
      selected: true,
      show: true,
    },
  ];

  get filters(): any {
    const debtorFilters = this.$store.state.debtor.filters;
    const subtitleFilters = this.$store.state.debtor.subtitleFilter;

    return { ...debtorFilters, ...subtitleFilters, resumo: subtitleFilters.resumo || debtorFilters.resumo };
  }

  get tabView(): string {
    return this.$store.state.global.currentTabView;
  }

  get pagination(): Pagination {
    return this.$store.state.debtor.pagination;
  }

  async getDebtors(page: number, filters: any) {
    try {
      const response = await this.$store.state.debtor.service.getAll(
        { page, nr_por_pagina: 50, paginacao: true },
        filters
      );
      const debtors: Debtor[] = response?.data?.results || [];

      const cityList: { [key: number]: any } = {};
      const moveList: { [key: number]: any } = {};
      const classificationList: { [key: number]: any } = {};

      for (const debtor of debtors) {
        const cityId = Number(debtor.cd_cidade);
        const moveId = Number(debtor.id);
        const classificationId = Number(debtor.cd_classificacao);

        if (cityId) cityList[cityId] = null;
        if (moveId) moveList[moveId] = null;
        if (classificationId) classificationList[classificationId] = null;
      }

      const cityPromises = Object.keys(cityList).map((_id) => {
        return this.$store.dispatch("location/getCity", { cd_cidade: _id });
      });
      const movePromises = Object.keys(moveList).map(async (_id) => {
        const debtorMoves = await this.$store.dispatch("movement/getAll", {
          cd: _id,
          service: "collection",
          disableFiles: true,
        });
        const debtor = debtors.find((item) => item.id === debtorMoves?.[0]?.cd_devedor);

        if (debtor?.nr_processo) {
          const lawsuit = (await this.$store.state.lawsuit.service.getWithNumber(debtor.nr_processo))?.data?.[0];

          if (lawsuit?.id) {
            const lawsuitMoves = await this.$store.dispatch("movement/getAll", {
              cd: lawsuit.id,
              service: "juridical",
              disableFiles: true,
            });

            lawsuitMoves.forEach((item: any) => {
              item.cd_devedor = debtor.id;
            });

            debtorMoves.push(...lawsuitMoves);
          }
        }

        return sortMovementByDate(debtorMoves);
      });
      const classificationPromises = Object.keys(classificationList).map((_id) => {
        return this.$store.state.debtor.service.getRating(_id);
      });

      const [cityResults, moveResults, classificationResults] = await Promise.allSettled([
        Promise.allSettled(cityPromises),
        Promise.allSettled(movePromises),
        Promise.allSettled(classificationPromises),
      ]);

      const cityResultItems = (
        cityResults.status === "fulfilled"
          ? cityResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);
      const moveResultItems = (
        moveResults.status === "fulfilled"
          ? moveResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
          : []
      ).filter((item) => item);
      const classificationResultItems = (
        classificationResults.status === "fulfilled"
          ? classificationResults.value.map((item) => (item.status === "fulfilled" ? item.value?.data || null : null))
          : []
      ).filter((item) => item);

      for (const cityItem of cityResultItems) {
        cityList[cityItem.id] = cityItem;
      }
      for (const moveItem of moveResultItems) {
        moveList[moveItem?.[0]?.cd_devedor] = moveItem;
      }
      for (const classificationItem of classificationResultItems) {
        classificationList[classificationItem.id] = classificationItem;
      }

      for (const debtor of debtors) {
        const cityId = Number(debtor.cd_cidade);
        const moveId = Number(debtor.id);
        const classificationId = Number(debtor.cd_classificacao);

        debtor.ob_cidade = cityList[cityId] || null;
        debtor.ob_classificacao = classificationList[classificationId] || null;
        debtor.movimentacoes = moveList[moveId] || [];
        debtor.movimentacao = debtor.movimentacoes?.slice(-1)?.[0];
      }

      return debtors;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  async onAskingToDownload() {
    this.loading.items = true;

    const selected = this.headerOptions.filter((item) => item.show && item.selected);
    const filters = { ...this.filters };

    try {
      const allDebtors: Debtor[] = [];

      for (let page = 1; page <= this.pages; page++) {
        const debtors = await this.getDebtors(page, filters);
        debtors?.forEach((debtor) => allDebtors.push(debtor));
      }

      this.items = allDebtors;

      this.headers = selected.map((header) => header.label);
      this.content = this.items.map((item) => selected.map((header) => header.handle(item)?.toString() || ""));

      handleExportXLSX(this.headers, this.content);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    } finally {
      this.loading.items = false;
    }
  }

  onAskingToCancel() {
    this.$store.commit("global/updateModalStatus", false);
  }

  created() {
    this.items = this.$store.state.debtor.all;
    this.totalItems = this.$store.state.debtor.pagination.total;
    this.pages = Math.ceil(this.totalItems / 50);
    this.minutes = Math.ceil(this.totalItems / 4 / 60);
  }
}
</script>

<style lang="scss">
.c-modal-debtor-excel {
  overflow-y: auto;
  max-height: 90vh;
  width: 100%;
  max-width: 520px;
  padding: 4px;

  .header {
    padding: 6px 12px 8px;

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    h6 {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;

      b {
        font-weight: 900;
      }
    }
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--info-color);
  }

  .p-listbox {
    border: none;
  }

  .p-listbox-list {
    display: flex;
    flex-wrap: wrap;

    .p-listbox-item {
      width: 50%;
      padding: 6px 12px;
      border-radius: var(--border-radius-50);
    }
  }

  .footer {
    padding: 12px 12px 6px;

    .export-alert {
      margin-bottom: 24px;
      font-size: 13px;
      font-weight: 600;

      i {
        color: var(--warning-color);
        font-size: 14px;
        margin-right: 4px;
      }
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .p-button {
        flex: none;
        width: 110px;
        font-weight: 600;
        font-size: 14px;
        height: 34px;
        border-radius: var(--border-radius-50);
      }
    }
  }
}
</style>
