import { InfoItem } from "@/utils/types";
import { HistoryTask, Task, TaskForm } from "@/store/task/types";
import { formatDate, formatDateTime, formatProcessNumberInCnjPattern } from "@/utils/formatters";
import { getTaskStatus } from "@/utils/acronyms";

export function handleGeneralInfoItems(task: Task, permissions?: any) {
  const items: InfoItem[] = [];

  if (task.id)
    items.push({
      prefix: "#",
      text: task.id,
      title: "ID",
      icon: "fas fa-fingerprint",
    });

  if (task.nr_processo) {
    items.push({
      text: formatProcessNumberInCnjPattern(task.nr_processo),
      prefix: "Processo: ",
      icon: "fas fa-gavel",
      title: task.lawsuit ? "Ir para detalhes do Processo" : "",
      to:
        task.lawsuit && permissions?.lawsuit
          ? { name: "process-number", params: { nr_processo: task.nr_processo } }
          : undefined,
    });
  }

  if (task.cd_liminar)
    items.push({
      text: task.cd_liminar,
      prefix: "Liminar: ",
      icon: "fas fa-info-circle",
    });

  if (task.cd_devedor && task.ob_devedor)
    items.push({
      prefix: "Devedor: ",
      text: task.ob_devedor.nm_devedor || "",
      icon: "fas fa-book-dead",
      to: { name: "debtor-details", params: { cd: String(task.cd_devedor) } },
    });

  if (task.cd_cliente && task.ob_cliente?.nm_cliente) {
    const item: any = {
      prefix: "Cliente: ",
      text: task.ob_cliente.nm_cliente,
      title: "Ir para detalhes do Cliente",
      icon: "fas fa-user",
    };

    if (permissions?.lawsuit && task.nr_processo) {
      item.to = {
        name: "lawsuit-customer-details",
        params: { cd: task.cd_cliente },
      };
    } else if (permissions?.debtor) {
      item.to = {
        name: "collection-customer-details",
        params: { cd: task.cd_cliente },
      };
    }
    items.push(item);
  }

  if (task.nm_parte_contraria)
    items.push({
      prefix: "Parte Contraria: ",
      text: task.nm_parte_contraria,
      icon: "fas fa-user",
    });

  if (task.cd_tipo_tarefa && task.ob_tipo_tarefa?.nm_tipo_tarefa)
    items.push({
      text: task.ob_tipo_tarefa.nm_tipo_tarefa,
      title: "Tipo de Tarefa",
      icon: "fas fa-thumbtack",
    });

  if (task.nm_cidade || task.nm_estado)
    items.push({
      text: `${task.nm_cidade} / ${task.nm_estado}`,
      title: "Comarca",
      icon: "fas fa-map-marker-alt",
    });

  if (task.ie_status)
    items.push({
      text: getTaskStatus(task.ie_status).label,
      title: "Status da tarefa",
      icon: "fas fa-info-circle",
    });

  if (task.cd_responsaveis && task.ob_responsavel?.nm_funcionario)
    items.push({
      prefix: "Responsável: ",
      text: task.ob_responsavel.nm_funcionario,
      title: "Responsavel pela tarefa",
      icon: "fas fa-user-tie",
    });

  return items;
}

export function handleDateInfoItems(task: Task, hasPermissionCoordinator: boolean) {
  const items: InfoItem[] = [];

  if (task.dt_criado) {
    items.push({
      prefix: "Criada em: ",
      text: formatDateTime(task.dt_criado),
      icon: "fas fa-calendar-alt",
      title: "Data de criação da tarefa",
    });
  }

  if (task.nm_usuario_cri) {
    items.push({
      prefix: "Criada por: ",
      text: task.ob_usuario_cri?.nm_funcionario || task.nm_usuario_cri,
      icon: "fas fa-user-pen",
      title: "Data de criação da tarefa",
    });
  }

  if (task.dt_vencimento) {
    items.push({
      prefix: "Vencimento: ",
      text: formatDateTime(task.dt_vencimento, task.hr_vencimento),
      icon: "fas fa-calendar-alt",
      title: "Data e hora de vencimento da tarefa",
    });
  }

  if (task.dt_fatal && hasPermissionCoordinator) {
    items.push({
      prefix: "Fatal: ",
      text: formatDate(task.dt_fatal),
      icon: "fas fa-calendar-alt",
      title: "Data fatal da tarefa",
    });
  }

  if (task.dt_conclusao) {
    items.push({
      prefix: "Conclusão: ",
      text: formatDate(task.dt_conclusao),
      icon: "fas fa-calendar-alt",
      title: "Data de conclusão da tarefa",
    });
  }

  if (task.dt_atualizado) {
    items.push({
      prefix: "Atualização: ",
      text: formatDateTime(task.dt_atualizado),
      icon: "fas fa-calendar-alt",
      title: "Data de atualização da tarefa",
    });
  }

  if (task.dt_reagendamento) {
    items.push({
      prefix: "Reagendamento: ",
      text: formatDateTime(task.dt_reagendamento),
      icon: "fas fa-calendar-alt",
      title: "Data do reagendamento da tarefa",
    });
  }

  return items;
}

export function handleAuditInfoItems(task: HistoryTask, hasPermissionCoordinator: boolean) {
  const auditItems = [];
  const generalItems = handleGeneralInfoItems(task as any);
  const dateItems = handleDateInfoItems(task as any, hasPermissionCoordinator);

  if (task.cd_tipo_tarefa)
    auditItems.push({
      text: task.cd_tipo_tarefa,
      title: "Tipo de Tarefa",
      icon: "fas fa-thumbtack",
    });

  if (task.nm_titulo)
    auditItems.push({
      prefix: "Titulo: ",
      text: task.nm_titulo,
      title: "Titulo da Tarefa",
      icon: "fas fa-tasks",
    });

  return [...generalItems, ...dateItems, ...auditItems];
}

export function validate(task: TaskForm) {
  if (task.dt_fatal && task.dt_fatal.length < 10)
    return {
      field: "dt_fatal",
      error: 'O campo "Prazo Fatal" é invalido!',
    };

  if (task.dt_vencimento?.length < 10)
    return {
      field: "dt_vencimento",
      error: 'O campo "Data do Vencimento" é invalido!',
    };

  if (!task?.cd_equipe)
    return {
      field: "cd_equipe",
      error: 'O campo "Equipes" é obrigatório!',
    };

  if (!task?.cd_tipo_tarefa)
    return {
      field: "taskType",
      error: 'O campo "Tipo de Tarefa" é obrigatório!',
    };

  if (task?.cd_cidade === 0)
    return {
      field: "city",
      error: 'O campo "Cidade" é obrigatório!',
    };

  return { field: null, error: null };
}

export const taskRecurrenceTypeOptions = [
  { value: 1, label: "dia", pluralLabel: "dias" },
  { value: 2, label: "semana", pluralLabel: "semanas" },
  { value: 3, label: "mês", pluralLabel: "meses" },
];

export const taskRecurrenceDayOptions = [
  { value: 6, label: "D" },
  { value: 0, label: "S" },
  { value: 1, label: "T" },
  { value: 2, label: "Q" },
  { value: 3, label: "Q" },
  { value: 4, label: "S" },
  { value: 5, label: "S" },
];
