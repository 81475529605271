import gojus from "@/services/gojus";

import { removeMoneyMask } from "@/utils/mask";
import { formatDateEn } from "@/utils/formatters";

import { Hearing, HearingActType, HearingFilters, HearingSummary } from "@/store/logistics/hearing/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

import { LogisticsCustomer } from "../customer/types";
import { State } from "@/store/location/types";

const { VUE_APP_API_LOGISTICS_URL, VUE_APP_API_ANALYTICS_URL } = process.env;

export class HearingService {
  endpoint = "audiencias/";
  cdEndpoint = (cd: number) => `audiencias/${cd}/`;
  solicitationEndpoint = "audiencias/solicitacoes/";
  endpointPauta = "audiencias/pauta/";
  postFileEndPoint = "audiencias/arquivos";
  getFileEndPoint = (cd: number) => `audiencias/arquivos/${cd}`;
  deleteFileEndPoint = (cd_arquivo: number, cd_audiencia: number) =>
    `audiencias/arquivos/${cd_arquivo}/${cd_audiencia}`;
  counterEndPoint = (cd_arquivo: number, cd_audiencia: number) =>
    `audiencias/arquivos/contador/${cd_arquivo}/${cd_audiencia}`;
  customerEndpoint = "clientes/";
  customerCDEndpoint = (cd: number) => `clientes/${cd}/`;
  solicitarCDEndpoint = (cd: number) => `audiencias/solicitar/${cd}/`;
  removeSolicitationEndpoint = (cd: number) => `audiencias/remover-solicitacao/${cd}/`;
  acttypeEndpoint = "tipos/?ie_tipo_ato=AU";
  summaryEndpoint = "audiencias/resumo/";
  reopenEndpoint = (cd: number) => `audiencias/reabrir/${cd}/`;
  cancelEndpoint = (cd: number) => `audiencias/cancelar/${cd}/`;
  finishEndpoint = (cd: number) => `audiencias/finalizar/${cd}/`;
  acceptEndpoint = (cd: number) => `audiencias/aceitar/${cd}/`;
  requestConferenceEndpoint = (cd: number) => `audiencias/solicitar-conferencia/${cd}/`;
  importPautaEndpoint = "audiencias/pauta/";
  officesEndPoint = "audiencias/escritorios-solicitacao/";
  stateRequestEndPoint = "audiencias/estados-solicitacao/";

  cancelToken: { [key: string]: any } = { get: null, getAll: null };

  async get(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<Hearing>({
      entity: this.cdEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        query,
      },
    });

    return { data };
  }

  async getAll(pagination: PaginationParams, filters: HearingFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Hearing>>({
      entity: this.endpoint,
      config: {
        query: { ...pagination, ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getAllSolicitation(pagination: PaginationParams, filters: HearingFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Hearing>>({
      entity: this.solicitationEndpoint,
      config: {
        query: { ...pagination, ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getSolicitation(filters: HearingFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Hearing>>({
      entity: this.solicitationEndpoint,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getAllFiles(cd_audiencia: number, cd_empresa?: number) {
    const response = await gojus.get<any[]>({
      entity: this.getFileEndPoint(cd_audiencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return response.data;
  }

  async getAllOffices() {
    const { data } = await gojus.get<any>({
      entity: this.officesEndPoint,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return { data }.data;
  }

  async getStatesRequest() {
    const { data } = await gojus.get<State[]>({
      entity: this.stateRequestEndPoint,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });
    return { data };
  }

  async create(hearing: Hearing) {
    const { data } = await gojus.post<Hearing>({
      entity: this.endpoint,
      data: this.handle(hearing),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async createFile(cd: number, file: File, cd_empresa?: number) {
    const response = await gojus.post<any>({
      entity: this.postFileEndPoint,
      data: this.handleFile(cd, file),
      config: { query: { cd_empresa }, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return response;
  }

  async importPauta(cd_cliente: number, file: File) {
    const form = new FormData();
    form.append("aq_pauta", file, file.name);
    form.append("cd_cliente", cd_cliente.toString());

    const response = await gojus.post<any>({
      entity: this.importPautaEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL, useErrorFormatter: false },
    });

    return response;
  }

  async edit(cd: number, hearing: Hearing) {
    const { data } = await gojus.patch<Hearing>({
      entity: this.cdEndpoint(cd),
      data: this.handle(hearing),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async getLogisticsCustomer(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<LogisticsCustomer>({
      entity: this.customerCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL, query },
    });

    return data;
  }

  async getLogisticsCustomers(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<LogisticsCustomer>>({
      entity: this.customerEndpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return { data };
  }

  async addCorrespondent(cd: number, form: any) {
    form.nr_valor_correspondente = removeMoneyMask(form.nr_valor_correspondente);
    form.nr_valor_preposto = 0;
    form.cd_preposto = null;

    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.patch<any>({
      entity: this.solicitarCDEndpoint(cd),
      data: form,
      config: {
        query,
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async removeCorrespondent(cd: number) {
    const response = await gojus.delete({
      entity: this.removeSolicitationEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return response;
  }

  async addPreposto(cd: number, form: any) {
    form.nr_valor_preposto = removeMoneyMask(form.nr_valor_preposto);
    form.nr_valor_correspondente = 0;
    form.cd_correspondente = null;

    const { data } = await gojus.patch<any>({
      entity: this.solicitarCDEndpoint(cd),
      data: form,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async removePreposto(cd: number) {
    const response = await gojus.delete({
      entity: this.removeSolicitationEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return response;
  }

  async removeFile(cd_audiencia: number, cd_arquivo: number, cd_empresa?: number) {
    const response = await gojus.delete({
      entity: this.deleteFileEndPoint(cd_arquivo, cd_audiencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return response;
  }

  async getCounterFile(cd_arquivo: number, cd_audiencia: number, cd_empresa?: number) {
    const response = await gojus.get({
      entity: this.counterEndPoint(cd_arquivo, cd_audiencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return response;
  }

  async reopen(cd: number) {
    const { data } = await gojus.patch({
      entity: this.reopenEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });
    return { data };
  }

  async cancel(cd: number, ds_motivo_cancelamento: string) {
    const { data } = await gojus.patch({
      entity: this.cancelEndpoint(cd),
      data: { ds_motivo_cancelamento },
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async finish(cd: number) {
    const { data } = await gojus.patch({
      entity: this.finishEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async accept(cd: number, cd_correspondente: number) {
    const { data } = await gojus.patch({
      entity: this.acceptEndpoint(cd),
      data: { cd_correspondente },
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async requestConference(cd: number, form: any) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.patch({
      entity: this.requestConferenceEndpoint(cd),
      data: form,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        query,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async getTypes() {
    const { data } = await gojus.get<HearingActType[]>({
      entity: this.acttypeEndpoint,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async getSummary(ie_solicitacao: any) {
    const { data } = await gojus.get<HearingSummary>({
      entity: this.summaryEndpoint,
      config: {
        query: { ie_solicitacao },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async getPauta(filters: any) {
    const { data } = await gojus.get<any>({
      entity: this.endpointPauta,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return { data };
  }

  async getExportCSV(filters: any) {
    const { data } = await gojus.get<Blob>({
      entity: this.endpoint,
      config: {
        query: { ...filters, exportar: "csv" },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
        responseType: "blob",
      },
    });

    return { data };
  }

  handle(hearing: Hearing) {
    if (hearing.dt_audiencia) {
      hearing.dt_audiencia = formatDateEn(hearing.dt_audiencia);
    } else delete hearing.dt_audiencia;

    if (hearing.nr_valor_correspondente)
      hearing.nr_valor_correspondente = removeMoneyMask(hearing.nr_valor_correspondente);
    if (hearing.nr_valor_preposto) hearing.nr_valor_preposto = removeMoneyMask(hearing.nr_valor_preposto);

    if (!hearing.cd_preposto && !hearing.cd_correspondente) {
      delete hearing.cd_preposto;
      delete hearing.cd_correspondente;
    } else if (hearing.ie_responsavel === "AD") {
      delete hearing.cd_preposto;
    } else if (hearing.ie_responsavel === "PR") {
      delete hearing.cd_correspondente;
    }

    hearing.nr_valor_recebido = removeMoneyMask(hearing.nr_valor_recebido);

    return hearing;
  }

  handleFile(cd: number, file: File) {
    const data = new FormData();

    data.append("cd_audiencia", cd.toString());
    data.append("aq_arquivo", file, file.name);

    return data;
  }
}

export default HearingService;
