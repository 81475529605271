import gojus from "@/services/gojus";

import { formatDateEn } from "@/utils/formatters";
import { removeMoneyMask } from "@/utils/mask";

import { Diligence, DiligenceFilters, DiligenceSummary, DiligenceActType } from "@/store/logistics/diligence/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

import { LogisticsCustomer } from "../customer/types";

const { VUE_APP_API_LOGISTICS_URL } = process.env;

export class DiligenceService {
  endpoint = "diligencias/";
  cdEndpoint = (cd: number) => `diligencias/${cd}/`;
  solicitationsEndpoint = "diligencias/solicitacoes/";
  postFileEndPoint = "diligencias/arquivos";
  getFileEndPoint = (cd: number) => `diligencias/arquivos/${cd}`;
  deleteFileEndPoint = (cd_arquivo: number, cd_diligencia: number) =>
    `diligencias/arquivos/${cd_arquivo}/${cd_diligencia}`;
  counterEndPoint = (cd_arquivo: number, cd_diligencia: number) =>
    `diligencias/arquivos/contador/${cd_arquivo}/${cd_diligencia}`;
  summaryEndpoint = "diligencias/resumo/";
  reopenEndpoint = (cd: number) => `diligencias/reabrir/${cd}/`;
  cancelEndpoint = (cd: number) => `diligencias/cancelar/${cd}/`;
  finishEndpoint = (cd: number) => `diligencias/finalizar/${cd}/`;
  solicitationEndpoint = (cd: number) => `diligencias/solicitar/${cd}/`;
  removeSolicitationEndpoint = (cd: number) => `diligencias/remover-solicitacao/${cd}/`;
  acceptEndpoint = (cd: number) => `diligencias/aceitar/${cd}/`;
  customerEndpoint = "clientes/";
  customerCDEndpoint = (cd: number) => `clientes/${cd}/`;
  acttypeEndpoint = "tipos/?ie_tipo_ato=DI";
  requestConferenceEndpoint = (cd: number) => `diligencias/solicitar-conferencia/${cd}/`;
  filterOverdueEndPoint = "diligencias/filtros-em-atraso/";

  cancelToken: { [key: string]: any } = { get: null, getAll: null };

  async get(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<Diligence>({
      entity: this.cdEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        query,
      },
    });

    return { data };
  }

  async getAll(pagination: PaginationParams, filters: DiligenceFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Diligence>>({
      entity: this.endpoint,
      config: {
        query: { ...pagination, ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getAllSolicitation(pagination: PaginationParams, filters: DiligenceFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Diligence>>({
      entity: this.solicitationsEndpoint,
      config: {
        query: { ...pagination, ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getSolicitation(filters: DiligenceFilters) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    this.cancelToken.getAll = gojus.getCancelToken();
    const { data } = await gojus.get<PaginationResponse<Diligence>>({
      entity: this.solicitationsEndpoint,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return { data };
  }

  async getAllFiles(cd_diligencia: number, cd_empresa?: number) {
    const { data } = await gojus.get<any[]>({
      entity: this.getFileEndPoint(cd_diligencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return data;
  }

  async getFiltersOverdue() {
    const { data } = await gojus.get<any>({
      entity: this.filterOverdueEndPoint,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return data;
  }

  async create(diligence: Diligence) {
    const { data } = await gojus.post<Diligence>({
      entity: this.endpoint,
      data: this.handle(diligence),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async createFile(cd: number, file: File, cd_empresa?: number) {
    const response = await gojus.post<any>({
      entity: this.postFileEndPoint,
      data: this.handleFile(cd, file),
      config: { query: { cd_empresa }, baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return response;
  }

  async edit(cd: number, diligence: Diligence) {
    const { data } = await gojus.patch<Diligence>({
      entity: this.cdEndpoint(cd),
      data: this.handle(diligence),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async removeCorrespondent(cd: number) {
    const response = await gojus.delete({
      entity: this.removeSolicitationEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return response;
  }

  async removeFile(cd_diligencia: number, cd_arquivo: number, cd_empresa?: number) {
    const response = await gojus.delete({
      entity: this.deleteFileEndPoint(cd_arquivo, cd_diligencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return response;
  }

  async getCounterFile(cd_arquivo: number, cd_diligencia: number, cd_empresa?: number) {
    const response = await gojus.get({
      entity: this.counterEndPoint(cd_arquivo, cd_diligencia),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return response;
  }

  async reopen(cd: number) {
    const { data } = await gojus.patch({
      entity: this.reopenEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async cancel(cd: number, ds_motivo_cancelamento: string) {
    const { data } = await gojus.patch({
      entity: this.cancelEndpoint(cd),
      data: { ds_motivo_cancelamento },
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async finish(cd: number) {
    const { data } = await gojus.patch({
      entity: this.finishEndpoint(cd),
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async accept(cd: number, cd_correspondente: number) {
    const { data } = await gojus.patch({
      entity: this.acceptEndpoint(cd),
      data: { cd_correspondente },
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async requestConference(cd: number, form: any) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.patch({
      entity: this.requestConferenceEndpoint(cd),
      data: form,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        query,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async getSummary(ie_solicitacao: boolean) {
    const { data } = await gojus.get<DiligenceSummary>({
      entity: this.summaryEndpoint,
      config: {
        query: { ie_solicitacao },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async addCorrespondent(cd: number, form: any) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    form.nr_valor_correspondente = removeMoneyMask(form.nr_valor_correspondente);
    const { data } = await gojus.patch<any>({
      entity: this.solicitationEndpoint(cd),
      data: form,
      config: {
        baseAPI: VUE_APP_API_LOGISTICS_URL,
        query,
        // apiKey: VUE_APP_API_LOGISTICS_KEY,
      },
    });

    return { data };
  }

  async getTypes() {
    const { data } = await gojus.get<DiligenceActType[]>({
      entity: this.acttypeEndpoint,
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL },
    });

    return { data };
  }

  async getLogisticsCustomer(cd: number) {
    const isCorrespondent = localStorage.getItem("IS_CORRESPONDENT");
    const query: any = {};
    if (isCorrespondent) {
      query.cd_empresa = -1;
    }

    const { data } = await gojus.get<LogisticsCustomer>({
      entity: this.customerCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_LOGISTICS_URL, query },
    });

    return data;
  }

  async getLogisticsCustomers(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<LogisticsCustomer>>({
      entity: this.customerEndpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_LOGISTICS_URL,
      },
    });

    return { data };
  }

  handle(diligence: any): Diligence {
    if (diligence.dt_prazo) diligence.dt_prazo = formatDateEn(diligence.dt_prazo);
    else delete diligence.dt_prazo;

    diligence.nr_valor_correspondente = removeMoneyMask(diligence.nr_valor_correspondente || "");

    diligence.nr_valor_recebido = removeMoneyMask(diligence.nr_valor_recebido || "");

    diligence.nr_valor_solicitacao = removeMoneyMask(diligence.nr_valor_solicitacao || "");

    if (!diligence.cd_correspondente) delete diligence.cd_correspondente;

    return diligence;
  }

  handleFile(cd: number, file: File) {
    const data = new FormData();

    data.append("cd_diligencia", cd.toString());
    data.append("aq_arquivo", file, file.name);

    return data;
  }
}
