import { GojusRequestConfig } from "@/services/gojus/types";

export function formatQuery(items: { [key: string]: any }) {
  for (const key in items) {
    const item = items[key];

    if (Array.isArray(item)) {
      items[key] = item.join(",");
    }

    if (!items[key] && items[key] !== false) {
      delete items[key];
    } else if (typeof items[key] === "string") {
      items[key] = items[key]?.trim();
    }
  }

  return items;
}

export function addQuery(config: GojusRequestConfig = {}) {
  config.params = {
    ...(config.params || {}),
    ...formatQuery(config.query || {}),
  };

  return config;
}

export function configRequestDefs(config: GojusRequestConfig = {}) {
  const { VUE_APP_API_URL, VUE_APP_API_VERSION } = process.env;

  config.useVersion = config.useVersion ?? true;
  config.useCompany = config.useCompany ?? true;
  config.useEstablishment = config.useEstablishment ?? false;
  config.useToken = config.useToken ?? true;

  config.baseURL = `${config.baseAPI || VUE_APP_API_URL}/${config.useVersion ? VUE_APP_API_VERSION : ""}`;

  config.headers = config.headers || {};
  if (config.useToken) config.headers.Authorization = `Bearer ${localStorage.getItem("TOKEN")}`;
  if (config.apiKey) config.headers["x-api-key"] = config.apiKey;

  config.params = config.params || {};

  const companyId = localStorage.getItem("CD_EMPRESA") || undefined;
  if (config.useCompany) {
    config.params.cd_empresa = companyId;
  }
  if (config.useEstablishment) {
    config.params.cd_estabelecimento = companyId;
  }

  return config;
}
