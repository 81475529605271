<template>
  <footer class="c-form-footer-template">
    <c-divider />

    <div class="actions">
      <p-button
        v-if="useCancel"
        class="p-button-danger p-button-text cancel"
        label="Cancelar"
        icon="fas fa-times"
        @click="$emit('click-back')"
      />

      <slot name="before-submit"></slot>

      <slot name="submit">
        <p-button :class="['p-button-success', 'submit', loading ? 'loading' : '']" type="submit" :disabled="loading">
          <template v-if="loading">
            <p-progress-spinner strokeWidth="10" animationDuration="0.8s" />
            <span>Aguarde</span>
          </template>
          <template v-else>
            <i v-if="submitIcon" :class="submitIcon"></i>
            <span class="submit-text">
              <slot name="submit-text"></slot>
            </span>
          </template>
        </p-button>
      </slot>

      <slot name="after-submit"></slot>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CDivider from "@/components/divider.vue";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

@Component({ components: { CDivider, PProgressSpinner } })
export default class CFormFooterTemplate extends Vue {
  @Prop({ required: false, default: false }) readonly loading!: boolean;
  @Prop({ required: false, default: "fas fa-cloud-upload-alt" })
  readonly submitIcon!: string | boolean;
  @Prop({ required: false, default: true }) readonly useCancel!: boolean;
}
</script>

<style lang="scss">
.c-form-footer-template {
  .c-divider {
    --margin-y: 12px;
  }

  .actions {
    display: flex;

    .p-button {
      margin-top: var(--margin-y);

      font-size: 1rem;
      font-weight: 500;
    }

    .p-button.cancel {
      padding-left: 0;
      box-shadow: unset;
    }

    .p-button.submit {
      i {
        margin-right: 6px;
      }

      &.loading {
        cursor: progress;
      }
    }
  }
}
</style>
