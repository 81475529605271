import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { BillToReceive, BillToReceiveForm, FavoredTypes } from "@/store/financial/bill-to-receive/types";

import { BillToReceiveService } from "@/store/financial/bill-to-receive/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class BillToReceiveModule extends VuexModule {
  all: BillToReceive[] = [];
  current: Partial<BillToReceive> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  genericFilters: any = {};

  service = new BillToReceiveService();

  @Mutation
  updateCurrent(billToReceive: BillToReceive) {
    this.current = billToReceive;
  }

  @Mutation
  updateBillToReceiveList(billToReceives: BillToReceive[]) {
    this.all = billToReceives;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updateGenericFilters(value: any) {
    this.genericFilters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const response = await this.service.get(payload.cd);
      const billToReceive = response.data;

      if (billToReceive.cd_recebido) {
        billToReceive.ob_recebido = await this.context.dispatch("getFavored", billToReceive);
      }

      this.context.commit("updateCurrent", billToReceive);

      return billToReceive;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getFavored(billToReceive: BillToReceive) {
    try {
      const getFavoredItem = async () => {
        try {
          if (billToReceive.ie_tipo_recebido === "CO")
            return (await this.context.rootState.collection.customer.service.get(billToReceive.cd_recebido))?.data;
          else if (billToReceive.ie_tipo_recebido === "JU")
            return (await this.context.rootState.lawsuit.customer.service.get(billToReceive.cd_recebido))?.data;
          else if (billToReceive.ie_tipo_recebido === "FU")
            return (await this.context.rootState.department.employee.service.get(billToReceive.cd_recebido))?.data;
          else if (billToReceive.ie_tipo_recebido === "LO")
            return (await this.context.rootState.logistics.customer.service.get(billToReceive.cd_recebido))?.data;
          else if (billToReceive.ie_tipo_recebido === "CR")
            return (await this.context.rootState.correspondent.service.get(billToReceive.cd_recebido))?.data;
          else if (["FA", "PS", "EM", "CL"].includes(billToReceive.ie_tipo_recebido))
            return (await this.context.rootState.financial.favored.service.get(billToReceive.cd_recebido))?.data;

          return {};
        } catch (error) {
          return null;
        }
      };

      const favored = await getFavoredItem();

      return { ...favored, ie_tipo_recebido: billToReceive.ie_tipo_recebido };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getFavoredList(payload: { query: string; ie_tipo_recebido: FavoredTypes }) {
    try {
      if (payload.ie_tipo_recebido === "CO")
        return (await this.context.rootState.collection.customer.service.getAll({}, { nm_cliente: payload.query }))
          ?.data;
      else if (payload.ie_tipo_recebido === "JU")
        return (await this.context.rootState.lawsuit.customer.service.getAll({}, { nm_cliente: payload.query }))?.data;
      else if (payload.ie_tipo_recebido === "FU")
        return (await this.context.rootState.department.employee.service.getAll({}, { nm_funcionario: payload.query }))
          ?.data;
      else if (payload.ie_tipo_recebido === "LO")
        return (await this.context.rootState.logistics.customer.service.getAll({}, { nm_cliente: payload.query }))
          ?.data;
      else if (payload.ie_tipo_recebido === "CR")
        return (await this.context.rootState.correspondent.service.getAll({ nm_correspondente: payload.query }))?.data;
      else if (["FA", "PS", "EM", "CL"].includes(payload.ie_tipo_recebido)) {
        const filters: any = { nm_favorecido: payload.query };
        if (payload.ie_tipo_recebido !== "FA") filters.ie_tipo_favorecido = payload.ie_tipo_recebido;

        return (await this.context.rootState.financial.favored.service.getAll(filters))?.data;
      }

      return [];
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: { genericFilters: any }) {
    try {
      let filters: any = {};

      if (payload?.genericFilters?.day?.length) {
        filters.days_vencimento = payload.genericFilters.day.join(",");
      }

      if (typeof payload?.genericFilters?.monthAndYear === "object") {
        filters.mes_vencimento = payload.genericFilters.monthAndYear.getMonth() + 1;
        filters.ano_vencimento = payload.genericFilters.monthAndYear.getFullYear();
      }

      if (payload?.genericFilters?.status_pagamento) {
        filters.status_recebimento = payload.genericFilters.status_pagamento;
      }

      if (Object.keys(this.filters).length) {
        filters = {};
      }

      const response = await this.service.getAll(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        { ...this.filters, ...filters }
      );

      this.context.commit("updateBillToReceiveList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error: any) {
      if (error?.status === 404) {
        this.context.commit("updateCurrentPaginationPage", 1);
        return await this.context.dispatch("getAll", payload);
      }

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: BillToReceiveForm }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a receber cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: BillToReceiveForm }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a receber editada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", {});

      const message: ToastParams[] = [{ severity: "success", summary: "Conta a receber excluída com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default BillToReceiveModule;
