<template>
  <form class="c-form-template" autocomplete="off" @submit.prevent="$emit('submit')">
    <slot name="header" v-if="useHeader">
      <c-form-header-template>
        <template #image>
          <slot name="image"></slot>
        </template>
        <template #title>
          <slot name="title"></slot>
        </template>
        <template #description>
          <slot name="description"></slot>
        </template>
      </c-form-header-template>
    </slot>

    <slot name="fields-container">
      <main :class="contentCSS">
        <slot name="default"></slot>
      </main>
    </slot>

    <slot name="submit-container" v-if="useFooter">
      <c-form-footer-template
        :loading="loading"
        :submitIcon="submitIcon"
        :useCancel="useCancel"
        @click-back="handleBack"
      >
        <template #before-submit>
          <slot name="before-submit"></slot>
        </template>
        <template #submit>
          <slot name="submit"></slot>
        </template>
        <template #submit-text>
          <slot name="submit-text"></slot>
        </template>
        <template #after-submit>
          <slot name="after-submit"></slot>
        </template>
      </c-form-footer-template>
    </slot>
  </form>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormHeaderTemplate from "@/components/form/header.vue";
import CFormFooterTemplate from "@/components/form/footer.vue";

@Component({ components: { CFormFooterTemplate, CFormHeaderTemplate } })
export default class CFormTemplate extends Vue {
  @Prop({ required: false, default: false }) readonly isFlex!: boolean;
  @Prop({ required: false, default: false }) readonly loading!: boolean;
  @Prop({ required: false, default: false }) readonly useHeader!: boolean;
  @Prop({ required: false, default: true }) readonly useFooter!: boolean;
  @Prop({ required: false, default: true }) readonly useCancel!: boolean;
  @Prop({ required: false, default: false }) readonly submitIcon!: string | boolean;

  get contentCSS() {
    return ["fields", this.isFlex ? "flex" : ""];
  }

  handleBack() {
    this.$emit("click-back");
  }
}
</script>

<style lang="scss" scoped>
.c-form-template {
  --margin: 18px;
  --padding: 0;
  --border-radius: var(--border-radius-50);

  width: 100%;
  padding: var(--padding);

  border-radius: var(--border-radius);

  .c-form-header-template {
    margin-bottom: var(--margin);
  }

  .fields.flex {
    display: flex;
    flex-wrap: wrap;

    margin-top: 0;
  }

  .p-button {
    display: inline-block;
    margin: var(--margin) 0;
    padding: 0.8rem 1rem !important;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;

    i {
      margin: 0 4px;
      font-size: 1.2rem;
      font-weight: 600;
    }

    &.p-button {
      margin-right: 6px;
    }
  }

  .c-dashboard-section-template + .c-dashboard-section-template {
    margin-top: 12px;
  }
}
</style>
