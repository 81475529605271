<template>
  <c-dashboard-template class="c-agreement-list-modal" :tableLoading="loading.list" :useTable="true">
    <template #top-right>
      <p-button
        class="p-button-success"
        icon="fas fa-plus-circle"
        label="Novo Acordo"
        @click="onAskingToAddAgreement(props.debtor)"
      />
    </template>

    <template #table>
      <p-data-table class="p-datatable-striped" dataKey="id" :value="agreements">
        <template #empty v-if="loading.list">Aguarde...</template>
        <template #empty v-else>Nenhum acordo encontrada.</template>

        <p-column field="id" header="ID" headerStyle="width: 5rem;" sortable>
          <template #body="{ data }">
            <span class="p-column-title">ID</span>
            <span v-if="data.dt_deletado" :class="['tag', 'danger-color']" title="Acordo Cancelado" />
            <span class="p-column-text break" v-text="data.id" />
          </template>
        </p-column>
        <p-column field="ob_tipo_acordo.nm_tipo_acordo" header="Acordo" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Acordo</span>
            <span class="p-column-text" v-text="data.ob_tipo_acordo.nm_tipo_acordo" />
          </template>
        </p-column>
        <p-column field="ob_tipo_debito.nm_tipo_debito" header="Débito" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Débito</span>
            <span class="p-column-text" v-text="data.ob_tipo_debito.nm_tipo_debito" />
          </template>
        </p-column>
        <p-column field="ie_origem_acordo" header="Origem" headerStyle="width: 6rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Origem</span>
            <span class="p-column-text" v-text="getAgreementOrigin(data.ie_origem_acordo).label" />
          </template>
        </p-column>
        <p-column field="nr_valor_entrada" header="Entrada" headerStyle="width: 7rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Entrada</span>
            <span class="p-column-text" v-text="addMoneyMask(data.nr_valor_entrada)" />
          </template>
        </p-column>
        <p-column field="nr_valor_debito_negociado" header="Negociado" headerStyle="width: 7rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Negociado</span>
            <span class="p-column-text" v-text="addMoneyMask(data.nr_valor_debito_negociado)" />
          </template>
        </p-column>
        <p-column field="nr_valor_parcela" header="Parcela" headerStyle="width: 7rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Parcela</span>
            <span class="p-column-text" v-text="addMoneyMask(data.nr_valor_parcela)" />
          </template>
        </p-column>
        <p-column field="nr_qtd_parcela" header="Pagamento" headerStyle="width: 7.4rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Pagamento</span>
            <span class="p-column-text">
              <span style="display: block" v-if="data.nr_qtd_parcela">
                {{ data.nr_qtd_parcela }}
              </span>
              {{ Number(data.nr_qtd_parcela) >= 1 ? "Parcelamento" : "À Vista" }}
            </span>
          </template>
        </p-column>
        <p-column field="dt_entrada" header="Entrada" headerStyle="width: 6rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Entrada</span>
            <span class="p-column-text" v-text="formatDate(data.dt_entrada)" />
          </template>
        </p-column>
        <p-column field="dt_inicio_debito" header="Inicio" headerStyle="width: 6rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Inicio</span>
            <span class="p-column-text" v-text="formatDate(data.dt_inicio_debito)" />
          </template>
        </p-column>
        <p-column field="dt_fim_debito" header="Fim" headerStyle="width: 6rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Fim</span>
            <span class="p-column-text" v-text="formatDate(data.dt_fim_debito)" />
          </template>
        </p-column>
        <p-column field="nr_dia_vencimento" header="Vencimento" headerStyle="width: 7.75rem" sortable>
          <template #body="{ data }">
            <span class="p-column-title">Vencimento</span>
            <template v-if="data.nr_dia_vencimento">
              <p class="p-column-text">
                {{ formatDate(getDueDate(data.nr_dia_vencimento, data.dt_fim_debito)) }}
              </p>
              <p class="p-column-text">
                Dia <b>{{ String(data.nr_dia_vencimento).padStart(2, "0") }}</b>
              </p>
            </template>
            <span class="p-column-text" v-else></span>
          </template>
        </p-column>
        <p-column field="ie_homologado" header="Homologado" headerStyle="width: 7.75rem">
          <template #body="{ data }">
            <span class="p-column-title">Homologado</span>
            <span class="p-column-text" v-text="data.ie_homologado ? 'Sim' : 'Não'" />
          </template>
        </p-column>

        <p-column headerStyle="width: 48px;">
          <template #body="{ data }">
            <p-button
              class="p-button-text p-button-rounded"
              icon="fas fa-ellipsis-v"
              :title="`Opções do Acordo #${data.id}`"
              :disabled="data.dt_deletado"
              aria-haspopup="true"
              aria-controls="overlay-menu"
              @click="onToggle($event, data)"
            />
            <p-menu
              id="overlay-menu"
              :ref="`${data.id}`"
              :model="getOptions(props.debtor, data)"
              appendTo="app"
              popup
            />
          </template>
        </p-column>
      </p-data-table>
    </template>
  </c-dashboard-template>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import CDashboardTemplate from "@/components/dashboard/dashboard.vue";
import Menu from "primevue/menu/Menu.vue";

import { DebtorAgreement } from "@/store/collection/debtor/types";
import { AgreementMenu } from "@/views/collection/debtor/mixins";
import { formatDate, formatDateEn } from "@/utils/formatters";
import { addMoneyMask } from "@/utils/mask";
import { getAgreementOrigin } from "@/utils/acronyms";

const PMenu: any = Menu;

@Component({ components: { CDashboardTemplate, PMenu } })
export default class CAgreementListModal extends Mixins(AgreementMenu) {
  @Prop({ required: true }) readonly props!: { [key: string]: any };

  formatDate = formatDate;
  addMoneyMask = addMoneyMask;
  getAgreementOrigin = getAgreementOrigin;

  loading = { list: false };
  agreements: DebtorAgreement[] = [];

  getDueDate(day: number, finalDate: string) {
    try {
      const currentDateObj = new Date();
      const currentDate = formatDateEn(currentDateObj);
      const lastDayOfMonth = formatDateEn(new Date(currentDateObj.getFullYear(), currentDateObj.getMonth() + 1, 0));

      const dueDateObj = new Date();
      dueDateObj.setDate(day);
      const initialDueDate = formatDateEn(dueDateObj);
      const dueDate = initialDueDate.slice(5, 7) !== currentDate.slice(5, 7) ? lastDayOfMonth : initialDueDate;
      const finalDueDate = dueDate?.slice(0, 7) > finalDate?.slice(0, 7) ? finalDate : dueDate;

      return finalDueDate;
    } catch {
      return "";
    }
  }

  getFields() {
    return {};
  }

  reset() {}

  async getComponentData() {
    if (!this.props.debtor?.id) return;

    this.loading.list = true;

    const response = await this.$store.state.debtor.service.getAgreements(this.props.debtor.id);
    this.agreements = response?.data || [];

    this.loading.list = false;
  }

  mounted() {
    this.getComponentData();
  }
}
</script>

<style lang="scss">
.p-dialog.agreement-list {
  width: 100vw;
  max-width: 1300px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-dialog-content {
    min-height: 40vh;
    padding: 0 0.5rem 1rem;

    .c-form-field-template.ds_liminar .p-inputtextarea {
      height: 40vh;

      overflow-y: auto !important;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #0000004d;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #3273dc;
      }
    }

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
