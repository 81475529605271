import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { GFile, FileParams } from "@/store/file/types";

import { FileService } from "@/store/file/service";

@Module({ namespaced: true })
export class FileModule extends VuexModule {
  all: GFile[] = [];
  service = new FileService();

  @Mutation
  updateFiles(files: GFile[]) {
    this.all = files;
  }

  @Action
  async getAll({ cd_owner, cd_empresa, updateList }: FileParams) {
    try {
      const response = await this.service.getAll(cd_owner, cd_empresa);
      if (updateList !== false) this.context.commit("updateFiles", response.data);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      // return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create({ files, cd_owner, cd_owner_key, cd_empresa }: FileParams) {
    try {
      let success = true;
      await Promise.allSettled(
        (files || []).map(async (file) => {
          const response = await this.service.create(file, cd_owner[cd_owner_key], cd_owner_key, cd_empresa);
          if (response.status !== 201) success = false;
        })
      );

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: files?.length > 1 ? "Arquivos cadastrados com sucesso!" : "Arquivo cadastrado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return success;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return false;
    }
  }

  @Action
  async incrementDownload({ cd }: FileParams) {
    try {
      const response = await this.service.incrementDownload(cd);
      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete({ cd, cd_owner, cd_empresa }: FileParams) {
    try {
      const response = await this.service.delete(cd, cd_owner, cd_empresa);

      const message: ToastParams[] = [{ severity: "success", summary: "Arquivo excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default FileModule;
