import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import gojus from "@/services/gojus";

import { ToastParams } from "@/store/global/types";
import {
  Task,
  TaskChangeResponsible,
  TaskPayload,
  TaskReschedule,
  TaskSummary,
  TaskType,
  TaskTypePayload,
} from "@/store/task/types";

import { TaskService } from "@/store/task/service";
import { Pagination, PaginationRow } from "@/store/types";
import { GojusError } from "@/services/gojus/types";
import { formatDateTimeEn } from "@/utils/formatters";

const { VUE_APP_API_ANALYTICS_URL } = process.env;
const endpoints = { dashboardCard: (endpoint: string) => `tarefa/${endpoint}` };

@Module({ namespaced: true })
export class TaskModule extends VuexModule {
  current: Task = { id: 0 };
  all: Task[] = [];
  types: TaskType[] = [];
  subtitleFilter: { [key: string]: any } = { ie_status: null };

  summary: TaskSummary = {
    a_vencer: 0,
    prazos_do_dia: 0,
    vencidas: 0,
    concluidas_do_dia: 0,
    canceladas: 0,
    reagendadas: 0,
    prazos_distribuir: 0,
    prazos_distribuir_operacional: 0,
    prazos_distribuir_processual: 0,
    solicitacoes_troca: 0,
  };

  taskNew: null | number = null;
  taskDeadlineDay: null | number = null;

  summaryFilter = "";
  pagination: Pagination = { row: 15, total: 0, current: 1 };
  filters: any = {};
  requestID = 0;

  service = new TaskService();

  @Mutation
  updateCurrent(task: Task) {
    this.current = task;
  }

  @Mutation
  updateSubtitleFilter(subtitle: { key: string; value: any }) {
    this.subtitleFilter[subtitle.key] = subtitle.value;
    this.filters.resumo = subtitle.value;
  }

  @Mutation
  updateTasks(tasks: Task[]) {
    this.all = tasks;
  }

  @Mutation
  updateTaskTypes(taskTypes: TaskType[]) {
    this.types = taskTypes;
  }

  @Mutation
  updateTaskDeadlineDay(taskDeadlineDay: number | null) {
    this.taskDeadlineDay = taskDeadlineDay;
  }

  @Mutation
  updateTaskNew(taskNew: number | null) {
    this.taskNew = taskNew;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
    if (this.summaryFilter) this.filters.resumo = this.summaryFilter;
  }

  @Mutation
  updateSummaryFilter(value: any) {
    this.summaryFilter = value;
    this.filters.resumo = value;
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Mutation
  updateSummary(taskSummary: any) {
    for (const key in taskSummary) {
      const newKey = key.replaceAll("-", "_").toLocaleLowerCase();
      taskSummary[newKey] = taskSummary[key];

      delete taskSummary[key];
    }

    this.summary = taskSummary;
  }

  @Mutation
  updateRequestID(requestID: number) {
    this.requestID = requestID;
  }

  @Action
  async get(payload: TaskPayload) {
    try {
      const { data: task } = await this.service.get(payload.cd);
      const filesResponse = await this.context.dispatch("getAllFiles", { cd: task.id });

      if (!payload.isNew && !task.dt_visualizado) {
        this.service.edit(task.id, {
          dt_visualizado: formatDateTimeEn(new Date().toISOString(), "00:00") || null,
        } as any);
      }

      try {
        if (payload.getType && task.cd_tipo_tarefa)
          task.ob_tipo_tarefa = await this.context.dispatch("getType", { cd: task.cd_tipo_tarefa });
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      try {
        if (payload.getLogisticsCustomer && task.cd_cliente) {
          if (task.ie_modulo_origem === "CO")
            task.ob_cliente = (await this.context.rootState.collection.customer.service.get(task.cd_cliente))?.data;
          else task.ob_cliente = (await this.context.rootState.lawsuit.customer.service.get(task.cd_cliente))?.data;

          if (!task.ob_cliente) task.ob_cliente = { id: 0 };
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      try {
        if (payload.getDebtor && task.cd_devedor) {
          task.ob_devedor = (await this.context.rootState.debtor.service.get(task.cd_devedor)).data;
          if (!task.ob_devedor) task.ob_devedor = { id: 0 };
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      try {
        if (task.cd_responsaveis.length > 0) {
          const responsibleResponse = await this.context.dispatch(
            "department/employee/search",
            { cd_authuser: task.cd_responsaveis[0] },
            { root: true }
          );
          task.ob_responsavel = responsibleResponse?.data?.results?.[0] || null;
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      try {
        if (payload.getCreateUser && task.nm_usuario_cri) {
          const createdUserResponse = await this.context.dispatch(
            "department/employee/search",
            { username: task.nm_usuario_cri },
            { root: true }
          );
          task.ob_usuario_cri = createdUserResponse?.data?.results?.[0] || null;
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      try {
        if (task.nr_processo) {
          const response = await this.context.dispatch(
            "lawsuit/getWithNumber",
            { nr_processo: task.nr_processo, getEmployee: false, getDebtor: false, disabledCommit: true },
            { root: true }
          );
          task.lawsuit = response?.lawsuit || null;
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
      }

      this.context.commit("updateCurrent", task);

      return { task, files: filesResponse };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params?: any) {
    try {
      this.context.commit("updateRequestID", this.requestID + 1);
      const currentRequestID = this.requestID;
      const coordenador = params.disabledCoordinator ? undefined : Boolean(params.coordinator ?? params.coordenador);

      const cd_equipe = params?.useTeamFilter
        ? this.context.rootState.user.employee?.equipes?.map((team: any) => team.id)
        : null;

      const pagination = { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true };
      const filters = { cd_equipe, ...this.filters, ...params.filters, coordenador };

      if (filters.cd_equipe === "null") filters.cd_equipe = null;
      const response = await this.service.getAll(pagination, filters);
      const tasks = response.data?.results || [];

      // Obtém os Ids

      let employeesId = [];
      let debtorsId = [];
      let lawsuitCustomersId = [];
      let collectionCustomersId = [];
      let taskTypesId = [];

      for (const task of tasks) {
        if (task.cd_cliente && task.cd_cliente !== "0" && params?.request?.cd_cliente !== false) {
          if (task.ie_modulo_origem === "CO") collectionCustomersId.push(task.cd_cliente);
          else lawsuitCustomersId.push(task.cd_cliente);
        }
        if (task.cd_devedor && task.cd_devedor !== "" && params?.request?.cd_devedor !== false)
          debtorsId.push(task.cd_devedor);
        if (task.cd_tipo_tarefa && params?.request?.cd_tipo_tarefa !== false) taskTypesId.push(task.cd_tipo_tarefa);
        if (task.cd_responsaveis.length > 0 && params?.request?.cd_responsaveis !== false)
          employeesId.push(task.cd_responsaveis[0]);
      }

      // Remove ids duplicados

      employeesId = [...new Set(employeesId)];
      debtorsId = [...new Set(debtorsId)];
      lawsuitCustomersId = [...new Set(lawsuitCustomersId)];
      collectionCustomersId = [...new Set(collectionCustomersId)];
      taskTypesId = [...new Set(taskTypesId)];

      // Monta a cadeira de requisições e separa a matrix de promises

      const [
        employeesPromises,
        debtorsPromises,
        lawsuitCustomersPromises,
        collectionCustomersPromises,
        taskTypesPromises,
      ] = await Promise.allSettled([
        Promise.allSettled(
          employeesId.map((cd_authuser) =>
            this.context.rootState.department.employee.service.getAll(
              { page: 1, nr_por_pagina: 10, paginacao: true },
              { cd_authuser }
            )
          )
        ),
        Promise.allSettled(debtorsId.map((cd_devedor) => this.context.rootState.debtor.service.get(cd_devedor))),
        Promise.allSettled(
          lawsuitCustomersId.map((cd_cliente) => this.context.rootState.lawsuit.customer.service.get(cd_cliente))
        ),
        Promise.allSettled(
          collectionCustomersId.map((cd_cliente) => this.context.rootState.collection.customer.service.get(cd_cliente))
        ),
        Promise.allSettled(
          taskTypesId.map((cd_tipo_tarefa) => this.context.dispatch("getType", { cd: cd_tipo_tarefa }))
        ),
      ]);

      // Valida requisições e obtém o resultado

      const employeesResult = employeesPromises.status === "fulfilled" ? employeesPromises.value : [];
      const debtorsResult = debtorsPromises.status === "fulfilled" ? debtorsPromises.value : [];
      const lawsuitCustomersResult =
        lawsuitCustomersPromises.status === "fulfilled" ? lawsuitCustomersPromises.value : [];
      const collectionCustomersResult =
        collectionCustomersPromises.status === "fulfilled" ? collectionCustomersPromises.value : [];
      const taskTypesResult = taskTypesPromises.status === "fulfilled" ? taskTypesPromises.value : [];

      // Obtém o valor de cada resultado de promise e adiciona ao objeto final

      const employees: { [key: string]: any } = {};
      const debtors: { [key: string]: any } = {};
      const lawsuitCustomers: { [key: string]: any } = {};
      const collectionCustomers: { [key: string]: any } = {};
      const taskTypes: { [key: string]: any } = {};

      for (const employeeResult of employeesResult) {
        const employee = employeeResult.status === "fulfilled" ? employeeResult.value?.data?.results?.[0] : null;
        if (employee?.cd_authuser) employees[employee.cd_authuser] = employee;
      }
      for (const debtorResult of debtorsResult) {
        const debtor = debtorResult.status === "fulfilled" ? debtorResult.value?.data : null;
        if (debtor?.id) debtors[debtor.id] = debtor;
      }
      for (const lawsuitCustomerResult of lawsuitCustomersResult) {
        const lawsuitCustomer = lawsuitCustomerResult.status === "fulfilled" ? lawsuitCustomerResult.value?.data : null;
        if (lawsuitCustomer?.id) lawsuitCustomers[lawsuitCustomer.id] = lawsuitCustomer;
      }
      for (const collectionCustomerResult of collectionCustomersResult) {
        const collectionCustomer =
          collectionCustomerResult.status === "fulfilled" ? collectionCustomerResult.value?.data : null;
        if (collectionCustomer?.id) collectionCustomers[collectionCustomer.id] = collectionCustomer;
      }
      for (const taskTypeResult of taskTypesResult) {
        const taskType = taskTypeResult.status === "fulfilled" ? taskTypeResult.value : null;
        if (taskType?.id) taskTypes[taskType.id] = taskType;
      }

      // Adiciona os dados dos objetos nas tarefas
      const defaultObj: any = { id: 0 };

      response.data.results = response.data.results.map((task) => ({
        ...task,
        ob_cliente: task.cd_cliente
          ? (task.ie_modulo_origem === "CO"
              ? collectionCustomers[task.cd_cliente]
              : lawsuitCustomers[task.cd_cliente]) || defaultObj
          : defaultObj,
        ob_devedor: task.cd_devedor ? debtors[task.cd_devedor] || defaultObj : defaultObj,
        ob_tipo_tarefa: task.cd_tipo_tarefa ? taskTypes[task.cd_tipo_tarefa] || defaultObj : defaultObj,
        ob_responsavel: task.cd_responsaveis?.[0] ? employees[task.cd_responsaveis[0]] || defaultObj : defaultObj,
      }));

      if (currentRequestID === this.requestID) {
        this.context.commit("updateTasks", response.data.results);
        this.context.commit("updatePaginationTotal", response.data.count);
      }

      return response;
    } catch (error: any) {
      if (!error?.isGojusError || params.disableLoop)
        return this.context.dispatch("global/handleError", error, { root: true });

      const gojusError: GojusError = error;
      if (gojusError.status !== 404) return this.context.dispatch("global/handleError", error, { root: true });

      this.context.commit("updateCurrentPaginationPage", this.pagination.current - 1 || 1);
      this.context.dispatch("getAll", { ...params, disableLoop: true });
    }
  }

  @Action
  async getTaskOnDay(params?: any) {
    try {
      const response = await this.service.getTaskOnDay(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        params.coordinator
          ? { ...this.filters, ...this.subtitleFilter, coordenador: true }
          : { ...this.filters, ...this.subtitleFilter }
      );

      await Promise.allSettled(
        response.data.results.map(async (task) => {
          if (task.cd_cliente) {
            if (task.ie_modulo_origem === "CO")
              task.ob_cliente = (await this.context.rootState.collection.customer.service.get(task.cd_cliente))?.data;
            else task.ob_cliente = (await this.context.rootState.lawsuit.customer.service.get(task.cd_cliente))?.data;

            if (!task.ob_cliente) task.ob_cliente = { id: 0 };
          } else task.ob_cliente = { id: 0 };
          if (task.cd_devedor) {
            task.ob_devedor = (await this.context.rootState.debtor.service.get(task.cd_devedor)).data;

            if (!task.ob_devedor) task.ob_devedor = { id: 0 };
          } else task.ob_devedor = { id: 0 };
          if (task.cd_tipo_tarefa) {
            task.ob_tipo_tarefa = await this.context.dispatch("getType", {
              cd: task.cd_tipo_tarefa,
            });
          }

          if (task.cd_responsaveis.length) {
            task.ob_responsavel =
              (
                await this.context.rootState.department.employee.service.getAll(
                  { page: 1, nr_por_pagina: 10, paginacao: true },
                  { cd_authuser: task.cd_responsaveis[0] }
                )
              )?.data?.results?.[0] || {};
          }
        })
      );

      this.context.commit("updateTasks", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async acceptChangeResponsible(cd: number) {
    try {
      const response = await this.service.acceptChangeResponsible(cd);

      const message: ToastParams[] = [{ severity: "success", summary: "Tarefa aceita com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async recuseChangeResponsible(cd: number) {
    try {
      const response = await this.service.recuseChangeResponsible(cd);

      const message: ToastParams[] = [{ severity: "success", summary: "Tarefa recusada!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async responsibleTaskOnDay(payload: { cd: number; dt_vencimento: string }) {
    const response = await this.service.responsibleTaskOnDay(payload.cd, payload.dt_vencimento);
    return response.data;
  }

  @Action
  async create(payload: TaskPayload) {
    try {
      if (!payload.form?.ie_modulo_origem) payload.form.ie_modulo_origem = "TA";

      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      if (response?.data?.id && payload?.form?.recurrence) {
        try {
          await this.service.addRecurrence({ ...payload.form.recurrence, cd_tarefa: response.data.id });
        } catch (error) {
          if (process.env.NODE_ENV === "development") console.error(error);
        }
      }

      const message: ToastParams[] = [{ severity: "success", summary: "Tarefa cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      if (payload.files?.length)
        await this.context.dispatch("createFile", { cd: response.data.id, files: payload.files });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: TaskPayload) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      if (payload?.form?.recurrence) {
        try {
          if (!payload?.form?.recurrence?.id) {
            await this.service.addRecurrence({ ...payload.form.recurrence, cd_tarefa: response.data.id });
          } else {
            await this.service.editRecurrence(payload.form.recurrence);
          }
        } catch (error) {
          if (process.env.NODE_ENV === "development") console.error(error);
        }
      }

      if (!payload?.disableSuccessMessage) {
        const message: ToastParams[] = [{ severity: "success", summary: "Tarefa editada com sucesso!" }];
        this.context.commit("global/updateToast", message, { root: true });
      }

      if (payload.files) await this.context.dispatch("createFile", { cd: response.data.id, files: payload.files });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editTeam(payload: { cd: number; form: { cd_equipe: number; nm_equipe: string } }) {
    try {
      const response = await this.service.editTeam(payload.cd, payload.form);

      if (response.status < 300) {
        const message: ToastParams[] = [{ severity: "success", summary: "Equipe da tarefa atualizada com sucesso!" }];
        this.context.commit("global/updateToast", message, { root: true });
      }

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAllFiles({ cd, cd_empresa }: any) {
    try {
      const response = await this.service.getAllFiles(cd, cd_empresa);
      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getCounterFile(params: any) {
    try {
      const response = await this.service.getCounterFile(params.cd, params.cd_tarefa);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteFile(params: any) {
    try {
      const response = await this.service.removeFile(params.cd_tarefa, params.cd);

      const message: ToastParams[] = [{ severity: "success", summary: "Arquivo excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async requestResponsible(params: TaskChangeResponsible) {
    try {
      const response = await this.service.requestResponsible(params.cd, params.cd_usuario_solicitado);
      const message: ToastParams[] = [{ severity: "success", summary: "Solicitação de Troca enviada ao funcionario!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createFile(params: any) {
    try {
      if (params.files?.length) {
        const requests = params.files.map((file: any) => this.service.createFile(params.cd, file));
        await Promise.allSettled(requests);
      }

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: params.files?.length > 1 ? "Arquivos cadastrados com sucesso!" : "Arquivo cadastrado com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return true;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: TaskPayload) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Tarefa excluída com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async cancel(params: { cd: number; ds_justificativa_cancelamento: string }) {
    try {
      const { data } = await this.service.cancel(params.cd, params.ds_justificativa_cancelamento);

      const messages: ToastParams[] = [{ severity: "success", summary: "Tarefa cancelada com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async finish(params: { cd: number; ds_detalhes_conclusao: string }) {
    try {
      const { data } = await this.service.finish(params.cd, params.ds_detalhes_conclusao);

      const messages: ToastParams[] = [{ severity: "success", summary: "Tarefa finalizada com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });
      await this.context.dispatch("edit", {
        cd: params.cd,
        form: { ds_detalhes_conclusao: params.ds_detalhes_conclusao },
        disableSuccessMessage: true,
      });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async reschedule(params: { cd: number; form: TaskReschedule }) {
    try {
      const response = await this.service.reschedule(params.cd, params.form);

      const messages: ToastParams[] = [{ severity: "success", summary: "Tarefa reagendada com sucesso!" }];
      this.context.commit("global/updateToast", messages, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getType(payload: TaskTypePayload) {
    try {
      const response = await this.service.getType(payload.cd);
      return response.data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getTypes() {
    try {
      const response = await this.service.getTypes();
      this.context.commit("updateTaskTypes", response.data);

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getSummary(params?: any) {
    try {
      const cd_equipe = params?.useTeamFilter
        ? this.context.rootState.user.employee?.equipes?.map((team: any) => team.id)
        : this.filters.cd_equipe || null;
      const { data } = await this.service.getSummary({ cd_equipe, ...(params || {}) });

      if (params?.setTaskDeadlineDay) {
        this.context.commit("updateTaskDeadlineDay", (data as any)["NOVAS-TAREFAS"]);
        this.context.commit("updateTaskNew", (data as any)["NAO-LIDAS"]);
      } else {
        this.context.commit("updateSummary", data);
      }

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getDashboardCard(payload: { card: string; filters?: any }) {
    try {
      const response = await gojus.get<{ value: number }>({
        entity: endpoints.dashboardCard(payload.card),
        config: { query: { ...(payload.filters || {}) }, baseAPI: VUE_APP_API_ANALYTICS_URL },
      });

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }
}

export default TaskModule;
