import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { LawsuitPartner } from "@/store/lawsuit/partner/types";
import { Pagination, PaginationRow } from "@/store/types";
import LawsuitPartnerService from "./service";
import { ToastParams } from "@/store/global/types";

@Module({ namespaced: true })
export class LawsuitPartnerModule extends VuexModule {
  current: LawsuitPartner | null = null;
  all: LawsuitPartner[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new LawsuitPartnerService();

  @Mutation
  updatePartner(partner: LawsuitPartner) {
    this.current = partner;
  }

  @Mutation
  updatePartners(partners: LawsuitPartner[]) {
    this.all = partners;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: Record<string, any>) {
    try {
      const response = await this.service.get(payload.cd);
      const item = response.data || null;
      this.context.commit("updatePartner", item);

      return item;
    } catch (error: any) {
      if (error.isGojusError && error.status === 404) return;

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: Record<string, any>) {
    try {
      const response = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        { ...this.filters, ...(payload?.filters || {}) }
      );
      const items = response.data.results || [];
      const count = response.data.count || 0;

      this.context.commit("updatePartners", items);
      this.context.commit("updatePaginationTotal", count);

      return items;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async search(payload: Record<string, any>) {
    try {
      const response = await this.service.getAll(null, payload);
      const items = response.data || [];

      return items;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: Record<string, any>) {
    try {
      const response = await this.service.create(payload.form);
      const item = response.data || null;

      this.context.commit(
        "global/updateToast",
        [
          {
            severity: "success",
            summary: "Cliente cadastrado com sucesso",
          },
        ] as ToastParams[],
        { root: true }
      );
      this.context.commit("updatePartner", item);

      return { response, item, success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { response: null, partner: null, success: false };
    }
  }

  @Action
  async edit(payload: Record<string, any>) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      const item = response.data || null;

      this.context.commit(
        "global/updateToast",
        [
          {
            severity: "success",
            summary: "Cliente editado com sucesso",
          },
        ] as ToastParams[],
        { root: true }
      );
      this.context.commit("updatePartner", item);

      return { response, item, success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { response: null, partner: null, success: false };
    }
  }

  @Action
  async delete(payload: Record<string, any>) {
    try {
      const response = await this.service.delete(payload.cd);
      const item = response.data || null;

      this.context.commit(
        "global/updateToast",
        [
          {
            severity: "success",
            summary: "Cliente excluído com sucesso",
          },
        ] as ToastParams[],
        { root: true }
      );
      this.context.commit("updatePartner", { id: 0 });

      return item;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default LawsuitPartnerModule;
