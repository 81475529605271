<template>
  <c-form-template class="c-task-responsible" :useFooter="false" :isFlex="true">
    <c-form-field-template width="100%">
      <template #label>Responsável</template>
      <p-auto-complete
        v-model="responsibleQuery"
        :suggestions="responsibles"
        field="nm_funcionario"
        :minLength="3"
        @complete="searchResponsible"
        @item-select="onLogisticsResponsibleSelected"
      /> </c-form-field-template
  ></c-form-template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete.vue";

const PAutoComplete: any = AutoComplete;

@Component({ components: { CFormTemplate, CFormFieldTemplate, PAutoComplete } })
export default class CModalTaskResponsible extends Vue {
  responsibleQuery: any = [];
  cd_usuario_solicitado: number | null = null;

  get responsibles() {
    return this.$store.state.department.employee.all;
  }

  async searchResponsible(event: any) {
    await this.$store.dispatch("department/employee/getAll", { nm_funcionario: event.query });
  }

  onLogisticsResponsibleSelected(event: any) {
    this.cd_usuario_solicitado = event.value.cd_authuser;
  }

  getFields() {
    return this.cd_usuario_solicitado;
  }

  reset() {}
}
</script>
