import gojus from "@/services/gojus";

import { CollectionCustomer } from "@/store/collection/customer/types";

import { removeMoneyMask, removeCPFMask, removeCNPJMask } from "@/utils/mask";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_COLLECTION_URL } = process.env;

export class CollectionCustomerService {
  endpoint = "clientes/";
  cdEndpoint = (cd: number) => `clientes/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<CollectionCustomer>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return { data };
  }

  async getAll(pagination: PaginationParams, filters?: any) {
    const { data } = await gojus.get<PaginationResponse<CollectionCustomer>>({
      entity: this.endpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_COLLECTION_URL,
      },
    });

    return { data };
  }

  async create(customer: CollectionCustomer) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: this.handle(customer),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async edit(cd: number, customer: CollectionCustomer) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: this.handle(customer),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_COLLECTION_URL },
    });
    return { data };
  }

  handle(customer: any): CollectionCustomer {
    customer.nr_valor_advogado = parseFloat(removeMoneyMask(customer.nr_valor_advogado));
    customer.nr_valor_advogado_proposto = parseFloat(removeMoneyMask(customer.nr_valor_advogado_proposto));
    customer.nr_valor_proposto = parseFloat(removeMoneyMask(customer.nr_valor_proposto));
    customer.nr_valor_diligencia = parseFloat(removeMoneyMask(customer.nr_valor_diligencia));
    customer.nr_valor_audiencia = parseFloat(removeMoneyMask(customer.nr_valor_audiencia));

    customer.nr_numero = parseInt(customer.nr_numero);

    if (customer.ie_tipo_cliente === "PF") {
      customer.nr_cpf = removeCPFMask(customer.nr_cpf);
      customer.nr_cnpj = "";
    } else {
      customer.nr_cnpj = removeCNPJMask(customer.nr_cnpj);
      customer.nr_cpf = "";
    }

    return customer;
  }
}

export default CollectionCustomerService;
