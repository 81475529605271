import gojus from "@/services/gojus";

import { ThesesBank, ThesesBankForm } from "@/store/lawsuit/theses-bank/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class ThesesBankService {
  endpoint = "teses/";
  cdEndpoint = (cd: number) => `teses/${cd}/`;

  async get(cd: number) {
    const response = await gojus.get<ThesesBank>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<ThesesBank[]>({
      entity: this.endpoint,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    const companyId = Number(localStorage.getItem("CD_EMPRESA"));
    response.data = response.data.filter((item) => Number(item.cd_estabelecimento) === companyId);

    return response;
  }

  async create(thesesBank: ThesesBankForm) {
    const response = await gojus.post<ThesesBank, ThesesBankForm>({
      entity: this.endpoint,
      data: thesesBank,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async edit(cd: number, thesesBank: ThesesBankForm) {
    const response = await gojus.patch<ThesesBank, ThesesBankForm>({
      entity: this.cdEndpoint(cd),
      data: thesesBank,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }
}

export default ThesesBankService;
