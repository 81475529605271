import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/tarefas",
    name: "task",
    component: () => import("@/views/task/list.vue"),
  },
  {
    path: "/dashboard/tarefas/adicionar",
    name: "task-add",
    component: () => import("@/views/task/add.vue"),
  },
  {
    path: "/dashboard/tarefas/:cd",
    name: "task-details",
    component: () => import("@/views/task/details.vue"),
  },
  {
    path: "/dashboard/tarefas/:cd/editar",
    name: "task-edit",
    component: () => import("@/views/task/edit.vue"),
  },
  {
    path: "/dashboard/tarefas/:cd/auditoria",
    name: "task-audit",
    component: () => import("@/views/task/audit.vue"),
  },
];

export default routes;
