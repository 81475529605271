<template>
  <c-form-template class="c-liminar-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="20%">
      <template #label>Data da Intimação<b class="is-required" /></template>
      <p-calendar
        v-model="dt_recebimento"
        ref="dt_recebimento"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        :yearRange="`1990:${new Date().getFullYear() + 10}`"
        @input="
          dt_recebimento = addDateMask(dt_recebimento);
          handleDateInput('dt_recebimento');
        "
      />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Data de Cumprimento</template>
      <p-calendar
        v-model="dt_cumprimento"
        ref="dt_cumprimento"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        :yearRange="`1990:${new Date().getFullYear() + 10}`"
        @input="
          dt_cumprimento = addDateMask(dt_cumprimento);
          handleDateInput('dt_cumprimento');
        "
      />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Data de Cassação</template>
      <p-calendar
        v-model="dt_casacao"
        ref="dt_casacao"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        :yearRange="`1990:${new Date().getFullYear() + 10}`"
        @input="
          dt_casacao = addDateMask(dt_casacao);
          handleDateInput('dt_casacao');
        "
      />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Prazo para cumprimento<b class="is-required" /></template>
      <p-calendar
        v-model="dt_prazo_cumprimento"
        ref="dt_prazo_cumprimento"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        :yearRange="`1990:${new Date().getFullYear() + 10}`"
        @input="
          dt_prazo_cumprimento = addDateMask(dt_prazo_cumprimento);
          handleDateInput('dt_prazo_cumprimento');
        "
      />
    </c-form-field-template>

    <c-form-field-template width="20%">
      <template #label>Data da Comprovação</template>
      <p-calendar
        v-model="dt_comprovacao"
        ref="dt_comprovacao"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        :yearRange="`1990:${new Date().getFullYear() + 10}`"
        @input="
          dt_comprovacao = addDateMask(dt_comprovacao);
          handleDateInput('dt_comprovacao');
        "
      />
    </c-form-field-template>

    <c-form-field-template class="ds_liminar" width="100%">
      <template #label>Liminar<b class="is-required" /></template>
      <p-textarea v-model="ds_liminar" :autoResize="false" required />
    </c-form-field-template>

    <template v-if="addTask">
      <c-form-field-template width="70%">
        <template #label>Titulo da Tarefa</template>
        <p-input-text v-model="taskTitle" autofocus />
      </c-form-field-template>

      <c-form-field-template width="30%">
        <template #label>Equipe da Tarefa<b class="is-required" /></template>
        <p-dropdown
          v-model="taskTeamId"
          :options="teams"
          optionValue="id"
          optionLabel="nm_equipe"
          required
          @input="handleTeamInput"
        />
      </c-form-field-template>
    </template>

    <div class="p-field-checkbox" v-if="$store.state.user.permissions.taskModule">
      <p-checkbox v-model="addTask" :binary="true" />
      <label class="p-checkbox-box-label" @click="addTask = !addTask">Deseja agendar uma Tarefa?</label>
    </div>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn } from "@/utils/formatters";
import { LawsuitLiminarForm } from "@/store/lawsuit/types";
import { addDateMask } from "@/utils/mask";
import { Team } from "@/store/team/types";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CLiminarModal extends Vue implements LawsuitLiminarForm {
  @Prop({ required: true }) readonly props!: { [key: string]: any };
  calendarConfig = calendarConfig;

  ds_liminar = "";
  dt_recebimento = "";
  dt_casacao = "";
  dt_cumprimento = "";
  dt_comprovacao = "";
  dt_prazo_cumprimento = "";
  cd_processo = 0;
  taskTitle = "";
  taskTeamId = null;
  taskTeamName = "";

  addTask = false;
  addDateMask = addDateMask;

  get teams(): Team[] {
    return this.$store.state.department.responsible.all;
  }

  handleTeamInput(teamId: number) {
    const team = this.teams?.find((item) => item.id === teamId);
    this.taskTeamName = team?.nm_equipe || "";
  }

  handleDateInput(ref: string) {
    const calendarComponent: any = this.$refs[ref];
    if (!calendarComponent) return;

    const calendarInputComponent: any = calendarComponent.$refs?.input;
    if (!calendarInputComponent) return;

    const calendarInputEl = calendarInputComponent.$el;

    setTimeout(() => {
      calendarInputEl.selectionStart = calendarInputEl.selectionEnd = 100;
    }, 0);
  }

  getFields() {
    const form: LawsuitLiminarForm = {
      dt_recebimento: formatDateEn(this.dt_recebimento) || null,
      dt_casacao: formatDateEn(this.dt_casacao) || undefined,
      dt_cumprimento: formatDateEn(this.dt_cumprimento) || null,
      ds_liminar: this.ds_liminar,
      cd_processo: this.cd_processo,
      dt_prazo_cumprimento: formatDateEn(this.dt_prazo_cumprimento) || null,
      addTask: this.addTask,
      taskTitle: this.taskTitle,
      taskTeamId: this.taskTeamId || undefined,
      taskTeamName: this.taskTeamName || undefined,
    };

    if (this.dt_comprovacao) form.dt_comprovacao = formatDateEn(this.dt_comprovacao);

    if (!form.dt_prazo_cumprimento || !form.dt_recebimento || (form.addTask && !form.taskTeamId)) {
      this.$store.commit("global/updateToast", [
        { summary: "Preencha todos os campos obrigatórios!", severity: "warn" },
      ]);

      return { hasError: true };
    }

    return form;
  }

  reset() {
    this.ds_liminar = "";
    this.dt_recebimento = "";
    this.dt_casacao = "";
    this.dt_cumprimento = "";

    this.addTask = false;
  }

  mounted() {
    this.$store.dispatch("department/responsible/getAll");
  }

  created() {
    this.ds_liminar = this.props.liminar?.ds_liminar || "";
    this.dt_recebimento = formatDate(this.props.liminar?.dt_recebimento || null);
    this.dt_casacao = formatDate(this.props.liminar?.dt_casacao || "");
    this.dt_cumprimento = formatDate(this.props.liminar?.dt_cumprimento || null);
    this.dt_comprovacao = formatDate(this.props.liminar?.dt_comprovacao || "");
    this.dt_prazo_cumprimento = formatDate(this.props.liminar?.dt_prazo_cumprimento || null);
    this.cd_processo = this.props.cd_processo;
    this.addTask = Boolean(this.props.liminar?.cd_liminar);
  }
}
</script>

<style lang="scss">
.p-dialog.liminar {
  width: 94vw;
  max-width: 1024px;

  .p-dialog-header {
    padding: 1rem;
  }

  .p-field-checkbox {
    margin-top: 1rem;

    .p-checkbox-box-label {
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;

    .c-form-field-template.ds_liminar .p-inputtextarea {
      height: 180px;
      overflow-y: auto !important;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #0000004d;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #fcfcfc;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #3273dc;
      }
    }

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
