<template>
  <div class="c-text-view">
    <c-dashboard-section-template v-for="(item, index) of props.items" :key="index">
      <template #title>{{ item.title }}</template>
      <template #content-container>
        <c-dashboard-desc-template :content="item.content" />
      </template>
    </c-dashboard-section-template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CDashboardDescTemplate from "@/components/dashboard/desc.vue";
import CDashboardSectionTemplate from "@/components/dashboard/section.vue";

export type TextViewItem = { title: string; content: string };

@Component({
  components: { CDashboardDescTemplate, CDashboardSectionTemplate },
})
export default class CMoveModal extends Vue {
  @Prop({ required: true }) readonly props!: { items: TextViewItem[] };
}
</script>

<style lang="scss">
.p-dialog.text-view {
  width: 80vw;
}
</style>
