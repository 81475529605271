export const emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function validateEmail(email: string) {
  if (email.match(emailFormat)) {
    return true;
  }

  return false;
}

// FIXME: Add more validation
export function validateCPF(cpf: string) {
  if (!cpf) return false;
  return true;
}

export function validateCNPJ(cnpj: string) {
  if (!cnpj) return false;

  const validTypes = typeof cnpj === "string" || Number.isInteger(cnpj) || Array.isArray(cnpj);

  if (!validTypes) return false;

  const r = cnpj.toString().match(/\d/g) || [];
  const numbers = r.map(Number);

  if (numbers.length !== 14) return false;

  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  function calc(x: number) {
    const slice = numbers.slice(0, x);

    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;

      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  }

  const digits = numbers.slice(12);

  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  return calc(13) === digits[1];
}

export function isObject(item: any): boolean {
  return typeof item === "function" || (typeof item === "object" && !!item);
}

export function isTaskCoordinator(permissions: string[]): boolean {
  return permissions.includes("/v1/tarefas/coordenador/:::get");
}

export function hasLegalModule(permissions: string[]): boolean {
  return permissions.includes("menu/juridico:::get");
}

export function hasDebtorModule(permissions: string[]): boolean {
  return permissions.includes("menu/cobranca:::get");
}

export function compareDates(date1: string, date2?: string) {
  if (!date2) date2 = new Date().toISOString();

  try {
    if (!date1.includes("T") && date2.includes("T")) date2 = date2.split("T")[0];
  } finally {
    const date1Ob = new Date(date1.includes("T") ? date1 : date1.replaceAll("-", "/"));
    const date2Ob = new Date(date2.includes("T") ? date2 : date2.replaceAll("-", "/"));

    return date1Ob >= date2Ob;
  }
}

export function isDateValid(date: any) {
  if (typeof date === "string") {
    if (date.includes("/")) {
      date = date.split("/").reverse().join("-");
    }

    date = new Date(date);
  }

  if (isNaN(date.getTime())) {
    return false;
  }

  return true;
}
