import { Module, VuexModule } from "vuex-module-decorators";

import WorkplaceModule from "@/store/department/workplace/module";
import OccupationModule from "@/store/department/occupation/module";
import EmployeeModule from "@/store/department/employee/module";
import ResponsibleModule from "./responsible/modulo";

@Module({ namespaced: true })
export class PersonalDepartmentModule extends VuexModule {
  static modules = {
    workplace: WorkplaceModule,
    occupation: OccupationModule,
    employee: EmployeeModule,
    responsible: ResponsibleModule,
  };
}

export default PersonalDepartmentModule;
