import gojus from "@/services/gojus";
import { Court } from "@/store/court/types";

const { VUE_APP_API_BASE_URL } = process.env;

export class CourtService {
  endpoint = "tribunais/";
  cdEndpoint = (cd: number) => `tribunais/${cd}/`;

  async getAll() {
    const { data } = await gojus.get<Court[]>({
      entity: this.endpoint,
      config: { baseAPI: VUE_APP_API_BASE_URL, useToken: false },
    });

    return { data };
  }
}

export default CourtService;
