import gojus from "@/services/gojus";
import { Expedient, ExpedientContent } from "./types";
import { PaginationResponse } from "../types";

const { VUE_APP_API_ANALYTICS_URL, VUE_APP_API_EXPEDIENT_URL } = process.env;

export class ExpedientService {
  endpoint = "notifications";
  cdEndpoint = (cd: number) => `notifications/${cd}`;
  endpointExcel = "expedientes/";
  summaryEndpoint = "notifications/resumo";
  darBaixaEndpoint = (cd: number) => `notifications/${cd}/dar-baixa`;
  contentEndpoint = "notifications/teor/";

  async getReportExcel(filters: any) {
    const { data } = await gojus.get<any>({
      entity: this.endpointExcel,
      config: {
        query: filters,
        baseAPI: VUE_APP_API_ANALYTICS_URL,
      },
    });

    return { data };
  }

  async content(payload: { process: string }) {
    const response = await gojus.get<ExpedientContent[]>({
      entity: this.contentEndpoint,
      config: {
        query: { process_number: payload.process },
        baseAPI: VUE_APP_API_EXPEDIENT_URL,
      },
    });
    return response;
  }

  async getAll(filters: any) {
    const response = await gojus.get<PaginationResponse<Expedient>>({
      entity: this.endpoint,
      config: {
        query: { ...filters },
        baseAPI: VUE_APP_API_EXPEDIENT_URL,
      },
    });
    return response;
  }

  async getSummary(filters?: { [key: string]: any }) {
    const { data } = await gojus.get<any>({
      entity: this.summaryEndpoint,
      config: { query: { ...filters }, baseAPI: VUE_APP_API_EXPEDIENT_URL },
    });

    return data;
  }

  async edit(cd: number, expedient: Expedient) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: expedient,
      config: { baseAPI: VUE_APP_API_EXPEDIENT_URL },
    });

    return data;
  }

  async darBaixa(cd: number) {
    const response = await gojus.patch({
      entity: this.darBaixaEndpoint(cd),
      config: { baseAPI: VUE_APP_API_EXPEDIENT_URL },
    });

    return response;
  }
}
