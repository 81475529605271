export interface MaskFields {
  decimal: string;
  thousands: string;
  prefix: string;
  precision: number;
  masked: boolean;
}

const mask: MaskFields = {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false,
};

export default mask;
