import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Transfer } from "@/store/financial/transfer/types";

import { TransferService } from "@/store/financial/transfer/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class TransferModule extends VuexModule {
  all: Transfer[] = [];
  list: Transfer[] = [];
  current: Partial<Transfer> = {};

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  genericFilters: any = {};

  service = new TransferService();

  @Mutation
  updateCurrent(transfer: Transfer) {
    this.current = transfer;
  }

  @Mutation
  updateTransferList(transfers: Transfer[]) {
    this.list = transfers;
  }

  @Mutation
  updateTransferAll(transfers: Transfer[]) {
    this.all = transfers;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updateGenericFilters(value: any) {
    this.genericFilters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: { cd: number }) {
    try {
      const { data: transfer } = await this.service.get(payload.cd);
      this.context.commit("updateCurrent", transfer);

      return transfer;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getPaginated(payload: { genericFilters: any }) {
    try {
      let filters: any = {};

      if (payload?.genericFilters?.day?.length) {
        filters.days_vencimento = payload.genericFilters.day.join(",");
      }

      if (typeof payload?.genericFilters?.monthAndYear === "object") {
        filters.mes_vencimento = payload.genericFilters.monthAndYear.getMonth() + 1;
        filters.ano_vencimento = payload.genericFilters.monthAndYear.getFullYear();
      }

      if (payload?.genericFilters?.status_pagamento) {
        filters.status_recebimento = payload.genericFilters.status_pagamento;
      }

      if (Object.keys(this.filters).length) {
        filters = {};
      }

      const response = await this.service.getPaginated(
        { page: this.pagination.current, nr_por_pagina: this.pagination.row, paginacao: true },
        { ...this.filters, ...filters }
      );

      this.context.commit("updateTransferList", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const response = await this.service.getAll(this.filters);
      this.context.commit("updateTransferAll", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: any }) {
    try {
      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Transferência cadastrada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: { cd: number; form: any }) {
    try {
      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Transferência editada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(payload: { cd: number }) {
    try {
      const response = await this.service.delete(payload.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Transferência excluída com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default TransferModule;
