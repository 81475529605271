import gojus from "@/services/gojus";

import { Movement, MovementForm, MovementServiceOption } from "@/store/movement/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL, VUE_APP_API_COLLECTION_URL, VUE_APP_API_PROCESS_URL } = process.env;

export class MovementService {
  endpoint = "movimentacoes/";
  cdEndpoint = (cd: number) => `movimentacoes/${cd}/`;
  cdCDEndpoint = (cd: number, cd_owner: number) => `movimentacoes/${cd}/${cd_owner}/`;
  cdExternalEndpoint = `movements`;

  async get(cd: number, service: MovementServiceOption) {
    const response = await gojus.get<Movement>({
      entity: this.cdEndpoint(cd),
      config: {
        baseAPI: service === "juridical" ? VUE_APP_API_JURIDICAL_URL : VUE_APP_API_COLLECTION_URL,
      },
    });

    return response;
  }

  async getAll(cd_key: string, cd_value: number, service: MovementServiceOption, pagination?: PaginationParams) {
    let query: any = {};

    if (service === "external") query.process = cd_value;
    else {
      query[cd_key] = cd_value;
      if (pagination) query = { ...query, ...pagination };
    }

    const response = await gojus.get<PaginationResponse<Movement> | Movement[]>({
      entity:
        service === "juridical"
          ? this.cdEndpoint(cd_value)
          : service === "external"
          ? this.cdExternalEndpoint
          : service === "collection"
          ? this.cdEndpoint(cd_value)
          : this.endpoint,
      config: {
        query,
        baseAPI:
          service === "juridical"
            ? VUE_APP_API_JURIDICAL_URL
            : service === "external"
            ? VUE_APP_API_PROCESS_URL
            : VUE_APP_API_COLLECTION_URL,
      },
    });

    return response;
  }

  async create(movement: MovementForm, service: MovementServiceOption) {
    movement.ie_ativo = true;
    const form = new FormData();

    if (Array.isArray(movement.aq_arquivo)) {
      movement.aq_arquivo = movement.aq_arquivo[0];
    }

    (Object.keys(movement) as Array<keyof typeof movement>).forEach((movementKey) => {
      if (movement[movementKey] !== undefined && movement[movementKey] !== null)
        form.append(movementKey, movement[movementKey]);
    });

    // if (service === "collection") form.delete("aq_arquivo");

    const response = await gojus.post<Movement, FormData>({
      entity: this.endpoint,
      data: form,
      config: {
        baseAPI: service === "juridical" ? VUE_APP_API_JURIDICAL_URL : VUE_APP_API_COLLECTION_URL,
      },
    });

    return response;
  }

  async favorite(cd: number, cd_owner: number, ie_favorito: boolean, service: MovementServiceOption) {
    const response = await gojus.patch({
      entity: this.cdCDEndpoint(cd, cd_owner),
      data: { ie_favorito },
      config: {
        baseAPI: service === "juridical" ? VUE_APP_API_JURIDICAL_URL : VUE_APP_API_COLLECTION_URL,
      },
    });

    return response;
  }

  async cancel(cd: number, cd_owner: number, service: MovementServiceOption) {
    const response = await gojus.patch({
      entity: this.cdCDEndpoint(cd, cd_owner),
      data: { ie_ativo: false },
      config: {
        baseAPI: service === "juridical" ? VUE_APP_API_JURIDICAL_URL : VUE_APP_API_COLLECTION_URL,
      },
    });

    return response;
  }
}

export default MovementService;
