<template>
  <c-form-template class="c-acttype-form" :useFooter="false" @submit="$emit('submit')">
    <c-form-field-template width="100%">
      <template #label>Nome</template>
      <p-input-text v-model="nm_tipo_ato" required autofocus />
    </c-form-field-template>

    <p-select-button
      v-model="ie_tipo_ato"
      :class="{ disabled: !isEditable }"
      :options="actTypes"
      optionLabel="label"
      optionValue="value"
      title="Essa opção não pode ser editada!"
      :disabled="!isEditable"
      @input="ie_tipo_ato = $event || 'AU'"
    />
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import { LogisticsActTypeForm } from "@/store/logistics/acttype/types";
import { actTypes } from "@/utils/acronyms";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CInputTextModal extends Vue implements LogisticsActTypeForm {
  @Prop({ required: true }) readonly props!: any;
  nm_tipo_ato = "";
  ie_tipo_ato: any = "AU";

  isEditable = false;
  actTypes = actTypes;

  getFields(): LogisticsActTypeForm {
    return { nm_tipo_ato: this.nm_tipo_ato, ie_tipo_ato: this.ie_tipo_ato };
  }

  reset() {}

  created() {
    this.nm_tipo_ato = this.props?.acttype?.nm_tipo_ato || "";
    this.ie_tipo_ato = this.props?.acttype?.ie_tipo_ato || "AU";

    this.isEditable = this.props?.acttype?.ie_tipo_ato;
  }
}
</script>

<style lang="scss">
.c-acttype-form {
  min-width: 360px;

  .p-selectbutton {
    text-align: center;
    margin-top: 0.5rem;

    &.disabled {
      filter: opacity(80%);
      pointer-events: none;
    }
  }
}
</style>
