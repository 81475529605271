import { Action, Module, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";
import CustomerModule from "@/store/collection/customer/module";

const { VUE_APP_API_ANALYTICS_URL } = process.env;
const endpoints = { dashboardCard: (endpoint: string) => `cobranca/devedor/${endpoint}` };

@Module({ namespaced: true })
export class CollectionModule extends VuexModule {
  static modules = {
    customer: CustomerModule,
  };

  @Action
  async getDashboardCard(payload: { card: string; filters?: any }) {
    try {
      const response = await gojus.get<{ value: number }>({
        entity: endpoints.dashboardCard(payload.card),
        config: { query: { ...(payload.filters || {}) }, baseAPI: VUE_APP_API_ANALYTICS_URL },
      });

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }
}

export default CollectionModule;
