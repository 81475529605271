import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Debtor, DebtorParams } from "@/store/collection/debtor/types";

import { DebtorService } from "@/store/collection/debtor/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class DebtorModule extends VuexModule {
  current: Debtor = { id: 0 };
  all: Debtor[] = [];
  summary = {
    parcelas: 0,
    sem_debito: 0,
    duas_parcelas: 0,
    tres_quatro_parcelas: 0,
    cinco_plus_parcelas: 0,
    em_aberto: 0,
    estrategico: 0,
    negociacao: 0,
    sem_interesse: 0,
    inviabilidade: 0,
    sem_contato: 0,
    suspenso: 0,
    encerrado: 0,
  };
  summaryFilter = "";

  sem_movimentacao_em_um_espaco_de_tempo = 0;

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  subtitleFilter: { [key: string]: any } = {
    ie_possui_acordo: "",
    resumo: "",
  };

  service = new DebtorService();
  dateUpdate: {
    dt_movimentacao_inicial: string;
    dt_movimentacao_final: string;
    filtro_dt_movimentacao: string;
    verificado: boolean | "null";
  } | null = null;
  isUpdate = false;
  hasFilters = false;
  permissions = {
    deleteDebtor: false,
  };

  @Mutation
  changeUpdate(params: {
    date: {
      dt_movimentacao_inicial: string;
      dt_movimentacao_final: string;
      filtro_dt_movimentacao: string;
      verificado: boolean | "null";
    } | null;
    value: boolean;
  }) {
    this.all = [];
    this.dateUpdate = params.date;
    this.isUpdate = params.value;
  }

  @Mutation
  updateHasFilters(value: boolean) {
    this.hasFilters = value;
  }

  @Mutation
  updateCurrent(debtor: Debtor) {
    this.current = debtor;
  }

  @Mutation
  updateDebtors(debtors: Debtor[]) {
    this.all = debtors;
  }

  @Mutation
  updatePermissions(permissions: string[]) {
    permissions.forEach((permission: string) => {
      if (permission === "/v1/devedores/id/:::delete") this.permissions.deleteDebtor = true;
    });
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = { ...this.subtitleFilter, ...(value || {}) };
    if (this.summaryFilter) this.filters.resumo = this.summaryFilter;

    this.filters.resumo = this.subtitleFilter.reusmo || value.resumo;

    if (value?.ie_possui_acordo === undefined || value?.ie_possui_acordo === null)
      this.subtitleFilter.ie_possui_acordo = "";
    else if (value.ie_possui_acordo) this.subtitleFilter.ie_possui_acordo = "True";
    else this.subtitleFilter.ie_possui_acordo = "False";

    this.pagination.current = 1;
  }

  @Mutation
  updateSummaryFilter(params: { value: any }) {
    this.summaryFilter = params.value;
    this.filters.resumo = params.value;
    this.subtitleFilter.resumo = params.value;
  }

  @Mutation
  updateSubtitleFilter(subtitle: { key: string; value: any }) {
    this.subtitleFilter[subtitle?.key] = subtitle.value;
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Mutation
  updateSummary(debtorSummary: any) {
    for (const key in debtorSummary) {
      const newKey = key.replaceAll("-", "_").toLocaleLowerCase();
      debtorSummary[newKey] = debtorSummary[key];

      delete debtorSummary[key];
    }

    this.summary = debtorSummary;
  }

  @Mutation
  updateUpdateless(updateless: any) {
    this.sem_movimentacao_em_um_espaco_de_tempo = updateless["SEM-MOVIMENTACAO-EM-UM-ESPACO-DE-TEMPO"];
  }

  @Action
  async get(params: DebtorParams) {
    try {
      const { data } = await this.service.get(params.cd);

      if (data.endereco_set?.length)
        data.ob_cidade = { ...data.endereco_set[0], id: Number(data.endereco_set[0]?.cd_cidade) || 0 };
      else data.ob_cidade = { id: 0 };

      if (data.cd_cliente) data.ob_cliente = data.cd_cliente;
      else data.cd_cliente = { id: 0 };

      if (data.cd_responsavel) {
        const response: any = await this.context.dispatch(
          "department/employee/search",
          { cd_authuser: data.cd_responsavel },
          { root: true }
        );
        data.ob_responsavel = response?.data?.results?.[0] || { id: 0 };
      } else data.ob_responsavel = { id: 0 };

      if (data.cd_status && Number(data.cd_status))
        data.ob_status = (await this.service.getStatusCD(data.cd_status)).data;
      if (data.cd_classificacao && Number(data.cd_classificacao))
        data.ob_classificacao = (await this.service.getRating(data.cd_classificacao)).data;

      if (data.nr_processo) {
        const response = await this.context.dispatch(
          "lawsuit/getWithNumber",
          { nr_processo: data.nr_processo, disabledCommit: true },
          { root: true }
        );
        data.ob_lawsuit = response?.lawsuit || {};
      }

      this.context.commit("updateCurrent", data);

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async search(params: DebtorParams) {
    try {
      const { data } = await this.service.get(params.cd);
      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getReportExcel(params: any) {
    const acordo =
      (params?.filters?.ie_possui_acordo || this.subtitleFilter.ie_possui_acordo) === "False" ? "SEM" : "COM";

    const response = this.isUpdate
      ? await this.service.getReportMovementExcel(this.dateUpdate)
      : await this.service.getReportExcel(null, {
          ...this.filters,
          ...this.subtitleFilter,
          resumo: this.subtitleFilter.resumo || this.filters.resumo,
          ...params?.filters,
          acordo,
        });

    return response;
  }

  @Action
  async getAll(params: any) {
    try {
      const response = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        {
          ...this.filters,
          ...this.subtitleFilter,
          resumo: this.subtitleFilter.resumo || this.filters.resumo,
          ...params?.filters,
        }
      );

      const responseData = response?.data || {};
      const debtorList = responseData.results || [];
      const responsibleList = await (async () => {
        if (!params.getResponsible) return [];

        const responsibleIds = [
          ...new Set(debtorList.map((debtor) => Number(debtor.cd_responsavel)).filter((_id) => _id)),
        ];
        const employeeList = await (async () => {
          const list: any[] = [];

          for (const cd_authuser of responsibleIds) {
            try {
              const response = await this.context.dispatch(
                "department/employee/search",
                { cd_authuser },
                { root: true }
              );
              if (response?.data?.results?.[0]) list.push(response.data.results[0]);
            } catch (error) {
              if (process.env.NODE_ENV === "development") console.error(error);
            }
          }

          return list;
        })();

        return employeeList;
      })();

      for (const debtor of debtorList) {
        if (debtor.endereco_set?.length) debtor.ob_cidade = debtor.endereco_set[0];
        else debtor.ob_cidade = { id: 0 };

        if (debtor.cd_cliente) debtor.ob_cliente = debtor.cd_cliente;
        else debtor.cd_cliente = { id: 0 };

        debtor.ob_responsavel = responsibleList.find(
          (responsible) => responsible.cd_authuser === debtor.cd_responsavel
        ) || { id: 0 };
      }

      this.context.commit("updateDebtors", debtorList);
      this.context.commit("updatePaginationTotal", responseData.count);
      if ((params?.filters.ie_possui_acordo || this.subtitleFilter.ie_possui_acordo) === "False") {
        await this.context.dispatch("getSummary", { acordo: "SEM" });
        await this.context.dispatch("getUpdateless", { acordo: "SEM" });
      } else {
        await this.context.dispatch("getSummary", { acordo: "COM" });
        await this.context.dispatch("getUpdateless", { acordo: "COM" });
      }

      return responseData;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getUpdates(params: any) {
    try {
      this.context.commit("updateDebtors", []);
      const { data } = await this.service.getUpdates({ ...this.dateUpdate });

      for (const debtor of data) {
        if (debtor.endereco_set?.length) debtor.ob_cidade = debtor.endereco_set[0];
        else debtor.ob_cidade = { id: 0 };

        if (debtor.cd_cliente) debtor.ob_cliente = debtor.cd_cliente;
        else debtor.cd_cliente = { id: 0 };
      }

      for (const debtor of data) {
        debtor.movimentacao = { id: 0 };

        for (const move of debtor?.movimentacoes || []) {
          if (move.id > debtor.movimentacao.id) {
            debtor.id_move = `d${debtor.id}m${move.id}`;
            debtor.movimentacao = move;
          }
        }
      }

      this.context.commit("updateDebtors", data);
      this.context.commit("updatePaginationTotal", data.length);
      if ((params?.filters.ie_possui_acordo || this.subtitleFilter.ie_possui_acordo) === "True")
        await this.context.dispatch("getSummary", { acordo: "COM" });
      else await this.context.dispatch("getSummary", { acordo: "SEM" });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: DebtorParams) {
    try {
      const { data } = await this.service.create(params.form);
      this.context.commit("updateCurrent", data);

      const message: ToastParams[] = [{ severity: "success", summary: "Devedor cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: DebtorParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.form);
      this.context.commit("updateCurrent", data);

      const message: ToastParams[] = [{ severity: "success", summary: "Devedor editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createHolder(params: any) {
    try {
      params.formHolder.cd_devedor_substituido = params.cd_devedor;
      const { data }: any = await this.service.createHolder(params.formHolder);

      const message: ToastParams[] = [{ severity: "success", summary: "Titular cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async strategic(params: { cd: number; ie_estrategico: boolean }) {
    try {
      const { data } = await this.service.strategic(params.cd, { ie_estrategico: params.ie_estrategico });
      this.context.commit("updateCurrent", data);

      const message: ToastParams[] = [{ severity: "success", summary: "Devedor editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: DebtorParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Devedor excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getSummary(filter: any) {
    try {
      const response = await this.service.getSummary(filter);
      this.context.commit("updateSummary", response);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getUpdateless(filters?: any) {
    try {
      const { data } = await this.service.getUpdateless({ ...filters, resumo: this.summaryFilter });
      this.context.commit("updateUpdateless", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default DebtorModule;
