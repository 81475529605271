import ExcellentExport from "excellentexport";
import { formatDate } from "@/utils/formatters";

export function handleExportXLSX(
  headers: string[],
  content: (string | number | null | undefined)[][],
  fileName = "export"
) {
  const date = new Date().toDateString();
  const time = new Date().toTimeString().slice(0, 5);

  const name = `${fileName}-${formatDate(date).replaceAll("/", "_")}-${time.replaceAll(":", "_")}`;

  const link = document.createElement("a");
  link.download = name;

  const options = { anchor: link, format: "xlsx" as any, filename: name };
  const sheets = [{ name: name, from: { array: [headers, ...content], arrayHasHeader: true } }];

  ExcellentExport.convert(options, sheets);
  link.click();
}
