<template>
  <div class="c-modal-kanban">
    <header class="options">
      <p-button
        class="p-button-warning option"
        :disabled="loading.items"
        icon="fas fa-print"
        label="Imprimir"
        @click="onClickPrint"
      />

      <div class="filters">
        <c-form-field-template class="option total-items" :useLabel="false">
          <p-input-number
            v-model.number="selectedItems"
            class="p-input-number"
            mode="decimal"
            showButtons
            :min="1"
            :max="totalItems"
          />
          <p-slider v-model="selectedItems" :min="1" :max="totalItems" :disabled="!totalItems" />
        </c-form-field-template>

        <p-button
          class="option apply p-button-success"
          label="Aplicar"
          :icon="loading.items ? 'pi pi-spin pi-spinner' : 'fas fa-check'"
          :disabled="loading.items"
          @click="onClickApplyOptions"
        />
      </div>

      <p-button class="p-button-danger option" icon="fas fa-times-circle" label="Cancelar" @click="onClickCancel" />
    </header>

    <main class="content" ref="content">
      <ul class="list">
        <li class="item" v-for="item of items" :key="item.id">
          <div class="field id">
            <span class="label">ID: </span>
            <span class="value" v-text="item.id"></span>
          </div>

          <div class="field data" v-if="props.is == 'diligence'">
            <span class="label">Prazo: </span>
            <span class="value" v-text="formatDate(item.dt_prazo)"></span>
          </div>

          <div class="field data" v-else>
            <span class="label">Data: </span>
            <span class="value" v-text="formatDateTime(item)"></span>
          </div>

          <div class="field workflow">
            <span class="label">Workflow: </span>
            <span class="value" v-text="item.nr_workflow"></span>
          </div>

          <div class="field cliente">
            <span class="label">Cliente: </span>
            <span class="value" v-text="item.ob_cliente ? limitCh(item.ob_cliente.nm_cliente) : ''"></span>
          </div>

          <div class="field lawsuit">
            <span class="label">Processo: </span>
            <span class="value" v-text="item.nr_processo"></span>
          </div>

          <div class="field author">
            <span class="label">Autor: </span>
            <span class="value" v-text="limitCh(item.nm_autor)"></span>
          </div>

          <div class="field comarca">
            <span class="label">Comarca: </span>
            <span class="value" v-text="limitCh(formatComarca(item.ob_cidade))"></span>
          </div>

          <div class="field correspondent">
            <span class="label">Corres: </span>
            <span class="value" v-text="limitCh(getCorrespondent(item))"></span>
          </div>

          <div class="field correspondent" v-if="props.is == 'hearing'">
            <span class="label">Prepos: </span>
            <span class="value" v-text="getPreposto(item)"></span>
          </div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import PSlider from "primevue/slider/Slider.vue";
import PButton from "primevue/button/Button.vue";

import CFormFieldTemplate from "@/components/form/field.vue";

import { Hearing } from "@/store/logistics/hearing/types";
import { Diligence } from "@/store/logistics/diligence/types";
import { formatComarca, formatDateTime, formatDate } from "@/utils/formatters";

@Component({ components: { CFormFieldTemplate, PButton, PSlider } })
export default class CModalKanban extends Vue {
  @Prop({ required: true }) readonly props!: any;
  @Ref() readonly content!: HTMLDivElement;

  loading: { [key: string]: boolean } = { items: false };

  items: Diligence[] & Hearing[] = [];
  totalItems = 0;
  selectedItems = 0;

  get isDiligence() {
    return this.props.is == "diligence";
  }

  get isHearing() {
    return this.props.is == "hearing";
  }

  get formatComarca() {
    return formatComarca;
  }

  get formatDate() {
    return formatDate;
  }

  get formatDateTime() {
    return (item: Diligence & Hearing) => formatDateTime(item.dt_audiencia, item.hr_audiencia);
  }

  get filters() {
    return this.$store.state.diligence.filters;
  }

  getCorrespondent(item: Diligence | Hearing) {
    if (!item.cd_correspondente) return "";
    return item.cd_correspondente[0]?.ob_correspondente?.nm_correspondente || "";
  }

  getPreposto(item: Hearing) {
    if (!item.cd_preposto) return "";
    return item.cd_preposto[0]?.ob_correspondente?.nm_correspondente || "";
  }

  limitCh(text: string) {
    if (text.length > 21) return text.substring(0, 21) + "...";
    return text;
  }

  async onClickApplyOptions() {
    this.loading.items = true;

    const data: {
      results: Diligence[] & Hearing[];
    } = await this.$store.dispatch(`${this.props.is}/getAll`, {
      update: { list: false },
      request: { cd_tipo: false, qtd_anexo: false },
      filters: {
        ...this.filters,
        nr_por_pagina: this.selectedItems,
        page: 1,
        paginacao: true,
      },
    });
    this.items = data.results;

    for (const item of data.results) {
      for (const correspondent of item.cd_correspondente || []) {
        if (correspondent.cd_correspondente)
          correspondent.ob_correspondente = await this.$store.dispatch("correspondent/get", {
            cd: correspondent.cd_correspondente,
          });
        else correspondent.ob_correspondente = { id: 0 };
      }

      for (const correspondent of item.cd_preposto || []) {
        if (correspondent.cd_correspondente)
          correspondent.ob_correspondente = await this.$store.dispatch("correspondent/get", {
            cd: correspondent.cd_correspondente,
          });
        else correspondent.ob_correspondente = { id: 0 };
      }
    }

    this.loading.items = false;
  }

  onClickPrint() {
    const kanbanWindow = window.open("", "PRINT", `height=${window.innerHeight}px,width=${window.innerWidth * 0.7}px`);

    kanbanWindow?.document.write("<html><head><title>Kanban</title>");
    kanbanWindow?.document.write(`
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,800&display=swap");
        * {
          margin: 0;
          padding: 0;

          box-sizing: border-box;
          font-family: "Poppins", sans-serif;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        body {
          font-size: 16px;
        }
        .list {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          border: 1px dashed;
          list-style: none;
        }
        .item {
          border: 1px dashed;
          padding: 3px;

          line-height: 1.1rem;
        }
        .label {
          font-size: 0.8rem;
          font-weight: 600;
        }
        .value {
          font-size: 0.9rem;
          font-weight: 400;
        }
      </style>
    `);
    kanbanWindow?.document.write("</head><body>");
    kanbanWindow?.document.write(this.content.innerHTML);
    kanbanWindow?.document.write("</body></html>");

    kanbanWindow?.document.close(); // necessary for IE >= 10
    kanbanWindow?.focus(); // necessary for IE >= 10*/

    kanbanWindow?.print();
    kanbanWindow?.close();
  }

  onClickCancel() {
    this.$store.commit("global/updateModalStatus", false);
  }

  async getCorrespondents(items: Diligence[] & Hearing[]) {
    for (const item of items) {
      for (const correspondent of item.cd_correspondente || []) {
        this.$set(
          correspondent,
          "ob_correspondente",
          correspondent.cd_correspondente
            ? await this.$store.dispatch("correspondent/get", { cd: correspondent.cd_correspondente })
            : { id: 0 }
        );
      }
    }
  }

  getFields() {
    return {};
  }

  reset() {}

  created() {
    this.items = this.$store.state[this.props.is].all;
    this.totalItems = this.$store.state[this.props.is].pagination.total;
    this.selectedItems = this.totalItems > 10 ? 10 : this.totalItems;
    this.getCorrespondents(this.items);
  }
}
</script>

<style lang="scss">
.c-modal-kanban {
  overflow-y: scroll;
  max-height: 90vh;

  padding: 4px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;

    .option {
      padding: 0.6rem 1rem;

      + .option {
        margin-left: 8px;
      }
    }

    .filters {
      display: flex;
      align-items: flex-start;

      .total-items {
        display: inline-block;
        padding: 0;
        width: 70%;

        .p-input-number {
          .p-inputtext {
            box-shadow: none;
          }
        }

        .p-slider {
          margin-right: 0.4rem;
        }
      }
    }
  }

  .content {
    .list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      border: 1px dashed;

      .item {
        border: 1px dashed;
        padding: 3px;

        line-height: 1.1rem;

        .label {
          font-size: 0.8rem;
          font-weight: 600;
        }

        .value {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
