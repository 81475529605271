import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { TeamService } from "@/store/team/service";

import { Team, TeamParams } from "@/store/team/types";
import { ToastParams } from "../global/types";

@Module({ namespaced: true })
class TeamModule extends VuexModule {
  all: Team[] = [];
  service = new TeamService();

  @Mutation
  updateTeam() {}

  @Mutation
  updateTeams(teams: Team[]) {
    this.all = teams;
  }

  @Action
  async get(params: { cd: number }) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateTeam", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll() {
    try {
      const { data } = await this.service.getAll();
      this.context.commit("updateTeams", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: TeamParams) {
    try {
      const { data } = await this.service.create(params.team);
      this.context.commit("updateTeam");

      const message: ToastParams[] = [{ severity: "success", summary: "Equipe cadastrada" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: TeamParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.team);
      this.context.commit("updateTeam");

      const message: ToastParams[] = [{ severity: "success", summary: "Equipe editada" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: TeamParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateTeam");

      const message: ToastParams[] = [{ severity: "success", summary: "Equipe excluída" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default TeamModule;
