import { Component, Vue } from "vue-property-decorator";

import { ModalParams } from "@/store/global/types";
import { LawsuitStage, LawsuitStageForm } from "@/store/lawsuit/types";

@Component
export class Options extends Vue {
  stage!: LawsuitStage;
  context!: string;

  goToDetails(stage: LawsuitStage) {
    this.$router.push({
      name: "fase-details",
      params: { cd: stage.id.toString() },
    });
  }

  onAskingToCreateStage() {
    const params: ModalParams = {
      content: { title: "Cadastrar Fase" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: (fields: { field: string }) => this.handleCreateStage({ nm_fase: fields.field }),
      },
      props: { label: "Nome da Fase", isUppercase: true },
      options: { modal: "input-text" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  async handleCreateStage(form: LawsuitStageForm) {
    await this.$store.dispatch("lawsuit/createStage", { form });

    if (this.context === "lawsuit-form") this.getStageList();
    else this.getComponentData();
  }

  onAskingToEdit(stage: LawsuitStage) {
    const params: ModalParams = {
      content: { title: `Editar fase #${stage.id}` },
      accept: {
        function: (fields: { field: string }) => this.handleEdit(stage.id, { nm_fase: fields.field }),
      },
      props: { label: "Nome da Fase", field: stage.nm_fase, isUppercase: true },
      options: { modal: "input-text" },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleEdit(cd: number, form: LawsuitStageForm) {
    const stage = await this.$store.dispatch("lawsuit/editStage", { cd, form });

    if (this.context === "list") this.getListData();
    if (this.context === "details") this.stage = stage;
  }

  onAskingToDelete(stage: LawsuitStage) {
    const params: ModalParams = {
      content: {
        title: `Deletar fase #${stage.id}`,
        text: "Essa ação não pode ser desfeita!",
      },
      accept: { function: () => this.handleDelete(stage.id) },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async handleDelete(cd: number) {
    await this.$store.dispatch("lawsuit/deleteStage", { cd });

    if (this.context === "list") this.getListData();
    if (this.context === "details") this.$router.replace({ name: "fase" });
  }

  getStageList() {}
  getListData() {}
  getComponentData() {}
}

@Component
export class ListMenu extends Options {
  getOptions(stage: LawsuitStage) {
    return [
      {
        label: "Visualizar",
        icon: "fas fa-info-circle",
        command: () => this.goToDetails(stage),
      },
      {
        label: "Editar",
        icon: "fas fa-edit",
        command: () => this.onAskingToEdit(stage),
      },
      {
        label: "Deletar",
        icon: "fas fa-trash-alt",
        command: () => this.onAskingToDelete(stage),
      },
    ];
  }

  onToggle(event: Event, stage: LawsuitStage) {
    const menuItem: any = this.$refs[stage.id];
    menuItem.toggle(event);
  }

  getComponentData() {}
}
