import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Movement, MovementPayload } from "@/store/movement/types";

import { MovementService } from "@/store/movement/service";
import { Pagination, PaginationRow } from "@/store/types";
import { sortMovementByDate } from "@/utils/formatters";

@Module({ namespaced: true })
export class MovementModule extends VuexModule {
  current: Movement = { id: 0 };
  all: Movement[] = [];

  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new MovementService();

  @Mutation
  updateCurrent(movement: Movement) {
    this.current = movement;
  }

  @Mutation
  updateMovements(movements: Movement[]) {
    this.all = movements;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async getAll(payload: MovementPayload) {
    try {
      const cdKey = payload.service === "collection" ? "cd_devedor" : "cd_processo";
      const fileKey = payload.service === "collection" ? "cd_movimentacao_cobranca" : "cd_movimentacao_juridico";

      const response: any = await this.service.getAll(cdKey, payload.cd, payload.service);
      const moves = sortMovementByDate(response?.data || []).map((item) => ({ ...item, service: payload.service }));

      if (payload.disableFiles) {
        return moves;
      }

      await Promise.allSettled(
        moves.map(async (move: any) => {
          const cd_owner: any = {};
          cd_owner[fileKey] = move?.id;

          const fileResponse = await this.context.dispatch(
            "file/getAll",
            { cd_owner, updateList: false },
            { root: true }
          );
          const files = fileResponse?.data || [];

          if (move.aq_arquivo) {
            files.push({
              aq_arquivo: move.aq_arquivo,
              id: 0,
              qtd_clique: 0,
              qtd_download: 0,
            });
          }

          move.aq_arquivo = files[0]?.aq_arquivo || null;
          move.files = files;
        })
      );

      return moves;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async get(payload: MovementPayload) {
    try {
      const { data: movement } = await this.service.get(payload.cd, payload.service);
      this.context.commit("updateCurrent", movement);

      return movement;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: MovementPayload) {
    try {
      const files = payload.form.aq_arquivo;
      delete payload.form.aq_arquivo;

      const response = await this.service.create(payload.form, payload.service);
      this.context.commit("updateCurrent", response.data);

      if (files?.length) {
        await this.context.dispatch("createFile", { cd: response.data.id, service: payload.service, files });
      }

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Movimentação adicionada com sucesso!",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createFile(payload: MovementPayload) {
    try {
      if (payload.service === "collection") {
        const cd_owner = { cd_movimentacao_cobranca: payload.cd };
        const cd_owner_key = "cd_movimentacao_cobranca";
        await this.context.dispatch("file/create", { files: payload.files, cd_owner, cd_owner_key }, { root: true });
      } else {
        const cd_owner = { cd_movimentacao_juridico: payload.cd };
        const cd_owner_key = "cd_movimentacao_juridico";
        await this.context.dispatch("file/create", { files: payload.files, cd_owner, cd_owner_key }, { root: true });
      }
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async favorite(payload: MovementPayload) {
    try {
      const response = await this.service.favorite(payload.cd, payload.cd_owner, payload.ie_favorito, payload.service);
      this.context.commit("updateCurrent", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async cancel(payload: MovementPayload) {
    try {
      const response = await this.service.cancel(payload.cd, payload.cd_owner, payload.service);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Movimentação cancelada com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default MovementModule;
