import gojus from "@/services/gojus";

import { FinancialFile, FinancialFileForm } from "@/store/financial/file/types";
const { VUE_APP_API_FINANCIAL_URL } = process.env;

export class FinancialFileService {
  endpoint = "arquivos/";
  cdEndpoint = (cd: number) => `arquivos/${cd}/`;

  async create(form: Omit<FinancialFileForm, "aq_arquivo"> & { aq_arquivo: File }) {
    const formData = new FormData();

    formData.append("aq_arquivo", form.aq_arquivo);
    if (form.cd_pagar) formData.append("cd_pagar", String(form.cd_pagar));
    else if (form.cd_receber) formData.append("cd_receber", String(form.cd_receber));
    else formData.append("cd_transferencia", String(form.cd_transferencia));

    const response = await gojus.post<FinancialFile, any>({
      entity: this.endpoint,
      data: formData,
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_FINANCIAL_URL },
    });

    return response;
  }
}

export default FinancialFileService;
