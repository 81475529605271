import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";

import IntegrationPublicationModule from "@/store/integration/publication/module";
import IntegrationFoundModule from "./found/module";
import IntegrationMovementModule from "./movement/module";
import { IntegrationSystemT } from "./types";
import IntegrationPetitionModule from "./petition/module";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
class IntegrationModule extends VuexModule {
  systems: IntegrationSystemT[] = [];
  attorneys: any[] = [];

  static modules = {
    publication: IntegrationPublicationModule,
    found: IntegrationFoundModule,
    movement: IntegrationMovementModule,
    petition: IntegrationPetitionModule,
  };

  @Mutation
  setAttorneys(attorneys: any[]) {
    this.attorneys = attorneys.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  @Mutation
  setSystems(systems: IntegrationSystemT[]) {
    this.systems = systems;
  }

  @Action
  async getSystems(payload?: { filters?: Record<string, any> }) {
    try {
      const response = await gojus.get<any[]>({
        entity: "/systems",
        config: { query: { ...payload?.filters }, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setSystems", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAttorneys() {
    try {
      const response = await gojus.get<any[]>({
        entity: "/attorney",
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setAttorneys", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createCertificate(payload: { form: any }) {
    try {
      const form = new FormData();
      Object.keys(payload.form).forEach((key) => {
        form.append(key, payload.form[key]);
      });

      const response = await gojus.post({
        entity: "certificate",
        data: form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editCertificate(payload: { id: number; form: any }) {
    try {
      const response = await gojus.patch({
        entity: `certificate/${payload.id}`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteCertificate(payload: { id: number }) {
    try {
      const response = await gojus.delete({
        entity: `certificate/${payload.id}`,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createAttorney(payload: { form: any }) {
    try {
      const response = await gojus.post({
        entity: "/attorney",
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return { response, success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async editAttorney(payload: { attorneyId: number; form: any }) {
    try {
      const response = await gojus.patch({
        entity: `/attorney/${payload.attorneyId}`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return { response, success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async getAttorneyAccount(payload: { attorneyId: number; accountId: number }) {
    try {
      const response = await gojus.get<any>({
        entity: `/attorney/${payload.attorneyId}`,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      const account = response.data?.accounts?.find((item: any) => item.id === payload.accountId);

      return account || null;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createAttorneyAccount(payload: { form: any }) {
    try {
      const response = await gojus.post({
        entity: "/account",
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editAttorneyAccount(payload: { cd: number; form: any }) {
    try {
      const response = await gojus.patch({
        entity: `/account/${payload.cd}`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default IntegrationModule;
