<template>
  <c-form-template class="c-filter-diligence" :isFlex="true" :useFooter="false">
    <c-form-field-template width="50%">
      <template #label>Dias em Atraso</template>
      <div class="p-inputgroup">
        <p-dropdown
          v-model="dias_atraso"
          :options="delayedDays"
          optionLabel="label"
          optionValue="value"
          appendTo="app"
          filter
          emptyFilterMessage="Nenhum resultado"
        />
        <p-button class="p-button-danger" icon="fas fa-trash" @click="dias_atraso = []" />
      </div>
    </c-form-field-template>
    <c-form-field-template width="50%">
      <template #label>Clientes</template>

      <div class="p-inputgroup">
        <p-multi-select
          v-model="cd_cliente"
          dataKey="id"
          :options="customers"
          optionLabel="nm_cliente"
          optionValue="id"
          filter
          appendTo="app"
          emptyFilterMessage="Nenhum resultado"
        />
        <p-button class="p-button-danger" icon="fas fa-trash" @click="cd_cliente = []" />
      </div>
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Correspondentes</template>

      <div class="p-inputgroup">
        <p-multi-select
          v-model="cd_correspondente"
          :options="correspondents"
          optionLabel="nm_correspondente"
          optionValue="id"
          filter
          appendTo="app"
          emptyFilterMessage="Nenhum resultado"
        />
        <p-button class="p-button-danger" icon="fas fa-trash" @click="cd_correspondente = []" />
      </div>
    </c-form-field-template>

    <c-form-field-template width="50%">
      <template #label>Usuários de cadastro</template>

      <div class="p-inputgroup">
        <p-multi-select
          v-model="nm_usuario_cri"
          :options="users"
          filter
          appendTo="app"
          emptyFilterMessage="Nenhum resultado"
        />
        <p-button class="p-button-danger" icon="fas fa-trash" @click="nm_usuario_cri = []" />
      </div>
    </c-form-field-template>

    <c-form-field-template width="50%">
      <template #label>Estados</template>
      <p-multi-select
        v-model="nm_estado"
        dataKey="id"
        :options="states"
        optionLabel="nm_estado"
        optionValue="nm_estado"
        appendTo="app"
        filter
        emptyFilterMessage="Nenhum resultado"
      />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import AutoComplete from "primevue/autocomplete/AutoComplete.vue";
import { Correspondent } from "@/store/correspondent/types";
import { State } from "@/store/location/types";

const PAutoComplete: any = AutoComplete;

@Component({ components: { CFormFieldTemplate, CFormTemplate, PAutoComplete } })
export default class CFilterDiligence extends Vue {
  dias_atraso: string | null = null;
  cd_cliente = [];
  cd_correspondente = [];
  nm_usuario_cri = [];
  nm_estado = [];

  customers = [];
  users = [];

  delayedDays = [
    { label: "1 Dia", value: "1" },
    { label: "2 Dias", value: "2" },
    { label: "De 3 a 5 Dias", value: "3-5" },
    { label: "De 6 a 10 Dias", value: "6-10" },
    { label: "De 11 a 30 Dias", value: "11-30" },
    { label: "Mais de 31 Dias", value: "31+" },
  ];

  get correspondents(): Correspondent[] {
    return this.$store.state.correspondent.all;
  }

  get states(): State[] {
    return this.$store.state.location.states;
  }

  loadFilters() {
    const filters = this.$store.state.diligence.filters;

    this.cd_cliente = filters.cd_cliente || [];
    this.cd_correspondente = filters.cd_correspondente || [];
    this.nm_usuario_cri = filters.nm_usuario_cri || [];
    this.nm_estado = filters.nm_estado;
  }

  getFields() {
    const filters = {
      dias_atraso: this.dias_atraso,

      cd_cliente: this.cd_cliente,
      cd_correspondente: this.cd_correspondente,
      nm_usuario_cri: this.nm_usuario_cri,
      nm_estado: this.nm_estado,
    };

    return filters;
  }

  reset() {
    this.dias_atraso = "";
    this.cd_cliente = [];
    this.cd_correspondente = [];
    this.nm_usuario_cri = [];
    this.nm_estado = [];
  }

  async componentData() {
    this.$store.dispatch("user/getAll");
    this.$store.dispatch("location/getStates");
    this.$store.dispatch("correspondent/getAll");
    const response = await this.$store.state.diligence.service.getFiltersOverdue();
    this.customers = response.clientes;
    this.users = response.usuarios_cri;
  }

  created() {
    this.componentData();
    this.loadFilters();
  }
}
</script>

<style lang="scss" scoped>
.c-filter-diligence {
  width: 80vw;
  max-width: 1080px;
  min-width: 460px;
}
</style>
