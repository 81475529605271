import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/departamento-pessoal/funcoes",
    name: "occupation",
    component: () => import("@/views/department/occupation/list.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/funcoes/adicionar",
    name: "occupation-add",
    component: () => import("@/views/department/occupation/add.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/funcoes/:cd",
    name: "occupation-details",
    component: () => import("@/views/department/occupation/details.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/funcoes/:cd/editar",
    name: "occupation-edit",
    component: () => import("@/views/department/occupation/edit.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/locais-de-trabalho",
    name: "workplace",
    component: () => import("@/views/department/workplace/list.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/locais-de-trabalho/adicionar",
    name: "workplace-add",
    component: () => import("@/views/department/workplace/add.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/locais-de-trabalho/:cd",
    name: "workplace-details",
    component: () => import("@/views/department/workplace/details.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/locais-de-trabalho/:cd/editar",
    name: "workplace-edit",
    component: () => import("@/views/department/workplace/edit.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/colaboradores",
    name: "employee",
    component: () => import("@/views/department/employee/list.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/colaboradores/adicionar",
    name: "employee-add",
    component: () => import("@/views/department/employee/add.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/colaboradores/:cd",
    name: "employee-details",
    component: () => import("@/views/department/employee/details.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/colaboradores/:cd/editar",
    name: "employee-edit",
    component: () => import("@/views/department/employee/edit.vue"),
  },
  {
    path: "/dashboard/departamento-pessoal/equipes",
    name: "groups-responsible",
    component: () => import("@/views/department/responsible/list.vue"),
  },
];

export default routes;
