import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { LawsuitService } from "@/store/lawsuit/service";

import {
  LawsuitArea,
  LawsuitStage,
  LawsuitInstance,
  Lawsuit,
  LawsuitAreaForm,
  LawsuitInstanceForm,
  LawsuitStageForm,
  LawsuitLiminarForm,
  LawsuitPayload,
  LawsuitStrategyForm,
} from "@/store/lawsuit/types";
import { ToastParams } from "@/store/global/types";
import { Pagination, PaginationRow } from "@/store/types";
import CustomerModule from "@/store/lawsuit/customer/module";
import ActionTypeModule from "@/store/lawsuit/action-type/module";
import ActionCauseModule from "@/store/lawsuit/action-cause/module";
import JuridicalFileModule from "@/store/lawsuit/file/module";
import LawsuitPartnerModule from "./partner/module";
import ThesesBankModule from "./theses-bank/module";
import { Employee } from "../department/employee/types";

@Module({ namespaced: true })
class LawsuitModule extends VuexModule {
  static modules = {
    customer: CustomerModule,
    partner: LawsuitPartnerModule,
    actionType: ActionTypeModule,
    actionCause: ActionCauseModule,
    thesesBank: ThesesBankModule,
    file: JuridicalFileModule,
  };

  current: Lawsuit = { id: 0 };
  newLawsuit: Lawsuit | null = null;
  all: Lawsuit[] = [];
  reasonsForProcessClosing: any[] = [];
  areas: LawsuitArea[] = [];
  stages: LawsuitStage[] = [];
  instances: LawsuitInstance[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};
  subtitleFilter: { [key: string]: any } = {
    ie_status: null,
    ie_judicial: null,
  };
  summaryFilter: any = {};

  initialForm: any = {};
  allWithError: Lawsuit[] = [];

  service = new LawsuitService();

  @Mutation
  updateLawsuit(lawsuit: Lawsuit) {
    this.current = lawsuit;
  }

  @Mutation
  updateLawsuitsWithError(lawsuits: Lawsuit[]) {
    this.allWithError = lawsuits;
  }

  @Mutation
  updateNewLawsuit(lawsuit: Lawsuit | null) {
    this.newLawsuit = lawsuit;
  }

  @Mutation
  updateLawsuits(lawsuits: Lawsuit[]) {
    this.all = lawsuits;
  }

  @Mutation
  updateAllReasonForProcessClosing(reasonsForProcessClosing: any[]) {
    this.reasonsForProcessClosing = reasonsForProcessClosing;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updateSubtitleFilter(subtitle: { key: string; value: any }) {
    this.subtitleFilter[subtitle.key] = subtitle.value;
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Mutation
  updateAreas(areas: LawsuitArea[]) {
    this.areas = areas;
  }

  @Mutation
  updateInstances(instances: LawsuitInstance[]) {
    this.instances = instances;
  }

  @Mutation
  updateStages(stages: LawsuitStage[]) {
    this.stages = stages;
  }

  @Mutation
  updateSummaryFilter(summaryFilter: any) {
    this.summaryFilter = summaryFilter;
  }

  @Action
  async get(payload: LawsuitPayload) {
    try {
      const response = await this.service.get(payload.cd);
      const lawsuit = response.data;

      if (lawsuit.cd_cidade) {
        try {
          lawsuit.ob_cidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: lawsuit.cd_cidade },
            { root: true }
          );
        } catch {
          lawsuit.ob_cidade = undefined;
        }
      }
      if (lawsuit.cd_orgao) {
        try {
          lawsuit.ob_orgao = await this.context.dispatch("forum/get", { cd: lawsuit.cd_orgao }, { root: true });
        } catch {
          lawsuit.ob_orgao = undefined;
        }
      }
      if (payload.getEmployee && lawsuit.cd_responsavel_tarefa) {
        try {
          const employeeResponse = await this.context.dispatch(
            "department/employee/search",
            { cd_authuser: lawsuit.cd_responsavel_tarefa },
            { root: true }
          );

          lawsuit.ob_responsavel_tarefa = employeeResponse?.data?.results?.[0] || null;
        } catch {
          lawsuit.ob_responsavel_tarefa = null;
        }
      }

      if (lawsuit.nr_processo) {
        try {
          const debtorResponse = await this.context.rootState.debtor.service.getAll(
            {},
            { nr_processo: lawsuit.nr_processo }
          );
          lawsuit.ob_debtor = debtorResponse?.data?.[0] || null;
        } catch {
          lawsuit.ob_debtor = null;
        }
      }

      if (!payload.disabledCommit) {
        this.context.commit("updateLawsuit", response.data);
      }

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getWithNumber(payload: LawsuitPayload) {
    try {
      const response = await this.service.getWithNumber(payload.nr_processo);
      if (!response.data?.length) return response;

      const lawsuit = response.data.find((item) => item.nr_processo === payload.nr_processo) || null;
      if (!lawsuit?.id) {
        this.context.commit("updateLawsuit", lawsuit);
        return response;
      }

      if (lawsuit.cd_cidade) {
        try {
          lawsuit.ob_cidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: lawsuit.cd_cidade },
            { root: true }
          );
        } catch {
          lawsuit.ob_cidade = undefined;
        }
      }
      if (lawsuit.cd_orgao) {
        try {
          lawsuit.ob_orgao = await this.context.dispatch("forum/get", { cd: lawsuit.cd_orgao }, { root: true });
        } catch {
          lawsuit.ob_orgao = undefined;
        }
      }

      if (payload.getEmployee && lawsuit.cd_responsavel_tarefa) {
        try {
          const employeeResponse = await this.context.dispatch(
            "department/employee/search",
            { cd_authuser: lawsuit.cd_responsavel_tarefa },
            { root: true }
          );
          lawsuit.ob_responsavel_tarefa = employeeResponse?.data?.results?.[0] || null;
        } catch {
          lawsuit.ob_responsavel_tarefa = null;
        }
      }

      if (payload.getDebtor !== false && lawsuit.nr_processo) {
        try {
          const debtorResponse = await this.context.rootState.debtor.service.getAll(
            {},
            { nr_processo: lawsuit.nr_processo }
          );
          lawsuit.ob_debtor = debtorResponse?.data?.[0] || null;
        } catch {
          lawsuit.ob_debtor = null;
        }
      }

      if (!payload.disabledCommit) {
        this.context.commit("updateLawsuit", lawsuit);
      }

      return { ...response, lawsuit };
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  @Action
  async getAll(payload?: { city?: boolean; taskResponsible?: boolean }) {
    try {
      const response = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        this.subtitleFilter["ie_judicial"] || this.subtitleFilter["ie_status"]
          ? { ...this.filters, ...this.subtitleFilter }
          : { ...this.filters }
      );
      const lawsuits = response.data.results || [];

      const employees: Employee[] = [];
      if (payload?.taskResponsible) {
        const cd_authuser = [...new Set(lawsuits.map((lawsuit) => lawsuit.cd_responsavel_tarefa).filter(Boolean))];
        const employeesResponse = await this.context.dispatch(
          "department/employee/searchWithCache",
          { filters: { cd_authuser } },
          { root: true }
        );

        employees.push(...employeesResponse);
      }

      for (const lawsuit of lawsuits) {
        if (lawsuit.cd_cidade && payload?.city !== false) {
          lawsuit.ob_cidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: lawsuit.cd_cidade },
            { root: true }
          );
        }
        if (lawsuit.cd_responsavel_tarefa) {
          lawsuit.ob_responsavel_tarefa = employees.find(
            (employee) => employee?.cd_authuser === lawsuit?.cd_responsavel_tarefa
          );
        }
      }

      this.context.commit("updateLawsuits", lawsuits);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAllWithError() {
    try {
      const response = await this.service.getAll({ page: 1, nr_por_pagina: 10, paginacao: true }, {
        sem_sistema_tramitacao: 1,
        ie_judicial: true,
        ie_status: "AT",
      } as any);
      const lawsuits = response.data.results || [];
      this.context.commit("updateLawsuitsWithError", lawsuits);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAllReasonForProcessClosing() {
    const response = await this.service.getAllReasonForProcessClosing();
    this.context.commit("updateAllReasonForProcessClosing", response.data);

    return response.data;
  }

  @Action
  async createReasonForProcessClosing(params: { form: any }) {
    const response = await this.service.createReasonForProcessClosing(params.form);
    return response;
  }

  @Action
  async responsibleLawsuit(payload: { cd: number }) {
    const response = await this.service.responsibleLawsuit(payload.cd);
    return response.data.qtd_processos_responsavel;
  }

  @Action
  async create(payload: LawsuitPayload) {
    try {
      const response = await this.service.create(payload.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Processo cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      this.context.commit("updateNewLawsuit", response.data);

      const initialForm = this.context.rootState.lawsuit.initialForm;
      if (initialForm.nr_processo === payload.form.nr_processo) {
        this.context.dispatch(
          "integration/found/editFound",
          { id: initialForm.foundId, form: { ie_registrado: true } },
          { root: true }
        );
      }
      this.context.rootState.lawsuit.initialForm = {};

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: { cd: number; form: Lawsuit }) {
    try {
      const response = await this.service.edit(params.cd, params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Processo editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getLawsuitSummaryFilter() {
    try {
      const response = await this.service.getLawsuitSummaryFilter();

      this.context.commit("updateSummaryFilter", response?.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editStrategic(params: { cd: number; ie_estrategico: boolean }) {
    try {
      const response = await this.service.editStrategic(params.cd, params.ie_estrategico);

      const message: ToastParams[] = [{ severity: "success", summary: "Processo editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async end(params: { cd: number; form: any }) {
    try {
      const response = await this.service.end(params.cd, params.form);

      const message: ToastParams[] = [{ severity: "success", summary: "Processo encerrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getLiminar(params: { cd: number }) {
    try {
      const response = await this.service.getLiminar(params.cd);
      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createLiminar(params: { form: LawsuitLiminarForm }) {
    try {
      const response = await this.service.createLiminar(params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Liminar #${response.data.id} ADICIONADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editLiminar(params: { cd_liminar: number; form: LawsuitLiminarForm }) {
    try {
      const response = await this.service.editLiminar(params.form.cd_processo, params.cd_liminar, params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Liminar #${params.cd_liminar} EDITADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteLiminar(params: { cd_processo: number; cd_liminar: number }) {
    try {
      const response = await this.service.deleteLiminar(params.cd_processo, params.cd_liminar);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Liminar #${params.cd_liminar} REMOVIDA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getStrategies(params: { cd: number }) {
    try {
      const response = await this.service.getStrategies(params.cd);
      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createStrategy(params: { form: LawsuitStrategyForm }) {
    try {
      const response = await this.service.createStrategy(params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Estratégia #${response.data.id} ADICIONADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editStrategy(params: { cd_processo: number; cd_estrategia: number; form: { ds_estrategia: string } }) {
    try {
      const response = await this.service.editStrategy(params.cd_processo, params.cd_estrategia, params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Estratégia #${params.cd_estrategia} EDITADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteStrategy(params: { cd_processo: number; cd_estrategia: number }) {
    try {
      const response = await this.service.deleteStrategy(params.cd_processo, params.cd_estrategia);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Estratégia #${params.cd_estrategia} REMOVIDA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getArea(params: { cd: number }) {
    try {
      const { data } = await this.service.getArea(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAreas() {
    try {
      const { data } = await this.service.getAreas();
      this.context.commit("updateAreas", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createArea(params: { form: LawsuitAreaForm }) {
    try {
      const response = await this.service.createArea(params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Área #${response.data.id} CADASTRADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editArea(params: { cd: number; form: LawsuitAreaForm }) {
    try {
      const { data } = await this.service.editArea(params.cd, params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Área #${params.cd} EDITADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteArea(params: { cd: number }) {
    try {
      const response = await this.service.deleteArea(params.cd);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Área #${params.cd} DELETADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getInstance(params: { cd: number }) {
    try {
      const { data } = await this.service.getInstance(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getInstances() {
    try {
      const { data } = await this.service.getInstances();
      this.context.commit("updateInstances", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createInstance(params: { form: LawsuitInstanceForm }) {
    try {
      const response = await this.service.createInstance(params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Instância #${response.data.id} CADASTRADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editInstance(params: { cd: number; form: LawsuitInstanceForm }) {
    try {
      const { data } = await this.service.editInstance(params.cd, params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Instância #${params.cd} EDITADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteInstance(params: { cd: number }) {
    try {
      const response = await this.service.deleteInstance(params.cd);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Instância #${params.cd} DELETADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getStage(params: { cd: number }) {
    try {
      const { data } = await this.service.getStage(params.cd);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getStages() {
    try {
      const { data } = await this.service.getStages();
      this.context.commit("updateStages", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createStage(params: { form: LawsuitStageForm }) {
    try {
      const response = await this.service.createStage(params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Fase #${response.data.id} CADASTRADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editStage(params: { cd: number; form: LawsuitStageForm }) {
    try {
      const { data } = await this.service.editStage(params.cd, params.form);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Fase #${params.cd} EDITADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async deleteStage(params: { cd: number }) {
    try {
      const response = await this.service.deleteStage(params.cd);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: `Fase #${params.cd} DELETADA com sucesso!`,
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default LawsuitModule;
