import router from "@/router";

export function isJoaoAzedo() {
  const isRoute = router.currentRoute.params.user === "joaoazedo";
  let isOffice = false;

  if (process.env.VUE_APP_MODE === "dev") {
    isOffice = localStorage.getItem("CD_EMPRESA") === "19";
  } else if (process.env.VUE_APP_MODE === "prod") {
    isOffice = localStorage.getItem("CD_EMPRESA") === "37";
  }

  return isRoute || isOffice;
}
