import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import CourtService from "@/store/court/service";
import { Court } from "@/store/court/types";

@Module({ namespaced: true })
class CourtModule extends VuexModule {
  all: Court[] = [];
  service = new CourtService();

  @Mutation
  updateCourts(courts: Court[]) {
    this.all = courts;
  }

  @Action
  async getAll() {
    try {
      const { data } = await this.service.getAll();
      this.context.commit("updateCourts", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default CourtModule;
