<template>
  <c-form-template class="c-update-process-stage" :useFooter="false">
    <c-form-field-template width="100%">
      <template #label>Fase do Processo</template>

      <div class="p-inputgroup">
        <p-dropdown
          v-model="cd_fase"
          :options="stages"
          dataKey="id"
          optionLabel="nm_fase"
          optionValue="id"
          ref="fase"
          filter
          filterPlaceholder="Fase do Processo"
        />
        <p-button
          class="p-button-success"
          icon="fas fa-plus"
          title="Cadastrar uma nova Fase"
          @click="onAskingToCreateStage"
        />
      </div>
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import { Options } from "@/views/lawsuit/stage/mixins";
import { Lawsuit, LawsuitStage } from "@/store/lawsuit/types";

@Component({ components: { CFormTemplate, CFormFieldTemplate, PProgressSpinner } })
export default class CModalPauta extends Mixins(Options) {
  @Prop({ required: true }) readonly props!: { lawsuit: Lawsuit };

  loading = { fases: false };
  cd_fase: string | number | null = null;

  get stages(): LawsuitStage[] {
    return this.$store.state.lawsuit.stages;
  }

  getFields() {
    return { cd_fase: this.cd_fase };
  }

  reset() {
    this.cd_fase = null;
  }

  created() {
    this.$store.dispatch("lawsuit/getStages");
    this.cd_fase = this.props.lawsuit.cd_fase?.id || null;
  }
}
</script>

<style lang="scss">
.p-dialog.update-process-stage {
  width: 100%;
  max-width: 480px;

  .p-dialog-footer {
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
