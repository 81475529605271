import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { LogisticsActType, LogisticsActTypeParams } from "@/store/logistics/acttype/types";

import { LogisticsActTypeService } from "@/store/logistics/acttype/service";

@Module({ namespaced: true })
export class LogisticsActTypeModule extends VuexModule {
  all: LogisticsActType[] = [];
  service = new LogisticsActTypeService();

  @Mutation
  updateLogisticsActType() {}

  @Mutation
  updateLogisticsActTypes(actTypes: LogisticsActType[]) {
    this.all = actTypes;
  }

  @Action
  async get(params: LogisticsActTypeParams) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateLogisticsActType");

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params: LogisticsActTypeParams) {
    try {
      const { data } = await this.service.getAll(params?.filters);
      this.context.commit("updateLogisticsActTypes", data);

      return data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  @Action
  async create(params: LogisticsActTypeParams) {
    try {
      const { data } = await this.service.create(params.form);
      this.context.commit("updateLogisticsActType");

      const message: ToastParams[] = [{ severity: "success", summary: "Tipo de ato cadastrado com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: LogisticsActTypeParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.form);
      this.context.commit("updateLogisticsActType");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Tipo de ato editado com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: LogisticsActTypeParams) {
    try {
      const { data } = await this.service.delete(params.cd);
      this.context.commit("updateLogisticsActType");

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Tipo de ato excluído com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default LogisticsActTypeModule;
