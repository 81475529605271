<template>
  <c-form-template class="c-date-time-modal" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template :width="props.useTime === false ? '100%' : '200px'">
      <template #label>{{ props.label.date }}</template>
      <p-calendar
        v-model="date"
        dateFormat="dd/mm/yy"
        :locale="calendarConfig"
        monthNavigator
        yearNavigator
        yearRange="2000:2030"
        required
      />
    </c-form-field-template>

    <c-form-field-template v-if="props.useTime !== false" width="140px">
      <template #label>{{ props.label.time }}</template>
      <p-input-mask v-model="time" mask="99:99" required />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

import { calendarConfig, formatDate, formatDateEn, formatDateTimeEn } from "@/utils/formatters";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CDateTimeModal extends Vue {
  @Prop({ required: true }) readonly props!: {
    label: { date: string; time: string };
    useTime?: boolean;
  };
  calendarConfig = calendarConfig;

  date = formatDate(new Date().toString());
  time = "";

  @Watch("time")
  validateTime(value: string) {
    const unmask = value.replaceAll("_", "0");
    const hr = Number(unmask.substring(0, 2));
    const min = Number(unmask.substring(3, 5));

    if (hr < 0 || hr > 23 || min < 0 || min > 59) this.time = "";
    else this.time = value;
  }

  getFields() {
    return this.props.useTime === false
      ? { date: formatDateEn(this.date) }
      : { dateTime: formatDateTimeEn(this.date, this.time) };
  }

  reset() {
    this.date = "";
    this.time = "";
  }
}
</script>

<style lang="scss">
.p-dialog.date-time {
  .p-dialog-header {
    padding: 1.5rem 1rem;
  }

  .p-dialog-content {
    padding: 0.5rem 1rem 1.5rem;

    .p-calendar {
      .p-datepicker {
        position: absolute !important;
      }
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
